import React, { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { Checkbox, IconButton, InputText, JustSelect, OptionType } from '@just-ai/just-ui';
import { PrompterDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { getInputWidth } from '../../../../Tabs/utils';
import { PrompterInfo } from '../CreateGroupModal';
import { t } from '../../../../../../localization';
import { useAppContext } from '../../../../../../AppContext';

type Props = {
  prompters: PrompterDto[];
  prompterInfo: PrompterInfo;
  setPrompterInfo: Dispatch<SetStateAction<PrompterInfo>>;
};

export const DEFAULT_MAX_RESPONSES = 3;
const MIN_RESPONSES = 1;
const MAX_RESPONSES = 10;

export const PromptersTab = memo(({ prompterInfo, setPrompterInfo, prompters }: Props) => {
  const { getPrompters } = useAppContext();
  const maxResponseCount = prompterInfo.contextPrompterSettings?.maxResponseCount || DEFAULT_MAX_RESPONSES;

  const options: OptionType[] = useMemo(
    () => [
      { label: t('Settings:Prompters:NotUsed'), value: 0 },
      ...prompters.map(({ id, name }) => ({ label: name, value: id })),
    ],
    [prompters]
  );

  const handleChangeContextPrompter = useCallback(
    (value: (string | number)[] | null) => setPrompterInfo({ ...prompterInfo, contextPrompterId: Number(value) || 0 }),
    [prompterInfo, setPrompterInfo]
  );

  const handleChoosePrompter = useCallback(
    (value: boolean, id?: string) => {
      if (!id) {
        return;
      }
      const prompter = prompters.find(prompter => +id === prompter.id);
      if (!prompter) {
        return;
      }
      if (value) {
        setPrompterInfo({ ...prompterInfo, prompters: [...prompterInfo.prompters, prompter] });
      } else {
        setPrompterInfo({ ...prompterInfo, prompters: prompterInfo.prompters.filter(({ id }) => id !== prompter.id) });
      }
    },
    [prompters, setPrompterInfo, prompterInfo]
  );

  const handleChangeMaxResponseCount = useCallback(
    (value: string) => {
      const maxResponseCount = Number(value) <= MIN_RESPONSES ? MIN_RESPONSES : Number(value);
      setPrompterInfo({
        ...prompterInfo,
        contextPrompterSettings: {
          ...prompterInfo.contextPrompterSettings,
          maxResponseCount: maxResponseCount >= MAX_RESPONSES ? MAX_RESPONSES : maxResponseCount,
        },
      });
    },
    [prompterInfo, setPrompterInfo]
  );

  const setMaxResponseCount = (maxResponseCount: number) =>
    setPrompterInfo({
      ...prompterInfo,
      contextPrompterSettings: { ...prompterInfo.contextPrompterSettings, maxResponseCount },
    });

  useEffect(() => {
    getPrompters();
  }, [getPrompters]);

  return (
    <div>
      <h3>{t('Settings:Prompters:ContextPrompter')}</h3>
      <p>
        <small>{t('Settings:Prompters:ContextPrompter:Description')}</small>
      </p>

      <JustSelect
        data-test-id='Prompter.Select.ContextPrompter'
        className='margin-bottom-4x'
        options={options}
        value={prompterInfo.contextPrompterId}
        onChange={handleChangeContextPrompter}
        fullWidth
      />

      {!!prompterInfo.contextPrompterId && (
        <>
          <div className='ModalFieldset'>
            <div>
              <p>{t('Settings:Prompters:MaxResponseCount')}</p>
              <p>
                <small>{t('Settings:Prompters:MaxResponseCount:Description')}</small>
              </p>
            </div>
            <div>
              <div className='flex-row gap-2x'>
                <IconButton
                  size='sm'
                  disabled={maxResponseCount! <= MIN_RESPONSES}
                  outline
                  data-test-id='Prompters.maxResponseCount.decrease'
                  onClick={() => setMaxResponseCount(maxResponseCount - 1)}
                  name='farMinus'
                  className='icon-withBorder'
                />
                <InputText
                  type='number'
                  style={{ width: getInputWidth(10), height: '32px', padding: '0.5rem 0', textAlign: 'center' }}
                  data-test-id='Prompters.Input.MaxResponseCount'
                  value={maxResponseCount.toString()}
                  onChange={handleChangeMaxResponseCount}
                  compact
                />
                <IconButton
                  size='sm'
                  disabled={maxResponseCount! >= MAX_RESPONSES}
                  onClick={() => setMaxResponseCount(maxResponseCount + 1)}
                  name='farPlus'
                  data-test-id='Prompters.maxResponseCount.increase'
                  className='icon-withBorder'
                />
              </div>
            </div>
          </div>
        </>
      )}

      <h3>{t('Settings:Prompters:Helpers')}</h3>
      <p>
        <small>{t('Settings:Prompters:Helpers:Description')}</small>
      </p>

      {prompters.map(({ name, id }) => (
        <Checkbox
          data-test-id={`Prompter.Checkbox.Name__${name}`}
          key={name}
          label={name}
          name={id.toString()}
          checked={prompterInfo.prompters.some(prompter => prompter.id === id)}
          onChange={handleChoosePrompter}
        />
      ))}
    </div>
  );
});
