import { t } from '../../localization';
import { BlockingBanner } from '../BlockingBanner/BlockingBanner';
import { useOperatorDataContext } from '../../modules/OperatorPlace/OperatorDataContext';
import { useAppContext } from '../../AppContext';
import { useEffect } from 'react';

export function PrompterErrorBanner() {
  const { isAdmin } = useAppContext();
  const { contextPrompterNameWithError, clearContextPrompterNameWithError, prompterRequest } = useOperatorDataContext();

  useEffect(() => {
    if (!prompterRequest && contextPrompterNameWithError) {
      clearContextPrompterNameWithError();
    }
  }, [clearContextPrompterNameWithError, prompterRequest, contextPrompterNameWithError]);

  if (!contextPrompterNameWithError) {
    return null;
  }

  return (
    <BlockingBanner
      title={t(
        isAdmin ? 'OperatorPlace:Prompter:Banner:Error:Admin' : 'OperatorPlace:Prompter:Banner:Error:Operator',
        contextPrompterNameWithError
      )}
      withoutBackground
      onClose={clearContextPrompterNameWithError}
    />
  );
}
