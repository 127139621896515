import { InputGroup, InputText } from '@just-ai/just-ui';
import { Dispatch, memo, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useAppContext } from '../../../../../../AppContext';
import { t } from '../../../../../../localization';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';
import { filterOperator } from '../../../../settingsHelpers';
import { OperatorInfo } from '../../../OperatorInfo';

type Props = {
  choosenOperators: number[];
  setChoosenOperators: Dispatch<SetStateAction<number[]>>;
};

export const UsersTab = memo(({ choosenOperators, setChoosenOperators }: Props) => {
  const { operators: unsortedOperators } = useAppContext();
  const isMobile = useIsMobile();
  const [filter, setFilter] = useState('');
  const [operators] = useState(() =>
    [...unsortedOperators].sort((a, b) =>
      !choosenOperators.includes(a.id) && choosenOperators.includes(b.id) ? 1 : -1
    )
  );

  const filteredOperators = useMemo(() => {
    return operators.filter(operator => filterOperator(filter, operator, choosenOperators, true));
  }, [operators, filter, choosenOperators]);

  const setOperators = useCallback(
    (id: number) => {
      setChoosenOperators(oldOperators => {
        if (oldOperators.includes(id)) {
          return oldOperators.filter(value => value !== id);
        } else {
          return [...oldOperators, id];
        }
      });
    },
    [setChoosenOperators]
  );
  return (
    <fieldset className='margin-bottom-6x'>
      <label>{t('Settings:Group group user label')}</label>
      <InputGroup PrependInner={[{ name: 'farSearch' }]}>
        <InputText
          onChange={setFilter}
          data-test-id='Groups.CreateModal.filter'
          blurInactive
          placeholder={t('Settings:Operators: operator search placeholder')}
        />
      </InputGroup>
      <div className='font-size-12 margin-top-3x margin-bottom-3x'>
        {t('Settings:Group operators founded')} {filteredOperators.length}.{' '}
        {t('Settings:Groups: create group selected users')} {choosenOperators.length}
      </div>
      <div
        className='flex-column'
        style={{
          maxHeight: isMobile ? 'calc(100vh - 30.5rem)' : '10rem',
          overflow: 'auto',
          gap: 4,
          marginBottom: 8,
        }}
      >
        {filteredOperators.map(operator => (
          <div key={operator.id} className='OperatorInfo_container'>
            <OperatorInfo operator={operator} onClick={setOperators} choosen={choosenOperators.includes(operator.id)} />
          </div>
        ))}
      </div>
    </fieldset>
  );
});
