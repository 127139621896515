import React from 'react';
import { Header } from './modules/Header';
import { Sidebar } from './modules/Sidebar';
import { Settings } from './modules/Settings';
import { OperatorPlace } from './modules/OperatorPlace';
import { ReconnectionBanner } from './components/ReconnectionBanner/ReconnectionBanner';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import NoAccessPage from './modules/NoAccessPage/NoAccessPage';
import { Statistics } from './modules/Statistics';
import { LimitExceededBanner } from './components/LimitExceededBanner/LimitExceededBanner';
import { PrompterErrorBanner } from './components/PrompterErrorBanner/PrompterErrorBanner';
import { NotificationFailedBanner } from './components/NotificationFailedBanner/NotificationFailedBanner';
import { useAppContext } from './AppContext';

export const AppRouter = ({ isAdmin, hasAccessAimychat }: { isAdmin: boolean; hasAccessAimychat: boolean }) => {
  const { pathname } = useLocation();
  const { isIframeEnabled } = useAppContext();

  if (!hasAccessAimychat) {
    return (
      <>
        <Header hasNoAccess />
        <Routes>
          <Route path='*' element={<NoAccessPage hasAccessAimychat={false} />} />
        </Routes>
      </>
    );
  }

  const isNoAccessForOperator = !isAdmin && pathname.indexOf('/settings') > -1;
  const isSidebarEnabled = isAdmin || isIframeEnabled;

  return (
    <>
      <Header hasNoAccess={isNoAccessForOperator} />
      {isSidebarEnabled && <Sidebar />}

      <main>
        <NotificationFailedBanner />
        <ReconnectionBanner />
        <LimitExceededBanner />
        <PrompterErrorBanner />
        <Routes>
          <Route path='/' element={<Navigate to='/operator/active' replace />} />
          <Route path='/operator/' element={<Navigate to='/operator/active' replace />} />
          <Route path='/operator/' element={<OperatorPlace />}>
            <Route path=':tab' />
          </Route>

          <Route path='/settings/' element={<Navigate to='/settings/groups' replace />} />
          <Route path='/settings/' element={isAdmin ? <Settings /> : <NoAccessPage />}>
            <Route path=':tab' />
          </Route>

          <Route path='/statistics/' element={<Navigate to='/statistics/main' replace />} />
          <Route path='/statistics/' element={isAdmin ? <Statistics /> : <NoAccessPage />}>
            <Route path=':tab' />
          </Route>
          <Route path='*' element={<Navigate to='/login' replace />} />
          <Route path='/c/profile' element={<Navigate to='/c/profile' replace />} />
        </Routes>
      </main>
    </>
  );
};
