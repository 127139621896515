import { memo, useCallback, useMemo } from 'react';
import { FileMessageDto, PrompterMessageType } from '@just-ai/aimychat-shared/dist/api/client/websocket';

export const File = memo(({ file: { url, mimeType } }: { file: FileMessageDto }) => {
  const mediaType = useMemo(
    () =>
      Object.values(PrompterMessageType).reduce(
        (result: PrompterMessageType, type: PrompterMessageType) =>
          mimeType?.includes(type.toLowerCase()) ? type : result,
        PrompterMessageType.FILE
      ),

    [mimeType]
  );

  const download = useCallback(async () => {
    try {
      const image = await fetch(url);
      const imageBlob = await image.blob();
      const imageURL = URL.createObjectURL(imageBlob);

      const link = document.createElement('a');
      link.href = imageURL;
      link.download = url.split('/').pop() || 'file';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
      window.open(url);
    }
  }, [url]);

  if (!url) {
    return null;
  }

  switch (mediaType) {
    case PrompterMessageType.TEXT:
    case PrompterMessageType.FILE:
      return (
        <a className='DialogMessage_link' href={url} download>
          {url}
        </a>
      );
    case PrompterMessageType.IMAGE:
      return <img className='DialogMessage_image' src={url} alt='dialog message pic' onClick={download} />;

    case PrompterMessageType.VIDEO:
      return <video className='DialogMessage_image' src={url} controls />;

    case PrompterMessageType.AUDIO:
      return <audio className='DialogMessage_audio' src={url} controls />;
    default:
      return null;
  }
});
