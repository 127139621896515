export const isDev = () => {
  // @ts-ignore
  return process.env.NODE_ENV === 'development' || window.isDev;
};

export const getUserLanguage = language => {
  const lang = (language || 'ENG').toLowerCase();
  switch (lang) {
    case 'ru': {
      return 'ru';
    }
    case 'pt': {
      return 'pt';
    }
    case 'cn':
    case 'zh': {
      return 'cn';
    }
    default:
      return 'eng';
  }
};
