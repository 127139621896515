import React, { useCallback } from 'react';
import { t } from '../../localization';
import { Spinner, Tabs } from '@just-ai/just-ui';
import './Settings.scss';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';
import { useNavigate, useParams, Route, Routes, Outlet } from 'react-router-dom';
import Groups from './Tabs/Groups';
import Operators from './Tabs/Operators';
import { useAppContext } from '../../AppContext';
import Params from './Tabs/Params';
import NoAccessPage from '../NoAccessPage/NoAccessPage';
import Prompters from './Tabs/Prompters';
import { Frames } from './Tabs/Frames';

enum TABS {
  GROUPS = 'groups',
  OPERATORS = 'operators',
  PARAMS = 'params',
  PROMPTERS = 'prompters',
  FRAMES = 'frames',
}
const isBeta = (tab: TABS) => tab === TABS.FRAMES;

function Settings() {
  const { isAdmin, operatorPlace, isPrompterAccess, isIframeEnabled } = useAppContext();
  const { tab = TABS.GROUPS } = useParams<{ tab: TABS }>();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const onChangeTab = useCallback((value: string) => navigate(`/settings/${value}`, { replace: true }), [navigate]);
  const tabs = Object.values(TABS)
    .filter(tab => (!isIframeEnabled && tab !== TABS.FRAMES) || isIframeEnabled)
    .map(tab => ({
      name: isBeta(tab) ? (
        <span>
          {t(`Settings:Tab:${tab}`)} <small className='beta'>Beta</small>
        </span>
      ) : (
        t(`Settings:Tab:${tab}`)
      ),
      value: tab,
      dataTestId: `Settings.Tab.${tab}`,
    }));

  if (!isAdmin) {
    return (
      <Routes>
        <Route path='*' element={<NoAccessPage />} />
      </Routes>
    );
  }

  return (
    <div className='Settings'>
      <h2>{t('Settings:main header')}</h2>
      <div className='Settings_tabs'>
        <div className='Settings_tabs_navigator'>
          <Tabs
            tabs={tabs.filter(
              ({ value }) => (value === TABS.PROMPTERS && isPrompterAccess) || value !== TABS.PROMPTERS
            )}
            fullWidth={isMobile}
            onChange={onChangeTab}
            activeTab={tab}
          />
        </div>

        <Outlet />

        <Routes>
          <Route path={TABS.GROUPS} element={<Groups />} />
          <Route path={TABS.PARAMS} element={operatorPlace ? <Params /> : <Spinner size='4x' />} />
          <Route path={TABS.OPERATORS} element={<Operators />} />
          {isPrompterAccess && <Route path={TABS.PROMPTERS} element={<Prompters />} />}
          {isIframeEnabled && <Route path={TABS.FRAMES} element={<Frames />} />}
        </Routes>
      </div>
    </div>
  );
}

export default Settings;
