import React, { SyntheticEvent, useCallback } from 'react';
import { OperatorWithGroupsDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { t } from '../../../localization';
import CSS from 'csstype';

interface OperatorInfoInterface {
  operator: OperatorWithGroupsDto;
  choosen?: boolean;
  onClick?: (id: number) => void;
}

export const OperatorInfo = React.memo(({ operator, choosen, onClick }: OperatorInfoInterface) => {
  const iconStyle: CSS.Properties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '40px',
    height: '40px',
  };

  const handleClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onClick && onClick(operator.id);
    },
    [operator.id, onClick]
  );

  const content = (
    <>
      <div data-test-id='OperatorInfo.avatar' style={{ position: 'relative', width: 40, height: 40, flexShrink: 0 }}>
        {operator.photoUrl ? (
          <img style={iconStyle} src={operator.photoUrl} alt='operator' />
        ) : (
          <svg style={iconStyle} width='40' height='40' viewBox='0 0 40 40' fill='none'>
            <circle cx='20' cy='20' r='19.5' fill='#F4F5F5' stroke='#C3C6C9' strokeDasharray='2 2' />
          </svg>
        )}
        {choosen && (
          <svg style={iconStyle} width='40' height='40' viewBox='0 0 40 40' fill='none'>
            <circle cx='20' cy='20' r='20' fill='#9747FF' fillOpacity='0.5' />
            <path
              d='M28.43 11.4128L16.0998 23.743L11.5693 19.2124C11.3496 18.9928 10.9935 18.9928 10.7738 19.2124L9.44795 20.5382C9.22829 20.7579 9.22829 21.114 9.44795 21.3337L15.7021 27.5879C15.9217 27.8075 16.2779 27.8075 16.4976 27.5879L30.5513 13.5341C30.771 13.3145 30.771 12.9583 30.5513 12.7386L29.2255 11.4128C29.0058 11.1931 28.6497 11.1931 28.43 11.4128Z'
              fill='white'
            />
          </svg>
        )}
      </div>
      <div className='flex-column gap-4'>
        <span data-test-id='OperatorInfo.fullName' className='font-color-bright operator_name '>
          {operator.firstName} {operator.lastName}
        </span>
        <span className='font-color-light-gray font-size-12'>
          {operator.role && (
            <span data-test-id='OperatorInfo.role' className='font-color-normal margin-right-2x'>
              {t(`Settings:Operator operator role ${operator.role}`)}
            </span>
          )}
          {operator.login}
        </span>
      </div>
    </>
  );
  return onClick ? (
    <button
      type='button'
      data-test-id='OperatorInfo.chooseButton'
      style={{ textAlign: 'left', flexShrink: 0, width: '100%' }}
      className='flex-row gap-16 reset-button '
      onClick={handleClick}
    >
      {content}
    </button>
  ) : (
    <div data-test-id='OperatorInfo.container' style={{ flexShrink: 0 }} className='flex-row gap-16'>
      {content}
    </div>
  );
});
