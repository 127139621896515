import React, { Dispatch, memo, SetStateAction, useCallback, useMemo } from 'react';
import { InputText, JustSelect, OptionType } from '@just-ai/just-ui';
import { OperatorGroupFullDto, OperatorWithGroupsDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { t } from '../../../../../../localization';
import { useAppContext } from '../../../../../../AppContext';
import { OperatorInfo } from '../../../OperatorInfo';
import { LabelComponentType } from '@just-ai/just-ui/dist/JustSelect/JustSelectItem';

type Props = {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  mainOperatorId?: number;
  setMainOperatorId: (id?: number) => void;
  groupToEdit?: OperatorGroupFullDto;
  errorText: string;
  wasFilled: boolean;
  setWasFilled: Dispatch<SetStateAction<boolean>>;
};

export const MainTab = memo(
  ({ name, setName, mainOperatorId, setMainOperatorId, groupToEdit, errorText, wasFilled, setWasFilled }: Props) => {
    const { operators: rawOperators, groups } = useAppContext();

    const handleChangeName = useCallback(
      (value: string) => {
        setName(value);
        if (!wasFilled) {
          setWasFilled(true);
        }
      },
      [setName, setWasFilled, wasFilled]
    );

    const operators: OperatorWithGroupsDto[] = useMemo(() => {
      const noMainOperator: OperatorWithGroupsDto = {
        id: -1,
        firstName: t('Settings:Group:NoMainOperator'),
      } as OperatorWithGroupsDto;
      return [noMainOperator, ...rawOperators];
    }, [rawOperators]);

    const LabelComponent: LabelComponentType = useCallback(
      ({ value }) => {
        const operator = operators.find(operator => operator.id === value);
        const choosen = value === mainOperatorId || (value === -1 && mainOperatorId === undefined);
        return <OperatorInfo operator={operator!} choosen={choosen} />;
      },
      [mainOperatorId, operators]
    );

    const options = useMemo(() => {
      const mainOperatorsIds = groups
        .map(({ mainOperator }) => {
          if (mainOperator?.id && mainOperator?.id !== groupToEdit?.mainOperator?.id) {
            return mainOperator?.id;
          }
        })
        .filter(Boolean);

      return operators
        .map(operator => {
          if (mainOperatorsIds.includes(operator?.id)) {
            return null;
          }

          const operatorInGroup = operator.groups?.some(({ id }) => id === groupToEdit?.id);
          if (operatorInGroup || operator.id === -1) {
            return {
              value: operator.id,
              label: `${operator?.firstName || ''} ${operator?.lastName || ''}`.trim(),
              LabelComponent: LabelComponent,
            };
          }
        })
        .filter(Boolean);
    }, [LabelComponent, groupToEdit?.id, groupToEdit?.mainOperator?.id, groups, operators]);

    const handleChangeMainOperatorId = useCallback(
      value => {
        const id = Number(value.join(''));
        setMainOperatorId(id === -1 ? undefined : id);
      },
      [setMainOperatorId]
    );

    return (
      <>
        <fieldset className='margin-bottom-6x'>
          <label>
            {t('Settings:Group group name label')}
            <span className='margin-left-1x font-color-danger'>*</span>
          </label>
          <InputText
            errorText={errorText}
            data-test-id='Groups.CreateModal.name'
            placeholder={t('Settings:Group group name placeholder')}
            blurInactive
            defaultValue={name}
            onChange={handleChangeName}
          />
          {!errorText && (
            <span className='font-size-12 font-color-light-gray'>{t('Settings:Group group name description')}</span>
          )}
        </fieldset>
        <fieldset className='margin-bottom-6x'>
          <label>{t('Settings:Group:MainOperator')}</label>
          <JustSelect
            defaultValue={mainOperatorId || -1}
            withoutSearch
            classNameForList='MainTab-SelectList'
            position='fixed'
            data-test-id='Operators.CreateModal.role'
            fullWidth
            options={options as OptionType[]}
            onChange={handleChangeMainOperatorId}
          />
          <span className='font-size-12 font-color-light-gray'>{t('Settings:Group:MainOperator:Description')}</span>
        </fieldset>
      </>
    );
  }
);
