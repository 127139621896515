import React, { memo } from 'react';

type Props = {
  label: string;
  description: string;
  children: React.ReactNode;
};

export const Filedset = memo(({ children, label, description }: Props) => {
  return (
    <fieldset className='flex-column gap-2x'>
      <label className='font-size-14 margin-bottom-0x font-bold'>{label}</label>
      <span className='font-size-12 font-color-light-gray'>{description}</span>
      {children}
    </fieldset>
  );
});
