import { Icon, IconButton } from '@just-ai/just-ui';
import { memo } from 'react';
import { FileMessageDto } from '@just-ai/aimychat-shared/dist/api/client/websocket';
import { t } from '../../../../localization';

import { useOperatorDataContext } from '../../OperatorDataContext';
import './PrompterFooter.scss';
import { PrompterMessage } from './PrompterMessage';

export type PrompterProps = {
  sendMessage: (text?: string, files?: FileMessageDto[]) => void;
};

export const PrompterFooter = memo(({ sendMessage }: PrompterProps) => {
  const {
    contextPrompterResponse,
    messageForPrompter,
    setMessageForPrompter,
    onGetNewPrompterMessage,
    setShowContextPrompter,
  } = useOperatorDataContext();

  if (!messageForPrompter || !contextPrompterResponse?.answerOptions) {
    return null;
  }

  const iconArgs = {
    outline: true,
    name: 'falTimes',
    id: 'close-prompter-button',
    'data-test-id': 'Chat.MessageForm.Promtper.Close.Button',
  };

  if (contextPrompterResponse?.answerOptions?.length) {
    return (
      <div className='PrompterWrapper'>
        <IconButton
          onClick={() => {
            setShowContextPrompter(false);
            setMessageForPrompter();
            onGetNewPrompterMessage({ prompterResponse: undefined, isContextPrompter: true });
          }}
          {...iconArgs}
        />
        <div className='PrompterMessages'>
          {contextPrompterResponse.answerOptions.map(({ messages, intent }) => (
            <PrompterMessage
              key={`prompt_${intent?.id}`}
              messages={messages}
              intent={intent}
              isContextPrompter
              sendMessage={sendMessage}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className='PrompterWrapper NoContent'>
      <div className='PrompterMessages'>
        <div className='Prompter__info'>
          <Icon name='farExclamationCircle' className='margin-right-2x' />
          {t('Prompters:Description:NoPromps')}
        </div>
      </div>
      <IconButton
        onClick={() => {
          setMessageForPrompter();
          onGetNewPrompterMessage({ prompterResponse: undefined, isContextPrompter: true });
        }}
        {...iconArgs}
      />
    </div>
  );
});
