import React, { Dispatch, SetStateAction, memo, useEffect } from 'react';
import { useOperatorDataContext } from './OperatorDataContext';
import './Chat.scss';
import { ChatHeader } from './chatComponents/ChatHeader';
import { Messages } from './chatComponents/Messages';
import { ChatFooter } from './chatComponents/Chatfooter/ChatFooter';
import { SmoothSpinner } from '../../components/SmoothSpinner/SmoothSpinner';
import classNames from 'classnames';

export type AboutProps = {
  setShowAbout?: Dispatch<SetStateAction<boolean>>;
};

const Chat = memo((props: AboutProps) => {
  const { chosenChat, chosenChatId, setMessageForPrompter, onGetNewPrompterMessage, showNotificationFailedBanner } =
    useOperatorDataContext();

  useEffect(() => {
    return () => {
      onGetNewPrompterMessage({ prompterResponse: undefined, isContextPrompter: true });
      setMessageForPrompter();
    };
  }, [onGetNewPrompterMessage, setMessageForPrompter]);

  if (!chosenChatId) {
    return null;
  }

  return (
    <div className={classNames('Chat_container', { Chat_container__with_banner: showNotificationFailedBanner })}>
      <SmoothSpinner size='4x' shown={Boolean(chosenChatId && !chosenChat)} />
      <ChatHeader {...props} />
      <Messages key={`messages_${chosenChat?.id}`} />
      {chosenChat && <ChatFooter key={`footer_${chosenChat?.id}`} />}
    </div>
  );
});

export default Chat;
