import React, { useCallback, useState } from 'react';
import { InputText, Modal, useToggle } from '@just-ai/just-ui';
import { t } from '../../../localization';
import { BanFunction } from '@just-ai/aimychat-shared/dist/operatorPlaceTypes';

export const BanModal = React.memo(
  ({ onClose, onBan, hideModal }: { onClose: () => void; onBan: BanFunction; hideModal: () => void }) => {
    const emptyFieldError = t('RequiredField');
    const [reason, setReason] = useState('');
    const [fetching, setFeching, finishFetching] = useToggle();
    const [messageToClient, setMessageToClient] = useState('');
    const [reasonError, setReasonError] = useState('');
    const [messageError, setMessageError] = useState('');

    const validateFields = useCallback(() => {
      setReasonError(!reason ? emptyFieldError : '');
      setMessageError(!messageToClient ? emptyFieldError : '');
    }, [emptyFieldError, messageToClient, reason]);

    const handleChangeMessage = useCallback(
      value => {
        setMessageToClient(value);
        setMessageError(!value ? emptyFieldError : '');
      },
      [emptyFieldError]
    );

    const handleChangeReason = useCallback(
      value => {
        setReason(value);
        setReasonError(!value ? emptyFieldError : '');
      },
      [emptyFieldError]
    );

    const handleSuccess = useCallback(() => {
      if (reason && messageToClient) {
        setFeching();
        onBan({
          reason,
          message: messageToClient,
          callbacks: {
            success: () => {
              hideModal();
              finishFetching();
            },
          },
        });
      }

      validateFields();
    }, [reason, messageToClient, validateFields, setFeching, onBan, hideModal, finishFetching]);

    return (
      <Modal
        buttonSubmitDisabled={Boolean(messageError || reasonError)}
        onCancelClick={fetching ? undefined : onClose}
        buttonCancelColor='secondary'
        onActionClick={handleSuccess}
        title={t('OperatorPlace: ban modal title')}
        buttonSubmitText={t('OperatorPlace: ban modal confirm button')}
        buttonCancelText={t('Cancel')}
        buttonSubmitColor='danger'
        buttonCancelTestId='OperatorPlace.BanModal.cancel'
        buttonSubmitTestId='OperatorPlace.BanModal.submit'
        naturalScrollbar
        inProgress={fetching}
        disableActionButtonAutoFocus
        isOpen
      >
        <fieldset className='margin-bottom-6x'>
          <label>
            {t('OperatorPlace: ban modal reason')}
            <span className='margin-left-1x font-color-danger'>*</span>
          </label>
          <InputText
            errorText={reasonError}
            data-test-id='OperatorPlace.BanModal.reason'
            placeholder={t('OperatorPlace: ban modal reason placeholder')}
            blurInactive
            defaultValue={reason}
            onChange={handleChangeReason}
          />
        </fieldset>
        <fieldset className='margin-bottom-6x'>
          <label>
            {t('OperatorPlace: ban modal message')}
            <span className='margin-left-1x font-color-danger'>*</span>
          </label>
          <InputText
            errorText={messageError}
            data-test-id='OperatorPlace.BanModal.message'
            placeholder={t('OperatorPlace: ban modal message placeholder')}
            blurInactive
            defaultValue={messageToClient}
            onChange={handleChangeMessage}
          />
        </fieldset>
      </Modal>
    );
  }
);
