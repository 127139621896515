import React from 'react';
import './Sidebar.scss';
import Links from '../../components/Links/Links';
import { IFrames } from './IFrames';
import { useAppContext } from '../../AppContext';

export function Sidebar() {
  const { isIframeEnabled, isAdmin } = useAppContext();
  return (
    <aside className='Sidebar'>
      <div>
        <Links short isAdmin={isAdmin} isIframeEnabled={isIframeEnabled} />
      </div>

      {isIframeEnabled && <IFrames />}
    </aside>
  );
}
