import { memo, useCallback, useState } from 'react';
import { Checkbox, InputText, Modal } from '@just-ai/just-ui';

import { useAppContext } from '../../../../AppContext';
import { t } from '../../../../localization';
import { Errors, ModalProps } from '../../Tabs/Prompters';

export const PrompterEditModal = memo(({ selectedPrompter, setSelectedPrompter, setOpenedModal }: ModalProps) => {
  const { getPrompters, prompters, prompterApiService } = useAppContext();
  const [nameError, setNameError] = useState();
  const [urlError, setUrlError] = useState();

  const handleChangePrompterName = useCallback(
    (name: string) => {
      if (selectedPrompter) {
        setSelectedPrompter({ ...selectedPrompter, name });
      }
    },
    [selectedPrompter, setSelectedPrompter]
  );
  const handleChangePrompterUrl = useCallback(
    (url: string) => {
      if (selectedPrompter) {
        setSelectedPrompter({ ...selectedPrompter, url });
      }
    },
    [selectedPrompter, setSelectedPrompter]
  );
  const handleChangeSendStart = useCallback(
    (enabledSendStart: boolean) => {
      if (selectedPrompter) {
        setSelectedPrompter({ ...selectedPrompter, enabledSendStart });
      }
    },
    [selectedPrompter, setSelectedPrompter]
  );

  const editPrompter = useCallback(async () => {
    setNameError(undefined);
    setUrlError(undefined);
    if (!selectedPrompter) {
      return;
    }

    const isUniqName = !prompters.some(({ id, name }) => name === selectedPrompter.name && id !== selectedPrompter.id);
    const isUniqUrl = !prompters.some(({ id, url }) => url === selectedPrompter.url && id !== selectedPrompter.id);

    if (!isUniqName) {
      setNameError(t(Errors.name));
      return;
    }

    if (!isUniqUrl) {
      setUrlError(t(Errors.url));
      return;
    }

    const data = await prompterApiService.updatePrompter(selectedPrompter?.id, selectedPrompter);
    if (data) {
      getPrompters();
      setSelectedPrompter(null);
    } else {
      setUrlError(t(Errors.urlNotFound));
    }
  }, [getPrompters, prompterApiService, prompters, selectedPrompter, setSelectedPrompter]);

  return (
    <Modal
      isOpen={!!selectedPrompter}
      onActionClick={editPrompter}
      onCancelClick={() => {
        setOpenedModal(null);
        setSelectedPrompter(null);
        setNameError(undefined);
        setUrlError(undefined);
      }}
      title={t('Settings:Prompters:Modal:Edit:Title')}
      buttonCancelColor='secondary'
      disableActionButtonAutoFocus
      buttonSubmitDisabled={!selectedPrompter?.name.trim() || !selectedPrompter?.url.trim()}
      buttonCancelText={t('Cancel')}
      buttonSubmitText={t('Save')}
      centered
    >
      <fieldset>
        <div>
          <label>{t('Settings:Prompters:Name:Placeholder')}</label>
          <span className='ErrorMessage'> *</span>
          <InputText
            blurInactive
            data-test-id='Prompter.EditModal.Name'
            defaultValue={selectedPrompter?.name}
            onChange={handleChangePrompterName}
            errorText={nameError}
          />
        </div>
        <div className='margin-bottom-6x'>
          <label className='margin-top-6x'>{t('Settings:Prompters:Url:Placeholder')}</label>
          <span className='ErrorMessage'> *</span>
          <InputText
            blurInactive
            data-test-id='Prompter.EditModal.Url'
            defaultValue={selectedPrompter?.url}
            onChange={handleChangePrompterUrl}
            errorText={urlError}
          />
        </div>

        <Checkbox
          inline
          className='padding-left-6x'
          label={t('Settings:Prompters:SendStart:Label')}
          name='send-start-edit'
          onChange={handleChangeSendStart}
          value={selectedPrompter?.enabledSendStart}
          data-test-id='Prompter.EditModal.enabledSendStart'
        />
      </fieldset>
    </Modal>
  );
});
