import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from '@just-ai/just-ui/dist/Spinner';
import { registerPreloadedIcons } from '@just-ai/just-ui/dist/Icon';

import { AppContextProvider, AppContext } from './AppContext';
import { AppRouter } from './routes';

import './App.scss';
import { WSContext, WSHandler } from '@just-ai/aimychat-shared';
import { OperatorDataContextProvider } from './modules/OperatorPlace/OperatorDataContext';
import { AppLogger } from '@just-ai/logger';

registerPreloadedIcons([
  'faCircleNotch',
  'faPause',
  'faPlay',
  'farPlus',
  'faPen',
  'falEllipsisH',
  'faGripVertical',
  'farPodcast',
]);

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AppContextProvider>
      <AppContext.Consumer>
        {({
          appLoading,
          isLoggedIn,
          accountLoading,
          accountOwner,
          hasAccess,
          permissions,
          config,
          productInfo,
          isAdmin,
          id,
        }) => {
          const { pathname } = document.location;
          const loginUri = '/c/login';
          const onlyJaicpUri = '/c/account-or-user-unsupported';

          let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
          if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
          }
          link.href = productInfo.favicon;
          document.title = productInfo.productName;

          if (appLoading || accountLoading) {
            return <Spinner />;
          }

          if (!isLoggedIn && !accountLoading && pathname !== loginUri && pathname !== onlyJaicpUri) {
            window.location.href = accountOwner ? loginUri : onlyJaicpUri;
            return null;
          }

          const isAimyChatEnabledByLicense = config.systemFeatures?.livechat_licensed;

          const hasAccessAimychat =
            isAimyChatEnabledByLicense &&
            (hasAccess || permissions.some(p => p === 'AIMYCHAT_MESSAGING' || p === 'AIMYCHAT_CONFIGURATION'));

          return (
            <WSHandler id={id} navigate={navigate} location={location} AppLogger={AppLogger}>
              <WSContext.Consumer>
                {ws => (
                  <OperatorDataContextProvider
                    id={-1}
                    location={ws.location}
                    navigate={ws.navigate}
                    send={ws.send}
                    subscribe={ws.subscribe}
                    unsubscribe={ws.unsubscribe}
                    wasDisconnectFromServer={ws.state.wasDisconnectFromServer}
                    wsSessionId={ws.state.urlId}
                    reconnecting={ws.state.reconnecting}
                    reconnectingFailed={ws.state.reconnectingFailed}
                  >
                    <AppRouter isAdmin={isAdmin} hasAccessAimychat={hasAccessAimychat} />
                  </OperatorDataContextProvider>
                )}
              </WSContext.Consumer>
            </WSHandler>
          );
        }}
      </AppContext.Consumer>
    </AppContextProvider>
  );
}

export default App;
