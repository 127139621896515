import { memo, useCallback } from 'react';
import { Button, Icon } from '@just-ai/just-ui';
import { t } from '../../../../localization';
import { useOperatorDataContext } from '../../OperatorDataContext';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';

export const FinishDialogButton = memo(() => {
  const { finishChat, chooseChat, active, chosenChat } = useOperatorDataContext();
  const isMobile = useIsMobile();
  const description = t('OperatorPlace: header finish the dialog');
  const dataTestId = 'Chat.Header.Info.finishButton';

  const handleClick = useCallback(() => {
    finishChat();
    const firstOtherActiveChat = active.filter(({ id }) => id !== chosenChat?.id)[0];
    if (firstOtherActiveChat) {
      chooseChat(firstOtherActiveChat.id);
    }
  }, [finishChat, active, chosenChat?.id, chooseChat]);

  if (isMobile) {
    return (
      <Button data-test-id={dataTestId} onClick={handleClick} flat>
        <Icon name='farCheckCircle' color='secondary' size='sm' />
        {description}
      </Button>
    );
  }

  return (
    <Button
      data-test-id={dataTestId}
      outline
      color='secondary'
      className='flex-row margin-left-4x'
      onClick={handleClick}
      size='sm'
    >
      <Icon size='sm' name='farCheckCircle' className='margin-right-1x flex-row' />
      <span>{description}</span>
    </Button>
  );
});
