import { InputText, Textarea } from '@just-ai/just-ui';
import { Dispatch, memo, SetStateAction, useCallback, useMemo } from 'react';
import { t } from '../../../../localization';
import './styles.scss';

type Props = {
  name: string;
  htmlCode: string;
  setName: Dispatch<SetStateAction<string>>;
  setHhtmlCode: Dispatch<SetStateAction<string>>;
  isNameUniq: boolean;
  isTextLong: boolean;
  setIsTextLong: (isTextLong: boolean) => void;
};

export const FrameForm = memo(
  ({ name, htmlCode, setName, setHhtmlCode, isNameUniq, isTextLong, setIsTextLong }: Props): JSX.Element => {
    const handleChangeHTML = useCallback((value: string) => setHhtmlCode(value), [setHhtmlCode]);
    const handleChangeName = useCallback(
      (value: string) => {
        setName(value);
        setIsTextLong(false);
      },
      [setName]
    );

    const errorText = useMemo(() => {
      if (!isNameUniq) return 'Settings:Frames:Input:UniqueName:Error';
      if (isTextLong) return 'Settings:Frames:Input:TextLong:Error';
      return '';
    }, [isNameUniq, isTextLong]);

    return (
      <>
        <p className='FrameForm'>
          <InputText
            data-test-id='frame-name'
            value={name}
            placeholder={t('Settings:Frames:Input:Name')}
            onChange={handleChangeName}
            errorText={t(errorText)}
          />
        </p>
        <p className='FrameForm'>
          <Textarea
            data-test-id='frame-html-code'
            value={htmlCode}
            placeholder={t('Settings:Frames:Input:HTMLCode')}
            onChange={handleChangeHTML}
          />
        </p>
      </>
    );
  }
);
