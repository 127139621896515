import React, { useState, useEffect } from 'react';
import { Button, Icon, IconButton } from '@just-ai/just-ui';
import { OperatorWithGroupsDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { OperatorInfo } from './OperatorInfo';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';
import { t } from '../../../localization';
import { ActionPanel } from '@just-ai/analytic-front';
import { useAppContext } from '../../../AppContext';
import { Limits } from './Limits/Limits';

const OperatorsList = React.memo(
  ({
    operators,
    setOperatorToEdit,
    setOperatorToDelete,
  }: {
    operators: OperatorWithGroupsDto[];
    setOperatorToEdit: (operator: OperatorWithGroupsDto) => void;
    setOperatorToDelete: (operator: OperatorWithGroupsDto) => void;
  }) => {
    const { operatorId, groupsColors } = useAppContext();
    const [pageSize, setPageSize] = useState(20);
    const [page, setPage] = useState(0);

    useEffect(() => {
      setPage(0);
    }, [operators.length]);

    const isMobile = useIsMobile();
    const operatorsForPage = operators.slice(page * pageSize, page * pageSize + pageSize);

    return (
      <div className='Operators_operatorList_wrapper'>
        <div className='Operators_operatorList'>
          {operatorsForPage.map(operator => (
            <div
              className='Operators_operator flex-row'
              data-test-id={`Operators.Operator.${operator.id}`}
              key={operator.id}
            >
              <OperatorInfo operator={operator} />
              <div className='flex-row gap-2x flex-wrap'>
                {operator.groups.map(group => (
                  <div
                    data-test-id={`Operators.Operator.group.${group.id}`}
                    key={group.id}
                    className='Operators_operator_group'
                    style={groupsColors[group.id]}
                  >
                    {group.name}
                  </div>
                ))}
              </div>
              {isMobile ? (
                <div>
                  <Button
                    name='farPen'
                    outline
                    data-test-id={`Operators.Operator.edit.${operator.id}`}
                    size='sm'
                    color='secondary'
                    className='margin-right-2x'
                    onClick={() => setOperatorToEdit(operator)}
                  >
                    <Icon size='sm' name='farPen' />
                    {t('Change')}
                  </Button>
                  {operator.id !== operatorId && (
                    <>
                      <Button
                        name='farPen'
                        outline
                        data-test-id={`Operators.Operator.edit.${operator.id}`}
                        size='sm'
                        color='secondary'
                        className='margin-right-2x'
                        onClick={() => setOperatorToEdit(operator)}
                      >
                        <Icon size='sm' name='farPen' />
                        {t('Change')}
                      </Button>

                      <Button
                        name='farTrashAlt'
                        color='secondary'
                        outline
                        size='sm'
                        data-test-id={`Operators.Operator.delete.${operator.id}`}
                        onClick={() => setOperatorToDelete(operator)}
                      >
                        <Icon size='sm' name='farTrashAlt' />
                        {t('Delete')}
                      </Button>
                    </>
                  )}
                </div>
              ) : (
                <div>
                  <IconButton
                    name='farPen'
                    outline
                    color='secondary'
                    size='sm'
                    className='margin-right-2x'
                    data-test-id={`Operators.Operator.edit.${operator.id}`}
                    onClick={() => setOperatorToEdit(operator)}
                  />
                  {operator.id !== operatorId && (
                    <IconButton
                      name='farTrashAlt'
                      outline
                      color='secondary'
                      size='sm'
                      data-test-id={`Operators.Operator.delete.${operator.id}`}
                      onClick={() => setOperatorToDelete(operator)}
                    />
                  )}
                </div>
              )}
            </div>
          ))}
          {operators && operators.length > 20 && (
            <ActionPanel
              size={pageSize}
              totalCount={operators.length}
              withViewControl={false}
              totalPages={Math.ceil(operators.length / pageSize)}
              position='bottom'
              page={page}
              t={t}
              leftSideOrder={['Paginator']}
              rightSideOrder={['Size']}
              changePageSize={setPageSize}
              withoutToThePage
              changePage={setPage}
            />
          )}
        </div>

        <Limits />
      </div>
    );
  }
);

export default OperatorsList;
