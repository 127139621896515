import { IconButton } from '@just-ai/just-ui';
import { FunctionComponent, memo } from 'react';
import { useOperatorDataContext } from '../OperatorDataContext';

type Props = {
  elements: { Left?: FunctionComponent; Middle: FunctionComponent; Right?: FunctionComponent };
};

export const ChatHeaderMobile = memo(({ elements: { Left, Middle, Right } }: Props) => {
  const { cleanChosenChat } = useOperatorDataContext();

  return (
    <div className='ChatHeader_container__mobile'>
      <div className='ChatHeader_container__mobile__button'>
        {Left ? <Left /> : <IconButton name='faChevronLeft' onClick={cleanChosenChat} flat />}
      </div>
      <div className='ChatHeader_container__mobile__title'>
        <Middle />
      </div>

      <div className='ChatHeader_container__mobile__button'>{!!Right && <Right />}</div>
    </div>
  );
});
