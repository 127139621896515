import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from '../../../localization';
import './Frames.scss';
import { Button, usePromiseProcessing } from '@just-ai/just-ui';
import { FramesList } from '../components/Frames/FramesList';
import { useAppContext } from '../../../AppContext';
import { IFrameCreateDto } from '@just-ai/api/dist/generated/Aimychat';
import { FrameForm } from '../components/Frames/FrameForm';
import { AppLogger } from '@just-ai/logger';

export const Frames = () => {
  const { OperatorPlaceIFrameService } = useAppContext();
  const [name, setName] = useState('');
  const [htmlCode, setHhtmlCode] = useState('');
  const [isTextLong, setIsTextLong] = useState(false);

  const onError = (error: Error) => {
    AppLogger.error({ message: error.message });
    if (error.name === 'AxiosError') {
      // @ts-ignore
      setIsTextLong(error.response?.data?.error === 'aimychatbe.common.iframe_name_is_too_long');
    }
  };

  const [createOperatorPlaceIFrameState, createOperatorPlaceIFrame] = usePromiseProcessing(
    (data: IFrameCreateDto) => OperatorPlaceIFrameService.createOperatorPlaceIFrame(data),
    { onError }
  );

  const [getOperatorPlaceIFramesState, getOperatorPlaceIFrames] = usePromiseProcessing(() =>
    OperatorPlaceIFrameService.getOperatorPlaceIFrames()
  );

  const submit = useCallback(async () => {
    setIsTextLong(false);

    const data = await createOperatorPlaceIFrame({ name, htmlCode });
    if (data.iframes) {
      setName('');
      setHhtmlCode('');
      getOperatorPlaceIFrames();
    }
  }, [createOperatorPlaceIFrame, getOperatorPlaceIFrames, htmlCode, name]);

  const iframes = useMemo(
    () => (getOperatorPlaceIFramesState.loading ? null : getOperatorPlaceIFramesState.result?.iframes || []),
    [getOperatorPlaceIFramesState.loading, getOperatorPlaceIFramesState.result]
  );

  const isNameUniq = useMemo(() => !iframes?.some(iframe => iframe.name === name), [iframes, name]);

  const isDisabled = useMemo(
    () => !(name && htmlCode) || createOperatorPlaceIFrameState.loading || !isNameUniq,
    [createOperatorPlaceIFrameState.loading, htmlCode, isNameUniq, name]
  );

  useEffect(() => {
    getOperatorPlaceIFrames();
  }, [getOperatorPlaceIFrames]);

  return (
    <div className='Frames'>
      <div className='Frames_container'>
        <p className='Frames_description'>
          <small>{t('Settings:Frames:Description')}</small>
        </p>
        <a href='/' className='Frames_link'>
          <small>{t('Settings:Frames:Description:Link')}</small>
        </a>

        <div className='Frames_add_new_container'>
          <p>
            <small>
              <b>{t('Settings:Frames:Add:New:Frame')}</b>
            </small>
          </p>
          <FrameForm
            name={name}
            htmlCode={htmlCode}
            setName={setName}
            setHhtmlCode={setHhtmlCode}
            isNameUniq={isNameUniq}
            isTextLong={isTextLong}
            setIsTextLong={setIsTextLong}
          />
          <Button color='primary' size='sm' disabled={isDisabled} onClick={submit} data-test-id='add-new-frame'>
            {t('Save')}
          </Button>
        </div>

        <FramesList iframes={iframes} getOperatorPlaceIFrames={getOperatorPlaceIFrames} />
      </div>
    </div>
  );
};
