import { memo } from 'react';
import { Button, Icon, Pophover, useToggle } from '@just-ai/just-ui';
import { useOperatorDataContext } from '../../OperatorDataContext';
import { BanModal } from '../BanChatModal';
import { t } from '../../../../localization';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';

export const BanButton = memo(() => {
  const isMobile = useIsMobile();
  const { banChat } = useOperatorDataContext();
  const [showBanModal, setShowBanModal, hideBanModal] = useToggle();
  const description = t('OperatorPlace: header ban button title');

  return (
    <>
      <Pophover closable={false} dark placement='bottom' target='banChat'>
        {description}
      </Pophover>
      <Button id='banChat' onClick={setShowBanModal}>
        <Icon data-test-id='Chat.Header.Info.blockButton' name='farBan' color='danger' size='sm' />
        {isMobile && description}
      </Button>
      {showBanModal && <BanModal onClose={hideBanModal} onBan={banChat} hideModal={hideBanModal} />}
    </>
  );
});
