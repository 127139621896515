import React, { memo } from 'react';
import { Button, DropdownButton, DropdownToggle, DropdownMenu as DropdownMenuJustUI } from '@just-ai/just-ui';
import { t } from '../../../../localization';

type Props = {
  direction?: 'up' | 'left' | 'right' | 'down';
  title: string;
  children: React.ReactNode;
  handleApply: () => void;
  handleCancel: () => void;
};

export const DropdownMenu = memo(({ handleCancel, handleApply, children, title, direction = 'down' }: Props) => (
  <DropdownButton data-test-id='dropdown-wrapper' direction={direction} className='margin-right-2x'>
    <DropdownToggle data-test-id='dropdown-toggle' className='DropdownButton'>
      <small>{title}</small>
    </DropdownToggle>
    <DropdownMenuJustUI data-test-id='dropdown-menu' className='DropdownMenu'>
      {children}
      <div className='ButtonGroup'>
        <Button color='secondary' size='sm' onClick={handleCancel} outline>
          {t('Cancel')}
        </Button>
        <Button color='primary' size='sm' onClick={handleApply}>
          {t('Apply')}
        </Button>
      </div>
    </DropdownMenuJustUI>
  </DropdownButton>
));
