import React, { useCallback, useEffect, useState, useRef, ChangeEvent, useMemo } from 'react';

import {
  Button,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  IconButton,
  SwitchButton,
  Tooltip,
} from '@just-ai/just-ui';

import './ChatFooter.scss';

import { Spinner } from '@just-ai/just-ui/dist/Spinner';
import classNames from 'classnames';

import { PrompterFooter } from './PrompterFooter';
import { useOperatorDataContext } from '../../OperatorDataContext';
import { useAppContext } from '../../../../AppContext';
import { useWSCallbacks } from '../../operatorPlaceHelpers';
import { OperatorChatStatus } from '@just-ai/aimychat-shared/dist/api/client/operator';
import { scrollToBottom } from '@just-ai/aimychat-shared/dist/utils';
import { JoiningButton } from '../JoiningButton';
import { t } from '../../../../localization';
import { FileMessageDto } from '@just-ai/aimychat-shared/dist/api/client/websocket';
import { Attachments } from './Attachments';

export const ChatFooter = () => {
  const {
    chosenChat,
    sendMessage,
    setShowContextPrompter,
    showContextPrompter,
    setMessage,
    text,
    files,
    setMessageForPrompter,
    messageForPrompter,
    prompterResponse,
    onGetNewPrompterMessage,
    groupsForTransfer,
  } = useOperatorDataContext();
  const { operatorPlace, isPrompterAccess } = useAppContext();
  const isBlocked = !!chosenChat?.blockingInfo?.isBlocked;
  const status = chosenChat?.status;
  const [error, setError] = useState(false);
  const [sending, setSending] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>();
  const [isComment, setIsComment] = useState(false);

  const contextPrompterId = useMemo(
    () => groupsForTransfer.find(({ id }) => id === chosenChat?.operatorGroup.id)?.contextPrompterId,
    [chosenChat?.operatorGroup.id, groupsForTransfer]
  );

  const callbacks = useWSCallbacks({
    success: () => {
      setMessage({ text: '', files: undefined });
      setSending(false);
      setError(false);
      setMessageForPrompter();
      onGetNewPrompterMessage({ prompterResponse: undefined, isContextPrompter: true });
    },
    error: () => {
      setError(true);
      setSending(false);
    },
  });

  const handleSendMessage = useCallback(
    (text?: string, files?: FileMessageDto[]) => {
      setError(false);
      sendMessage({ text, files, callbacks, isComment });
      setSending(true);
      scrollToBottom();
    },
    [callbacks, sendMessage, setSending, isComment]
  );

  const onConfirm = useCallback(() => {
    if (fileUploading || (!text && !files?.length)) {
      return;
    }
    handleSendMessage(text, files);
    setMessage({ text: undefined, files: undefined });
    setSelectedFiles([]);
  }, [fileUploading, text, files, handleSendMessage, setMessage]);

  useEffect(() => {
    const onKeyDown = event => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
        onConfirm();
      }
    };

    if (textAreaRef.current) {
      textAreaRef.current?.addEventListener('keydown', onKeyDown);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      textAreaRef.current && textAreaRef.current?.removeEventListener('keydown', onKeyDown);
    };
  }, [onConfirm]);

  const onClickUpload = useCallback(() => fileRef?.current?.click(), [fileRef]);

  const onPrompterClick = useCallback(() => {
    setShowContextPrompter(!showContextPrompter);
    (document.activeElement as HTMLElement)?.blur();
  }, [setShowContextPrompter, showContextPrompter]);

  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = '0px';
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + 'px';
    }
  }, [text]);

  useEffect(() => {
    if (showContextPrompter && messageForPrompter && prompterResponse) {
      scrollToBottom();
    }
  }, [showContextPrompter, messageForPrompter, prompterResponse]);

  const onChangeText = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setMessage({ files, text: event.target.value });
      if (error) {
        setError(false);
      }
    },
    [error, files, setMessage]
  );

  const handleFilesChange = useCallback(
    async ({ target }: ChangeEvent<HTMLInputElement>) => {
      const filesFromPrompter = await Promise.all(
        (files || []).map(async file => {
          const blob = await (await fetch(file.url)).blob();
          return new File([blob], file.url.split('/').pop() || 'file', {
            type: blob.type === 'application/octet-stream' ? file.mimeType : blob.type,
          });
        })
      );

      const existedFiles = selectedFiles || [];
      const newUniqFiles = Array.from(target.files || []).filter(
        file => !existedFiles.some(({ name, size }) => name === file.name && size === file.size)
      );

      setSelectedFiles([...existedFiles, ...newUniqFiles, ...filesFromPrompter]);
    },
    [selectedFiles, files]
  );

  if (isBlocked) {
    return null;
  }

  if (status !== OperatorChatStatus.ACTIVE) {
    return <JoiningButton />;
  }

  return (
    <div className={classNames('FooterWrapper', { FooterWrapper_commentsMode: isComment })}>
      {isPrompterAccess && <PrompterFooter sendMessage={handleSendMessage} />}
      <div className='FooterWrapper__WithoutPropmpter'>
        <input
          ref={fileRef}
          data-test-id='Chat.MessageForm.FileInput'
          type='file'
          style={{ position: 'fixed', top: '100vh', left: '100vw' }}
          disabled={operatorPlace?.isBlockedByTariff}
          onChange={handleFilesChange}
          multiple
        />
        <Attachments
          setSelectedFiles={setSelectedFiles}
          selectedFiles={selectedFiles}
          fileUploading={fileUploading}
          setFileUploading={setFileUploading}
          isComment={isComment}
        />
        <textarea
          ref={textAreaRef}
          placeholder={t('OperatorPlace:Dialog:Footer input placeholder')}
          data-test-id='Chat.MessageForm.TextInput'
          className='FooterWrapper_input'
          maxLength={3000}
          onChange={onChangeText}
          disabled={operatorPlace?.isBlockedByTariff}
          value={text}
          autoFocus
        />
        {error && (
          <p className='FooterWrapper_error'>
            <small>{t('OperatorPlace:SendMessage:Error')}</small>
          </p>
        )}
        <div className='BottomControls'>
          <div className='LeftSide'>
            <DropdownButton className='Dropdown' data-test-id='dropdown-wrapper' direction='up'>
              <DropdownToggle className='DropdownButton' data-test-id='dropdown-toggle'>
                <Icon name={isComment ? 'farPen' : 'farCommentAltLines'} className='margin-right-2x' size='sm' />
                {t(isComment ? 'Comments:Comment' : 'Comments:Message')}
                <Icon name='farChevronDown' className='margin-left-2x' size='sm' />
              </DropdownToggle>
              <DropdownMenu className='DropdownMenu' data-test-id='dropdown-menu'>
                <DropdownItem
                  onClick={() => setIsComment(false)}
                  data-test-id='dropdown-item-active'
                  iconLeft='farCommentAltLines'
                  active={!isComment}
                >
                  {t('Comments:Message')}
                </DropdownItem>
                <DropdownItem
                  data-test-id='dropdown-item-active'
                  iconLeft='farPen'
                  onClick={() => setIsComment(true)}
                  active={isComment}
                >
                  {t('Comments:Comment')}
                </DropdownItem>
              </DropdownMenu>
            </DropdownButton>

            {isPrompterAccess && (
              <>
                <div className='VerticalLine' />
                <div className='Prompter'>
                  <SwitchButton
                    size='sm'
                    id='context-prompter-button'
                    data-test-id='Chat.MessageForm.contextPrompterButton'
                    label={t('Comments:Promps')}
                    labelPosition='right'
                    onChange={onPrompterClick}
                    value={showContextPrompter}
                    disabled={!contextPrompterId}
                  />
                </div>
              </>
            )}
          </div>
          <div className='RightSide'>
            <div className='position-relative d-flex'>
              <IconButton
                flat
                id='attach-file-button'
                color='secondary'
                disabled={fileUploading}
                data-test-id='Chat.MessageForm.fileButton'
                className='icon-withBorder'
                name='farPaperclip'
                onClick={onClickUpload}
              />
              {(fileUploading || sending) && <Spinner backgroundColor='var(--yelow-100)' size='1x' />}
            </div>

            <IconButton id='emoji-button' name='farSmileWink' disabled />

            <div className='VerticalLine' />
            <div className='d-flex' id='send-message-button'>
              <Button
                disabled={fileUploading || (!text?.trim() && !files) || operatorPlace?.isBlockedByTariff}
                className={classNames('SendButton d-flex', { iconDisabled: fileUploading })}
                data-test-id='Chat.MessageForm.sendButton'
                onClick={onConfirm}
              >
                <img src='/img/send-message.svg' alt='send-message-icon' />
              </Button>

              <Tooltip placement='top' target='send-message-button' textAlign='left' delay={{ show: 400, hide: 200 }}>
                <div>
                  <strong>Enter</strong> - {t('OperatorPlace:Dialog:Footer tooltip send message')}
                </div>
                <div>
                  <strong>Shift+Enter</strong> - {t('OperatorPlace:Dialog:Footer tooltip line break')}
                </div>
              </Tooltip>
            </div>

            <Tooltip placement='top' target='attach-file-button' textAlign='left' delay={{ show: 400, hide: 200 }}>
              {t('OperatorPlace:Dialog:Footer tooltip attach file')}
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
