import React, { useEffect, useState } from 'react';
import OperatorChatTabs from './OperatorChatTabs';
import Chat from './Chat';
import ChatInfo from './ChatInfo';
import './OperatorPlace.scss';
import { t } from '../../localization';
import { useOperatorDataContext } from './OperatorDataContext';
import { useParams } from 'react-router-dom';
import { OperatorChatStatus } from '@just-ai/aimychat-shared/dist/api/client/operator';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';
import { useAppContext } from '../../AppContext';
import classNames from 'classnames';

export default function OperatorPlace() {
  const [isShowAbout, setShowAbout] = useState(false);
  const { chosenChatId, contextPrompterNameWithError } = useOperatorDataContext();
  const { getAgentLimits, getOperatorPlace } = useAppContext();
  const { tab } = useParams<{ tab: OperatorChatStatus }>();
  const isMobile = useIsMobile();

  useEffect(() => {
    getAgentLimits();
    getOperatorPlace();
  }, [getAgentLimits, getOperatorPlace]);

  return (
    <div className={classNames('OperatorPlace_container', { WithBanner: contextPrompterNameWithError })}>
      <OperatorChatTabs />
      {!isShowAbout && <Chat setShowAbout={setShowAbout} />}
      <ChatInfo setShowAbout={setShowAbout} />
      {!chosenChatId && !isMobile && (
        <div className='OperatorPlace_nochoosenInfo'>
          <span className='font-size-16 font-color-light-gray' style={{ fontWeight: 500 }}>
            {t(`OperatorPlace: no user choosen info ${tab}`)}
          </span>
        </div>
      )}
    </div>
  );
}
