import React, { createContext, useContext } from 'react';
import { SubscriberCallback, WSHandlerType, wsPath, sortChats } from '@just-ai/aimychat-shared';

import { v4 as uuid } from 'uuid';
import {
  BanFunction,
  ChooseChatFunction,
  GetChatsFunction,
  JoinChatFunction,
  MoreMessagesFunction,
  OFLINE_ACTIONS,
  RequestCallbacks,
  SendMessageFunction,
  SetOnlineStatusOperatorFunction,
  StateListsName,
  TransferChatFunction,
  NoopFunction,
} from '@just-ai/aimychat-shared/dist/operatorPlaceTypes';
import { AppContext, AppContextType } from '../../AppContext';
import { SoundNotificationMode } from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { NavigateFunction, useLocation } from 'react-router-dom';
import {
  FileMessageDto,
  GetPrompterAnswerOptionsDto,
  IncomingPrompterResponseDto,
  MessageDto,
  OperatorGroupsWithStatusDto,
  OperatorGroupWithStatusDto,
  PrompterMessageType,
  PrompterResponseDto,
  WsErrorCode,
  BlockClientDto,
  ChatHistoryDto,
  ClientBlockingStatus,
  CloseChatByOperatorDto,
  GetChatHistoryDto,
  GetCompletedChatsDto,
  JoinChatDto,
  MessageStatus,
  MessageType,
  NewOperatorStatusDto,
  OperatorChatDto,
  OperatorChatStatus,
  TransferToGroupDto,
  UnblockClientDto,
  UpdateLastReadTimeDto,
  UpdateOperatorStatusAction,
  UpdateOperatorStatusDto,
  MessageFilterKey,
} from '@just-ai/aimychat-shared/dist/api/client/websocket';
import { scrollToBottom } from '@just-ai/aimychat-shared/dist/utils';
import {
  OperatorChatWithLastMessageSimpleDto,
  OperatorChatWithLastMessageDto,
} from '@just-ai/aimychat-shared/dist/api/client/operator';
import { AppLogger } from '@just-ai/logger';

export enum PrompterRequestIds {
  context = 'context',
  prompter = 'prompter',
}

export const USER_CHAT_FILTERS = 'USER_CHAT_FILTERS';

type Files = (FileMessageDto & { name: string })[];

export type OperatorMessage = {
  text?: string;
  files?: Files;
};

export type OperatorDataContextType = {
  created: OperatorChatWithLastMessageSimpleDto[];
  active: OperatorChatWithLastMessageSimpleDto[];
  completed: OperatorChatWithLastMessageSimpleDto[];
  groupsForTransfer: OperatorGroupWithStatusDto[];
  operatorGroups: OperatorGroupWithStatusDto[];

  loading: {
    created: boolean;
    active: boolean;
    completed: boolean;
  };

  isCompletedHasToLoad: boolean;
  isMessagesHaveToLoad: boolean;
  isJoinChatAvailable: boolean;
  chosenChatId?: number;
  messages?: MessageDto[];
  chosenChat?: OperatorChatDto;
  isOnline?: boolean;
  isOffline?: boolean;
  wasDisconnectFromServer: boolean;
  reconnecting?: boolean;
  reconnectingFailed?: boolean;

  chooseChat: ChooseChatFunction;
  sendMessage: SendMessageFunction;
  joinChat: JoinChatFunction;
  finishChat: NoopFunction;
  banChat: BanFunction;
  unbanChat: NoopFunction;
  transferChat: TransferChatFunction;
  getHistoryChats: GetChatsFunction;
  setOnlineStatusOperator: SetOnlineStatusOperatorFunction;
  loadMoreMessages: MoreMessagesFunction;
  getNewMessages: (id?: string) => void;
  cleanChosenChat: NoopFunction;
  showContextPrompter: boolean;
  setShowContextPrompter: (value: boolean) => void;
  contextPrompterResponse?: IncomingPrompterResponseDto;
  prompterResponse?: IncomingPrompterResponseDto;
  messageForPrompter?: MessageDto;
  getNewPromterMessage: (props: GetPrompterAnswerOptionsDto) => string;
  setMessage: (value: OperatorMessage) => void;
  text?: string;
  files?: Files;
  setMessageForPrompter: (message?: MessageDto) => void;
  onGetNewPrompterMessage: (value: PrompterResponseDto) => void;
  setPrompterRequest: (text: string) => void;
  clearPrompterChoice: () => void;
  prompterRequest: string;
  requestId?: string;
  prompterFullResponse?: PrompterResponseDto;
  getOperatorGroups: () => void;
  clearContextPrompterNameWithError: () => void;
  contextPrompterNameWithError: string;
  showNotificationFailedBanner: boolean;
  hideNotificationFailedBanner: () => void;
  clearPrompterResponse: () => void;
};

export class OperatorDataContextProviderState {
  created: OperatorChatWithLastMessageSimpleDto[] = [];
  active: OperatorChatWithLastMessageSimpleDto[] = [];
  completed: OperatorChatWithLastMessageSimpleDto[] = [];
  isCompletedHasToLoad: boolean = true;
  completedTotalNumber: number = 0;
  groupsForTransfer: OperatorGroupWithStatusDto[] = [];
  operatorGroups: OperatorGroupWithStatusDto[] = [];
  messages?: MessageDto[];
  chosenChat?: OperatorChatDto;
  chosenChatId?: number;
  isMessagesHaveToLoad: boolean = false;
  loading: {
    created: boolean;
    active: boolean;
    completed: boolean;
  } = {
    created: true,
    active: true,
    completed: true,
  };
  showContextPrompter = Boolean(sessionStorage.getItem('showContextPrompter'));
  messageForPrompter?: MessageDto;
  contextPrompterResponse?: IncomingPrompterResponseDto = {} as IncomingPrompterResponseDto;
  prompterResponse?: IncomingPrompterResponseDto = {} as IncomingPrompterResponseDto;
  text?: string;
  files?: Files;
  prompterRequest = '';
  prompterFullResponse;
  contextPrompterNameWithError = '';
  showNotificationFailedBanner = false;
}

export interface OperatorDataContextProviderInterface {
  send: (path: string, message?: unknown) => void;
  subscribe: (path: string, callback: SubscriberCallback) => string;
  unsubscribe: (path: string, id: string) => void;
  wasDisconnectFromServer: boolean;
  wsSessionId?: string;
  reconnecting?: boolean;
  reconnectingFailed?: boolean;
}

const OperatorDataContext = createContext({} as OperatorDataContextType);

const { ALL, NOTHING, ONLYNEWCHATS, ONLYNEWMESSAGES } = SoundNotificationMode;
const COMPLETED_SIZE = 100;
const CHAT_SIZE = 100;

class OperatorDataContextProvider extends React.Component<
  OperatorDataContextProviderInterface & WSHandlerType,
  OperatorDataContextProviderState
> {
  static contextType = AppContext;
  context!: AppContextType;

  constructor(props: WSHandlerType & OperatorDataContextProviderInterface) {
    super(props);
    this.state = new OperatorDataContextProviderState();
    this.location = props.location;
    this.navigate = props.navigate;
    this.audio = null;
  }

  requests: { [key: string]: RequestCallbacks } = {};
  navigate: NavigateFunction;
  location: ReturnType<typeof useLocation>;
  audio: HTMLAudioElement | null;

  componentDidMount() {
    this.props.subscribe(wsPath.chatCreated, this.onGetChats.bind(this, 'created'));
    this.props.subscribe(wsPath.chatActive, this.onGetChats.bind(this, 'active'));
    this.props.subscribe(wsPath.chatCompleted, this.onGetChats.bind(this, 'completed'));
    this.props.subscribe(wsPath.chatHistory, this.onGetChatHistory);
    this.props.subscribe(wsPath.newMessage, this.onGetNewMessage);
    this.props.subscribe(wsPath.newChatStatus, this.onGetNewChatStatus);
    this.props.subscribe(wsPath.newSetGroupForOperator, this.onGetOperatorGroups);
    this.props.subscribe(wsPath.operatorGroups, this.onGetGroupsFortransfer);
    this.props.subscribe(wsPath.newMessageStatus, this.onGetNewMessageStatusDTO);
    this.props.subscribe(wsPath.chatBlockInfo, this.onGetBlockingInfo);
    this.props.subscribe(wsPath.newOperatorStatus, this.onGetOperatorStatus);
    this.props.subscribe('/error', this.onError);
    this.props.subscribe('/prompter/new-message', this.onGetNewPrompterMessage);
    this.getOperatorGroups();
    this.props.send(wsPath.chatCreated, {});
    this.props.send(wsPath.chatActive, {});

    this.getHistoryChats({ getFromStart: true });
    this.audio = new Audio(this.context.operatorPlace?.notification?.value);
  }

  getOperatorGroups = () => this.props.send(wsPath.operatorGroups, {});
  onError = async data => {
    if ('code' in data && data.code === WsErrorCode.PROMPTERDOESNOTANSWER && this.state.prompterRequest) {
      const { prompters } = await this.context.prompterApiService.getPrompters();

      const prompter = prompters.find(({ id }) => id === data.args.prompterId);
      if (prompter) {
        this.setState({ contextPrompterNameWithError: prompter.name });
      }
    }
  };

  onGetNewPrompterMessage = (props: PrompterResponseDto) => {
    const { prompterResponse, isContextPrompter } = props;
    this.clearContextPrompterNameWithError();
    const notEmptyAnswerOptions =
      prompterResponse?.answerOptions.filter(({ messages }) =>
        messages.every(
          ({ type, text }: any) => (text && type === PrompterMessageType.TEXT) || type !== PrompterMessageType.TEXT
        )
      ) || [];
    const data = prompterResponse ? { ...prompterResponse, answerOptions: notEmptyAnswerOptions } : undefined;
    if (isContextPrompter) {
      this.setState({ contextPrompterResponse: data }, () => {
        scrollToBottom();
      });
      return;
    }
    return this.setState({ prompterResponse: data, prompterFullResponse: props });
  };
  getNewPromterMessage = ({
    prompterId,
    text,
    operatorChatId,
    isContextPrompter,
    requestId,
  }: GetPrompterAnswerOptionsDto) => {
    const reqId = `${requestId || ''} ${this.getRequestId()}`.trim();
    this.props.send('/prompter/new-message', {
      requestId: reqId,
      prompterId,
      operatorChatId,
      text,
      isContextPrompter,
    });
    return reqId;
  };

  setShowContextPrompter = (showContextPrompter: boolean) => {
    this.setState({ showContextPrompter });
    sessionStorage.setItem('showContextPrompter', showContextPrompter ? 'true' : '');
  };
  setMessageForPrompter = (messageForPrompter?: MessageDto) => this.setState({ messageForPrompter });
  onGetOperatorStatus = ({ requestId, isOnline }: NewOperatorStatusDto) => {
    this.handleRequestId(requestId);
    this.context.updateOnlineStatus(isOnline);
  };

  setPrompterRequest = (prompterRequest: string) => this.setState({ prompterRequest });

  getHistoryChats: GetChatsFunction = ({ callbacks, groupIds = [], filter, getFromStart }) => {
    const { completed } = this.state;
    let completedBeforeTs = new Date();
    if (completed.length > 0 && !getFromStart) {
      completedBeforeTs = completed[completed.length - 1]?.completionTime || completedBeforeTs;
    }
    if (getFromStart) {
      this.setState({ loading: { ...this.state.loading, completed: true } });
    }
    this.props.send(wsPath.chatCompleted, {
      completedBeforeTs,
      size: COMPLETED_SIZE,
      operatorGroupIds: groupIds,
      clientFilter: filter,
      requestId: this.getRequestId(callbacks),
    } as GetCompletedChatsDto);
  };

  //@ts-ignore
  private onGetChatHistory = ({ chat, messages, requestId }: ChatHistoryDto) => {
    this.handleRequestId(requestId);
    const { status } = chat;
    let list: OperatorChatWithLastMessageSimpleDto[];
    let key: StateListsName;
    switch (status) {
      case OperatorChatStatus.ACTIVE:
        list = this.state.active;
        key = StateListsName.ACTIVE;
        break;
      case OperatorChatStatus.COMPLETED:
        list = this.state.completed;
        key = StateListsName.COMPLETED;
        break;
      case OperatorChatStatus.CREATED:
        list = this.state.created;
        key = StateListsName.CREATED;
        break;
    }

    const chatInList = list.find(chatInList => chatInList.id === chat.id);

    if (chatInList) {
      chatInList.unreadMessageCount = 0;
      this.setState({ [key as 'completed']: [...list] });
    }

    this.setState({
      messages: chat.id === this.state.chosenChatId ? messages : [...(messages || []), ...(this.state.messages || [])],
      chosenChat: chat,
      isMessagesHaveToLoad: messages.length >= CHAT_SIZE,
    });

    if (chat.status === OperatorChatStatus.ACTIVE) {
      this.updateLastReadTime(chat.id);
    }
  };

  private updateLastReadTime(chatId: number) {
    this.props.send(`/chats/${chatId}/update-last-read-time`, {
      requestId: this.getRequestId(),
      time: new Date(),
    } as UpdateLastReadTimeDto);
  }

  private onGetBlockingInfo = ({ isBlocked, operatorChatId }: ClientBlockingStatus) => {
    const { chosenChat } = this.state;
    if (Number.isInteger(operatorChatId) && chosenChat?.id === operatorChatId) {
      this.setState({ chosenChat: { ...chosenChat, blockingInfo: { ...chosenChat.blockingInfo, isBlocked } } });
    }
  };

  private onGetOperatorGroups = ({ groups }: OperatorGroupsWithStatusDto) => this.setState({ operatorGroups: groups });

  private onGetGroupsFortransfer = ({ groups }: OperatorGroupsWithStatusDto) =>
    this.setState({ groupsForTransfer: groups });

  private onGetNewChatStatus = ({
    chat,
    systemMessage,
    requestId,
  }: {
    requestId: string;
    chat: OperatorChatWithLastMessageDto;
    systemMessage: MessageDto;
  }) => {
    if (!chat) {
      return;
    }

    this.handleRequestId(requestId);
    const { active, completed, created, chosenChat } = this.state;
    const groups = this.context.groups || [];

    const { id, clientInfo, status, operatorGroup, chatId, isChatadapterClient, creationTime } = chat;
    const { type, operator } = systemMessage;

    const groupId = operatorGroup.id;

    let newActive = active.filter(({ id: chatId }) => id !== chatId);
    let newCompleted = completed.filter(({ id: chatId }) => id !== chatId);
    let newCreated = created.filter(({ id: chatId }) => id !== chatId);

    if (chosenChat?.id === id) this.setState({ chosenChat: { ...chosenChat, status } });

    const newChat = {
      id,
      clientInfo,
      status,
      operatorGroup,
      chatId,
      isChatadapterClient,
      lastMessage: chat.lastMessage,
      creationTime,
    };

    switch (status) {
      case OperatorChatStatus.COMPLETED:
        newCompleted = this.sortChats([...newCompleted, newChat], true);
        if (type === MessageType.OPERATORCHATCOMPLETED && chosenChat?.id === id) {
          this.cleanChosenChat();
        }
        break;
      case OperatorChatStatus.CREATED:
        this.notify(ONLYNEWCHATS);
        const currentGroups = groups.length ? groups : this.context.operator?.groups || [];
        if (currentGroups.map(group => group.id).includes(groupId)) {
          this.notify(ONLYNEWCHATS);

          newCreated = this.sortChats([...newCreated, newChat]);
        }
        if (
          (type === MessageType.TRANSFERTOOPERATORGROUP || type === MessageType.TRANSFERAFTEROPERATORSILENCE) &&
          chosenChat?.id === id
        ) {
          this.cleanChosenChat();
        }
        break;
      case OperatorChatStatus.ACTIVE:
        if (status === OperatorChatStatus.ACTIVE && operator?.id === this.context.operatorId) {
          newActive = this.sortChats([...newActive, newChat]);

          if (type === MessageType.OPERATORJOINED && window.location.pathname.includes('/operator/')) {
            this.navigate('/operator/active', { replace: true });
            this.chooseChat(id, true);
          }
        }
        this.props.send(wsPath.chatCompleted, {});
        scrollToBottom();
        break;
    }

    this.setState({ active: newActive, completed: newCompleted, created: newCreated });
  };

  cleanChosenChat = () => this.setState({ chosenChat: undefined, chosenChatId: undefined });

  private getRequestId = (callbacks?: RequestCallbacks) => {
    const requestId: string = uuid();
    if (!callbacks) return requestId;

    this.requests[requestId] = callbacks;
    return requestId;
  };

  private handleRequestId = (requestId?: string, isFail?: boolean) => {
    if (!requestId) return;
    const callbacks = this.requests[requestId];
    if (!callbacks) {
      return;
    }
    if (isFail && !!callbacks.error) {
      callbacks.error!();
    } else if (!!callbacks.success) {
      callbacks.success!();
    }
    delete this.requests[requestId];
  };

  private onGetNewMessageStatusDTO = ({ requestId, message }: { requestId: string; message?: MessageDto }) => {
    const { chosenChat, messages } = this.state;

    if (!message || message?.status === MessageStatus.FAILED) {
      this.handleRequestId(requestId, true);
      return;
    }

    if (message.status !== MessageStatus.SENT) {
      return;
    }

    this.handleRequestId(requestId);
    if (chosenChat?.id === message.operatorChat.id) {
      this.setState({ messages: [...(messages ?? []), message] });
    }
    this.updateLastMessageInChat(message);
  };

  private notify = (type: SoundNotificationMode) => {
    if (this.context.operatorPlace?.notificationMode === NOTHING) {
      return;
    }

    if (type === this.context.operatorPlace?.notificationMode || this.context.operatorPlace?.notificationMode === ALL) {
      this.audio?.play().catch(error => {
        AppLogger.error({
          message: `Notification playing is failed in OperatorDataContext "audio?.play()": ${JSON.stringify(error)}`,
        });
        this.setState({ showNotificationFailedBanner: true });
      });
    }
  };

  private onGetNewMessage = (data: MessageDto) => {
    const isChatsPage = window.location.pathname.includes('/operator/');
    const { chosenChat, messages } = this.state;
    const { operatorChat } = data;

    if (chosenChat && messages && chosenChat.id === operatorChat.id) {
      this.setState({ messages: [...messages, data] });

      if (
        this.state.showContextPrompter &&
        data.type === MessageType.CLIENTMESSAGE &&
        messages.some(({ type }) => type === MessageType.NEWOPERATORCHAT)
      ) {
        const contextPrompterId = this.state.groupsForTransfer.find(
          ({ id }) => id === chosenChat?.operatorGroup.id
        )?.contextPrompterId;
        if (contextPrompterId) {
          this.getNewPromterMessage({
            prompterId: contextPrompterId.toString(),
            text: data.text,
            operatorChatId: operatorChat.id,
            isContextPrompter: true,
          });
          this.setMessageForPrompter(data);
          scrollToBottom();
        }
      }

      scrollToBottom();
    }

    this.updateLastMessageInChat(data);

    if (!this.state.chosenChatId || data.operatorChat.id !== this.state.chosenChatId || !isChatsPage) {
      this.notify(ONLYNEWMESSAGES);
    }
  };

  updateLastMessageInChat = (message: MessageDto) => {
    if (!message) {
      return;
    }

    if ([MessageType.OPERATORMESSAGE, MessageType.CLIENTMESSAGE, MessageType.COMMENT].includes(message.type)) {
      this.props.send(wsPath.chatActive, {});
    }
  };

  private onGetChats = (
    key: 'completed' | 'active' | 'created',
    { chats = [], requestId }: { chats: OperatorChatWithLastMessageSimpleDto[]; requestId: string }
  ) => {
    this.handleRequestId(requestId);
    const newChats = this.state.loading[key] || key === 'active' ? chats : [...this.state[key], ...chats];
    const newState = {
      //@ts-ignore
      [key as 'completed']: this.sortChats(newChats),
      loading: {
        ...this.state.loading,
        [key]: false,
      },
      isCompletedHasToLoad: this.state.isCompletedHasToLoad,
    };
    if (key === 'completed' && chats.length < COMPLETED_SIZE) {
      newState.isCompletedHasToLoad = false;
      newState.completed = chats;
    }
    this.setState({ ...newState });
  };

  sortChats = (
    chats: OperatorChatWithLastMessageSimpleDto[],
    isArchive?: boolean
  ): OperatorChatWithLastMessageSimpleDto[] =>
    sortChats(chats, isArchive, this.context.operatorPlace?.clientQueueSorting);

  chooseChat: ChooseChatFunction = (id, isJoinChat = true) => {
    if ((id === this.state.chosenChat?.id && !isJoinChat) || this.context.operatorPlace?.isBlockedByTariff) {
      this.cleanChosenChat();
      return;
    }

    this.getOperatorGroups();
    this.clearPrompterChoice();

    this.setState(
      {
        chosenChatId: id,
        messages: [],
        isMessagesHaveToLoad: false,
        contextPrompterResponse: undefined,
        messageForPrompter: undefined,
        text: undefined,
        files: undefined,
      },
      () => {
        this.getNewMessages(id);
      }
    );
  };

  setMessage = ({ text, files }: OperatorMessage) => {
    this.setState({ text, files });
  };

  clearPrompterResponse = () => this.setState({ prompterResponse: undefined });

  clearPrompterChoice = () => {
    this.onGetNewPrompterMessage({ isContextPrompter: false, prompterResponse: undefined });
    this.setPrompterRequest('');
  };

  clearOperatorChanges = () => {
    this.setState({ text: '', files: undefined });
  };

  getFiltersForCurrentChat = () => {
    const data = JSON.parse(sessionStorage.getItem(USER_CHAT_FILTERS) || '{}');
    if (this.state.chosenChatId) {
      const filters = ((data[this.state.chosenChatId] || []) as MessageFilterKey[]).map(filter => {
        if (filter === ('SYSTEMMESSAGES' as MessageFilterKey)) {
          return 'SYSTEM_MESSAGES' as MessageFilterKey;
        }
        return filter;
      });
      return filters.length && filters.length < 3 ? filters : undefined;
    }
    return undefined;
  };

  getNewMessages = chatId => {
    const id = chatId || this.state.chosenChatId;
    if (!id) {
      return;
    }
    this.props.send(`/chats/${id}/chat-history`, {
      size: CHAT_SIZE,
      requestId: this.getRequestId(),
      messageTypes: this.getFiltersForCurrentChat(),
    });
  };

  loadMoreMessages: MoreMessagesFunction = callbacks => {
    const messageTypes = this.getFiltersForCurrentChat();
    const data: GetChatHistoryDto = { size: CHAT_SIZE, requestId: this.getRequestId(callbacks), messageTypes };
    if (this.state.messages && this.state.messages[0]) {
      data.endTime = this.state.messages[0]?.creationTimestamp;
    }
    this.props.send(`/chats/${this.state.chosenChatId}/chat-history`, data);
  };

  sendMessage: SendMessageFunction = ({ text, files, callbacks, isComment }) => {
    const { chosenChat } = this.state;
    if (chosenChat) {
      const requestId = this.getRequestId(callbacks);
      this.props.send(`/chats/${chosenChat?.id}/send-message`, { text, files, requestId, isComment });
    }
  };

  joinChat: JoinChatFunction = callbacks => {
    this.props.send(`/chats/${this.state.chosenChat?.id}/join-chat`, {
      requestId: this.getRequestId(callbacks),
    } as JoinChatDto);
  };

  finishChat: NoopFunction = () => {
    this.props.send(`/chats/${this.state.chosenChat?.id}/close-chat`, {
      requestId: this.getRequestId(),
    } as CloseChatByOperatorDto);
  };

  unbanChat: NoopFunction = () => {
    const { chosenChat } = this.state;

    this.props.send(`/chats/${chosenChat?.id}/unblock-client`, {
      requestId: this.getRequestId(),
    } as UnblockClientDto);
  };

  banChat: BanFunction = ({ reason, message, callbacks }) => {
    const { chosenChat } = this.state;

    this.props.send(`/chats/${chosenChat?.id}/block-client`, {
      requestId: this.getRequestId(callbacks),
      reason,
      messageForClient: message,
    } as BlockClientDto);
  };

  transferToGroup: TransferChatFunction = ({ groupId, callbacks }) => {
    this.props.send(`/chats/${this.state.chosenChat?.id}/transfer-to-group`, {
      requestId: this.getRequestId(callbacks),
      groupId,
    } as TransferToGroupDto);
  };

  setOnlineStatusOperator: SetOnlineStatusOperatorFunction = ({ isOnline, callbacks, action }) => {
    let data: UpdateOperatorStatusDto = {
      requestId: this.getRequestId(callbacks),
      isOnline,
    };
    if (isOnline === false && action === OFLINE_ACTIONS.IN_QUEUE) {
      data.action = UpdateOperatorStatusAction.TRANSFERACTIVECHATSTOQUEUE;
    } else if (isOnline === false && action === OFLINE_ACTIONS.FINISH) {
      data.action = UpdateOperatorStatusAction.CLOSEACTIVECHATS;
    }
    this.props.send(`/operators/update-status`, data);
  };

  clearContextPrompterNameWithError = () =>
    this.state.contextPrompterNameWithError && this.setState({ contextPrompterNameWithError: '' });

  hideNotificationFailedBanner = () => this.setState({ showNotificationFailedBanner: false });

  render() {
    const maxActiveChatsForOperator = this.context?.operatorPlace?.maxActiveChatsForOperator;

    return (
      <OperatorDataContext.Provider
        value={{
          ...this.state,
          isOnline: this.context.isOnline,
          isJoinChatAvailable: maxActiveChatsForOperator
            ? Boolean(maxActiveChatsForOperator > this.state.active.length)
            : true,
          wasDisconnectFromServer: this.props.wasDisconnectFromServer,
          reconnecting: this.props.reconnecting,
          reconnectingFailed: this.props.reconnectingFailed,
          cleanChosenChat: this.cleanChosenChat,
          chooseChat: this.chooseChat,
          sendMessage: this.sendMessage,
          joinChat: this.joinChat,
          finishChat: this.finishChat,
          banChat: this.banChat,
          unbanChat: this.unbanChat,
          transferChat: this.transferToGroup,
          getHistoryChats: this.getHistoryChats,
          setOnlineStatusOperator: this.setOnlineStatusOperator,
          loadMoreMessages: this.loadMoreMessages,
          getNewMessages: this.getNewMessages,
          getNewPromterMessage: this.getNewPromterMessage,
          setShowContextPrompter: this.setShowContextPrompter,
          setMessageForPrompter: this.setMessageForPrompter,
          setMessage: this.setMessage,
          onGetNewPrompterMessage: this.onGetNewPrompterMessage,
          setPrompterRequest: this.setPrompterRequest,
          clearPrompterChoice: this.clearPrompterChoice,
          getOperatorGroups: this.getOperatorGroups,
          clearContextPrompterNameWithError: this.clearContextPrompterNameWithError,
          hideNotificationFailedBanner: this.hideNotificationFailedBanner,
          clearPrompterResponse: this.clearPrompterResponse,
        }}
      >
        {this.props.children}
      </OperatorDataContext.Provider>
    );
  }
}

const useOperatorDataContext = () => useContext(OperatorDataContext);

export { OperatorDataContext, OperatorDataContextProvider, useOperatorDataContext };
