import React, { useCallback } from 'react';
import { Modal, useToggle } from '@just-ai/just-ui';
import { t } from '../../../localization';
import OperatorService from '@just-ai/aimychat-shared/dist/service/OperatorService';
import { OperatorDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { getUserFullName } from '../../OperatorPlace/operatorPlaceHelpers';

const DeleteOperatorModal = ({
  onClose,
  deleteOperator,
  accountId,
  operatorToDelete,
}: {
  onClose: () => void;
  deleteOperator: (id: number) => void;
  accountId: number;
  operatorToDelete: OperatorDto;
}) => {
  const [inDeleting, setInDeleting, finishDeleting] = useToggle(false);

  const onConfirm = useCallback(async () => {
    try {
      const service = new OperatorService(accountId);
      setInDeleting();
      await service.deleteOperator(operatorToDelete.id);
      deleteOperator(operatorToDelete.id);
      finishDeleting();
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [accountId, setInDeleting, operatorToDelete, deleteOperator, finishDeleting, onClose]);

  const userFullName = getUserFullName(operatorToDelete);
  const email = userFullName ? ` (${operatorToDelete.email})` : operatorToDelete.email;

  return (
    <Modal
      onClickCloseIcon={onClose}
      onCancelClick={onClose}
      buttonCancelColor='secondary'
      buttonSubmitColor='danger'
      onActionClick={onConfirm}
      title={t('Settings:Operator delete modal title')}
      buttonSubmitText={t('Delete')}
      buttonCancelText={t('Cancel')}
      buttonCancelTestId='Operators.DeleteModal.cancel'
      buttonSubmitTestId='Operators.DeleteModal.submit'
      className='mobile-modal'
      disableActionButtonAutoFocus
      isOpen
      inProgress={inDeleting}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: t(
            'Settings:Operator delete modal info',
            `<b data-test-id="Operators.DeleteModal.fullName">${userFullName}</b>${operatorToDelete.email ? email : ''}`
          ),
        }}
      />
    </Modal>
  );
};

export default DeleteOperatorModal;
