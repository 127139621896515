import { IconButton, Spinner } from '@just-ai/just-ui';
import './BlockingBanner.scss';

type Props = {
  title: string;
  withSpinner?: boolean;
  withoutBackground?: boolean;
  onClose?: () => void;
};

export function BlockingBanner({ title, withSpinner, withoutBackground, onClose }: Props) {
  return (
    <>
      <div className='Banner'>
        <span style={{ marginRight: 8 }} dangerouslySetInnerHTML={{ __html: title }}></span>
        {withSpinner && <Spinner animationTime={1000} color='primary' inline size='sm' />}
        {onClose && <IconButton name='falTimes' onClick={onClose} />}
      </div>
      {!withoutBackground && <div className='Background'></div>}
    </>
  );
}
