import React from 'react';
import { Dropdown, DropdownItem, Icon, IconButton, Popover, useToggle } from '@just-ai/just-ui';
import { t } from '../../localization';
import { useAppContext } from '../../AppContext';

const HelpMenu = () => {
  const { productInfo, language } = useAppContext();
  const [openPopover, , , togglePopover] = useToggle(false);

  return (
    <>
      <IconButton className='navbar-btn p-0' id='helpMenuPopover' name='farQuestionCircle' onClick={togglePopover} />
      <Popover
        isOpen={openPopover}
        placement='bottom'
        target='helpMenuPopover'
        className='header-menu-popover help-menu-popover'
        toggle={togglePopover}
      >
        <Dropdown className='header-menu' isOpen toggle={togglePopover}>
          <a
            href={productInfo.helpUrl[language.toLowerCase()] || productInfo.helpUrl.eng}
            className='text-decoration-none'
            target='_blank'
            rel='noreferrer'
          >
            <DropdownItem className='header-menu__item'>
              {t('Header:documentation')}
              <Icon name='faExternalLinkAlt' size='sm' className='btn-icon right external-link' />
            </DropdownItem>
          </a>
        </Dropdown>
      </Popover>
    </>
  );
};

export default HelpMenu;
