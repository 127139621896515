import { NavLink } from 'react-router-dom';
import { Icon } from '@just-ai/just-ui';
import React from 'react';
import './Links.scss';
import { t } from '../../localization';
import classNames from 'classnames';

export default function Links({
  short,
  isAdmin,
  isIframeEnabled,
}: {
  short?: boolean;
  isAdmin: boolean;
  isIframeEnabled: boolean;
}) {
  const className = classNames('Link', { short: short });
  return (
    <>
      {(isAdmin || isIframeEnabled) && (
        <NavLink data-test-id='Sidebar.link.operator' to='/operator' className={className}>
          <Icon name='farComment' color='info' />
          {!short && t('Links:chats')}
        </NavLink>
      )}
      {isAdmin && (
        <>
          <NavLink data-test-id='Sidebar.link.analytics' to='/statistics' className={className}>
            <Icon name='farPoll' />
            {!short && t('Links:statistics')}
          </NavLink>
          <NavLink data-test-id='Sidebar.link.settings' to='/settings' className={className}>
            <Icon name='farCog' />
            {!short && t('Links:settings')}
          </NavLink>
        </>
      )}
    </>
  );
}
