var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { createContext } from 'react';
import webstomp from 'webstomp-client';
import SockJS from 'sockjs-client';
var RECONNECT_TIMEOUT = 5000;
var MAX_ATTEMPTS = 50; //0 - unlimited
export var WSContext = createContext({});
var WSHandler = /** @class */ (function (_super) {
    __extends(WSHandler, _super);
    function WSHandler(props) {
        var _this = _super.call(this, props) || this;
        _this.listeners = [];
        _this.subscribers = {};
        _this.reconnectTimerId = undefined;
        _this.queueSubscribers = [];
        _this.queueTimerId = undefined;
        _this.connectedPathsId = {};
        _this.queueMessages = [];
        _this.connectingToAccountId = NaN;
        _this.connectedAccountId = NaN;
        _this.state = {
            connectedAccountId: NaN,
            mounted: false,
            wasDisconnectFromServer: false,
            urlId: '',
            attempt: 0,
            reconnecting: false,
            reconnectingFailed: false,
        };
        _this.getUrlForWS = function () {
            var uri = _this.props.url || '/api/aimychat-be/websocket';
            var url = "".concat(window.location.protocol, "//").concat(window.location.host);
            return "".concat(url).concat(uri);
        };
        _this.convertSubscribersToQueue = function (subscribers) {
            var queue = [];
            Object.keys(subscribers).forEach(function (path) {
                Object.keys(subscribers[path]).forEach(function (id) {
                    queue.push({ path: path, callback: subscribers[path][id], id: id });
                });
            });
            return queue;
        };
        _this.onConnect = function () {
            var _a, _b, _c, _d;
            _this.connectedAccountId = _this.connectingToAccountId;
            _this.queueSubscribers = __spreadArray(__spreadArray([], _this.queueSubscribers, true), _this.convertSubscribersToQueue(_this.subscribers), true);
            _this.subscribers = {};
            var url = (_d = (_c = (_b = (_a = _this.stompClient) === null || _a === void 0 ? void 0 : _a.ws) === null || _b === void 0 ? void 0 : _b._transport) === null || _c === void 0 ? void 0 : _c.ws) === null || _d === void 0 ? void 0 : _d.url;
            if (url) {
                var urlId = url.split('/').reverse()[1];
                _this.setState({ urlId: urlId });
                _this.urlId = urlId;
            }
            var subscribersLength = _this.queueSubscribers.length;
            for (var i = 0; i < subscribersLength; i++) {
                var subscriber = _this.queueSubscribers.pop();
                subscriber && _this.subscribe(subscriber.path, subscriber.callback, subscriber.id);
            }
            var messagesLength = _this.queueMessages.length;
            for (var i = 0; i < messagesLength; i++) {
                var msg = _this.queueMessages.pop();
                msg && _this.send(msg.path, msg.message);
            }
            _this.clearReconnectTimerId();
        };
        _this.handleError = function (_a) {
            var message = _a.message, exception = _a.exception;
            if (_this.props.AppLogger) {
                _this.props.AppLogger.error({ message: message, exception: exception });
            }
            else {
                console.error('FORCED DISCONNECTED', message, exception);
            }
        };
        _this.onError = function (error) {
            var exception = {
                name: error.type,
                message: error.reason,
            };
            _this.setState({ connectedAccountId: NaN });
            if ('code' in error && error.code === 3000) {
                _this.handleError({ message: 'FORCED DISCONNECTED', exception: exception });
                _this.setState({ wasDisconnectFromServer: true });
            }
            else {
                _this.handleError({ message: 'WS STOMP CONNECTION FAIL', exception: exception });
                _this.attemptConnectAfterTimeout();
            }
        };
        _this.disconnect = function () {
            var _a;
            (_a = _this.stompClient) === null || _a === void 0 ? void 0 : _a.disconnect();
            _this.connectedAccountId = NaN;
        };
        _this.connect = function () {
            var _a, _b;
            if (process.env.REACT_APP_ENABLE_WS_PROXY !== 'true')
                return;
            _this.setState({ attempt: _this.state.attempt + 1 });
            _this.connectingToAccountId = _this.props.id;
            try {
                var ws = new SockJS(_this.getUrlForWS());
                ws.onclose = _this.attemptConnectAfterTimeout;
                ws.onerror = function (error) { return _this.onError(error); };
                if ((_a = _this.stompClient) === null || _a === void 0 ? void 0 : _a.connected)
                    return;
                _this.stompClient = webstomp.over(ws, { debug: false, heartbeat: { incoming: 1000, outgoing: 1000 } });
                (_b = _this.stompClient) === null || _b === void 0 ? void 0 : _b.connect({}, function () {
                    _this.setState({ attempt: 0 });
                    _this.setState({ reconnecting: false });
                    _this.onConnect();
                }, function (message) { return _this.onError(message); });
            }
            catch (error) {
                if (error instanceof Error) {
                    _this.handleError({ message: 'WS CONNECT ERROR', exception: error });
                }
                _this.attemptConnectAfterTimeout();
            }
            finally {
                _this.setState({ attempt: _this.state.attempt + 1 });
            }
        };
        _this.attemptConnectAfterTimeout = function () {
            _this.setState({ reconnecting: true, reconnectingFailed: false });
            if (_this.state.attempt < MAX_ATTEMPTS) {
                _this.reconnectTimerId = setTimeout(function () { return _this.connect(); }, RECONNECT_TIMEOUT);
                return;
            }
            else if (!_this.state.reconnecting) {
                _this.setState({ reconnecting: true });
            }
            _this.handleError({ message: "WS RECONNECT LIMIT REACHED: ".concat(MAX_ATTEMPTS) });
            _this.setState({ reconnecting: false, reconnectingFailed: true });
        };
        _this.send = function (path, message) {
            var _a;
            if ((_a = _this.stompClient) === null || _a === void 0 ? void 0 : _a.connected) {
                _this.stompClient.send("/app".concat(path), JSON.stringify(message));
            }
            else {
                _this.queueMessages.push({ path: path, message: message });
            }
        };
        _this.subscribe = function (rawPath, callback, oldId) {
            var _a;
            var id = oldId || String(performance.now());
            if (!((_a = _this.stompClient) === null || _a === void 0 ? void 0 : _a.connected)) {
                _this.queueSubscribers.push({
                    path: rawPath,
                    callback: callback,
                    id: id,
                });
                return id;
            }
            var prefix = '/user';
            var isPathWasConnected = rawPath.includes(prefix);
            var uriWithotPreviousConnectionId = isPathWasConnected ? "/".concat(rawPath.split('/').slice(3).join('/')) : rawPath;
            var path = "".concat(prefix, "/").concat(_this.urlId).concat(uriWithotPreviousConnectionId);
            if (Object.keys(_this.subscribers[path] || {}).length) {
                _this.subscribers[path][id] = callback;
                return id;
            }
            _this.subscribers[path] = {};
            _this.subscribers[path][id] = callback;
            _this.connectedPathsId[path] = _this.stompClient.subscribe(path, function (frame) {
                var body = frame && frame.body;
                try {
                    body = JSON.parse(body);
                }
                catch (e) {
                    console.error('WS PARSE BODY ERROR', e);
                }
                Object.keys(_this.subscribers[path]).forEach(function (id) { return _this.subscribers[path][id](body, frame); });
            }).id;
            return id;
        };
        _this.unsubscribe = function (path, id) {
            var _a;
            if (!((_a = _this.stompClient) === null || _a === void 0 ? void 0 : _a.connected)) {
                var deletedIndex = _this.queueSubscribers.findIndex(function (s) { return s.path === path && s.id === id; });
                if (deletedIndex !== -1) {
                    _this.queueSubscribers.splice(deletedIndex, 1);
                }
                return;
            }
            if (_this.subscribers[path] && _this.subscribers[path][id]) {
                delete _this.subscribers[path][id];
                if (Object.keys(_this.subscribers[path]).length === 0) {
                    _this.stompClient.unsubscribe(_this.connectedPathsId[path]);
                    delete _this.connectedPathsId[path];
                }
            }
        };
        _this.location = props.location;
        _this.navigate = props.navigate;
        return _this;
    }
    WSHandler.prototype.componentDidMount = function () {
        if (!this.props.id || this.props.id === -1)
            return;
        this.connect();
        this.setState({ mounted: true });
    };
    WSHandler.prototype.componentDidUpdate = function () {
        if (!this.props.id ||
            this.props.id === -1 ||
            this.props.id === this.connectedAccountId ||
            this.props.id === this.connectingToAccountId)
            return;
        this.connect();
    };
    WSHandler.prototype.clearReconnectTimerId = function () {
        this.setState({ attempt: 0 });
        if (this.reconnectTimerId) {
            clearTimeout(this.reconnectTimerId);
            this.reconnectTimerId = undefined;
        }
    };
    WSHandler.prototype.componentWillUnmount = function () {
        var _a;
        if ((_a = this.stompClient) === null || _a === void 0 ? void 0 : _a.connected)
            this.stompClient.disconnect();
        this.clearReconnectTimerId();
    };
    WSHandler.prototype.render = function () {
        if (!this.state.mounted) {
            return null;
        }
        return React.createElement(WSContext.Provider, { value: __assign(__assign({}, this), { id: this.props.id }) }, this.props.children);
    };
    return WSHandler;
}(React.Component));
export { WSHandler };
