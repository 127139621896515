import React, { useEffect } from 'react';
import { useOperatorDataContext } from '../../modules/OperatorPlace/OperatorDataContext';
import { t } from '../../localization';
import { BlockingBanner } from '../BlockingBanner/BlockingBanner';

export function NotificationFailedBanner() {
  const { showNotificationFailedBanner, hideNotificationFailedBanner } = useOperatorDataContext();

  useEffect(() => {
    const hideBanner = () => {
      if (showNotificationFailedBanner) {
        hideNotificationFailedBanner();
      }
    };

    document.addEventListener('click', hideBanner);

    return () => document.removeEventListener('click', hideBanner);
  }, [hideNotificationFailedBanner, showNotificationFailedBanner]);

  if (!showNotificationFailedBanner) {
    return null;
  }

  return (
    <BlockingBanner title={t('Notification:Failed:Alert')} withoutBackground onClose={hideNotificationFailedBanner} />
  );
}
