import React from 'react';
import { Modal, useToggle } from '@just-ai/just-ui';
import { t } from '../../localization';
import { useOperatorDataContext } from '../OperatorPlace/OperatorDataContext';
import { useAppContext } from '../../AppContext';

export function InitOflineModal({ onToggleOnline }: { onToggleOnline: () => void }) {
  const { isOnline } = useOperatorDataContext();
  const { productInfo } = useAppContext();

  const [showModal, , hideModal] = useToggle(!isOnline);

  if (isOnline || !showModal) return null;

  return (
    <Modal
      title={t('Header: init ofline modal title')}
      buttonSubmitText={t('Confirm')}
      buttonCancelText={t('Cancel')}
      buttonSubmitColor='primary'
      onCancelClick={hideModal}
      onActionClick={() => {
        onToggleOnline();
        hideModal();
      }}
      isOpen
    >
      <span>{t('Header: init ofline modal description', productInfo.productName)}</span>
    </Modal>
  );
}
