// tslint:disable
// @ts-nocheck
/**
 * Dto set for websocket connection
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ExternalChatHistoryMessageAuthorType;
(function (ExternalChatHistoryMessageAuthorType) {
    ExternalChatHistoryMessageAuthorType["CLIENT"] = "CLIENT";
    ExternalChatHistoryMessageAuthorType["COMPANION"] = "COMPANION";
})(ExternalChatHistoryMessageAuthorType || (ExternalChatHistoryMessageAuthorType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var GroupChatMessageAuthor;
(function (GroupChatMessageAuthor) {
    GroupChatMessageAuthor["OPERATOR"] = "OPERATOR";
    GroupChatMessageAuthor["CLIENT"] = "CLIENT";
    GroupChatMessageAuthor["SYSTEM"] = "SYSTEM";
})(GroupChatMessageAuthor || (GroupChatMessageAuthor = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var GroupChatMessageStatus;
(function (GroupChatMessageStatus) {
    GroupChatMessageStatus["CREATED"] = "CREATED";
    GroupChatMessageStatus["PENDING"] = "PENDING";
    GroupChatMessageStatus["SENT"] = "SENT";
    GroupChatMessageStatus["FAILED"] = "FAILED";
    GroupChatMessageStatus["TIMEOUT"] = "TIMEOUT";
})(GroupChatMessageStatus || (GroupChatMessageStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var GroupChatMessageType;
(function (GroupChatMessageType) {
    GroupChatMessageType["GROUP_CHAT_CREATED"] = "GROUP_CHAT_CREATED";
    GroupChatMessageType["GROUP_CHAT_DEACTIVATED"] = "GROUP_CHAT_DEACTIVATED";
    GroupChatMessageType["GROUP_CHAT_ACTIVATED"] = "GROUP_CHAT_ACTIVATED";
    GroupChatMessageType["GROUP_CHAT_OWNER_UPDATED"] = "GROUP_CHAT_OWNER_UPDATED";
    GroupChatMessageType["GROUP_CHAT_CLIENT_MESSAGE"] = "GROUP_CHAT_CLIENT_MESSAGE";
    GroupChatMessageType["GROUP_CHAT_OPERATOR_MESSAGE"] = "GROUP_CHAT_OPERATOR_MESSAGE";
    GroupChatMessageType["GROUP_CHAT_OPERATOR_JOINED"] = "GROUP_CHAT_OPERATOR_JOINED";
    GroupChatMessageType["GROUP_CHAT_OPERATOR_LEFT"] = "GROUP_CHAT_OPERATOR_LEFT";
    GroupChatMessageType["GROUP_CHAT_CLIENT_JOINED"] = "GROUP_CHAT_CLIENT_JOINED";
    GroupChatMessageType["GROUP_CHAT_CLIENT_LEFT"] = "GROUP_CHAT_CLIENT_LEFT";
    GroupChatMessageType["GROUP_CHAT_NAME_UPDATED"] = "GROUP_CHAT_NAME_UPDATED";
    GroupChatMessageType["GROUP_CHAT_SYSTEM_CUSTOM_EVENT"] = "GROUP_CHAT_SYSTEM_CUSTOM_EVENT";
})(GroupChatMessageType || (GroupChatMessageType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var GroupChatType;
(function (GroupChatType) {
    GroupChatType["PERSONAL"] = "PERSONAL";
    GroupChatType["GROUP"] = "GROUP";
    GroupChatType["DEAL"] = "DEAL";
})(GroupChatType || (GroupChatType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var HistoryDirection;
(function (HistoryDirection) {
    HistoryDirection["BEFORE"] = "BEFORE";
    HistoryDirection["AFTER"] = "AFTER";
    HistoryDirection["BIDIRECTIONALLY"] = "BIDIRECTIONALLY";
})(HistoryDirection || (HistoryDirection = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var MessageAuthor;
(function (MessageAuthor) {
    MessageAuthor["BOT"] = "BOT";
    MessageAuthor["OPERATOR"] = "OPERATOR";
    MessageAuthor["CLIENT"] = "CLIENT";
    MessageAuthor["SYSTEM"] = "SYSTEM";
})(MessageAuthor || (MessageAuthor = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var MessageFilterKey;
(function (MessageFilterKey) {
    MessageFilterKey["MESSAGES"] = "MESSAGES";
    MessageFilterKey["COMMENTS"] = "COMMENTS";
    MessageFilterKey["SYSTEMMESSAGES"] = "SYSTEM_MESSAGES";
})(MessageFilterKey || (MessageFilterKey = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var MessageStatus;
(function (MessageStatus) {
    MessageStatus["CREATED"] = "CREATED";
    MessageStatus["SENT"] = "SENT";
    MessageStatus["FAILED"] = "FAILED";
})(MessageStatus || (MessageStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var MessageType;
(function (MessageType) {
    MessageType["NEWOPERATORCHAT"] = "NEW_OPERATOR_CHAT";
    MessageType["CLIENTMESSAGE"] = "CLIENT_MESSAGE";
    MessageType["OPERATORMESSAGE"] = "OPERATOR_MESSAGE";
    MessageType["BOTMESSAGE"] = "BOT_MESSAGE";
    MessageType["OPERATORJOINED"] = "OPERATOR_JOINED";
    MessageType["OPERATORLEFT"] = "OPERATOR_LEFT";
    MessageType["CLIENTLEFT"] = "CLIENT_LEFT";
    MessageType["TRANSFERTOOPERATORGROUP"] = "TRANSFER_TO_OPERATOR_GROUP";
    MessageType["TRANSFERAFTEROPERATORSILENCE"] = "TRANSFER_AFTER_OPERATOR_SILENCE";
    MessageType["OPERATORCHATCOMPLETED"] = "OPERATOR_CHAT_COMPLETED";
    MessageType["CLIENTBLOCKINGBYOPERATOR"] = "CLIENT_BLOCKING_BY_OPERATOR";
    MessageType["CLIENTUNBLOCKINGBYOPERATOR"] = "CLIENT_UNBLOCKING_BY_OPERATOR";
    MessageType["COMMENT"] = "COMMENT";
    MessageType["OPERATORINVITEDTOTHECHAT"] = "OPERATOR_INVITED_TO_THE_CHAT";
    MessageType["MAINTAINERANDINVITEDOPERATORSWAP"] = "MAINTAINER_AND_INVITED_OPERATOR_SWAP";
})(MessageType || (MessageType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var OperatorChatStatus;
(function (OperatorChatStatus) {
    OperatorChatStatus["CREATED"] = "CREATED";
    OperatorChatStatus["ACTIVE"] = "ACTIVE";
    OperatorChatStatus["COMPLETED"] = "COMPLETED";
})(OperatorChatStatus || (OperatorChatStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var PrompterMessageType;
(function (PrompterMessageType) {
    PrompterMessageType["TEXT"] = "TEXT";
    PrompterMessageType["IMAGE"] = "IMAGE";
    PrompterMessageType["AUDIO"] = "AUDIO";
    PrompterMessageType["VIDEO"] = "VIDEO";
    PrompterMessageType["FILE"] = "FILE";
    PrompterMessageType["BUTTONS"] = "BUTTONS";
})(PrompterMessageType || (PrompterMessageType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var UpdateOperatorStatusAction;
(function (UpdateOperatorStatusAction) {
    UpdateOperatorStatusAction["CLOSEACTIVECHATS"] = "CLOSE_ACTIVE_CHATS";
    UpdateOperatorStatusAction["TRANSFERACTIVECHATSTOQUEUE"] = "TRANSFER_ACTIVE_CHATS_TO_QUEUE";
})(UpdateOperatorStatusAction || (UpdateOperatorStatusAction = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var WsErrorCode;
(function (WsErrorCode) {
    WsErrorCode["UNAUTHORIZED"] = "UNAUTHORIZED";
    WsErrorCode["ACCESSDENIED"] = "ACCESS_DENIED";
    WsErrorCode["OPERATORCHATNOTFOUND"] = "OPERATOR_CHAT_NOT_FOUND";
    WsErrorCode["OPERATORNOTFOUND"] = "OPERATOR_NOT_FOUND";
    WsErrorCode["OPERATORGROUPNOTFOUND"] = "OPERATOR_GROUP_NOT_FOUND";
    WsErrorCode["CLIENTISALREADYBLOCKED"] = "CLIENT_IS_ALREADY_BLOCKED";
    WsErrorCode["OPERATORCHATISNOTACTIVE"] = "OPERATOR_CHAT_IS_NOT_ACTIVE";
    WsErrorCode["OPERATORCHATISALREADYACTIVE"] = "OPERATOR_CHAT_IS_ALREADY_ACTIVE";
    WsErrorCode["TOOMANYACTIVECHATS"] = "TOO_MANY_ACTIVE_CHATS";
    WsErrorCode["OPERATORISNOTMEMBEROFCHAT"] = "OPERATOR_IS_NOT_MEMBER_OF_CHAT";
    WsErrorCode["PROMPTERNOTFOUND"] = "PROMPTER_NOT_FOUND";
    WsErrorCode["PROMPTERDOESNOTANSWER"] = "PROMPTER_DOES_NOT_ANSWER";
    WsErrorCode["OPERATORSNOTINTHESAMEGROUP"] = "OPERATORS_NOT_IN_THE_SAME_GROUP";
    WsErrorCode["REPEATEDOPERATORSINLIST"] = "REPEATED_OPERATORS_IN_LIST";
    WsErrorCode["OPERATORISNOTINVITEDOPERATOR"] = "OPERATOR_IS_NOT_INVITED_OPERATOR";
    WsErrorCode["OPERATORISNOTMAINOPERATOR"] = "OPERATOR_IS_NOT_MAIN_OPERATOR";
    WsErrorCode["OPERATORISMEMBEROFCHAT"] = "OPERATOR_IS_MEMBER_OF_CHAT";
    WsErrorCode["INCORRECTOPERATORTOSWAPWITH"] = "INCORRECT_OPERATOR_TO_SWAP_WITH";
    WsErrorCode["GROUPCHATNOTFOUND"] = "GROUP_CHAT_NOT_FOUND";
    WsErrorCode["OPERATORISNOTMEMBEROFGROUPCHAT"] = "OPERATOR_IS_NOT_MEMBER_OF_GROUP_CHAT";
    WsErrorCode["FAILEDTOCREATEGROUPCHAT"] = "FAILED_TO_CREATE_GROUP_CHAT";
    WsErrorCode["FAILEDTOUPDATEGROUPCHAT"] = "FAILED_TO_UPDATE_GROUP_CHAT";
    WsErrorCode["GROUPCHATMESSAGENOTFOUND"] = "GROUP_CHAT_MESSAGE_NOT_FOUND";
    WsErrorCode["FAILEDTOCREATEGROUPCHATREMINDER"] = "FAILED_TO_CREATE_GROUP_CHAT_REMINDER";
    WsErrorCode["FAILEDTOUPDATEGROUPCHATREMINDER"] = "FAILED_TO_UPDATE_GROUP_CHAT_REMINDER";
    WsErrorCode["FAILEDTODELETEGROUPCHATREMINDER"] = "FAILED_TO_DELETE_GROUP_CHAT_REMINDER";
    WsErrorCode["FAILEDTOCREATEGROUPCHATPOSTPONEDMESSAGE"] = "FAILED_TO_CREATE_GROUP_CHAT_POSTPONED_MESSAGE";
    WsErrorCode["FAILEDTOUPDATEGROUPCHATPOSTPONEDMESSAGE"] = "FAILED_TO_UPDATE_GROUP_CHAT_POSTPONED_MESSAGE";
    WsErrorCode["FAILEDTODELETEGROUPCHATPOSTPONEDMESSAGE"] = "FAILED_TO_DELETE_GROUP_CHAT_POSTPONED_MESSAGE";
})(WsErrorCode || (WsErrorCode = {}));
