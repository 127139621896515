import { useOperatorDataContext } from '../../modules/OperatorPlace/OperatorDataContext';
import { t } from '../../localization';
import { BlockingBanner } from '../BlockingBanner/BlockingBanner';

export function ReconnectionBanner() {
  const { reconnectingFailed, reconnecting } = useOperatorDataContext();

  if (!reconnectingFailed && !reconnecting) {
    return null;
  }

  return <BlockingBanner title={t('OperatorPlace: no connection')} withSpinner />;
}
