import React, { useCallback, useState } from 'react';
import { t } from '../../localization';
import { Tabs } from '@just-ai/just-ui';
import { useAppContext } from '../../AppContext';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';
import { StatisticsMainTab } from './Tabs/Main/Main';
import { Range } from '@just-ai/just-ui/dist/DatePicker';

import './Statistics.scss';
import { Blocks } from './Tabs/components/Blocks';
import { Bar } from './Tabs/components/Charts';
import { Filters } from './Tabs/components/Filters';
import { Outlet, Routes, useNavigate, Route, useParams } from 'react-router-dom';

export enum TABS {
  MAIN = 'main',
  GROUPS = 'groups',
  OPERATORS = 'operators',
}

export type Rest = {
  operatorId?: number;
  groupId?: number;
  startDate: Date;
  endDate: Date;
};

const getDate = (value: any, defaultDate: Date) => {
  if (!value) {
    return defaultDate;
  }
  const date = new Date(value);

  return date instanceof Date ? date : defaultDate;
};

function Statistics() {
  const { tab = TABS.MAIN } = useParams<{ tab: TABS }>();
  const { groups, operatorId: id } = useAppContext();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [groupId, setGroupId] = useState(sessionStorage.getItem('groupId') || groups[0].id);
  const [operatorId, setOperatorId] = useState(sessionStorage.getItem('operatorId') || id);
  const startDate = getDate(
    sessionStorage.getItem('startDate'),
    new Date(new Date().setDate(new Date().getDate() - 14))
  );
  const endDate = getDate(sessionStorage.getItem('endDate'), new Date());

  const [range, setRange] = useState<Range>({ startDate, endDate });
  const onChangeTab = useCallback(
    (value: string) => {
      if (tab !== value) {
        navigate(`/statistics/${value}`, { replace: true });
      }
    },
    [navigate, tab]
  );

  const tabs = Object.values(TABS).map(tab => ({
    name: t(`Statistics: tab ${tab}`),
    value: tab,
    dataTestId: `Statistics.Tab.${tab}`,
  }));

  const rest: Rest = {
    ...range,
    groupId: tab === TABS.GROUPS ? +groupId : undefined,
    operatorId: tab === TABS.OPERATORS ? +operatorId : undefined,
  };

  return (
    <div className='Statistics'>
      <h2>{t('Statistics:main header')}</h2>
      <div className='Statistics_tabs'>
        <div className='Statistics_tabs_navigator'>
          <Tabs tabs={tabs} fullWidth={isMobile} onChange={onChangeTab} activeTab={tab} />
        </div>
        <p className='Statistics_tabs_description'>
          <small>{t(`Statistics: title ${tab}`)}</small>
        </p>
        <Filters
          range={range}
          setRange={setRange}
          groupId={+groupId}
          setGroupId={setGroupId}
          operatorId={+operatorId}
          setOperatorId={setOperatorId}
        />
        <Bar {...rest} />
        <Blocks {...rest} />

        <Outlet />

        <Routes>
          <Route path={TABS.MAIN} element={<StatisticsMainTab range={range} />} />
        </Routes>
      </div>
    </div>
  );
}

export default Statistics;
