import { memo } from 'react';
import { Button } from '@just-ai/just-ui';
import { t } from '../../../../localization';
import { useOperatorDataContext } from '../../OperatorDataContext';

export const UnBanButton = memo(() => {
  const { chosenChat, unbanChat } = useOperatorDataContext();
  const isBlocked = !!chosenChat?.blockingInfo?.isBlocked;

  if (!isBlocked) {
    return null;
  }
  return (
    <Button
      data-test-id='Chat.Header.Info.unblockButton'
      outline
      color='secondary'
      className='flex-row'
      onClick={unbanChat}
      size='sm'
    >
      <span>{t('OperatorPlace: header unblock the dialog')}</span>
    </Button>
  );
});
