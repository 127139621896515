import { InputText } from '@just-ai/just-ui';
import { memo, useCallback } from 'react';
import classes from './TimeInput.module.scss';

type Props = {
  id: string;
  title: string;
  min?: number;
  max?: number;
  value?: number;
  dataTestId: string;
  onChange: (value: number) => void;
};

export const TimeInput = memo(({ onChange, value = 0, min = 0, max = 59, title, id, dataTestId }: Props) => {
  const handleChange = useCallback((value: string) => onChange(Number.parseInt(value)), [onChange]);

  return (
    <div className={classes.Wrapper}>
      <InputText
        id={id}
        data-test-id={dataTestId}
        onChange={handleChange}
        value={value.toString()}
        min={min}
        max={max}
        compact
      />
      <div className={classes.Description}>{title}</div>
    </div>
  );
});
