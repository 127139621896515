import 'chart.js/auto';
import { JustSelect, Spinner } from '@just-ai/just-ui';
import { memo, useEffect, useMemo, useState } from 'react';
import { Bar as ChartsJs2Bar } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../../../AppContext';
import { t } from '../../../../localization';
import { Rest, TABS } from '../../Statistics';
import { ChartType, IntervalType } from '@just-ai/aimychat-shared/dist/api/client/statistics';
import { StatisticsChartService } from '@just-ai/aimychat-shared/dist/service/Statistics/StatisticsChartService';
import { ChartDataset } from 'chart.js/auto';

type Data = {
  labels?: number[] | string[];
  datasets?: ChartDataset<'bar', number[]>[];
};

export const Bar = memo(({ operatorId, groupId, startDate, endDate }: Rest) => {
  const { language, id } = useAppContext();
  const { tab = TABS.MAIN } = useParams<{ tab: TABS }>();
  const [data, setData] = useState<Data>();
  const [chartType, setChartType] = useState(ChartType.CHATSCOUNT);
  const [intervalType, setIntervalType] = useState(IntervalType.DAY);

  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);

  const locale = language.toLocaleLowerCase().indexOf('cn') > -1 ? 'zh-CN' : language;

  const statisticsService = useMemo(
    () => new StatisticsChartService({ accountId: id, startDate, endDate, chartType, intervalType }),
    [chartType, endDate, id, intervalType, startDate]
  );

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          precision: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const handleChageChartType = value => setChartType(value);
  const handleChangeIntervalType = value => setIntervalType(value);

  useEffect(() => {
    const getCharts = async () => {
      let data;
      switch (tab) {
        case TABS.MAIN:
          data = await statisticsService.getCommonChart();
          break;
        case TABS.GROUPS:
          if (groupId) {
            data = await statisticsService.getOperatorGroupChart(groupId);
          }
          break;
        case TABS.OPERATORS:
          if (operatorId) {
            data = await statisticsService.getOperatorChart(operatorId);
          }
          break;
        default:
      }

      setData(data);
    };

    getCharts();
  }, [chartType, endDate, groupId, intervalType, operatorId, startDate, statisticsService, tab]);

  const labels = useMemo(
    () =>
      data?.labels?.map(
        label =>
          `${new Date(label).toLocaleString(locale, {
            month: 'numeric',
            day: 'numeric',
          })}`
      ) || [],
    [data?.labels, locale]
  );

  if (!data) {
    return <Spinner size='1x' />;
  }

  const { datasets = [] } = data;

  datasets.forEach(dataset => (dataset.label = t(`Statistics: summary ${dataset.label}`)));

  ['#8BB588', '#DC6C63'].forEach((color, index) => {
    const dataset = datasets[index];
    if (dataset) {
      dataset.backgroundColor = color;
    }
  });

  return (
    <div className='Layout'>
      <div className='Filters'>
        <JustSelect
          className='ChartTypeSelector'
          defaultValue={chartType}
          data-test-id='ChartTypeSelectortDataTestId'
          onChange={handleChageChartType}
          options={Object.values(ChartType)
            .filter(type => (tab === TABS.MAIN ? type !== ChartType.AVERAGECONNECTTIME : true))
            .map(type => ({ label: t(`Statistics: charts ${type}`), value: type }))}
          size='xs'
        />

        <JustSelect
          className='IntervalTypeSelector'
          defaultValue={intervalType}
          data-test-id='IntervalTypeSelectorDataTestId'
          onChange={handleChangeIntervalType}
          options={Object.values(IntervalType).map(type => ({
            label: t(`Statistics: intervals ${type.toLowerCase()}`),
            value: type,
          }))}
          size='xs'
        />
      </div>

      <div className='Charts'>
        <ChartsJs2Bar data={{ labels, datasets }} options={options} />
      </div>
    </div>
  );
});
