import React, { memo, useEffect, useMemo, useState } from 'react';

import { t } from '../../../../../../localization';
import { Checkbox, usePromiseProcessing } from '@just-ai/just-ui';
import { OperatorGroupFullDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { useAppContext } from '../../../../../../AppContext';
import { Spinner } from 'reactstrap';
import { IFrameOperatorGroupDto, UpdateOperatorGroupIFrameDto } from '@just-ai/api/dist/generated/Aimychat';

type Props = {
  group?: OperatorGroupFullDto;
  setIframes: React.Dispatch<React.SetStateAction<UpdateOperatorGroupIFrameDto[]>>;
};

export const FramesTab = memo(({ group, setIframes }: Props) => {
  const { OperatorPlaceIFrameService } = useAppContext();

  const [groupIFramesState, getOperatorGroupIFrames] = usePromiseProcessing((id: number) =>
    OperatorPlaceIFrameService.getOperatorGroupIFrames(id)
  );

  const [getAllIFramesState, getAllIFrames] = usePromiseProcessing(() =>
    OperatorPlaceIFrameService.getOperatorPlaceIFrames()
  );

  const iframesDry = useMemo(
    () =>
      group
        ? groupIFramesState.result?.iframes || []
        : (getAllIFramesState.result?.iframes || []).map(({ id, name }) => ({ id, name, isEnabled: false })),
    [getAllIFramesState.result?.iframes, group, groupIFramesState.result?.iframes]
  );

  const [currentIframes, setCurrentIframes] = useState<IFrameOperatorGroupDto[]>([]);

  const handleChangeGroupIFrames = ({ id, isEnabled }: IFrameOperatorGroupDto) => {
    setCurrentIframes(iframes =>
      iframes.map(iframe => ({ ...iframe, isEnabled: iframe.id === id ? !isEnabled : iframe.isEnabled }))
    );
  };

  useEffect(() => {
    setIframes(currentIframes);
  }, [currentIframes, setIframes]);

  useEffect(() => {
    const id = group?.id;
    if (id) {
      getOperatorGroupIFrames(id);
    } else {
      getAllIFrames();
    }
  }, [getAllIFrames, getOperatorGroupIFrames, group?.id]);

  useEffect(() => {
    if (groupIFramesState.result?.iframes) {
      setIframes(groupIFramesState.result?.iframes);
    }
  }, [groupIFramesState.result?.iframes, setIframes]);

  useEffect(() => {
    setCurrentIframes(iframesDry);
  }, [iframesDry]);

  return (
    <>
      <p>
        <b>{t('Settings:Frames:Title')}</b>
      </p>
      <p>
        <small>{t('Settings:Groups:Frames:Description')}</small>
      </p>
      {groupIFramesState.loading || (getAllIFramesState.loading && <Spinner size='sm' />)}
      {currentIframes.map(group => (
        <Checkbox
          key={group.id}
          name={group.name}
          label={group.name}
          checked={group.isEnabled}
          onChange={() => handleChangeGroupIFrames(group)}
        />
      ))}
    </>
  );
});
