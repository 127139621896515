var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OperatorPlaceIFrameApi, } from "../../generated/Aimychat";
export default class OperatorPlaceIFrameService {
    constructor(axios, accountId) {
        this.OperatorPlaceIFrameService = new OperatorPlaceIFrameApi({}, OperatorPlaceIFrameService.BASE_PATH, axios);
        this.accountId = accountId;
    }
    createOperatorPlaceIFrame(iFrameCreateDto) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.OperatorPlaceIFrameService.createOperatorPlaceIFrame(this.accountId, iFrameCreateDto);
            return data;
        });
    }
    deleteOperatorPlaceIFrame(iFrameDeleteDto) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.OperatorPlaceIFrameService.deleteOperatorPlaceIFrame(this.accountId, iFrameDeleteDto);
            return data;
        });
    }
    updateOperatorPlaceIFrame(iFrameUpdateDto) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.OperatorPlaceIFrameService.updateOperatorPlaceIFrame(this.accountId, iFrameUpdateDto);
            return data;
        });
    }
    getOperatorGroupIFrames(operatorGroupId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.OperatorPlaceIFrameService.getOperatorGroupIFrames(this.accountId, operatorGroupId);
            return data;
        });
    }
    getOperatorPlaceIFrames() {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.OperatorPlaceIFrameService.getOperatorPlaceIFrames(this.accountId);
            return data;
        });
    }
    getOperatorIFrames(operatorId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.OperatorPlaceIFrameService.getOperatorIFrames(this.accountId, operatorId);
            return data;
        });
    }
    updateOperatorGroupIFrame(operatorGroupId, updateOperatorGroupIFramesDto) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.OperatorPlaceIFrameService.updateOperatorGroupIFrame(this.accountId, operatorGroupId, updateOperatorGroupIFramesDto);
            return data;
        });
    }
}
OperatorPlaceIFrameService.BASE_PATH = "";
