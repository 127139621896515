import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { formatNumberToString, Icon, Button, Select, InputText, Popover, SwitchButton } from '@just-ai/just-ui';
import { useToggle } from '../../../../utils/hooks';
import './ActionPanel.scss';
import { AnalyticsContext } from '../../../../context/AnalyticsContext';
import classNames from 'classnames';
export const NUMBER_KEYS = {
    sessions: {
        '1': 'Analytics: one session',
        '2': 'Analytics: two sessions',
        '3': 'Analytics: two sessions',
        '4': 'Analytics: two sessions',
    },
    messages: {
        '1': 'Analytics: one message',
        '2': 'Analytics: two messages',
        '3': 'Analytics: two messages',
        '4': 'Analytics: two messages',
    },
    routes: {
        '1': 'Analytics: one route',
        '2': 'Analytics: two routes',
        '3': 'Analytics: two routes',
        '4': 'Analytics: two routes',
    },
    states: {
        '1': 'Analytics: one state',
        '2': 'Analytics: two states',
        '3': 'Analytics: two states',
        '4': 'Analytics: two states',
    },
    clients: {},
};
export default function ActionPanel({ totalCount, totalPages, page, t, size, downloadReport, position, changePageSize: rawChangePageSize, changePage: rawChangePage, withViewControl, showAnswers, compactView, showAdditionalAction, showMarkup, setShowAnswers, setShowAdditionalAction, setCompactView, setShowMarkup, type, totalLabel, downloadReportComponent, hideTotalCount, withoutToThePage, setHideEmptyMessages, hideEmptyMessages, leftSideOrder = ['Counter'], rightSideOrder = ['ViewControl', 'Size', 'Paginator'], className, }) {
    const changePageSize = useCallback((x) => {
        //@ts-ignore
        rawChangePageSize(Number.parseInt(x.value));
    }, [rawChangePageSize]);
    const changePage = useCallback((x) => rawChangePage(x.selected), [rawChangePage]);
    const setPage = useCallback((e) => {
        if (e.key === 'Enter') {
            let value = Number.parseInt(e.target.value) - 1;
            if (value < 0 || Number.isNaN(value))
                value = 0;
            if (value > totalPages - 1)
                value = totalPages - 1;
            //@ts-ignore
            e.target.value = (value + 1).toString();
            rawChangePage(value);
        }
    }, [rawChangePage, totalPages]);
    const renderComponents = (component) => {
        if (component === 'Paginator' && totalPages > 1) {
            return (_jsx(Paginator, { totalPages: totalPages, changePage: changePage, page: page, withoutToThePage: withoutToThePage, setPage: setPage, t: t }, component));
        }
        if (component === 'Size' && rawChangePageSize) {
            return _jsx(Size, { changePageSize: changePageSize, t: t, position: position, size: size }, component);
        }
        if (component === 'ViewControl' && withViewControl) {
            return (_jsx(ViewControl, { t: t, showAnswers: showAnswers, setShowAnswers: setShowAnswers, showMarkup: showMarkup, setShowMarkup: setShowMarkup, showAdditionalAction: showAdditionalAction, setShowAdditionalAction: setShowAdditionalAction }, component));
        }
        if (component === 'Counter' && !hideTotalCount) {
            return _jsx(Counter, { totalCount: totalCount, t: t, type: type, totalLabel: totalLabel }, void 0);
        }
        return null;
    };
    const RightSide = rightSideOrder.map(renderComponents);
    const LeftSide = leftSideOrder.map(renderComponents);
    return (_jsxs("div", Object.assign({ className: `${className || ''} AnalyticActionPanel_actionPanel`, id: 'scrollToElement' }, { children: [_jsxs("div", Object.assign({ className: 'AnalyticActionPanel_leftSide' }, { children: [LeftSide, setCompactView && (_jsx(SwitchButton, { id: 'showMarkup', value: !compactView, onLabel: t('Analytics: messages compact view'), offLabel: t('Analytics: messages compact view'), onChange: setCompactView, labelPosition: 'right' }, void 0)), setHideEmptyMessages && (_jsx("div", Object.assign({ style: { marginLeft: '0.5rem' } }, { children: _jsx(SwitchButton, { id: 'hideEmptyMessages', value: hideEmptyMessages, onLabel: t('Analytics: hide empty message label'), offLabel: t('Analytics: hide empty message label'), onChange: setHideEmptyMessages, labelPosition: 'right' }, void 0) }), void 0)), downloadReport && _jsx(DownloadReportButton, { t: t, type: type, downloadReport: downloadReport }, void 0), downloadReportComponent && downloadReportComponent()] }), void 0), _jsx("div", Object.assign({ className: 'AnalyticActionPanel_rightSide' }, { children: RightSide }), void 0)] }), void 0));
}
function DownloadReportButton({ t, type, downloadReport, }) {
    return (_jsxs(Button, Object.assign({ "data-test-id": 'dialogLogDownload', flat: true, color: 'secondary', className: 'AnalyticActionPanel_downloadReporter', onClick: downloadReport, withoutPadding: true }, { children: [_jsx(Icon, { name: 'farDownload' }, void 0), " ", t(`Analytics: download reports ${type}`)] }), void 0));
}
function Counter({ totalLabel, t, totalCount, type, }) {
    //@ts-ignore
    let key = NUMBER_KEYS[type] && NUMBER_KEYS[type][totalCount.toString()];
    if (!key && type)
        key = `Analytics: ${type}`;
    const number = formatNumberToString({ value: totalCount });
    return (_jsx("span", Object.assign({ "data-test-id": 'analytic-found-count' }, { children: totalLabel ? totalLabel() : t('Analytics: found', key ? t(key, number) : number) }), void 0));
}
function Paginator({ totalPages, changePage, page, withoutToThePage, setPage, t, }) {
    return (_jsxs(_Fragment, { children: [' ', _jsx(ReactPaginate, { pageCount: totalPages, pageRangeDisplayed: 2, marginPagesDisplayed: 2, onPageChange: changePage, forcePage: page, containerClassName: 'AnalyticActionPanel_paginator', pageClassName: 'AnalyticActionPanel_page', pageLinkClassName: 'AnalyticActionPanel_page_link', breakClassName: 'AnalyticActionPanel_page', breakLinkClassName: 'AnalyticActionPanel_page_link', activeClassName: 'AnalyticActionPanel_active', previousClassName: 'AnalyticActionPanel_previous', nextClassName: 'AnalyticActionPanel_next', previousLinkClassName: 'AnalyticActionPanel_previous-link', nextLinkClassName: 'AnalyticActionPanel_next-link', nextLabel: _jsx(Icon, { name: 'farChevronRight', "data-test-id": 'pagination-next', id: 'pagination-next' }, void 0), previousLabel: _jsx(Icon, { name: 'farChevronLeft', "data-test-id": 'pagination-previous', id: 'pagination-previous' }, void 0) }, void 0), _jsx("div", Object.assign({ className: 'AnalyticActionPanel_setPage_input_container', style: { gridTemplateColumns: `auto calc(2rem + ${(page + 1).toString().length} * 0.75rem)` } }, { children: !withoutToThePage && (_jsxs(_Fragment, { children: [_jsx("span", { children: t('Analytics: To the page') }, void 0), _jsx(InputText, { "data-test-id": 'AnalyticActionPanel-page-input', type: 'number', className: 'AnalyticActionPanel_setPage_input', value: (page + 1).toString(), onBlur: setPage, min: 1, onKeyDown: setPage }, void 0)] }, void 0)) }), void 0)] }, void 0));
}
function Size({ t, changePageSize, position, size, }) {
    return (_jsxs("div", Object.assign({ className: 'AnalyticActionPanel_pageSizeControl', "data-test-id": 'AnalyticActionPanel-page-size-wrapper' }, { children: [t('Analytics: Show'), _jsx(Select, { "data-test-id": 'AnalyticActionPanel-page-size', onChange: changePageSize, menuPlacement: position === 'bottom' ? 'top' : 'bottom', value: { value: (size === null || size === void 0 ? void 0 : size.toString()) || '0', label: (size === null || size === void 0 ? void 0 : size.toString()) || '0' }, options: ['20', '50', '100'].map(x => ({ value: x, label: x })) }, void 0)] }), void 0));
}
function ViewControl({ showAnswers, t, setShowAnswers, showMarkup, setShowMarkup, showAdditionalAction, setShowAdditionalAction, }) {
    var _a, _b, _c;
    const { config } = useContext(AnalyticsContext);
    const [isOpen, , setClose, toggle] = useToggle(false);
    return (_jsxs("div", { children: [_jsxs(Button, Object.assign({ id: 'messagesViewShowControlButton', "data-test-id": 'phrasesViewShowControl', className: classNames('AnalyticActionPanel_showSettingsButton', isOpen && 'AnalyticActionPanel_openSettingsButton'), flat: true, color: 'secondary', onClick: toggle }, { children: [_jsx(Icon, { name: 'farSlidersH' }, void 0), " ", t('Analytics: messages view settings')] }), void 0), _jsxs(Popover, Object.assign({ isOpen: isOpen, target: 'messagesViewShowControlButton', onCancelClick: setClose, hideArrow: true }, { children: [((_a = config.messages) === null || _a === void 0 ? void 0 : _a.showAnswerViewControl) && (_jsx(SwitchButton, { id: 'showAnswers', "data-test-id": 'AnalyticActionPanel-showAnswers', value: showAnswers, labelPosition: 'right', onLabel: t('Analytics: messages view settings show answers'), offLabel: t('Analytics: messages view settings show answers'), onChange: setShowAnswers }, void 0)), ((_b = config.messages) === null || _b === void 0 ? void 0 : _b.showMarkupViewControl) && (_jsx(SwitchButton, { id: 'showMarkup', "data-test-id": 'AnalyticActionPanel-showMarkup', value: showMarkup, onLabel: t('Analytics: messages view settings show markup'), offLabel: t('Analytics: messages view settings show markup'), onChange: setShowMarkup, labelPosition: 'right' }, void 0)), ((_c = config.messages) === null || _c === void 0 ? void 0 : _c.showAdditionalActionsViewControl) && (_jsx(SwitchButton, { id: 'showAdditionalAction', "data-test-id": 'AnalyticActionPanel-showAdditionalAction', value: showAdditionalAction, onLabel: t('Analytics: messages view settings show additional action'), offLabel: t('Analytics: messages view settings show additional action'), onChange: setShowAdditionalAction, labelPosition: 'right' }, void 0))] }), void 0)] }, void 0));
}
