import { Icon, Tooltip } from '@just-ai/just-ui';

import { memo } from 'react';
import { t } from '../../../../localization';

type Props = {
  label: string;
  value: number | string;
  tab: string;
};

export const Block = memo(({ label, value, tab }: Props) => {
  if (value === null) {
    return null;
  }
  return (
    <div className='Block'>
      <div>
        <p>
          <small>{t(`Statistics: summary ${label}`)}</small>
        </p>
        <Icon id={label} name='farQuestionCircle' color='secondary' />
        <Tooltip placement='top' target={label} textAlign='left' delay={{ show: 400, hide: 200 }}>
          <small>{t(`Statistics: tooltip ${tab} ${label}`)}</small>
        </Tooltip>
      </div>
      <h3>{value}</h3>
    </div>
  );
});
