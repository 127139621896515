import { useEffect, useRef } from 'react';
import { OperatorDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { ClientInfoDto, OperatorWsDto } from '@just-ai/aimychat-shared/dist/api/client/operator';
import { t } from '../../localization';

export const getUserFullName = (clientInfo?: OperatorDto | ClientInfoDto | OperatorWsDto) => {
  return clientInfo ? `${clientInfo?.firstName || ''} ${clientInfo?.lastName || ''}`.trim() : t('OperatorPlace: bot');
};
export function useWSCallbacks(callbacks: { success?: () => void; error?: () => void }) {
  const callbackRef = useRef<{ success?: () => void; error?: () => void }>({
    ...callbacks,
  });

  useEffect(() => {
    return () => {
      callbackRef.current.success = undefined;
      callbackRef.current.error = undefined;
    };
  }, [callbackRef.current]);

  return callbackRef.current;
}
