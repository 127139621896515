import { commonLocalization } from './common.loc';
import { headerLocalization } from '../modules/Header/header.loc';
import { settingsLocalization } from '../modules/Settings/settings.loc';
import { statisticsLocalization } from '../modules/Statistics/statistics.loc.js';
import { operatorPlaceLocalization } from '@just-ai/aimychat-shared';
import { Decliner } from './decliner';
import { channelsLocalization } from './channels.loc';

const Localize = require('localize');

const localization = new Localize({
  ...commonLocalization,
  ...channelsLocalization,
  ...headerLocalization,
  ...settingsLocalization,
  ...operatorPlaceLocalization,
  ...statisticsLocalization,
});
localization.addTranslations = translate => {
  const mapKeys = (obj, mapper) =>
    Object.entries(obj).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [mapper(value, key)]: value,
      }),
      {}
    );
  mapKeys(translate, key => {
    if (!localization.getTranslations().hasOwnProperty(key)) {
      localization.loadTranslations(translate);
    }
  });
};

localization.checkExistKey = key => {
  return key !== localization.translate(key);
};

localization.translate.decline = (arr, count) => {
  return new Decliner(arr).decline(count);
};

localization.throwOnMissingTranslation(false);

export default localization;

export const { translate: t } = localization;
