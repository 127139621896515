import { t } from '../../../localization';
import { useAppContext } from '../../../AppContext';
import { IconButton, JustSelect } from '@just-ai/just-ui';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  OperatorPlaceDto,
  SoundNotificationDto,
  SoundNotificationMode as NotificationMode,
} from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { AppLogger } from '@just-ai/logger';

type Props = {
  operatorPlace: OperatorPlaceDto;
};

export const SoundNotifications = memo(({ operatorPlace }: Props) => {
  const { operatorPlaceService } = useAppContext();
  const [soundOptions, setSoundOptions] = useState<SoundNotificationDto[]>();

  const notificationModeOptions = useMemo(
    () => Object.values(NotificationMode).map(value => ({ value, label: t(`SoundNotifications option ${value}`) })),
    []
  );
  const { notification, notificationMode = notificationModeOptions[0].value } = operatorPlace;
  const [audio, setAudio] = useState(new Audio(notification?.value));
  const options = useMemo(
    () => soundOptions?.map(option => ({ value: option.label, label: option.label })),
    [soundOptions]
  );

  const getNotificationModeOption = useCallback(
    (value: NotificationMode) => notificationModeOptions.find(option => option.value === value),
    [notificationModeOptions]
  );

  const [notificationModeOption, setNotificationModeOption] = useState(getNotificationModeOption(notificationMode));
  const [soundOption, setSoundOption] = useState(notification);

  const changeNotificationMode = useCallback(
    ([value]) => {
      operatorPlaceService.updateOperatorPlace({ ...operatorPlace, notificationMode: value });
      setNotificationModeOption(getNotificationModeOption(value));
    },
    [getNotificationModeOption, operatorPlace, operatorPlaceService]
  );

  const changeSound = useCallback(
    ([label]) => {
      const notification = soundOptions?.find(option => option.label === label);
      if (!notification) return;
      audio.src = notification.value;
      setAudio(audio);
      if (notification) {
        setSoundOption(notification);
        operatorPlaceService.updateOperatorPlace({ ...operatorPlace, notification });
      }
    },
    [audio, operatorPlace, operatorPlaceService, soundOptions]
  );

  const playSound = useCallback(() => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
      audio
        .play()
        .catch(error =>
          AppLogger.error({ message: `Notification playing is failed here "audio.play()": ${JSON.stringify(error)}` })
        );
    }
  }, [audio]);

  useEffect(() => {
    if (soundOptions) return;

    const getSoundNotifications = async () => {
      const { notifications } = await operatorPlaceService.getAllSoundNotifications();
      setSoundOptions(notifications);
    };

    getSoundNotifications();
  }, [soundOptions, operatorPlaceService]);

  return (
    <div className='SoundNotifications__wrapper'>
      <JustSelect
        data-test-id='SoundNotifications.option'
        onChange={changeNotificationMode}
        value={notificationModeOption?.value}
        options={notificationModeOptions}
      />
      <div className='playSourceContainer'>
        <JustSelect
          data-test-id='ChatWidgetEditor.SoundNotifications.sound'
          onChange={changeSound}
          value={soundOption?.label}
          options={options}
        />
        <IconButton
          flat
          id='SoundNotifications.button.play'
          data-test-id='SoundNotifications.button.play'
          name='farPlay'
          onClick={playSound}
          disabled={!notification?.value}
          outline
          color='secondary'
        />
      </div>
    </div>
  );
});
