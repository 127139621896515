// tslint:disable
// @ts-nocheck
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as globalImportUrl from 'url';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError } from './base';
/**
 *
 * @export
 * @enum {string}
 */
export var AccountBlockType;
(function (AccountBlockType) {
    AccountBlockType["NONE"] = "NONE";
    AccountBlockType["NOTPAYED"] = "NOT_PAYED";
    AccountBlockType["FULL"] = "FULL";
})(AccountBlockType || (AccountBlockType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var AccountStatus;
(function (AccountStatus) {
    AccountStatus["ACTIVE"] = "ACTIVE";
    AccountStatus["PAYMENTBLOCKED"] = "PAYMENT_BLOCKED";
    AccountStatus["FULLBLOCKED"] = "FULL_BLOCKED";
})(AccountStatus || (AccountStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var BlockType;
(function (BlockType) {
    BlockType["NONE"] = "NONE";
    BlockType["NOTPAYED"] = "NOT_PAYED";
    BlockType["FULL"] = "FULL";
})(BlockType || (BlockType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ConversationalCloudProduct;
(function (ConversationalCloudProduct) {
    ConversationalCloudProduct["Jaicp"] = "jaicp";
    ConversationalCloudProduct["Aimylogic"] = "aimylogic";
    ConversationalCloudProduct["Aimyvoice"] = "aimyvoice";
    ConversationalCloudProduct["Cc"] = "cc";
    ConversationalCloudProduct["Caila"] = "caila";
    ConversationalCloudProduct["Aimychat"] = "aimychat";
})(ConversationalCloudProduct || (ConversationalCloudProduct = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var Locale;
(function (Locale) {
    Locale["RU"] = "RU";
    Locale["EN"] = "EN";
    Locale["CN"] = "CN";
    Locale["PT"] = "PT";
    Locale["KZ"] = "KZ";
    Locale["ES"] = "ES";
})(Locale || (Locale = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var PartnerState;
(function (PartnerState) {
    PartnerState["ACTIVE"] = "ACTIVE";
    PartnerState["BLOCKED"] = "BLOCKED";
    PartnerState["UNVERIFIED"] = "UNVERIFIED";
})(PartnerState || (PartnerState = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var PartnerType;
(function (PartnerType) {
    PartnerType["JAICP"] = "JAICP";
    PartnerType["AIMYLOGIC"] = "AIMYLOGIC";
    PartnerType["JAICPANDAIMYLOGIC"] = "JAICP_AND_AIMYLOGIC";
})(PartnerType || (PartnerType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var PaymentSystem;
(function (PaymentSystem) {
    PaymentSystem["YANDEXCASH"] = "YANDEX_CASH";
    PaymentSystem["UNDEFINED"] = "UNDEFINED";
    PaymentSystem["SYSTEMCASH"] = "SYSTEM_CASH";
    PaymentSystem["STRIPE"] = "STRIPE";
})(PaymentSystem || (PaymentSystem = {}));
/**
 * AccountInvitationEndpointApi - axios parameter creator
 * @export
 */
export var AccountInvitationEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} userId
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationByToken: function (userId, token, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling acceptInvitationByToken.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token', 'Required parameter token was null or undefined when calling acceptInvitationByToken.');
            }
            var localVarPath = "/api/accountsadmin/c/account-invitation/accept-by-token";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationByUserId: function (userId, accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling acceptInvitationByUserId.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling acceptInvitationByUserId.');
            }
            var localVarPath = "/api/accountsadmin/c/account-invitation/accept-by-user-id";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }
            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} invitationId
         * @param {ChangeRolesDto} changeRolesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeInvitationRoles: function (invitationId, changeRolesDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'invitationId' is not null or undefined
            if (invitationId === null || invitationId === undefined) {
                throw new RequiredError('invitationId', 'Required parameter invitationId was null or undefined when calling changeInvitationRoles.');
            }
            // verify required parameter 'changeRolesDto' is not null or undefined
            if (changeRolesDto === null || changeRolesDto === undefined) {
                throw new RequiredError('changeRolesDto', 'Required parameter changeRolesDto was null or undefined when calling changeInvitationRoles.');
            }
            var localVarPath = "/api/accountsadmin/c/account-invitation/{invitationId}/roles/change"
                .replace("{".concat("invitationId", "}"), encodeURIComponent(String(invitationId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof changeRolesDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(changeRolesDto !== undefined ? changeRolesDto : {}) : (changeRolesDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} invitationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitation: function (invitationId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'invitationId' is not null or undefined
            if (invitationId === null || invitationId === undefined) {
                throw new RequiredError('invitationId', 'Required parameter invitationId was null or undefined when calling deleteInvitation.');
            }
            var localVarPath = "/api/accountsadmin/c/account-invitation/{invitationId}"
                .replace("{".concat("invitationId", "}"), encodeURIComponent(String(invitationId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} page
         * @param {number} size
         * @param {string} searchText
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByLoginOrFullNameInAccount: function (accountId, page, size, searchText, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling findByLoginOrFullNameInAccount.');
            }
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page', 'Required parameter page was null or undefined when calling findByLoginOrFullNameInAccount.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size', 'Required parameter size was null or undefined when calling findByLoginOrFullNameInAccount.');
            }
            // verify required parameter 'searchText' is not null or undefined
            if (searchText === null || searchText === undefined) {
                throw new RequiredError('searchText', 'Required parameter searchText was null or undefined when calling findByLoginOrFullNameInAccount.');
            }
            var localVarPath = "/api/accountsadmin/c/account-invitation";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsByUser: function (userId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getInvitationsByUser.');
            }
            var localVarPath = "/api/accountsadmin/c/account-invitation/get-by-user";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {InviteUserDto} inviteUserDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser: function (inviteUserDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'inviteUserDto' is not null or undefined
            if (inviteUserDto === null || inviteUserDto === undefined) {
                throw new RequiredError('inviteUserDto', 'Required parameter inviteUserDto was null or undefined when calling inviteUser.');
            }
            var localVarPath = "/api/accountsadmin/c/account-invitation/invite";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof inviteUserDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(inviteUserDto !== undefined ? inviteUserDto : {}) : (inviteUserDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountInvitationEndpointApi - functional programming interface
 * @export
 */
export var AccountInvitationEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} userId
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationByToken: function (userId, token, options) {
            var localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).acceptInvitationByToken(userId, token, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationByUserId: function (userId, accountId, options) {
            var localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).acceptInvitationByUserId(userId, accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} invitationId
         * @param {ChangeRolesDto} changeRolesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeInvitationRoles: function (invitationId, changeRolesDto, options) {
            var localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).changeInvitationRoles(invitationId, changeRolesDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} invitationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitation: function (invitationId, options) {
            var localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).deleteInvitation(invitationId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} page
         * @param {number} size
         * @param {string} searchText
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByLoginOrFullNameInAccount: function (accountId, page, size, searchText, options) {
            var localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).findByLoginOrFullNameInAccount(accountId, page, size, searchText, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsByUser: function (userId, options) {
            var localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).getInvitationsByUser(userId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {InviteUserDto} inviteUserDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser: function (inviteUserDto, options) {
            var localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).inviteUser(inviteUserDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccountInvitationEndpointApi - factory interface
 * @export
 */
export var AccountInvitationEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} userId
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationByToken: function (userId, token, options) {
            return AccountInvitationEndpointApiFp(configuration).acceptInvitationByToken(userId, token, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationByUserId: function (userId, accountId, options) {
            return AccountInvitationEndpointApiFp(configuration).acceptInvitationByUserId(userId, accountId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} invitationId
         * @param {ChangeRolesDto} changeRolesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeInvitationRoles: function (invitationId, changeRolesDto, options) {
            return AccountInvitationEndpointApiFp(configuration).changeInvitationRoles(invitationId, changeRolesDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} invitationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitation: function (invitationId, options) {
            return AccountInvitationEndpointApiFp(configuration).deleteInvitation(invitationId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {number} page
         * @param {number} size
         * @param {string} searchText
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByLoginOrFullNameInAccount: function (accountId, page, size, searchText, options) {
            return AccountInvitationEndpointApiFp(configuration).findByLoginOrFullNameInAccount(accountId, page, size, searchText, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsByUser: function (userId, options) {
            return AccountInvitationEndpointApiFp(configuration).getInvitationsByUser(userId, options)(axios, basePath);
        },
        /**
         *
         * @param {InviteUserDto} inviteUserDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser: function (inviteUserDto, options) {
            return AccountInvitationEndpointApiFp(configuration).inviteUser(inviteUserDto, options)(axios, basePath);
        },
    };
};
/**
 * AccountInvitationEndpointApi - object-oriented interface
 * @export
 * @class AccountInvitationEndpointApi
 * @extends {BaseAPI}
 */
var AccountInvitationEndpointApi = /** @class */ (function (_super) {
    __extends(AccountInvitationEndpointApi, _super);
    function AccountInvitationEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} userId
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    AccountInvitationEndpointApi.prototype.acceptInvitationByToken = function (userId, token, options) {
        return AccountInvitationEndpointApiFp(this.configuration).acceptInvitationByToken(userId, token, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} userId
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    AccountInvitationEndpointApi.prototype.acceptInvitationByUserId = function (userId, accountId, options) {
        return AccountInvitationEndpointApiFp(this.configuration).acceptInvitationByUserId(userId, accountId, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} invitationId
     * @param {ChangeRolesDto} changeRolesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    AccountInvitationEndpointApi.prototype.changeInvitationRoles = function (invitationId, changeRolesDto, options) {
        return AccountInvitationEndpointApiFp(this.configuration).changeInvitationRoles(invitationId, changeRolesDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} invitationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    AccountInvitationEndpointApi.prototype.deleteInvitation = function (invitationId, options) {
        return AccountInvitationEndpointApiFp(this.configuration).deleteInvitation(invitationId, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} accountId
     * @param {number} page
     * @param {number} size
     * @param {string} searchText
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    AccountInvitationEndpointApi.prototype.findByLoginOrFullNameInAccount = function (accountId, page, size, searchText, options) {
        return AccountInvitationEndpointApiFp(this.configuration).findByLoginOrFullNameInAccount(accountId, page, size, searchText, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    AccountInvitationEndpointApi.prototype.getInvitationsByUser = function (userId, options) {
        return AccountInvitationEndpointApiFp(this.configuration).getInvitationsByUser(userId, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {InviteUserDto} inviteUserDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    AccountInvitationEndpointApi.prototype.inviteUser = function (inviteUserDto, options) {
        return AccountInvitationEndpointApiFp(this.configuration).inviteUser(inviteUserDto, options)(this.axios, this.basePath);
    };
    return AccountInvitationEndpointApi;
}(BaseAPI));
export { AccountInvitationEndpointApi };
/**
 * AccountManagementEndpointApi - axios parameter creator
 * @export
 */
export var AccountManagementEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {CreateAccountAsSadminDto} createAccountAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountAsSadmin: function (createAccountAsSadminDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'createAccountAsSadminDto' is not null or undefined
            if (createAccountAsSadminDto === null || createAccountAsSadminDto === undefined) {
                throw new RequiredError('createAccountAsSadminDto', 'Required parameter createAccountAsSadminDto was null or undefined when calling createAccountAsSadmin.');
            }
            var localVarPath = "/api/accountsadmin/c/sadmin/accounts";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof createAccountAsSadminDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(createAccountAsSadminDto !== undefined ? createAccountAsSadminDto : {}) : (createAccountAsSadminDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AccountSearchFilter} accountSearchFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountsByFilterAsSadmin: function (accountSearchFilter, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountSearchFilter' is not null or undefined
            if (accountSearchFilter === null || accountSearchFilter === undefined) {
                throw new RequiredError('accountSearchFilter', 'Required parameter accountSearchFilter was null or undefined when calling findAccountsByFilterAsSadmin.');
            }
            var localVarPath = "/api/accountsadmin/c/sadmin/accounts/search";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof accountSearchFilter !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(accountSearchFilter !== undefined ? accountSearchFilter : {}) : (accountSearchFilter || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountAsSadmin: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getAccountAsSadmin.');
            }
            var localVarPath = "/api/accountsadmin/c/sadmin/accounts/{accountId}"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdateAccountAsSadminDto} updateAccountAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountAsSadmin: function (accountId, updateAccountAsSadminDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateAccountAsSadmin.');
            }
            // verify required parameter 'updateAccountAsSadminDto' is not null or undefined
            if (updateAccountAsSadminDto === null || updateAccountAsSadminDto === undefined) {
                throw new RequiredError('updateAccountAsSadminDto', 'Required parameter updateAccountAsSadminDto was null or undefined when calling updateAccountAsSadmin.');
            }
            var localVarPath = "/api/accountsadmin/c/sadmin/accounts/{accountId}"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof updateAccountAsSadminDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(updateAccountAsSadminDto !== undefined ? updateAccountAsSadminDto : {}) : (updateAccountAsSadminDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountManagementEndpointApi - functional programming interface
 * @export
 */
export var AccountManagementEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {CreateAccountAsSadminDto} createAccountAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountAsSadmin: function (createAccountAsSadminDto, options) {
            var localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).createAccountAsSadmin(createAccountAsSadminDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {AccountSearchFilter} accountSearchFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountsByFilterAsSadmin: function (accountSearchFilter, options) {
            var localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).findAccountsByFilterAsSadmin(accountSearchFilter, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountAsSadmin: function (accountId, options) {
            var localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).getAccountAsSadmin(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdateAccountAsSadminDto} updateAccountAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountAsSadmin: function (accountId, updateAccountAsSadminDto, options) {
            var localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).updateAccountAsSadmin(accountId, updateAccountAsSadminDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccountManagementEndpointApi - factory interface
 * @export
 */
export var AccountManagementEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {CreateAccountAsSadminDto} createAccountAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountAsSadmin: function (createAccountAsSadminDto, options) {
            return AccountManagementEndpointApiFp(configuration).createAccountAsSadmin(createAccountAsSadminDto, options)(axios, basePath);
        },
        /**
         *
         * @param {AccountSearchFilter} accountSearchFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountsByFilterAsSadmin: function (accountSearchFilter, options) {
            return AccountManagementEndpointApiFp(configuration).findAccountsByFilterAsSadmin(accountSearchFilter, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountAsSadmin: function (accountId, options) {
            return AccountManagementEndpointApiFp(configuration).getAccountAsSadmin(accountId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdateAccountAsSadminDto} updateAccountAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountAsSadmin: function (accountId, updateAccountAsSadminDto, options) {
            return AccountManagementEndpointApiFp(configuration).updateAccountAsSadmin(accountId, updateAccountAsSadminDto, options)(axios, basePath);
        },
    };
};
/**
 * AccountManagementEndpointApi - object-oriented interface
 * @export
 * @class AccountManagementEndpointApi
 * @extends {BaseAPI}
 */
var AccountManagementEndpointApi = /** @class */ (function (_super) {
    __extends(AccountManagementEndpointApi, _super);
    function AccountManagementEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {CreateAccountAsSadminDto} createAccountAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementEndpointApi
     */
    AccountManagementEndpointApi.prototype.createAccountAsSadmin = function (createAccountAsSadminDto, options) {
        return AccountManagementEndpointApiFp(this.configuration).createAccountAsSadmin(createAccountAsSadminDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {AccountSearchFilter} accountSearchFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementEndpointApi
     */
    AccountManagementEndpointApi.prototype.findAccountsByFilterAsSadmin = function (accountSearchFilter, options) {
        return AccountManagementEndpointApiFp(this.configuration).findAccountsByFilterAsSadmin(accountSearchFilter, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementEndpointApi
     */
    AccountManagementEndpointApi.prototype.getAccountAsSadmin = function (accountId, options) {
        return AccountManagementEndpointApiFp(this.configuration).getAccountAsSadmin(accountId, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} accountId
     * @param {UpdateAccountAsSadminDto} updateAccountAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementEndpointApi
     */
    AccountManagementEndpointApi.prototype.updateAccountAsSadmin = function (accountId, updateAccountAsSadminDto, options) {
        return AccountManagementEndpointApiFp(this.configuration).updateAccountAsSadmin(accountId, updateAccountAsSadminDto, options)(this.axios, this.basePath);
    };
    return AccountManagementEndpointApi;
}(BaseAPI));
export { AccountManagementEndpointApi };
/**
 * AccountUserEndpointApi - axios parameter creator
 * @export
 */
export var AccountUserEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {ChangeRolesDto} changeRolesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountRoles: function (accountId, userId, changeRolesDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling changeAccountRoles.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling changeAccountRoles.');
            }
            // verify required parameter 'changeRolesDto' is not null or undefined
            if (changeRolesDto === null || changeRolesDto === undefined) {
                throw new RequiredError('changeRolesDto', 'Required parameter changeRolesDto was null or undefined when calling changeAccountRoles.');
            }
            var localVarPath = "/api/accountsadmin/c/accounts/{accountId}/users/{userId}/roles/change"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof changeRolesDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(changeRolesDto !== undefined ? changeRolesDto : {}) : (changeRolesDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserFromAccount: function (accountId, userId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteUserFromAccount.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling deleteUserFromAccount.');
            }
            var localVarPath = "/api/accountsadmin/c/accounts/{accountId}/users/{userId}"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountUserEndpointApi - functional programming interface
 * @export
 */
export var AccountUserEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {ChangeRolesDto} changeRolesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountRoles: function (accountId, userId, changeRolesDto, options) {
            var localVarAxiosArgs = AccountUserEndpointApiAxiosParamCreator(configuration).changeAccountRoles(accountId, userId, changeRolesDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserFromAccount: function (accountId, userId, options) {
            var localVarAxiosArgs = AccountUserEndpointApiAxiosParamCreator(configuration).deleteUserFromAccount(accountId, userId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccountUserEndpointApi - factory interface
 * @export
 */
export var AccountUserEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {ChangeRolesDto} changeRolesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountRoles: function (accountId, userId, changeRolesDto, options) {
            return AccountUserEndpointApiFp(configuration).changeAccountRoles(accountId, userId, changeRolesDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserFromAccount: function (accountId, userId, options) {
            return AccountUserEndpointApiFp(configuration).deleteUserFromAccount(accountId, userId, options)(axios, basePath);
        },
    };
};
/**
 * AccountUserEndpointApi - object-oriented interface
 * @export
 * @class AccountUserEndpointApi
 * @extends {BaseAPI}
 */
var AccountUserEndpointApi = /** @class */ (function (_super) {
    __extends(AccountUserEndpointApi, _super);
    function AccountUserEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {ChangeRolesDto} changeRolesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUserEndpointApi
     */
    AccountUserEndpointApi.prototype.changeAccountRoles = function (accountId, userId, changeRolesDto, options) {
        return AccountUserEndpointApiFp(this.configuration).changeAccountRoles(accountId, userId, changeRolesDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUserEndpointApi
     */
    AccountUserEndpointApi.prototype.deleteUserFromAccount = function (accountId, userId, options) {
        return AccountUserEndpointApiFp(this.configuration).deleteUserFromAccount(accountId, userId, options)(this.axios, this.basePath);
    };
    return AccountUserEndpointApi;
}(BaseAPI));
export { AccountUserEndpointApi };
/**
 * AccountsEndpointV2Api - axios parameter creator
 * @export
 */
export var AccountsEndpointV2ApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {ChangeCountryIsoCodeRequest} changeCountryIsoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCountryIsoCode: function (accountId, changeCountryIsoCodeRequest, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling changeCountryIsoCode.');
            }
            // verify required parameter 'changeCountryIsoCodeRequest' is not null or undefined
            if (changeCountryIsoCodeRequest === null || changeCountryIsoCodeRequest === undefined) {
                throw new RequiredError('changeCountryIsoCodeRequest', 'Required parameter changeCountryIsoCodeRequest was null or undefined when calling changeCountryIsoCode.');
            }
            var localVarPath = "/api/accountsadmin/c/accounts/{accountId}/change-country-iso-code"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof changeCountryIsoCodeRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(changeCountryIsoCodeRequest !== undefined ? changeCountryIsoCodeRequest : {}) : (changeCountryIsoCodeRequest || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AccountFullNameDto} accountFullNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: function (accountFullNameDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountFullNameDto' is not null or undefined
            if (accountFullNameDto === null || accountFullNameDto === undefined) {
                throw new RequiredError('accountFullNameDto', 'Required parameter accountFullNameDto was null or undefined when calling createAccount.');
            }
            var localVarPath = "/api/accountsadmin/c/accounts";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof accountFullNameDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(accountFullNameDto !== undefined ? accountFullNameDto : {}) : (accountFullNameDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getAccount.');
            }
            var localVarPath = "/api/accountsadmin/c/accounts/{accountId}"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdateAccountAsOwnerDto} updateAccountAsOwnerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: function (accountId, updateAccountAsOwnerDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateAccount.');
            }
            // verify required parameter 'updateAccountAsOwnerDto' is not null or undefined
            if (updateAccountAsOwnerDto === null || updateAccountAsOwnerDto === undefined) {
                throw new RequiredError('updateAccountAsOwnerDto', 'Required parameter updateAccountAsOwnerDto was null or undefined when calling updateAccount.');
            }
            var localVarPath = "/api/accountsadmin/c/accounts/{accountId}"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof updateAccountAsOwnerDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(updateAccountAsOwnerDto !== undefined ? updateAccountAsOwnerDto : {}) : (updateAccountAsOwnerDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountsEndpointV2Api - functional programming interface
 * @export
 */
export var AccountsEndpointV2ApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {ChangeCountryIsoCodeRequest} changeCountryIsoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCountryIsoCode: function (accountId, changeCountryIsoCodeRequest, options) {
            var localVarAxiosArgs = AccountsEndpointV2ApiAxiosParamCreator(configuration).changeCountryIsoCode(accountId, changeCountryIsoCodeRequest, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {AccountFullNameDto} accountFullNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: function (accountFullNameDto, options) {
            var localVarAxiosArgs = AccountsEndpointV2ApiAxiosParamCreator(configuration).createAccount(accountFullNameDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: function (accountId, options) {
            var localVarAxiosArgs = AccountsEndpointV2ApiAxiosParamCreator(configuration).getAccount(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdateAccountAsOwnerDto} updateAccountAsOwnerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: function (accountId, updateAccountAsOwnerDto, options) {
            var localVarAxiosArgs = AccountsEndpointV2ApiAxiosParamCreator(configuration).updateAccount(accountId, updateAccountAsOwnerDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccountsEndpointV2Api - factory interface
 * @export
 */
export var AccountsEndpointV2ApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {ChangeCountryIsoCodeRequest} changeCountryIsoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCountryIsoCode: function (accountId, changeCountryIsoCodeRequest, options) {
            return AccountsEndpointV2ApiFp(configuration).changeCountryIsoCode(accountId, changeCountryIsoCodeRequest, options)(axios, basePath);
        },
        /**
         *
         * @param {AccountFullNameDto} accountFullNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: function (accountFullNameDto, options) {
            return AccountsEndpointV2ApiFp(configuration).createAccount(accountFullNameDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: function (accountId, options) {
            return AccountsEndpointV2ApiFp(configuration).getAccount(accountId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdateAccountAsOwnerDto} updateAccountAsOwnerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: function (accountId, updateAccountAsOwnerDto, options) {
            return AccountsEndpointV2ApiFp(configuration).updateAccount(accountId, updateAccountAsOwnerDto, options)(axios, basePath);
        },
    };
};
/**
 * AccountsEndpointV2Api - object-oriented interface
 * @export
 * @class AccountsEndpointV2Api
 * @extends {BaseAPI}
 */
var AccountsEndpointV2Api = /** @class */ (function (_super) {
    __extends(AccountsEndpointV2Api, _super);
    function AccountsEndpointV2Api() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} accountId
     * @param {ChangeCountryIsoCodeRequest} changeCountryIsoCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsEndpointV2Api
     */
    AccountsEndpointV2Api.prototype.changeCountryIsoCode = function (accountId, changeCountryIsoCodeRequest, options) {
        return AccountsEndpointV2ApiFp(this.configuration).changeCountryIsoCode(accountId, changeCountryIsoCodeRequest, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {AccountFullNameDto} accountFullNameDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsEndpointV2Api
     */
    AccountsEndpointV2Api.prototype.createAccount = function (accountFullNameDto, options) {
        return AccountsEndpointV2ApiFp(this.configuration).createAccount(accountFullNameDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsEndpointV2Api
     */
    AccountsEndpointV2Api.prototype.getAccount = function (accountId, options) {
        return AccountsEndpointV2ApiFp(this.configuration).getAccount(accountId, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} accountId
     * @param {UpdateAccountAsOwnerDto} updateAccountAsOwnerDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsEndpointV2Api
     */
    AccountsEndpointV2Api.prototype.updateAccount = function (accountId, updateAccountAsOwnerDto, options) {
        return AccountsEndpointV2ApiFp(this.configuration).updateAccount(accountId, updateAccountAsOwnerDto, options)(this.axios, this.basePath);
    };
    return AccountsEndpointV2Api;
}(BaseAPI));
export { AccountsEndpointV2Api };
/**
 * AuthorizationEndpointApi - axios parameter creator
 * @export
 */
export var AuthorizationEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {AutoLoginDto} autoLoginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoLogin: function (autoLoginDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'autoLoginDto' is not null or undefined
            if (autoLoginDto === null || autoLoginDto === undefined) {
                throw new RequiredError('autoLoginDto', 'Required parameter autoLoginDto was null or undefined when calling autoLogin.');
            }
            var localVarPath = "/api/accountsadmin/public/authorization/auto-login";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof autoLoginDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(autoLoginDto !== undefined ? autoLoginDto : {}) : (autoLoginDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ChangeAccountDto} changeAccountDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccount: function (changeAccountDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'changeAccountDto' is not null or undefined
            if (changeAccountDto === null || changeAccountDto === undefined) {
                throw new RequiredError('changeAccountDto', 'Required parameter changeAccountDto was null or undefined when calling changeAccount.');
            }
            var localVarPath = "/api/accountsadmin/c/authorization/change-account";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof changeAccountDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(changeAccountDto !== undefined ? changeAccountDto : {}) : (changeAccountDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIsUserAuthorized: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/accountsadmin/c/authorization/check-authorized";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LoginDto} loginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: function (loginDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'loginDto' is not null or undefined
            if (loginDto === null || loginDto === undefined) {
                throw new RequiredError('loginDto', 'Required parameter loginDto was null or undefined when calling login.');
            }
            var localVarPath = "/api/accountsadmin/public/authorization/login";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof loginDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(loginDto !== undefined ? loginDto : {}) : (loginDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SessionLogoutDto} sessionLogoutDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: function (sessionLogoutDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'sessionLogoutDto' is not null or undefined
            if (sessionLogoutDto === null || sessionLogoutDto === undefined) {
                throw new RequiredError('sessionLogoutDto', 'Required parameter sessionLogoutDto was null or undefined when calling logout.');
            }
            var localVarPath = "/api/accountsadmin/c/internal/authorization/logout";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof sessionLogoutDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(sessionLogoutDto !== undefined ? sessionLogoutDto : {}) : (sessionLogoutDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout1: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/accountsadmin/c/authorization/logout";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AuthorizationEndpointApi - functional programming interface
 * @export
 */
export var AuthorizationEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {AutoLoginDto} autoLoginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoLogin: function (autoLoginDto, options) {
            var localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).autoLogin(autoLoginDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {ChangeAccountDto} changeAccountDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccount: function (changeAccountDto, options) {
            var localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).changeAccount(changeAccountDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIsUserAuthorized: function (options) {
            var localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).checkIsUserAuthorized(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {LoginDto} loginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: function (loginDto, options) {
            var localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).login(loginDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {SessionLogoutDto} sessionLogoutDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: function (sessionLogoutDto, options) {
            var localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).logout(sessionLogoutDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout1: function (options) {
            var localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).logout1(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AuthorizationEndpointApi - factory interface
 * @export
 */
export var AuthorizationEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {AutoLoginDto} autoLoginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoLogin: function (autoLoginDto, options) {
            return AuthorizationEndpointApiFp(configuration).autoLogin(autoLoginDto, options)(axios, basePath);
        },
        /**
         *
         * @param {ChangeAccountDto} changeAccountDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccount: function (changeAccountDto, options) {
            return AuthorizationEndpointApiFp(configuration).changeAccount(changeAccountDto, options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIsUserAuthorized: function (options) {
            return AuthorizationEndpointApiFp(configuration).checkIsUserAuthorized(options)(axios, basePath);
        },
        /**
         *
         * @param {LoginDto} loginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: function (loginDto, options) {
            return AuthorizationEndpointApiFp(configuration).login(loginDto, options)(axios, basePath);
        },
        /**
         *
         * @param {SessionLogoutDto} sessionLogoutDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: function (sessionLogoutDto, options) {
            return AuthorizationEndpointApiFp(configuration).logout(sessionLogoutDto, options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout1: function (options) {
            return AuthorizationEndpointApiFp(configuration).logout1(options)(axios, basePath);
        },
    };
};
/**
 * AuthorizationEndpointApi - object-oriented interface
 * @export
 * @class AuthorizationEndpointApi
 * @extends {BaseAPI}
 */
var AuthorizationEndpointApi = /** @class */ (function (_super) {
    __extends(AuthorizationEndpointApi, _super);
    function AuthorizationEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {AutoLoginDto} autoLoginDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    AuthorizationEndpointApi.prototype.autoLogin = function (autoLoginDto, options) {
        return AuthorizationEndpointApiFp(this.configuration).autoLogin(autoLoginDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {ChangeAccountDto} changeAccountDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    AuthorizationEndpointApi.prototype.changeAccount = function (changeAccountDto, options) {
        return AuthorizationEndpointApiFp(this.configuration).changeAccount(changeAccountDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    AuthorizationEndpointApi.prototype.checkIsUserAuthorized = function (options) {
        return AuthorizationEndpointApiFp(this.configuration).checkIsUserAuthorized(options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {LoginDto} loginDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    AuthorizationEndpointApi.prototype.login = function (loginDto, options) {
        return AuthorizationEndpointApiFp(this.configuration).login(loginDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {SessionLogoutDto} sessionLogoutDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    AuthorizationEndpointApi.prototype.logout = function (sessionLogoutDto, options) {
        return AuthorizationEndpointApiFp(this.configuration).logout(sessionLogoutDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    AuthorizationEndpointApi.prototype.logout1 = function (options) {
        return AuthorizationEndpointApiFp(this.configuration).logout1(options)(this.axios, this.basePath);
    };
    return AuthorizationEndpointApi;
}(BaseAPI));
export { AuthorizationEndpointApi };
/**
 * CcRolesEndpointApi - axios parameter creator
 * @export
 */
export var CcRolesEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {NotEditableRolesChangeDto} notEditableRolesChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeNotEditableRoles: function (accountId, userId, notEditableRolesChangeDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling changeNotEditableRoles.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling changeNotEditableRoles.');
            }
            // verify required parameter 'notEditableRolesChangeDto' is not null or undefined
            if (notEditableRolesChangeDto === null || notEditableRolesChangeDto === undefined) {
                throw new RequiredError('notEditableRolesChangeDto', 'Required parameter notEditableRolesChangeDto was null or undefined when calling changeNotEditableRoles.');
            }
            var localVarPath = "/api/accountsadmin/accounts/{accountId}/users/{userId}/roles/not-editable/change"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof notEditableRolesChangeDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(notEditableRolesChangeDto !== undefined ? notEditableRolesChangeDto : {}) : (notEditableRolesChangeDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * CcRolesEndpointApi - functional programming interface
 * @export
 */
export var CcRolesEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {NotEditableRolesChangeDto} notEditableRolesChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeNotEditableRoles: function (accountId, userId, notEditableRolesChangeDto, options) {
            var localVarAxiosArgs = CcRolesEndpointApiAxiosParamCreator(configuration).changeNotEditableRoles(accountId, userId, notEditableRolesChangeDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * CcRolesEndpointApi - factory interface
 * @export
 */
export var CcRolesEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {NotEditableRolesChangeDto} notEditableRolesChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeNotEditableRoles: function (accountId, userId, notEditableRolesChangeDto, options) {
            return CcRolesEndpointApiFp(configuration).changeNotEditableRoles(accountId, userId, notEditableRolesChangeDto, options)(axios, basePath);
        },
    };
};
/**
 * CcRolesEndpointApi - object-oriented interface
 * @export
 * @class CcRolesEndpointApi
 * @extends {BaseAPI}
 */
var CcRolesEndpointApi = /** @class */ (function (_super) {
    __extends(CcRolesEndpointApi, _super);
    function CcRolesEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {NotEditableRolesChangeDto} notEditableRolesChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CcRolesEndpointApi
     */
    CcRolesEndpointApi.prototype.changeNotEditableRoles = function (accountId, userId, notEditableRolesChangeDto, options) {
        return CcRolesEndpointApiFp(this.configuration).changeNotEditableRoles(accountId, userId, notEditableRolesChangeDto, options)(this.axios, this.basePath);
    };
    return CcRolesEndpointApi;
}(BaseAPI));
export { CcRolesEndpointApi };
/**
 * EmailVerificationEndpointApi - axios parameter creator
 * @export
 */
export var EmailVerificationEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationEmailInfo: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/accountsadmin/c/verification/verification-email-info";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SendVerificationEmailDto} sendVerificationEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerificationMail: function (sendVerificationEmailDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'sendVerificationEmailDto' is not null or undefined
            if (sendVerificationEmailDto === null || sendVerificationEmailDto === undefined) {
                throw new RequiredError('sendVerificationEmailDto', 'Required parameter sendVerificationEmailDto was null or undefined when calling sendVerificationMail.');
            }
            var localVarPath = "/api/accountsadmin/c/verification/send-verification-mail";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof sendVerificationEmailDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(sendVerificationEmailDto !== undefined ? sendVerificationEmailDto : {}) : (sendVerificationEmailDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * EmailVerificationEndpointApi - functional programming interface
 * @export
 */
export var EmailVerificationEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationEmailInfo: function (options) {
            var localVarAxiosArgs = EmailVerificationEndpointApiAxiosParamCreator(configuration).getVerificationEmailInfo(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {SendVerificationEmailDto} sendVerificationEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerificationMail: function (sendVerificationEmailDto, options) {
            var localVarAxiosArgs = EmailVerificationEndpointApiAxiosParamCreator(configuration).sendVerificationMail(sendVerificationEmailDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * EmailVerificationEndpointApi - factory interface
 * @export
 */
export var EmailVerificationEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationEmailInfo: function (options) {
            return EmailVerificationEndpointApiFp(configuration).getVerificationEmailInfo(options)(axios, basePath);
        },
        /**
         *
         * @param {SendVerificationEmailDto} sendVerificationEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerificationMail: function (sendVerificationEmailDto, options) {
            return EmailVerificationEndpointApiFp(configuration).sendVerificationMail(sendVerificationEmailDto, options)(axios, basePath);
        },
    };
};
/**
 * EmailVerificationEndpointApi - object-oriented interface
 * @export
 * @class EmailVerificationEndpointApi
 * @extends {BaseAPI}
 */
var EmailVerificationEndpointApi = /** @class */ (function (_super) {
    __extends(EmailVerificationEndpointApi, _super);
    function EmailVerificationEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailVerificationEndpointApi
     */
    EmailVerificationEndpointApi.prototype.getVerificationEmailInfo = function (options) {
        return EmailVerificationEndpointApiFp(this.configuration).getVerificationEmailInfo(options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {SendVerificationEmailDto} sendVerificationEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailVerificationEndpointApi
     */
    EmailVerificationEndpointApi.prototype.sendVerificationMail = function (sendVerificationEmailDto, options) {
        return EmailVerificationEndpointApiFp(this.configuration).sendVerificationMail(sendVerificationEmailDto, options)(this.axios, this.basePath);
    };
    return EmailVerificationEndpointApi;
}(BaseAPI));
export { EmailVerificationEndpointApi };
/**
 * OAuth2EndpointApi - axios parameter creator
 * @export
 */
export var OAuth2EndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} provider
         * @param {string} language
         * @param {ConversationalCloudProduct} [product]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oAuth2PreLogin: function (provider, language, product, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'provider' is not null or undefined
            if (provider === null || provider === undefined) {
                throw new RequiredError('provider', 'Required parameter provider was null or undefined when calling oAuth2PreLogin.');
            }
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language', 'Required parameter language was null or undefined when calling oAuth2PreLogin.');
            }
            var localVarPath = "/api/accountsadmin/public/authorization/oauth2/{provider}"
                .replace("{".concat("provider", "}"), encodeURIComponent(String(provider)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }
            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OAuth2EndpointApi - functional programming interface
 * @export
 */
export var OAuth2EndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} provider
         * @param {string} language
         * @param {ConversationalCloudProduct} [product]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oAuth2PreLogin: function (provider, language, product, options) {
            var localVarAxiosArgs = OAuth2EndpointApiAxiosParamCreator(configuration).oAuth2PreLogin(provider, language, product, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OAuth2EndpointApi - factory interface
 * @export
 */
export var OAuth2EndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} provider
         * @param {string} language
         * @param {ConversationalCloudProduct} [product]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oAuth2PreLogin: function (provider, language, product, options) {
            return OAuth2EndpointApiFp(configuration).oAuth2PreLogin(provider, language, product, options)(axios, basePath);
        },
    };
};
/**
 * OAuth2EndpointApi - object-oriented interface
 * @export
 * @class OAuth2EndpointApi
 * @extends {BaseAPI}
 */
var OAuth2EndpointApi = /** @class */ (function (_super) {
    __extends(OAuth2EndpointApi, _super);
    function OAuth2EndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} provider
     * @param {string} language
     * @param {ConversationalCloudProduct} [product]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2EndpointApi
     */
    OAuth2EndpointApi.prototype.oAuth2PreLogin = function (provider, language, product, options) {
        return OAuth2EndpointApiFp(this.configuration).oAuth2PreLogin(provider, language, product, options)(this.axios, this.basePath);
    };
    return OAuth2EndpointApi;
}(BaseAPI));
export { OAuth2EndpointApi };
/**
 * OptionsEndpointApi - axios parameter creator
 * @export
 */
export var OptionsEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptions: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/accountsadmin/public/options";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OptionsEndpointApi - functional programming interface
 * @export
 */
export var OptionsEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptions: function (options) {
            var localVarAxiosArgs = OptionsEndpointApiAxiosParamCreator(configuration).getOptions(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OptionsEndpointApi - factory interface
 * @export
 */
export var OptionsEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptions: function (options) {
            return OptionsEndpointApiFp(configuration).getOptions(options)(axios, basePath);
        },
    };
};
/**
 * OptionsEndpointApi - object-oriented interface
 * @export
 * @class OptionsEndpointApi
 * @extends {BaseAPI}
 */
var OptionsEndpointApi = /** @class */ (function (_super) {
    __extends(OptionsEndpointApi, _super);
    function OptionsEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsEndpointApi
     */
    OptionsEndpointApi.prototype.getOptions = function (options) {
        return OptionsEndpointApiFp(this.configuration).getOptions(options)(this.axios, this.basePath);
    };
    return OptionsEndpointApi;
}(BaseAPI));
export { OptionsEndpointApi };
/**
 * PasswordResetEndpointApi - axios parameter creator
 * @export
 */
export var PasswordResetEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ResetPasswordDto} resetPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: function (resetPasswordDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'resetPasswordDto' is not null or undefined
            if (resetPasswordDto === null || resetPasswordDto === undefined) {
                throw new RequiredError('resetPasswordDto', 'Required parameter resetPasswordDto was null or undefined when calling resetPassword.');
            }
            var localVarPath = "/api/accountsadmin/c/password/reset";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof resetPasswordDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(resetPasswordDto !== undefined ? resetPasswordDto : {}) : (resetPasswordDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SendResetPasswordDto} sendResetPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordEmail: function (sendResetPasswordDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'sendResetPasswordDto' is not null or undefined
            if (sendResetPasswordDto === null || sendResetPasswordDto === undefined) {
                throw new RequiredError('sendResetPasswordDto', 'Required parameter sendResetPasswordDto was null or undefined when calling sendResetPasswordEmail.');
            }
            var localVarPath = "/api/accountsadmin/public/password/reset/send-email";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof sendResetPasswordDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(sendResetPasswordDto !== undefined ? sendResetPasswordDto : {}) : (sendResetPasswordDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PasswordResetEndpointApi - functional programming interface
 * @export
 */
export var PasswordResetEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {ResetPasswordDto} resetPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: function (resetPasswordDto, options) {
            var localVarAxiosArgs = PasswordResetEndpointApiAxiosParamCreator(configuration).resetPassword(resetPasswordDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {SendResetPasswordDto} sendResetPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordEmail: function (sendResetPasswordDto, options) {
            var localVarAxiosArgs = PasswordResetEndpointApiAxiosParamCreator(configuration).sendResetPasswordEmail(sendResetPasswordDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * PasswordResetEndpointApi - factory interface
 * @export
 */
export var PasswordResetEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {ResetPasswordDto} resetPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: function (resetPasswordDto, options) {
            return PasswordResetEndpointApiFp(configuration).resetPassword(resetPasswordDto, options)(axios, basePath);
        },
        /**
         *
         * @param {SendResetPasswordDto} sendResetPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordEmail: function (sendResetPasswordDto, options) {
            return PasswordResetEndpointApiFp(configuration).sendResetPasswordEmail(sendResetPasswordDto, options)(axios, basePath);
        },
    };
};
/**
 * PasswordResetEndpointApi - object-oriented interface
 * @export
 * @class PasswordResetEndpointApi
 * @extends {BaseAPI}
 */
var PasswordResetEndpointApi = /** @class */ (function (_super) {
    __extends(PasswordResetEndpointApi, _super);
    function PasswordResetEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {ResetPasswordDto} resetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetEndpointApi
     */
    PasswordResetEndpointApi.prototype.resetPassword = function (resetPasswordDto, options) {
        return PasswordResetEndpointApiFp(this.configuration).resetPassword(resetPasswordDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {SendResetPasswordDto} sendResetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetEndpointApi
     */
    PasswordResetEndpointApi.prototype.sendResetPasswordEmail = function (sendResetPasswordDto, options) {
        return PasswordResetEndpointApiFp(this.configuration).sendResetPasswordEmail(sendResetPasswordDto, options)(this.axios, this.basePath);
    };
    return PasswordResetEndpointApi;
}(BaseAPI));
export { PasswordResetEndpointApi };
/**
 * RegistrationEndpointApi - axios parameter creator
 * @export
 */
export var RegistrationEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectCountryIsoCode: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/accountsadmin/public/registration/detect-country-iso-code";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RegistrationRequestDto} registrationRequestDto
         * @param {string} [CAPTCHA_BYPASS_TOKEN]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: function (registrationRequestDto, CAPTCHA_BYPASS_TOKEN, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'registrationRequestDto' is not null or undefined
            if (registrationRequestDto === null || registrationRequestDto === undefined) {
                throw new RequiredError('registrationRequestDto', 'Required parameter registrationRequestDto was null or undefined when calling register.');
            }
            var localVarPath = "/api/accountsadmin/public/registration/register";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof registrationRequestDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(registrationRequestDto !== undefined ? registrationRequestDto : {}) : (registrationRequestDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * RegistrationEndpointApi - functional programming interface
 * @export
 */
export var RegistrationEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectCountryIsoCode: function (options) {
            var localVarAxiosArgs = RegistrationEndpointApiAxiosParamCreator(configuration).detectCountryIsoCode(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {RegistrationRequestDto} registrationRequestDto
         * @param {string} [CAPTCHA_BYPASS_TOKEN]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: function (registrationRequestDto, CAPTCHA_BYPASS_TOKEN, options) {
            var localVarAxiosArgs = RegistrationEndpointApiAxiosParamCreator(configuration).register(registrationRequestDto, CAPTCHA_BYPASS_TOKEN, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * RegistrationEndpointApi - factory interface
 * @export
 */
export var RegistrationEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectCountryIsoCode: function (options) {
            return RegistrationEndpointApiFp(configuration).detectCountryIsoCode(options)(axios, basePath);
        },
        /**
         *
         * @param {RegistrationRequestDto} registrationRequestDto
         * @param {string} [CAPTCHA_BYPASS_TOKEN]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: function (registrationRequestDto, CAPTCHA_BYPASS_TOKEN, options) {
            return RegistrationEndpointApiFp(configuration).register(registrationRequestDto, CAPTCHA_BYPASS_TOKEN, options)(axios, basePath);
        },
    };
};
/**
 * RegistrationEndpointApi - object-oriented interface
 * @export
 * @class RegistrationEndpointApi
 * @extends {BaseAPI}
 */
var RegistrationEndpointApi = /** @class */ (function (_super) {
    __extends(RegistrationEndpointApi, _super);
    function RegistrationEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationEndpointApi
     */
    RegistrationEndpointApi.prototype.detectCountryIsoCode = function (options) {
        return RegistrationEndpointApiFp(this.configuration).detectCountryIsoCode(options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {RegistrationRequestDto} registrationRequestDto
     * @param {string} [CAPTCHA_BYPASS_TOKEN]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationEndpointApi
     */
    RegistrationEndpointApi.prototype.register = function (registrationRequestDto, CAPTCHA_BYPASS_TOKEN, options) {
        return RegistrationEndpointApiFp(this.configuration).register(registrationRequestDto, CAPTCHA_BYPASS_TOKEN, options)(this.axios, this.basePath);
    };
    return RegistrationEndpointApi;
}(BaseAPI));
export { RegistrationEndpointApi };
/**
 * RolesEndpointApi - axios parameter creator
 * @export
 */
export var RolesEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalRoles: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/accountsadmin/c/sadmin/roles/internal";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesAvailableToAccount: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getRolesAvailableToAccount.');
            }
            var localVarPath = "/api/accountsadmin/c/accounts/{accountId}/available-roles"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * RolesEndpointApi - functional programming interface
 * @export
 */
export var RolesEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalRoles: function (options) {
            var localVarAxiosArgs = RolesEndpointApiAxiosParamCreator(configuration).getInternalRoles(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesAvailableToAccount: function (accountId, options) {
            var localVarAxiosArgs = RolesEndpointApiAxiosParamCreator(configuration).getRolesAvailableToAccount(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * RolesEndpointApi - factory interface
 * @export
 */
export var RolesEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalRoles: function (options) {
            return RolesEndpointApiFp(configuration).getInternalRoles(options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesAvailableToAccount: function (accountId, options) {
            return RolesEndpointApiFp(configuration).getRolesAvailableToAccount(accountId, options)(axios, basePath);
        },
    };
};
/**
 * RolesEndpointApi - object-oriented interface
 * @export
 * @class RolesEndpointApi
 * @extends {BaseAPI}
 */
var RolesEndpointApi = /** @class */ (function (_super) {
    __extends(RolesEndpointApi, _super);
    function RolesEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesEndpointApi
     */
    RolesEndpointApi.prototype.getInternalRoles = function (options) {
        return RolesEndpointApiFp(this.configuration).getInternalRoles(options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesEndpointApi
     */
    RolesEndpointApi.prototype.getRolesAvailableToAccount = function (accountId, options) {
        return RolesEndpointApiFp(this.configuration).getRolesAvailableToAccount(accountId, options)(this.axios, this.basePath);
    };
    return RolesEndpointApi;
}(BaseAPI));
export { RolesEndpointApi };
/**
 * SmsCodeEndpointApi - axios parameter creator
 * @export
 */
export var SmsCodeEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Send sms code for authorization
         * @param {SendSmsCodeDto} sendSmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsCodeForAuthorization: function (sendSmsCodeDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'sendSmsCodeDto' is not null or undefined
            if (sendSmsCodeDto === null || sendSmsCodeDto === undefined) {
                throw new RequiredError('sendSmsCodeDto', 'Required parameter sendSmsCodeDto was null or undefined when calling sendSmsCodeForAuthorization.');
            }
            var localVarPath = "/api/accountsadmin/public/sms-code/send/authorization";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof sendSmsCodeDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(sendSmsCodeDto !== undefined ? sendSmsCodeDto : {}) : (sendSmsCodeDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send sms code for verification
         * @param {SendSmsCodeDto} sendSmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsCodeForPhoneVerification: function (sendSmsCodeDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'sendSmsCodeDto' is not null or undefined
            if (sendSmsCodeDto === null || sendSmsCodeDto === undefined) {
                throw new RequiredError('sendSmsCodeDto', 'Required parameter sendSmsCodeDto was null or undefined when calling sendSmsCodeForPhoneVerification.');
            }
            var localVarPath = "/api/accountsadmin/c/sms-code/send/phone-verification";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof sendSmsCodeDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(sendSmsCodeDto !== undefined ? sendSmsCodeDto : {}) : (sendSmsCodeDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify phone by entered sms code
         * @param {VerifySmsCodeDto} verifySmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySmsCode: function (verifySmsCodeDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'verifySmsCodeDto' is not null or undefined
            if (verifySmsCodeDto === null || verifySmsCodeDto === undefined) {
                throw new RequiredError('verifySmsCodeDto', 'Required parameter verifySmsCodeDto was null or undefined when calling verifySmsCode.');
            }
            var localVarPath = "/api/accountsadmin/public/sms-code/verify";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof verifySmsCodeDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(verifySmsCodeDto !== undefined ? verifySmsCodeDto : {}) : (verifySmsCodeDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SmsCodeEndpointApi - functional programming interface
 * @export
 */
export var SmsCodeEndpointApiFp = function (configuration) {
    return {
        /**
         * Send sms code for authorization
         * @param {SendSmsCodeDto} sendSmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsCodeForAuthorization: function (sendSmsCodeDto, options) {
            var localVarAxiosArgs = SmsCodeEndpointApiAxiosParamCreator(configuration).sendSmsCodeForAuthorization(sendSmsCodeDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Send sms code for verification
         * @param {SendSmsCodeDto} sendSmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsCodeForPhoneVerification: function (sendSmsCodeDto, options) {
            var localVarAxiosArgs = SmsCodeEndpointApiAxiosParamCreator(configuration).sendSmsCodeForPhoneVerification(sendSmsCodeDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Verify phone by entered sms code
         * @param {VerifySmsCodeDto} verifySmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySmsCode: function (verifySmsCodeDto, options) {
            var localVarAxiosArgs = SmsCodeEndpointApiAxiosParamCreator(configuration).verifySmsCode(verifySmsCodeDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SmsCodeEndpointApi - factory interface
 * @export
 */
export var SmsCodeEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Send sms code for authorization
         * @param {SendSmsCodeDto} sendSmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsCodeForAuthorization: function (sendSmsCodeDto, options) {
            return SmsCodeEndpointApiFp(configuration).sendSmsCodeForAuthorization(sendSmsCodeDto, options)(axios, basePath);
        },
        /**
         * Send sms code for verification
         * @param {SendSmsCodeDto} sendSmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsCodeForPhoneVerification: function (sendSmsCodeDto, options) {
            return SmsCodeEndpointApiFp(configuration).sendSmsCodeForPhoneVerification(sendSmsCodeDto, options)(axios, basePath);
        },
        /**
         * Verify phone by entered sms code
         * @param {VerifySmsCodeDto} verifySmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySmsCode: function (verifySmsCodeDto, options) {
            return SmsCodeEndpointApiFp(configuration).verifySmsCode(verifySmsCodeDto, options)(axios, basePath);
        },
    };
};
/**
 * SmsCodeEndpointApi - object-oriented interface
 * @export
 * @class SmsCodeEndpointApi
 * @extends {BaseAPI}
 */
var SmsCodeEndpointApi = /** @class */ (function (_super) {
    __extends(SmsCodeEndpointApi, _super);
    function SmsCodeEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Send sms code for authorization
     * @param {SendSmsCodeDto} sendSmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsCodeEndpointApi
     */
    SmsCodeEndpointApi.prototype.sendSmsCodeForAuthorization = function (sendSmsCodeDto, options) {
        return SmsCodeEndpointApiFp(this.configuration).sendSmsCodeForAuthorization(sendSmsCodeDto, options)(this.axios, this.basePath);
    };
    /**
     * Send sms code for verification
     * @param {SendSmsCodeDto} sendSmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsCodeEndpointApi
     */
    SmsCodeEndpointApi.prototype.sendSmsCodeForPhoneVerification = function (sendSmsCodeDto, options) {
        return SmsCodeEndpointApiFp(this.configuration).sendSmsCodeForPhoneVerification(sendSmsCodeDto, options)(this.axios, this.basePath);
    };
    /**
     * Verify phone by entered sms code
     * @param {VerifySmsCodeDto} verifySmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsCodeEndpointApi
     */
    SmsCodeEndpointApi.prototype.verifySmsCode = function (verifySmsCodeDto, options) {
        return SmsCodeEndpointApiFp(this.configuration).verifySmsCode(verifySmsCodeDto, options)(this.axios, this.basePath);
    };
    return SmsCodeEndpointApi;
}(BaseAPI));
export { SmsCodeEndpointApi };
/**
 * TariffLimitsEndpointApi - axios parameter creator
 * @export
 */
export var TariffLimitsEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentLimits: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getAgentLimits.');
            }
            var localVarPath = "/api/accountsadmin/c/accounts/{accountId}/agent-limits"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * TariffLimitsEndpointApi - functional programming interface
 * @export
 */
export var TariffLimitsEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentLimits: function (accountId, options) {
            var localVarAxiosArgs = TariffLimitsEndpointApiAxiosParamCreator(configuration).getAgentLimits(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * TariffLimitsEndpointApi - factory interface
 * @export
 */
export var TariffLimitsEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentLimits: function (accountId, options) {
            return TariffLimitsEndpointApiFp(configuration).getAgentLimits(accountId, options)(axios, basePath);
        },
    };
};
/**
 * TariffLimitsEndpointApi - object-oriented interface
 * @export
 * @class TariffLimitsEndpointApi
 * @extends {BaseAPI}
 */
var TariffLimitsEndpointApi = /** @class */ (function (_super) {
    __extends(TariffLimitsEndpointApi, _super);
    function TariffLimitsEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffLimitsEndpointApi
     */
    TariffLimitsEndpointApi.prototype.getAgentLimits = function (accountId, options) {
        return TariffLimitsEndpointApiFp(this.configuration).getAgentLimits(accountId, options)(this.axios, this.basePath);
    };
    return TariffLimitsEndpointApi;
}(BaseAPI));
export { TariffLimitsEndpointApi };
/**
 * UniversalLoginEndpointApi - axios parameter creator
 * @export
 */
export var UniversalLoginEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        universalLogin: function (token, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token', 'Required parameter token was null or undefined when calling universalLogin.');
            }
            var localVarPath = "/api/accountsadmin/public/universal/login/login.gif";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        universalLoginRequest: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/accountsadmin/c/universal/login/request";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * UniversalLoginEndpointApi - functional programming interface
 * @export
 */
export var UniversalLoginEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        universalLogin: function (token, options) {
            var localVarAxiosArgs = UniversalLoginEndpointApiAxiosParamCreator(configuration).universalLogin(token, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        universalLoginRequest: function (options) {
            var localVarAxiosArgs = UniversalLoginEndpointApiAxiosParamCreator(configuration).universalLoginRequest(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * UniversalLoginEndpointApi - factory interface
 * @export
 */
export var UniversalLoginEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        universalLogin: function (token, options) {
            return UniversalLoginEndpointApiFp(configuration).universalLogin(token, options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        universalLoginRequest: function (options) {
            return UniversalLoginEndpointApiFp(configuration).universalLoginRequest(options)(axios, basePath);
        },
    };
};
/**
 * UniversalLoginEndpointApi - object-oriented interface
 * @export
 * @class UniversalLoginEndpointApi
 * @extends {BaseAPI}
 */
var UniversalLoginEndpointApi = /** @class */ (function (_super) {
    __extends(UniversalLoginEndpointApi, _super);
    function UniversalLoginEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UniversalLoginEndpointApi
     */
    UniversalLoginEndpointApi.prototype.universalLogin = function (token, options) {
        return UniversalLoginEndpointApiFp(this.configuration).universalLogin(token, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UniversalLoginEndpointApi
     */
    UniversalLoginEndpointApi.prototype.universalLoginRequest = function (options) {
        return UniversalLoginEndpointApiFp(this.configuration).universalLoginRequest(options)(this.axios, this.basePath);
    };
    return UniversalLoginEndpointApi;
}(BaseAPI));
export { UniversalLoginEndpointApi };
/**
 * UserEndpointApi - axios parameter creator
 * @export
 */
export var UserEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} userId
         * @param {ChangeEmailDto} changeEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail: function (userId, changeEmailDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling changeEmail.');
            }
            // verify required parameter 'changeEmailDto' is not null or undefined
            if (changeEmailDto === null || changeEmailDto === undefined) {
                throw new RequiredError('changeEmailDto', 'Required parameter changeEmailDto was null or undefined when calling changeEmail.');
            }
            var localVarPath = "/api/accountsadmin/c/users/{userId}/email"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof changeEmailDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(changeEmailDto !== undefined ? changeEmailDto : {}) : (changeEmailDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeFullNameDto} changeFullNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeFullName: function (userId, changeFullNameDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling changeFullName.');
            }
            // verify required parameter 'changeFullNameDto' is not null or undefined
            if (changeFullNameDto === null || changeFullNameDto === undefined) {
                throw new RequiredError('changeFullNameDto', 'Required parameter changeFullNameDto was null or undefined when calling changeFullName.');
            }
            var localVarPath = "/api/accountsadmin/c/users/{userId}/full-name"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof changeFullNameDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(changeFullNameDto !== undefined ? changeFullNameDto : {}) : (changeFullNameDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {string} locale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLanguage: function (userId, locale, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling changeLanguage.');
            }
            // verify required parameter 'locale' is not null or undefined
            if (locale === null || locale === undefined) {
                throw new RequiredError('locale', 'Required parameter locale was null or undefined when calling changeLanguage.');
            }
            var localVarPath = "/api/accountsadmin/c/users/{userId}/language"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeUserPasswordDto} changeUserPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: function (userId, changeUserPasswordDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling changePassword.');
            }
            // verify required parameter 'changeUserPasswordDto' is not null or undefined
            if (changeUserPasswordDto === null || changeUserPasswordDto === undefined) {
                throw new RequiredError('changeUserPasswordDto', 'Required parameter changeUserPasswordDto was null or undefined when calling changePassword.');
            }
            var localVarPath = "/api/accountsadmin/c/users/{userId}/password"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof changeUserPasswordDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(changeUserPasswordDto !== undefined ? changeUserPasswordDto : {}) : (changeUserPasswordDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvatar: function (userId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling deleteAvatar.');
            }
            var localVarPath = "/api/accountsadmin/c/users/{userId}/avatar"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedAccounts: function (userId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getAllowedAccounts.');
            }
            var localVarPath = "/api/accountsadmin/c/users/{userId}/allowed-accounts"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {string} actionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCaptchaNeeded: function (userId, actionType, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling isCaptchaNeeded.');
            }
            // verify required parameter 'actionType' is not null or undefined
            if (actionType === null || actionType === undefined) {
                throw new RequiredError('actionType', 'Required parameter actionType was null or undefined when calling isCaptchaNeeded.');
            }
            var localVarPath = "/api/accountsadmin/c/users/{userId}/is-captcha-needed"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (actionType !== undefined) {
                localVarQueryParameter['actionType'] = actionType;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAndSetAvatar: function (userId, file, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling uploadAndSetAvatar.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file', 'Required parameter file was null or undefined when calling uploadAndSetAvatar.');
            }
            var localVarPath = "/api/accountsadmin/c/users/{userId}/upload-and-set-avatar"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            var localVarFormParams = new FormData();
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * UserEndpointApi - functional programming interface
 * @export
 */
export var UserEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} userId
         * @param {ChangeEmailDto} changeEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail: function (userId, changeEmailDto, options) {
            var localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).changeEmail(userId, changeEmailDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeFullNameDto} changeFullNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeFullName: function (userId, changeFullNameDto, options) {
            var localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).changeFullName(userId, changeFullNameDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {string} locale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLanguage: function (userId, locale, options) {
            var localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).changeLanguage(userId, locale, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeUserPasswordDto} changeUserPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: function (userId, changeUserPasswordDto, options) {
            var localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).changePassword(userId, changeUserPasswordDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvatar: function (userId, options) {
            var localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).deleteAvatar(userId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedAccounts: function (userId, options) {
            var localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).getAllowedAccounts(userId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {string} actionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCaptchaNeeded: function (userId, actionType, options) {
            var localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).isCaptchaNeeded(userId, actionType, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAndSetAvatar: function (userId, file, options) {
            var localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).uploadAndSetAvatar(userId, file, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * UserEndpointApi - factory interface
 * @export
 */
export var UserEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} userId
         * @param {ChangeEmailDto} changeEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail: function (userId, changeEmailDto, options) {
            return UserEndpointApiFp(configuration).changeEmail(userId, changeEmailDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeFullNameDto} changeFullNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeFullName: function (userId, changeFullNameDto, options) {
            return UserEndpointApiFp(configuration).changeFullName(userId, changeFullNameDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {string} locale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLanguage: function (userId, locale, options) {
            return UserEndpointApiFp(configuration).changeLanguage(userId, locale, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeUserPasswordDto} changeUserPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: function (userId, changeUserPasswordDto, options) {
            return UserEndpointApiFp(configuration).changePassword(userId, changeUserPasswordDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvatar: function (userId, options) {
            return UserEndpointApiFp(configuration).deleteAvatar(userId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedAccounts: function (userId, options) {
            return UserEndpointApiFp(configuration).getAllowedAccounts(userId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {string} actionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCaptchaNeeded: function (userId, actionType, options) {
            return UserEndpointApiFp(configuration).isCaptchaNeeded(userId, actionType, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAndSetAvatar: function (userId, file, options) {
            return UserEndpointApiFp(configuration).uploadAndSetAvatar(userId, file, options)(axios, basePath);
        },
    };
};
/**
 * UserEndpointApi - object-oriented interface
 * @export
 * @class UserEndpointApi
 * @extends {BaseAPI}
 */
var UserEndpointApi = /** @class */ (function (_super) {
    __extends(UserEndpointApi, _super);
    function UserEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} userId
     * @param {ChangeEmailDto} changeEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    UserEndpointApi.prototype.changeEmail = function (userId, changeEmailDto, options) {
        return UserEndpointApiFp(this.configuration).changeEmail(userId, changeEmailDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} userId
     * @param {ChangeFullNameDto} changeFullNameDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    UserEndpointApi.prototype.changeFullName = function (userId, changeFullNameDto, options) {
        return UserEndpointApiFp(this.configuration).changeFullName(userId, changeFullNameDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} userId
     * @param {string} locale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    UserEndpointApi.prototype.changeLanguage = function (userId, locale, options) {
        return UserEndpointApiFp(this.configuration).changeLanguage(userId, locale, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} userId
     * @param {ChangeUserPasswordDto} changeUserPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    UserEndpointApi.prototype.changePassword = function (userId, changeUserPasswordDto, options) {
        return UserEndpointApiFp(this.configuration).changePassword(userId, changeUserPasswordDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    UserEndpointApi.prototype.deleteAvatar = function (userId, options) {
        return UserEndpointApiFp(this.configuration).deleteAvatar(userId, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    UserEndpointApi.prototype.getAllowedAccounts = function (userId, options) {
        return UserEndpointApiFp(this.configuration).getAllowedAccounts(userId, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} userId
     * @param {string} actionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    UserEndpointApi.prototype.isCaptchaNeeded = function (userId, actionType, options) {
        return UserEndpointApiFp(this.configuration).isCaptchaNeeded(userId, actionType, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} userId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    UserEndpointApi.prototype.uploadAndSetAvatar = function (userId, file, options) {
        return UserEndpointApiFp(this.configuration).uploadAndSetAvatar(userId, file, options)(this.axios, this.basePath);
    };
    return UserEndpointApi;
}(BaseAPI));
export { UserEndpointApi };
/**
 * UserManagementEndpointApi - axios parameter creator
 * @export
 */
export var UserManagementEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {CreateUserAsSadminDto} createUserAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAsSadmin: function (createUserAsSadminDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'createUserAsSadminDto' is not null or undefined
            if (createUserAsSadminDto === null || createUserAsSadminDto === undefined) {
                throw new RequiredError('createUserAsSadminDto', 'Required parameter createUserAsSadminDto was null or undefined when calling createUserAsSadmin.');
            }
            var localVarPath = "/api/accountsadmin/c/sadmin/users";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof createUserAsSadminDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(createUserAsSadminDto !== undefined ? createUserAsSadminDto : {}) : (createUserAsSadminDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [searchText]
         * @param {boolean} [internal]
         * @param {boolean} [fetchRoles]
         * @param {boolean} [fetchAccounts]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsersByFilterAsSadmin: function (page, size, searchText, internal, fetchRoles, fetchAccounts, options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/accountsadmin/c/sadmin/users/search";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }
            if (internal !== undefined) {
                localVarQueryParameter['internal'] = internal;
            }
            if (fetchRoles !== undefined) {
                localVarQueryParameter['fetchRoles'] = fetchRoles;
            }
            if (fetchAccounts !== undefined) {
                localVarQueryParameter['fetchAccounts'] = fetchAccounts;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAsSadmin: function (userId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserAsSadmin.');
            }
            var localVarPath = "/api/accountsadmin/c/sadmin/users/{userId}"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {UpdateUserAsSadminDto} updateUserAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAsSadmin: function (userId, updateUserAsSadminDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling updateUserAsSadmin.');
            }
            // verify required parameter 'updateUserAsSadminDto' is not null or undefined
            if (updateUserAsSadminDto === null || updateUserAsSadminDto === undefined) {
                throw new RequiredError('updateUserAsSadminDto', 'Required parameter updateUserAsSadminDto was null or undefined when calling updateUserAsSadmin.');
            }
            var localVarPath = "/api/accountsadmin/c/sadmin/users/{userId}"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof updateUserAsSadminDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(updateUserAsSadminDto !== undefined ? updateUserAsSadminDto : {}) : (updateUserAsSadminDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * UserManagementEndpointApi - functional programming interface
 * @export
 */
export var UserManagementEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {CreateUserAsSadminDto} createUserAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAsSadmin: function (createUserAsSadminDto, options) {
            var localVarAxiosArgs = UserManagementEndpointApiAxiosParamCreator(configuration).createUserAsSadmin(createUserAsSadminDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [searchText]
         * @param {boolean} [internal]
         * @param {boolean} [fetchRoles]
         * @param {boolean} [fetchAccounts]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsersByFilterAsSadmin: function (page, size, searchText, internal, fetchRoles, fetchAccounts, options) {
            var localVarAxiosArgs = UserManagementEndpointApiAxiosParamCreator(configuration).findUsersByFilterAsSadmin(page, size, searchText, internal, fetchRoles, fetchAccounts, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAsSadmin: function (userId, options) {
            var localVarAxiosArgs = UserManagementEndpointApiAxiosParamCreator(configuration).getUserAsSadmin(userId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {UpdateUserAsSadminDto} updateUserAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAsSadmin: function (userId, updateUserAsSadminDto, options) {
            var localVarAxiosArgs = UserManagementEndpointApiAxiosParamCreator(configuration).updateUserAsSadmin(userId, updateUserAsSadminDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * UserManagementEndpointApi - factory interface
 * @export
 */
export var UserManagementEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {CreateUserAsSadminDto} createUserAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAsSadmin: function (createUserAsSadminDto, options) {
            return UserManagementEndpointApiFp(configuration).createUserAsSadmin(createUserAsSadminDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [searchText]
         * @param {boolean} [internal]
         * @param {boolean} [fetchRoles]
         * @param {boolean} [fetchAccounts]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsersByFilterAsSadmin: function (page, size, searchText, internal, fetchRoles, fetchAccounts, options) {
            return UserManagementEndpointApiFp(configuration).findUsersByFilterAsSadmin(page, size, searchText, internal, fetchRoles, fetchAccounts, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAsSadmin: function (userId, options) {
            return UserManagementEndpointApiFp(configuration).getUserAsSadmin(userId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {UpdateUserAsSadminDto} updateUserAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAsSadmin: function (userId, updateUserAsSadminDto, options) {
            return UserManagementEndpointApiFp(configuration).updateUserAsSadmin(userId, updateUserAsSadminDto, options)(axios, basePath);
        },
    };
};
/**
 * UserManagementEndpointApi - object-oriented interface
 * @export
 * @class UserManagementEndpointApi
 * @extends {BaseAPI}
 */
var UserManagementEndpointApi = /** @class */ (function (_super) {
    __extends(UserManagementEndpointApi, _super);
    function UserManagementEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {CreateUserAsSadminDto} createUserAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementEndpointApi
     */
    UserManagementEndpointApi.prototype.createUserAsSadmin = function (createUserAsSadminDto, options) {
        return UserManagementEndpointApiFp(this.configuration).createUserAsSadmin(createUserAsSadminDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [searchText]
     * @param {boolean} [internal]
     * @param {boolean} [fetchRoles]
     * @param {boolean} [fetchAccounts]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementEndpointApi
     */
    UserManagementEndpointApi.prototype.findUsersByFilterAsSadmin = function (page, size, searchText, internal, fetchRoles, fetchAccounts, options) {
        return UserManagementEndpointApiFp(this.configuration).findUsersByFilterAsSadmin(page, size, searchText, internal, fetchRoles, fetchAccounts, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementEndpointApi
     */
    UserManagementEndpointApi.prototype.getUserAsSadmin = function (userId, options) {
        return UserManagementEndpointApiFp(this.configuration).getUserAsSadmin(userId, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} userId
     * @param {UpdateUserAsSadminDto} updateUserAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementEndpointApi
     */
    UserManagementEndpointApi.prototype.updateUserAsSadmin = function (userId, updateUserAsSadminDto, options) {
        return UserManagementEndpointApiFp(this.configuration).updateUserAsSadmin(userId, updateUserAsSadminDto, options)(this.axios, this.basePath);
    };
    return UserManagementEndpointApi;
}(BaseAPI));
export { UserManagementEndpointApi };
/**
 * UserSearchEndpointApi - axios parameter creator
 * @export
 */
export var UserSearchEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} page
         * @param {number} size
         * @param {string} searchText
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountUsers: function (accountId, page, size, searchText, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling findAccountUsers.');
            }
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page', 'Required parameter page was null or undefined when calling findAccountUsers.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size', 'Required parameter size was null or undefined when calling findAccountUsers.');
            }
            // verify required parameter 'searchText' is not null or undefined
            if (searchText === null || searchText === undefined) {
                throw new RequiredError('searchText', 'Required parameter searchText was null or undefined when calling findAccountUsers.');
            }
            var localVarPath = "/api/accountsadmin/c/accounts/{accountId}/users"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} page
         * @param {number} size
         * @param {number} accountId
         * @param {string} [login]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsers: function (page, size, accountId, login, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page', 'Required parameter page was null or undefined when calling findUsers.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size', 'Required parameter size was null or undefined when calling findUsers.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling findUsers.');
            }
            var localVarPath = "/api/accountsadmin/c/internal/users";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (login !== undefined) {
                localVarQueryParameter['login'] = login;
            }
            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsers1: function (userId, accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling findUsers1.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling findUsers1.');
            }
            var localVarPath = "/api/accountsadmin/c/internal/users/{userId}"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * UserSearchEndpointApi - functional programming interface
 * @export
 */
export var UserSearchEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} page
         * @param {number} size
         * @param {string} searchText
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountUsers: function (accountId, page, size, searchText, options) {
            var localVarAxiosArgs = UserSearchEndpointApiAxiosParamCreator(configuration).findAccountUsers(accountId, page, size, searchText, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} page
         * @param {number} size
         * @param {number} accountId
         * @param {string} [login]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsers: function (page, size, accountId, login, options) {
            var localVarAxiosArgs = UserSearchEndpointApiAxiosParamCreator(configuration).findUsers(page, size, accountId, login, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsers1: function (userId, accountId, options) {
            var localVarAxiosArgs = UserSearchEndpointApiAxiosParamCreator(configuration).findUsers1(userId, accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * UserSearchEndpointApi - factory interface
 * @export
 */
export var UserSearchEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} page
         * @param {number} size
         * @param {string} searchText
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountUsers: function (accountId, page, size, searchText, options) {
            return UserSearchEndpointApiFp(configuration).findAccountUsers(accountId, page, size, searchText, options)(axios, basePath);
        },
        /**
         *
         * @param {number} page
         * @param {number} size
         * @param {number} accountId
         * @param {string} [login]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsers: function (page, size, accountId, login, options) {
            return UserSearchEndpointApiFp(configuration).findUsers(page, size, accountId, login, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsers1: function (userId, accountId, options) {
            return UserSearchEndpointApiFp(configuration).findUsers1(userId, accountId, options)(axios, basePath);
        },
    };
};
/**
 * UserSearchEndpointApi - object-oriented interface
 * @export
 * @class UserSearchEndpointApi
 * @extends {BaseAPI}
 */
var UserSearchEndpointApi = /** @class */ (function (_super) {
    __extends(UserSearchEndpointApi, _super);
    function UserSearchEndpointApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} accountId
     * @param {number} page
     * @param {number} size
     * @param {string} searchText
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSearchEndpointApi
     */
    UserSearchEndpointApi.prototype.findAccountUsers = function (accountId, page, size, searchText, options) {
        return UserSearchEndpointApiFp(this.configuration).findAccountUsers(accountId, page, size, searchText, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} page
     * @param {number} size
     * @param {number} accountId
     * @param {string} [login]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSearchEndpointApi
     */
    UserSearchEndpointApi.prototype.findUsers = function (page, size, accountId, login, options) {
        return UserSearchEndpointApiFp(this.configuration).findUsers(page, size, accountId, login, options)(this.axios, this.basePath);
    };
    /**
     *
     * @param {number} userId
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSearchEndpointApi
     */
    UserSearchEndpointApi.prototype.findUsers1 = function (userId, accountId, options) {
        return UserSearchEndpointApiFp(this.configuration).findUsers1(userId, accountId, options)(this.axios, this.basePath);
    };
    return UserSearchEndpointApi;
}(BaseAPI));
export { UserSearchEndpointApi };
