import React, { useCallback, useMemo } from 'react';
import { DynamicVirtualList } from '@just-ai/analytic-front/dist/Reporter/components/ClientsDialogs/DynamicVirtualList';
import { useAppContext } from '../../../AppContext';
import { buildRenderMessagesComponents } from './buildRenderMessagesComponents';
import { useOperatorDataContext } from '../OperatorDataContext';
import { MessageDto } from '@just-ai/aimychat-shared/dist/api/client/websocket';

export const Messages = () => {
  const { language } = useAppContext();
  const {
    setMessageForPrompter,
    getNewPromterMessage,
    groupsForTransfer: groups,
    isMessagesHaveToLoad,
    loadMoreMessages,
    messages,
    chosenChat: chat,
  } = useOperatorDataContext();

  const contextPrompterId = useMemo(
    () => groups.find(({ id }) => id === chat?.operatorGroup.id)?.contextPrompterId,
    [chat?.operatorGroup.id, groups]
  );

  const choosePrompterMessage = useCallback(
    (message: MessageDto) => {
      if (!contextPrompterId) {
        return;
      }
      getNewPromterMessage({
        prompterId: contextPrompterId.toString(),
        text: message.text,
        operatorChatId: chat?.id,
        isContextPrompter: true,
      });
      setMessageForPrompter(message);
    },
    [chat?.id, contextPrompterId, getNewPromterMessage, setMessageForPrompter]
  );

  if (!messages || !chat) {
    return null;
  }

  return (
    <DynamicVirtualList
      bottomAutoScroll
      elements={buildRenderMessagesComponents({
        messages,
        chat,
        isMessagesHaveToLoad,
        language,
        choosePrompterMessage,
        contextPrompterId,
        loadMoreMessages,
      })}
      smoothScroll={false}
    />
  );
};
