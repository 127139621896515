export var operatorPlaceLocalization = {
    'OperatorPlace: tab completed': {
        eng: 'History',
        ru: 'Архив',
        pt: 'História',
    },
    'OperatorPlace: tab active': {
        eng: 'Active',
        ru: 'В работе',
        pt: 'Ativo',
    },
    'OperatorPlace: tab created': {
        eng: 'In queue',
        ru: 'В очереди',
        pt: 'Na fila',
    },
    'OperatorPlace: filter input placeholder': {
        eng: 'Search by customer name',
        ru: 'Поиск по имени клиента',
        pt: 'Pesquisar por nome de cliente',
    },
    'OperatorPlace: header finish the dialog': {
        eng: 'Close',
        ru: 'Завершить',
        pt: 'Fechar',
    },
    'OperatorPlace: header transfer button title': {
        eng: 'Forward',
        ru: 'Перевести',
        pt: 'Encaminhar',
    },
    'OperatorPlace: header ban button title': {
        eng: 'Block',
        ru: 'Заблокировать',
        pt: 'Bloquear',
    },
    'OperatorPlace:Dialog:Footer join dialog button': {
        eng: 'Join the chat',
        ru: 'Подключиться к диалогу',
        pt: 'Entrar no chat',
    },
    'OperatorPlace:AboutClient:Header': {
        eng: 'Customer details',
        ru: 'О клиенте',
        pt: 'Detalhes do cliente',
    },
    'OperatorPlace:Prompters:Header': {
        eng: 'Prompters',
        ru: 'Суфлеры',
        pt: 'Prompters',
    },
    'OperatorPlace: fields header': {
        eng: 'Fields',
        ru: 'Поля',
        pt: 'Campos',
    },
    'OperatorPlace: bot info header': {
        eng: 'Information from bot',
        ru: 'Информация от бота',
        pt: 'Informações do bot',
    },
    'OperatorPlace: about client id header': {
        eng: 'ID',
        ru: 'ID',
        pt: 'ID',
    },
    'OperatorPlace: about client name header': {
        eng: 'User name',
        ru: 'Имя пользователя',
        pt: 'Nome de usuário',
    },
    'OperatorPlace: about client phone header': {
        eng: 'Phone number',
        ru: 'Номер телефона',
        pt: 'Número de telefone',
    },
    'OperatorPlace: about client email header': {
        eng: 'Email',
        ru: 'Email',
        pt: 'E-mail',
    },
    'OperatorPlace: about client chatId header': {
        eng: 'Bot ID',
        ru: 'ID бота',
        pt: 'ID de bot',
    },
    'OperatorPlace:Dialog:Footer input placeholder': {
        eng: 'Enter your message…',
        ru: 'Введите сообщение…',
        pt: 'Digite sua mensagem…',
    },
    'OperatorPlace:Dialog:Footer tooltip send message': {
        eng: 'send a message',
        ru: 'отправка сообщения',
        pt: 'enviar uma mensagem',
    },
    'OperatorPlace:Dialog:Footer tooltip line break': {
        eng: 'insert a new line',
        ru: 'перенос строки',
        pt: 'inserir uma nova linha',
    },
    'OperatorPlace:Dialog:Footer tooltip attach file': {
        eng: 'Attach file',
        ru: 'Прикрепить файл',
        pt: 'Anexe o arquivo',
    },
    'OperatorPlace:Dialog:Footer:Tooltip:Prompter:On': {
        eng: 'Enable prompter hints',
        ru: 'Включить подсказки суфлера',
        pt: 'Ativar dicas do prompter',
    },
    'OperatorPlace:Dialog:Footer:Tooltip:Prompter:Off': {
        eng: 'Disable prompter hints',
        ru: 'Отключить подсказки суфлера',
        pt: 'Desativar dicas do prompter',
    },
    'OperatorPlace:Dialog:Footer file dimension B': {
        eng: 'B',
        ru: 'байт',
        pt: 'B',
    },
    'OperatorPlace:Dialog:Footer file dimension KB': {
        eng: 'KB',
        ru: 'Кбайт',
        pt: 'KB',
    },
    'OperatorPlace:Dialog:Footer file dimension MB': {
        eng: 'MB',
        ru: 'Мбайт',
        pt: 'MB',
    },
    'OperatorPlace:Dialog:Footer file dimension GB': {
        eng: 'GB',
        ru: 'Гбайт',
        pt: 'GB',
    },
    'OperatorPlace: ban modal title': {
        eng: 'Block customer',
        ru: 'Заблокировать клиента',
        pt: 'Bloquear cliente',
    },
    'OperatorPlace: ban modal confirm button': {
        eng: 'Block',
        ru: 'Заблокировать',
        pt: 'Bloquear',
    },
    'OperatorPlace: ban modal reason': {
        eng: 'Reason',
        ru: 'Причина',
        pt: 'Motivo',
    },
    'OperatorPlace: ban modal reason placeholder': {
        eng: 'What the customer has violated',
        ru: 'Что нарушил клиент',
        pt: 'O que o cliente violou',
    },
    'OperatorPlace: ban modal message': {
        eng: 'Message to customer',
        ru: 'Сообщение клиенту',
        pt: 'Mensagem para o cliente',
    },
    'OperatorPlace: ban modal message placeholder': {
        eng: 'You have been blocked because…',
        ru: 'Вы были заблокированы, потому что…',
        pt: 'Você foi bloqueado porque…',
    },
    'OperatorPlace: transfer modal title': {
        eng: 'Forward chat',
        ru: 'Перевести диалог',
        pt: 'Encaminhar chat',
    },
    'OperatorPlace: transfer modal confirm button': {
        eng: 'Forward',
        ru: 'Перевести',
        pt: 'Encaminhar',
    },
    'OperatorPlace: transfer modal description': {
        eng: 'Select an agent group to forward the chat to.',
        ru: 'Выберите группу операторов, на которую будет переведен диалог.',
        pt: 'Selecione um grupo de agentes para o qual encaminhar o chat.',
    },
    'OperatorPlace: no user choosen info completed': {
        eng: 'Select a chat to view it.',
        ru: 'Выберите диалог, чтобы его просмотреть.',
        pt: 'Selecione um chat para visualizá-lo.',
    },
    'OperatorPlace: no user choosen info active': {
        eng: 'Select a chat to continue it.',
        ru: 'Выберите диалог, чтобы его продолжить.',
        pt: 'Selecione um chat para continuá-lo.',
    },
    'OperatorPlace: no user choosen info created': {
        eng: 'Select a chat to view and join it.',
        ru: 'Выберите диалог, чтобы просмотреть его и присоединиться.',
        pt: 'Selecione um chat para visualizar e entrar nele.',
    },
    'OperatorPlace: chat empty list created': {
        eng: 'Here you will see chats that nobody has joined yet.',
        ru: 'Здесь будут диалоги, которые еще не взяли в работу.',
        pt: 'Aqui você verá chats nos quais ninguém entrou ainda.',
    },
    'OperatorPlace: chat empty list active': {
        eng: 'Here you will see your active chats.',
        ru: 'Здесь будут ваши активные диалоги.',
        pt: 'Aqui você verá seus chats ativos.',
    },
    'OperatorPlace: chat empty list completed': {
        eng: 'Here you will see all closed chats assigned to your groups.',
        ru: 'Здесь будут все завершенные диалоги, назначенные на ваши группы.',
        pt: 'Aqui você verá todos os chats fechados atribuídos aos seus grupos.',
    },
    'OperatorPlace:Dialog chat completed': {
        eng: 'The chat with the agent has finished',
        ru: 'Диалог с оператором завершен',
        pt: 'O chat com o agente foi finalizado',
    },
    'OperatorPlace:Dialog operator joined': {
        eng: '$[1] ($[2]) has joined the chat',
        ru: 'Оператор $[1] ($[2]) подключился',
        pt: '$[1] ($[2]) se juntou ao chat',
    },
    'OperatorPlace:Dialog new operator': {
        eng: 'Connecting to the chat with an agent',
        ru: 'Подключение к диалогу с оператором',
        pt: 'Conectando o chat ao agente',
    },
    'OperatorPlace:Dialog silence operator': {
        eng: 'Switching to another agent',
        ru: 'Переключаем на другого оператора',
        pt: 'Mudar para outro agente',
    },
    'OperatorPlace:Dialog:TransferTo:AnotherGroup': {
        eng: 'The chat is assigned to the group: $[1]',
        ru: 'Диалог переведен на группу: $[1]',
        pt: 'O chat é atribuído ao grupo: $[1]',
    },
    'OperatorPlace:Dialog client left': {
        eng: 'The customer has closed the chat',
        ru: 'Клиент завершил диалог',
        pt: 'O cliente fechou o chat',
    },
    'OperatorPlace:Dialog operator left': {
        eng: 'The agent has closed the chat',
        ru: 'Оператор завершил диалог',
        pt: 'O agente fechou a chat',
    },
    'OperatorPlace: header unblock the dialog': {
        eng: 'Unblock',
        ru: 'Разблокировать',
        pt: 'Desbloquear',
    },
    'OperatorPlace: header blocked status': {
        eng: 'Blocked',
        ru: 'Заблокирован',
        pt: 'Bloqueado',
    },
    'OperatorPlace:Dialog blocked': {
        eng: 'The customer is blocked',
        ru: 'Клиент заблокирован',
        pt: 'O cliente está bloqueado',
    },
    'OperatorPlace:Dialog unblocked': {
        eng: 'The customer is unblocked',
        ru: 'Клиент разблокирован',
        pt: 'O cliente está desbloqueado',
    },
    'OperatorPlace: Load More': {
        eng: 'Load more',
        ru: 'Загрузить еще',
        pt: 'Carregar mais',
    },
    'OperatorPlace:Dialog:Footer join modal title': {
        eng: 'Join the chat',
        ru: 'Подключиться к диалогу',
        pt: 'Entrar no chat',
    },
    'OperatorPlace:Dialog:Footer join modal description': {
        eng: 'Once you join the chat, your status will be set to “Online”.',
        ru: 'При подключении к диалогу ваш статус будет изменен на «В сети».',
        pt: 'Depois de entrar no chat, seu status será definido como “On-line”.',
    },
    'OperatorPlace:Dialog:Footer join modal confirm': {
        eng: 'Join',
        ru: 'Подключиться',
        pt: 'Entrar',
    },
    'OperatorPlace: no connection': {
        eng: 'Connection lost. Reconnecting…',
        ru: 'Соединение потеряно. Идет переподключение…',
        pt: 'Conexão perdida. Reconectando…',
    },
    'OperatorPlace:Settings number of minutes': {
        eng: 'Number of minutes',
        ru: 'Количество минут',
        pt: 'Número de minutos',
    },
    'OperatorPlace: bot': {
        eng: 'Bot',
        ru: 'Бот',
        pt: 'Bot',
    },
    'OperatorPlace: user already been added': {
        eng: 'This user has already been added.',
        ru: 'Такой пользователь уже добавлен.',
        pt: 'Este usuário já foi adicionado.',
    },
    'OperatorPlace: user does not exist': {
        eng: 'There is no such a user in your account. Please, invite them.',
        ru: 'Такого пользователя нет в вашем аккаунте. Пригласите его.',
        pt: 'Não existe tal usuário em sua conta. Convide-os.',
    },
    'OperatorPlace:Users:Limit:Admin': {
        eng: "You have $[1], your plan includes only $[2]. Please delete extra ones in <a href='/settings/operators'>Settings</a>.",
        ru: "Сейчас у вас $[1], ваш тариф включает $[2]. Удалите лишних в <a href='/settings/operators'>настройках</a>.",
        pt: "Você tem $[1], seu plano inclui apenas $[2]. Exclua os extras em <a href='/settings/operators'>Configurações</a> .",
    },
    'OperatorPlace:Groups:Limit:Admin': {
        eng: "You have $[1], your plan includes only $[2]. Please delete extra ones in <a href='/settings'>Settings</a>.",
        ru: "Сейчас у вас $[1], ваш тариф включает $[2]. Удалите лишние в <a href='/settings'>настройках</a>.",
        pt: "Você tem $[1], seu plano inclui apenas $[2]. Exclua os extras em <a href='/settings'>Configurações</a> .",
    },
    'OperatorPlace:Prompter:Banner:Error:Operator': {
        eng: 'The $[1] prompter doesn’t respond. Please contact your administrator.',
        ru: 'Суфлер $[1] не отвечает. Обратитесь к администратору.',
        pt: 'O prompter $[1] não responde. Entre em contato com seu administrador.',
    },
    'OperatorPlace:Prompter:Banner:Error:Admin': {
        eng: 'The $[1] prompter doesn’t respond. Please check its settings.',
        ru: 'Суфлер $[1] не отвечает. Проверьте его настройки.',
        pt: 'O prompter $[1] não responde. Verifique suas configurações.',
    },
    'OperatorPlace:All:Limit:Admin': {
        eng: "There are more groups and users than your plan includes: $[1] and $[2] instead of $[3] and $[4]. Please reduce their number in <a href='/settings'>Settings</a>.",
        ru: "Групп и пользователей больше, чем включает ваш тариф: $[1] и $[2] вместо $[3] и $[4]. Сократите их количество в <a href='/settings'>настройках</a>.",
        pt: "Existem mais grupos e usuários do que o seu plano inclui: $[1] e $[2] em vez de $[3] e $[4]. Reduza o número em <a href='/settings'>Configurações</a>.",
    },
    'OperatorPlace:Limit:Agent': {
        eng: 'The service is unavailable. Please contact your administrator.',
        ru: 'Сервис недоступен. Обратитесь к администратору.',
        pt: 'O serviço está indisponível. Entre em contato com seu administrador.',
    },
    'OperatorPlace:Account:Blocked:Agent': {
        eng: 'The account is temporarily blocked. Please contact your administrator.',
        ru: 'Аккаунт временно заблокирован. Обратитесь к администратору.',
        pt: 'A conta está temporariamente bloqueada. Entre em contato com seu administrador.',
    },
    'OperatorPlace:Account:Blocked:Admin': {
        eng: 'We have not received payment from you, your account is blocked. Pay for the subscription to continue using Aimychat.',
        ru: 'Мы не получили от вас оплату, ваш аккаунт был заблокирован. Оплатите подписку, чтобы продолжить работу с Aimychat.',
    },
    'OperatorPlace:Account:Blocked:Admin:Tovie': {
        eng: 'We have not received payment from you, your account is blocked. Pay for the subscription to continue using Tovie Agent.',
        pt: 'Não recebemos seu pagamento, e sua conta está bloqueada. Pague pela assinatura para continuar usando o Tovie Agent.',
    },
    'Plural:Groups:Zero': {
        eng: 'no groups',
        ru: 'нет групп',
        pt: 'nenhum grupo',
    },
    'Plural:Groups:One': {
        eng: 'one group',
        ru: 'одна группа',
        pt: 'um grupo',
    },
    'Plural:Groups:Two': {
        eng: '2 groups',
        ru: '2 группы',
        pt: '2 grupos',
    },
    'Plural:Groups:Few': {
        eng: '$[1] groups',
        ru: '$[1] группы',
        pt: '$[1] grupos',
    },
    'Plural:Groups:Many': {
        eng: '$[1] groups',
        ru: '$[1] групп',
        pt: '$[1] grupos',
    },
    'Plural:Users:Zero': {
        eng: 'no users',
        ru: 'нет пользователей',
        pt: 'nenhum usuário',
    },
    'Plural:Users:One': {
        eng: 'one user',
        ru: 'один пользователь',
        pt: 'um usuário',
    },
    'Plural:Users:Two': {
        eng: '2 users',
        ru: '2 пользователя',
        pt: '2 usuários',
    },
    'Plural:Users:Few': {
        eng: '$[1] users',
        ru: '$[1] пользователя',
        pt: '$[1] usuários',
    },
    'Plural:Users:Many': {
        eng: '$[1] users',
        ru: '$[1] пользователей',
        pt: '$[1] usuários',
    },
    'Prompters:Description:AnswersHere': {
        eng: 'Here you will see prompter answers<br> to your requests.',
        ru: 'Здесь будут ответы суфлера<br> на ваши запросы.',
        pt: 'Aqui você verá as respostas do prompter<br> aos seus pedidos.',
    },
    'Prompters:Description:NoAnswer': {
        eng: 'The prompter has no answers to your request. Please try to enter another one.',
        ru: 'У суфлера нет ответов на ваш запрос. Попробуйте ввести другой.',
        pt: 'O prompter não tem respostas para sua solicitação. Tente inserir outro.',
    },
    'Prompters:Description:NoPromps': {
        eng: 'The context prompter doesn’t have any suitable answers.',
        ru: 'Нет подсказок от контекстного суфлера.',
        pt: 'O prompter de contexto não tem nenhuma resposta adequada.',
    },
    'Prompters:Tab:YourRequest': {
        eng: 'Your request',
        ru: 'Ваш запрос',
        pt: 'Sua solicitação',
    },
    'OperatorPlace:Chats:Attachment': {
        eng: 'File',
        ru: 'Файл',
        pt: 'Arquivo',
    },
    'Comments:Message': {
        eng: 'Message',
        ru: 'Сообщение',
        pt: 'Mensagem',
    },
    'Comments:Comment': {
        eng: 'Comment',
        ru: 'Комментарий',
        pt: 'Comentário',
    },
    'Comments:Promps': {
        eng: 'Prompter hints',
        ru: 'Подсказки суфлера',
        pt: 'Dicas do prompter',
    },
    'OperationPlace:Filters:MESSAGES': {
        eng: 'Messages',
        ru: 'Сообщения',
        pt: 'Mensagens',
    },
    'OperationPlace:Filters:COMMENTS': {
        eng: 'Comments',
        ru: 'Комментарии',
        pt: 'Comentários',
    },
    'OperationPlace:Filters:SYSTEMMESSAGES': {
        eng: 'Notifications',
        ru: 'Оповещения',
        pt: 'Notificações',
    },
    'OperationPlace:Filters:Show': {
        eng: 'Show',
        ru: 'Показывать',
        pt: 'Mostrar',
    },
};
