import { IconButton } from '@just-ai/just-ui';
import axios from 'axios';
import classNames from 'classnames';
import { Dispatch, memo, MouseEvent, SetStateAction, useCallback, useEffect } from 'react';
import { useOperatorDataContext } from '../../OperatorDataContext';

type Props = {
  setSelectedFiles: Dispatch<SetStateAction<File[] | undefined>>;
  selectedFiles?: File[];
  setFileUploading: Dispatch<SetStateAction<boolean>>;
  fileUploading: boolean;
  isComment: boolean;
};

export const Attachments = memo(
  ({ setFileUploading, selectedFiles, setSelectedFiles, fileUploading, isComment }: Props) => {
    const { files, setMessage, text } = useOperatorDataContext();

    useEffect(() => {
      if (!selectedFiles?.length || (files?.length && selectedFiles?.length === files?.length)) {
        return;
      }
      setFileUploading(true);

      const saveFilesToServer = async () => {
        const files = await Promise.all(
          selectedFiles.map(async file => {
            const fd = new FormData();
            fd.append('file', file);
            const { data } = await axios.post('/restapi/file/upload', fd);
            return { url: data, mimeType: file.type, name: file.name };
          })
        ).catch(() => {
          setFileUploading(false);
          return undefined;
        });

        setMessage({ text, files });
        setFileUploading(false);
      };

      saveFilesToServer();

      return () => {
        setSelectedFiles([]);
      };
    }, [files, selectedFiles, selectedFiles?.length, setFileUploading, setMessage, setSelectedFiles, text]);

    const onClearFile = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        setMessage({ text, files: files?.filter(({ name }, index) => `${name}_${index}` !== e.currentTarget.id) });
        setSelectedFiles(selectedFiles?.filter(({ name }, index) => `${name}_${index}` !== e.currentTarget.id));
      },
      [files, selectedFiles, setMessage, setSelectedFiles, text]
    );

    if (!files?.length || fileUploading) {
      return null;
    }

    return (
      <div className='FileInfoWrapper'>
        {files.map(({ name }, index) => (
          <div key={`${name}_${index}`} className={classNames('FileInfo', { CommentMode: isComment })}>
            <div className='FileInfo_Name'>{name}</div>
            <IconButton
              id={`${name}_${index}`}
              data-test-id='Chat.MessageForm.clearFileButton'
              className='FileInfo_clearFileButton'
              size='sm'
              name='farTrashAlt'
              onClick={onClearFile}
            />
          </div>
        ))}
      </div>
    );
  }
);
