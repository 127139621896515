import React, { memo, useMemo } from 'react';
import { Spinner } from '@just-ai/just-ui';
import { t } from '../../../localization';
import { StateListsName } from '@just-ai/aimychat-shared/dist/operatorPlaceTypes';
import { OperatorDataContextType } from '../OperatorDataContext';

type Props = {
  tab: StateListsName;
  loading: boolean;
  amount: number;
  contextData: OperatorDataContextType;
};

export const TabLabel = memo(({ tab, loading, amount, contextData }: Props) => {
  const hasUnreadedMessages = useMemo(
    () => contextData[tab].some(({ unreadMessageCount }) => unreadMessageCount),
    [contextData, tab]
  );

  return (
    <div className='flex-row flex-center' style={{ whiteSpace: 'nowrap', width: '100%' }}>
      {loading && <Spinner size='1x' style={{ zIndex: '999' }} />}
      <span>{t(`OperatorPlace: tab ${tab}`)}</span>
      {amount > 0 && tab !== StateListsName.COMPLETED && (
        <div
          data-test-id={`OperatorPlace.Tab.${tab}.amount.${amount}`}
          className='margin-left-2x'
          style={{
            padding: '2px 4px',
            borderRadius: '2px',
            backgroundColor: hasUnreadedMessages ? 'var(--indigo-600)' : 'var(--gray-200)',
            color: hasUnreadedMessages ? 'var(--white)' : 'var(--gray-800)',
          }}
        >
          {amount}
        </div>
      )}
    </div>
  );
});
