export const headerLocalization = {
  'Header: set offline modal title': {
    eng: 'Change status',
    ru: 'Изменить статус',
    pt: 'Alterar status',
  },
  'Header: set offline description': {
    eng: 'You have active chats left. Choose what to do with them.',
    ru: 'У вас остались незавершенные диалоги. Выберите, что с ними сделать.',
    pt: 'Você ainda tem chats ativos. Escolha o que fazer com eles.',
  },
  'Header: set offline option label in_queue': {
    eng: 'Forward to group queue',
    ru: 'Передать в очередь группы',
    pt: 'Encaminhar para a fila do grupo',
  },
  'Header: set offline option label leave_open': {
    eng: 'Leave the chats open',
    ru: 'Оставить диалоги открытыми',
    pt: 'Deixar os chats abertos',
  },
  'Header: set offline option label finish': {
    eng: 'Close all',
    ru: 'Завершить все',
    pt: 'Fechar tudo',
  },
  'Header: set offline option in_queue description': {
    eng: 'Other group agents will be able to continue the chats',
    ru: 'Другие операторы группы смогут продолжить диалоги',
    pt: 'Outros agentes do grupo poderão continuar os chats',
  },
  'Header: set offline option leave_open description': {
    eng: 'You can return to the chats later',
    ru: 'Вы сможете вернуться к этим диалогам позже',
    pt: 'Você pode retornar aos chats mais tarde',
  },
  'Header: set offline option finish description': {
    eng: 'The bot will join the chats and continue the script',
    ru: 'К диалогам подключится бот и продолжит сценарий',
    pt: 'O bot entrará nos chats e continuará o script',
  },
  'Header: disconnect modal title': {
    eng: 'Disconnection',
    ru: 'Отключение',
    pt: 'Desconexão',
  },
  'Header: disconnect modal button': {
    eng: 'Sign in again',
    ru: 'Войти снова',
    pt: 'Entrar novamente',
  },
  'Header: disconnect modal description': {
    eng: 'A user with the same login has signed in via another browser or device.',
    ru: 'Пользователь с таким же логином авторизовался в другом браузере или устройстве.',
    pt: 'Um usuário com o mesmo login entrou por meio de outro navegador ou dispositivo.',
  },
  'Header: init ofline modal title': {
    eng: 'Change status',
    ru: 'Изменить статус',
    pt: 'Alterar status',
  },
  'Header: init ofline modal description': {
    eng: 'Do you want to change your status to “Online”? If chats are automatically assigned to you in $[1], you will start getting them.',
    ru: 'Вы хотите изменить статус на «В сети»? Если в $[1] диалоги назначаются вам автоматически, они начнут вам приходить.',
    pt: 'Deseja alterar seu status para “On-line”? Se os chats forem atribuídos automaticamente a você em $[1], você começará a recebê-los.',
  },
};
