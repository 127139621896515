import { Button, Modal } from '@just-ai/just-ui';
import React, { useCallback } from 'react';
import { useWSCallbacks } from '../operatorPlaceHelpers';
import { useToggle } from '@just-ai/aimychat-shared/dist/utils/hooks';
import { useOperatorDataContext } from '../OperatorDataContext';
import { t } from '../../../localization';
import { Spinner } from '@just-ai/just-ui/dist/Spinner';

export const JoiningButton = () => {
  const { joinChat, isJoinChatAvailable, isOnline } = useOperatorDataContext();
  const [joining, setJoining, setFinishJoingin] = useToggle();
  const [showModal, setShow, setHide] = useToggle();

  const callbacks = useWSCallbacks({
    success: () => {
      setFinishJoingin();
      setHide();
    },
  });

  const handleJoinChat = useCallback(() => {
    if (isOnline) {
      setJoining();
      joinChat(callbacks);
    } else {
      setShow();
    }
  }, [callbacks, isOnline, joinChat, setJoining, setShow]);
  const handleJoinChatFromModal = useCallback(() => {
    setJoining();
    joinChat(callbacks);
  }, [callbacks, joinChat, setJoining]);

  return (
    <div className='ChatFooter_waiting'>
      <Button
        data-test-id='Chat.MessageForm.joinButton'
        color='primary'
        onClick={handleJoinChat}
        disabled={!isJoinChatAvailable || joining}
      >
        {joining && <Spinner size='1x' bluredBackground />}
        {t('OperatorPlace:Dialog:Footer join dialog button')}
      </Button>
      <Modal
        isOpen={showModal}
        title={t('OperatorPlace:Dialog:Footer join modal title')}
        onCancelClick={setHide}
        buttonSubmitText={t('OperatorPlace:Dialog:Footer join modal confirm')}
        buttonCancelText={t('Cancel')}
        onActionClick={handleJoinChatFromModal}
        inProgress={joining}
      >
        <span>{t('OperatorPlace:Dialog:Footer join modal description')}</span>
      </Modal>
    </div>
  );
};
