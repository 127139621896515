/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable unused-imports/no-unused-imports-ts */
/* eslint-disable @typescript-eslint/no-unused-vars */
// tslint:disable
// @ts-nocheck
/**
 * Aimychat service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as globalImportUrl from 'url';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError } from './base';
/**
 *
 * @export
 * @enum {string}
 */
export var ChartType;
(function (ChartType) {
    ChartType["CHATSCOUNT"] = "CHATS_COUNT";
    ChartType["AVERAGECONNECTTIME"] = "AVERAGE_CONNECT_TIME";
    ChartType["AVERAGEFIRSTMESSAGETIME"] = "AVERAGE_FIRST_MESSAGE_TIME";
    ChartType["AVERAGEDURATION"] = "AVERAGE_DURATION";
})(ChartType || (ChartType = {}));
/**
 * How clients are sorted in the \"Queue\" tab
 * @export
 * @enum {string}
 */
export var ClientQueueSorting;
(function (ClientQueueSorting) {
    ClientQueueSorting["ASC"] = "ASC";
    ClientQueueSorting["DESC"] = "DESC";
})(ClientQueueSorting || (ClientQueueSorting = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var IntervalType;
(function (IntervalType) {
    IntervalType["DAY"] = "DAY";
    IntervalType["WEEK"] = "WEEK";
    IntervalType["MONTH"] = "MONTH";
})(IntervalType || (IntervalType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var OperatorRole;
(function (OperatorRole) {
    OperatorRole["AGENT"] = "AGENT";
    OperatorRole["ADMIN"] = "ADMIN";
})(OperatorRole || (OperatorRole = {}));
/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export var E2ECheckApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck: function (loggingEnabledE2ECheck, zRequestId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
            if (loggingEnabledE2ECheck === null || loggingEnabledE2ECheck === undefined) {
                throw new RequiredError('loggingEnabledE2ECheck', 'Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.');
            }
            var localVarPath = "/api/aimychat-be/e2e-check";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (zRequestId !== undefined && zRequestId !== null) {
                localVarHeaderParameter['Z-requestId'] = String(zRequestId);
            }
            if (loggingEnabledE2ECheck !== undefined && loggingEnabledE2ECheck !== null) {
                localVarHeaderParameter['loggingEnabledE2ECheck'] = String(JSON.stringify(loggingEnabledE2ECheck));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export var E2ECheckApiFp = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck: function (loggingEnabledE2ECheck, zRequestId, options) {
            var localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * E2ECheckApi - factory interface
 * @export
 */
export var E2ECheckApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck: function (loggingEnabledE2ECheck, zRequestId, options) {
            return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(axios, basePath);
        },
    };
};
/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
var E2ECheckApi = /** @class */ (function (_super) {
    __extends(E2ECheckApi, _super);
    function E2ECheckApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2ECheckApi
     */
    E2ECheckApi.prototype.e2ECheck = function (loggingEnabledE2ECheck, zRequestId, options) {
        return E2ECheckApiFp(this.configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(this.axios, this.basePath);
    };
    return E2ECheckApi;
}(BaseAPI));
export { E2ECheckApi };
/**
 * OperatorApi - axios parameter creator
 * @export
 */
export var OperatorApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {AddOperatorDto} addOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToOperatorPlace: function (accountId, addOperatorDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling addOperatorToOperatorPlace.');
            }
            // verify required parameter 'addOperatorDto' is not null or undefined
            if (addOperatorDto === null || addOperatorDto === undefined) {
                throw new RequiredError('addOperatorDto', 'Required parameter addOperatorDto was null or undefined when calling addOperatorToOperatorPlace.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operators/add-operator"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof addOperatorDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(addOperatorDto !== undefined ? addOperatorDto : {}) : (addOperatorDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperator: function (accountId, operatorId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperator.');
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError('operatorId', 'Required parameter operatorId was null or undefined when calling getOperator.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operators/{operatorId}"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("operatorId", "}"), encodeURIComponent(String(operatorId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {number} ccUserId Conversational cloud user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorByCcUserId: function (accountId, ccUserId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorByCcUserId.');
            }
            // verify required parameter 'ccUserId' is not null or undefined
            if (ccUserId === null || ccUserId === undefined) {
                throw new RequiredError('ccUserId', 'Required parameter ccUserId was null or undefined when calling getOperatorByCcUserId.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operators/by-cc-user-id"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (ccUserId !== undefined) {
                localVarQueryParameter['ccUserId'] = ccUserId;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operators
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperators: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperators.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operators"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromOperatorPlace: function (accountId, operatorId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling removeOperatorFromOperatorPlace.');
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError('operatorId', 'Required parameter operatorId was null or undefined when calling removeOperatorFromOperatorPlace.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operators/{operatorId}"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("operatorId", "}"), encodeURIComponent(String(operatorId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorDto} updateOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperator: function (accountId, operatorId, updateOperatorDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateOperator.');
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError('operatorId', 'Required parameter operatorId was null or undefined when calling updateOperator.');
            }
            // verify required parameter 'updateOperatorDto' is not null or undefined
            if (updateOperatorDto === null || updateOperatorDto === undefined) {
                throw new RequiredError('updateOperatorDto', 'Required parameter updateOperatorDto was null or undefined when calling updateOperator.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operators/{operatorId}"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("operatorId", "}"), encodeURIComponent(String(operatorId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof updateOperatorDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(updateOperatorDto !== undefined ? updateOperatorDto : {}) : (updateOperatorDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OperatorApi - functional programming interface
 * @export
 */
export var OperatorApiFp = function (configuration) {
    return {
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {AddOperatorDto} addOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToOperatorPlace: function (accountId, addOperatorDto, options) {
            var localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).addOperatorToOperatorPlace(accountId, addOperatorDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperator: function (accountId, operatorId, options) {
            var localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperator(accountId, operatorId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {number} ccUserId Conversational cloud user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorByCcUserId: function (accountId, ccUserId, options) {
            var localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperatorByCcUserId(accountId, ccUserId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operators
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperators: function (accountId, options) {
            var localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperators(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromOperatorPlace: function (accountId, operatorId, options) {
            var localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).removeOperatorFromOperatorPlace(accountId, operatorId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorDto} updateOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperator: function (accountId, operatorId, updateOperatorDto, options) {
            var localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).updateOperator(accountId, operatorId, updateOperatorDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OperatorApi - factory interface
 * @export
 */
export var OperatorApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {AddOperatorDto} addOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToOperatorPlace: function (accountId, addOperatorDto, options) {
            return OperatorApiFp(configuration).addOperatorToOperatorPlace(accountId, addOperatorDto, options)(axios, basePath);
        },
        /**
         * Get operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperator: function (accountId, operatorId, options) {
            return OperatorApiFp(configuration).getOperator(accountId, operatorId, options)(axios, basePath);
        },
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {number} ccUserId Conversational cloud user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorByCcUserId: function (accountId, ccUserId, options) {
            return OperatorApiFp(configuration).getOperatorByCcUserId(accountId, ccUserId, options)(axios, basePath);
        },
        /**
         * Get operators
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperators: function (accountId, options) {
            return OperatorApiFp(configuration).getOperators(accountId, options)(axios, basePath);
        },
        /**
         * Remove operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromOperatorPlace: function (accountId, operatorId, options) {
            return OperatorApiFp(configuration).removeOperatorFromOperatorPlace(accountId, operatorId, options)(axios, basePath);
        },
        /**
         * Update operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorDto} updateOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperator: function (accountId, operatorId, updateOperatorDto, options) {
            return OperatorApiFp(configuration).updateOperator(accountId, operatorId, updateOperatorDto, options)(axios, basePath);
        },
    };
};
/**
 * OperatorApi - object-oriented interface
 * @export
 * @class OperatorApi
 * @extends {BaseAPI}
 */
var OperatorApi = /** @class */ (function (_super) {
    __extends(OperatorApi, _super);
    function OperatorApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Add operator to operator place
     * @param {number} accountId Account identifier
     * @param {AddOperatorDto} addOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    OperatorApi.prototype.addOperatorToOperatorPlace = function (accountId, addOperatorDto, options) {
        return OperatorApiFp(this.configuration).addOperatorToOperatorPlace(accountId, addOperatorDto, options)(this.axios, this.basePath);
    };
    /**
     * Get operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    OperatorApi.prototype.getOperator = function (accountId, operatorId, options) {
        return OperatorApiFp(this.configuration).getOperator(accountId, operatorId, options)(this.axios, this.basePath);
    };
    /**
     * Add operator to operator place
     * @param {number} accountId Account identifier
     * @param {number} ccUserId Conversational cloud user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    OperatorApi.prototype.getOperatorByCcUserId = function (accountId, ccUserId, options) {
        return OperatorApiFp(this.configuration).getOperatorByCcUserId(accountId, ccUserId, options)(this.axios, this.basePath);
    };
    /**
     * Get operators
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    OperatorApi.prototype.getOperators = function (accountId, options) {
        return OperatorApiFp(this.configuration).getOperators(accountId, options)(this.axios, this.basePath);
    };
    /**
     * Remove operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    OperatorApi.prototype.removeOperatorFromOperatorPlace = function (accountId, operatorId, options) {
        return OperatorApiFp(this.configuration).removeOperatorFromOperatorPlace(accountId, operatorId, options)(this.axios, this.basePath);
    };
    /**
     * Update operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {UpdateOperatorDto} updateOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    OperatorApi.prototype.updateOperator = function (accountId, operatorId, updateOperatorDto, options) {
        return OperatorApiFp(this.configuration).updateOperator(accountId, operatorId, updateOperatorDto, options)(this.axios, this.basePath);
    };
    return OperatorApi;
}(BaseAPI));
export { OperatorApi };
/**
 * OperatorGroupApi - axios parameter creator
 * @export
 */
export var OperatorGroupApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Add operator to operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToGroup: function (accountId, operatorGroupId, operatorIdDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling addOperatorToGroup.');
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError('operatorGroupId', 'Required parameter operatorGroupId was null or undefined when calling addOperatorToGroup.');
            }
            // verify required parameter 'operatorIdDto' is not null or undefined
            if (operatorIdDto === null || operatorIdDto === undefined) {
                throw new RequiredError('operatorIdDto', 'Required parameter operatorIdDto was null or undefined when calling addOperatorToGroup.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}/add-operator"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("operatorGroupId", "}"), encodeURIComponent(String(operatorGroupId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof operatorIdDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(operatorIdDto !== undefined ? operatorIdDto : {}) : (operatorIdDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create operator group
         * @param {number} accountId Account identifier
         * @param {CreateOperatorGroupDto} createOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorGroup: function (accountId, createOperatorGroupDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createOperatorGroup.');
            }
            // verify required parameter 'createOperatorGroupDto' is not null or undefined
            if (createOperatorGroupDto === null || createOperatorGroupDto === undefined) {
                throw new RequiredError('createOperatorGroupDto', 'Required parameter createOperatorGroupDto was null or undefined when calling createOperatorGroup.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof createOperatorGroupDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(createOperatorGroupDto !== undefined ? createOperatorGroupDto : {}) : (createOperatorGroupDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroup: function (accountId, operatorGroupId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorGroup.');
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError('operatorGroupId', 'Required parameter operatorGroupId was null or undefined when calling getOperatorGroup.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("operatorGroupId", "}"), encodeURIComponent(String(operatorGroupId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroups: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorGroups.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator groups
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupsByToken: function (token, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token', 'Required parameter token was null or undefined when calling getOperatorGroupsByToken.');
            }
            var localVarPath = "/api/aimychat-be/tokens/{token}/operator-groups"
                .replace("{".concat("token", "}"), encodeURIComponent(String(token)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove operator from operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromGroup: function (accountId, operatorGroupId, operatorIdDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling removeOperatorFromGroup.');
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError('operatorGroupId', 'Required parameter operatorGroupId was null or undefined when calling removeOperatorFromGroup.');
            }
            // verify required parameter 'operatorIdDto' is not null or undefined
            if (operatorIdDto === null || operatorIdDto === undefined) {
                throw new RequiredError('operatorIdDto', 'Required parameter operatorIdDto was null or undefined when calling removeOperatorFromGroup.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}/remove-operator"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("operatorGroupId", "}"), encodeURIComponent(String(operatorGroupId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof operatorIdDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(operatorIdDto !== undefined ? operatorIdDto : {}) : (operatorIdDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorGroup: function (accountId, operatorGroupId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling removeOperatorGroup.');
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError('operatorGroupId', 'Required parameter operatorGroupId was null or undefined when calling removeOperatorGroup.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("operatorGroupId", "}"), encodeURIComponent(String(operatorGroupId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroup: function (accountId, operatorGroupId, updateOperatorGroupDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateOperatorGroup.');
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError('operatorGroupId', 'Required parameter operatorGroupId was null or undefined when calling updateOperatorGroup.');
            }
            // verify required parameter 'updateOperatorGroupDto' is not null or undefined
            if (updateOperatorGroupDto === null || updateOperatorGroupDto === undefined) {
                throw new RequiredError('updateOperatorGroupDto', 'Required parameter updateOperatorGroupDto was null or undefined when calling updateOperatorGroup.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("operatorGroupId", "}"), encodeURIComponent(String(operatorGroupId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof updateOperatorGroupDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(updateOperatorGroupDto !== undefined ? updateOperatorGroupDto : {}) : (updateOperatorGroupDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OperatorGroupApi - functional programming interface
 * @export
 */
export var OperatorGroupApiFp = function (configuration) {
    return {
        /**
         * Add operator to operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToGroup: function (accountId, operatorGroupId, operatorIdDto, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create operator group
         * @param {number} accountId Account identifier
         * @param {CreateOperatorGroupDto} createOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorGroup: function (accountId, createOperatorGroupDto, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).createOperatorGroup(accountId, createOperatorGroupDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroup: function (accountId, operatorGroupId, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroup(accountId, operatorGroupId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroups: function (accountId, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroups(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator groups
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupsByToken: function (token, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroupsByToken(token, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove operator from operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromGroup: function (accountId, operatorGroupId, operatorIdDto, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorGroup: function (accountId, operatorGroupId, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).removeOperatorGroup(accountId, operatorGroupId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroup: function (accountId, operatorGroupId, updateOperatorGroupDto, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OperatorGroupApi - factory interface
 * @export
 */
export var OperatorGroupApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Add operator to operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToGroup: function (accountId, operatorGroupId, operatorIdDto, options) {
            return OperatorGroupApiFp(configuration).addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options)(axios, basePath);
        },
        /**
         * Create operator group
         * @param {number} accountId Account identifier
         * @param {CreateOperatorGroupDto} createOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorGroup: function (accountId, createOperatorGroupDto, options) {
            return OperatorGroupApiFp(configuration).createOperatorGroup(accountId, createOperatorGroupDto, options)(axios, basePath);
        },
        /**
         * Get operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroup: function (accountId, operatorGroupId, options) {
            return OperatorGroupApiFp(configuration).getOperatorGroup(accountId, operatorGroupId, options)(axios, basePath);
        },
        /**
         * Get operator groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroups: function (accountId, options) {
            return OperatorGroupApiFp(configuration).getOperatorGroups(accountId, options)(axios, basePath);
        },
        /**
         * Get operator groups
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupsByToken: function (token, options) {
            return OperatorGroupApiFp(configuration).getOperatorGroupsByToken(token, options)(axios, basePath);
        },
        /**
         * Remove operator from operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromGroup: function (accountId, operatorGroupId, operatorIdDto, options) {
            return OperatorGroupApiFp(configuration).removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options)(axios, basePath);
        },
        /**
         * Remove operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorGroup: function (accountId, operatorGroupId, options) {
            return OperatorGroupApiFp(configuration).removeOperatorGroup(accountId, operatorGroupId, options)(axios, basePath);
        },
        /**
         * Update operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroup: function (accountId, operatorGroupId, updateOperatorGroupDto, options) {
            return OperatorGroupApiFp(configuration).updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options)(axios, basePath);
        },
    };
};
/**
 * OperatorGroupApi - object-oriented interface
 * @export
 * @class OperatorGroupApi
 * @extends {BaseAPI}
 */
var OperatorGroupApi = /** @class */ (function (_super) {
    __extends(OperatorGroupApi, _super);
    function OperatorGroupApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Add operator to operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {OperatorIdDto} operatorIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.addOperatorToGroup = function (accountId, operatorGroupId, operatorIdDto, options) {
        return OperatorGroupApiFp(this.configuration).addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options)(this.axios, this.basePath);
    };
    /**
     * Create operator group
     * @param {number} accountId Account identifier
     * @param {CreateOperatorGroupDto} createOperatorGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.createOperatorGroup = function (accountId, createOperatorGroupDto, options) {
        return OperatorGroupApiFp(this.configuration).createOperatorGroup(accountId, createOperatorGroupDto, options)(this.axios, this.basePath);
    };
    /**
     * Get operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.getOperatorGroup = function (accountId, operatorGroupId, options) {
        return OperatorGroupApiFp(this.configuration).getOperatorGroup(accountId, operatorGroupId, options)(this.axios, this.basePath);
    };
    /**
     * Get operator groups
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.getOperatorGroups = function (accountId, options) {
        return OperatorGroupApiFp(this.configuration).getOperatorGroups(accountId, options)(this.axios, this.basePath);
    };
    /**
     * Get operator groups
     * @param {string} token Operator place token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.getOperatorGroupsByToken = function (token, options) {
        return OperatorGroupApiFp(this.configuration).getOperatorGroupsByToken(token, options)(this.axios, this.basePath);
    };
    /**
     * Remove operator from operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {OperatorIdDto} operatorIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.removeOperatorFromGroup = function (accountId, operatorGroupId, operatorIdDto, options) {
        return OperatorGroupApiFp(this.configuration).removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options)(this.axios, this.basePath);
    };
    /**
     * Remove operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.removeOperatorGroup = function (accountId, operatorGroupId, options) {
        return OperatorGroupApiFp(this.configuration).removeOperatorGroup(accountId, operatorGroupId, options)(this.axios, this.basePath);
    };
    /**
     * Update operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.updateOperatorGroup = function (accountId, operatorGroupId, updateOperatorGroupDto, options) {
        return OperatorGroupApiFp(this.configuration).updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options)(this.axios, this.basePath);
    };
    return OperatorGroupApi;
}(BaseAPI));
export { OperatorGroupApi };
/**
 * OperatorPlaceApi - axios parameter creator
 * @export
 */
export var OperatorPlaceApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get operator place configuration
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlace: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorPlace.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-place"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator place configuration
         * @param {number} accountId Account identifier
         * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlace: function (accountId, updateOperatorPlaceDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateOperatorPlace.');
            }
            // verify required parameter 'updateOperatorPlaceDto' is not null or undefined
            if (updateOperatorPlaceDto === null || updateOperatorPlaceDto === undefined) {
                throw new RequiredError('updateOperatorPlaceDto', 'Required parameter updateOperatorPlaceDto was null or undefined when calling updateOperatorPlace.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-place"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = (typeof updateOperatorPlaceDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(updateOperatorPlaceDto !== undefined ? updateOperatorPlaceDto : {}) : (updateOperatorPlaceDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator place token
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceToken: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateOperatorPlaceToken.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-place/token"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OperatorPlaceApi - functional programming interface
 * @export
 */
export var OperatorPlaceApiFp = function (configuration) {
    return {
        /**
         * Get operator place configuration
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlace: function (accountId, options) {
            var localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).getOperatorPlace(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator place configuration
         * @param {number} accountId Account identifier
         * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlace: function (accountId, updateOperatorPlaceDto, options) {
            var localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).updateOperatorPlace(accountId, updateOperatorPlaceDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator place token
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceToken: function (accountId, options) {
            var localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).updateOperatorPlaceToken(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OperatorPlaceApi - factory interface
 * @export
 */
export var OperatorPlaceApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get operator place configuration
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlace: function (accountId, options) {
            return OperatorPlaceApiFp(configuration).getOperatorPlace(accountId, options)(axios, basePath);
        },
        /**
         * Update operator place configuration
         * @param {number} accountId Account identifier
         * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlace: function (accountId, updateOperatorPlaceDto, options) {
            return OperatorPlaceApiFp(configuration).updateOperatorPlace(accountId, updateOperatorPlaceDto, options)(axios, basePath);
        },
        /**
         * Update operator place token
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceToken: function (accountId, options) {
            return OperatorPlaceApiFp(configuration).updateOperatorPlaceToken(accountId, options)(axios, basePath);
        },
    };
};
/**
 * OperatorPlaceApi - object-oriented interface
 * @export
 * @class OperatorPlaceApi
 * @extends {BaseAPI}
 */
var OperatorPlaceApi = /** @class */ (function (_super) {
    __extends(OperatorPlaceApi, _super);
    function OperatorPlaceApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get operator place configuration
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceApi
     */
    OperatorPlaceApi.prototype.getOperatorPlace = function (accountId, options) {
        return OperatorPlaceApiFp(this.configuration).getOperatorPlace(accountId, options)(this.axios, this.basePath);
    };
    /**
     * Update operator place configuration
     * @param {number} accountId Account identifier
     * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceApi
     */
    OperatorPlaceApi.prototype.updateOperatorPlace = function (accountId, updateOperatorPlaceDto, options) {
        return OperatorPlaceApiFp(this.configuration).updateOperatorPlace(accountId, updateOperatorPlaceDto, options)(this.axios, this.basePath);
    };
    /**
     * Update operator place token
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceApi
     */
    OperatorPlaceApi.prototype.updateOperatorPlaceToken = function (accountId, options) {
        return OperatorPlaceApiFp(this.configuration).updateOperatorPlaceToken(accountId, options)(this.axios, this.basePath);
    };
    return OperatorPlaceApi;
}(BaseAPI));
export { OperatorPlaceApi };
/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export var StatisticsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get chart for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonChart: function (accountId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getCommonChart.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom', 'Required parameter dateFrom was null or undefined when calling getCommonChart.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo', 'Required parameter dateTo was null or undefined when calling getCommonChart.');
            }
            // verify required parameter 'chartType' is not null or undefined
            if (chartType === null || chartType === undefined) {
                throw new RequiredError('chartType', 'Required parameter chartType was null or undefined when calling getCommonChart.');
            }
            // verify required parameter 'intervalType' is not null or undefined
            if (intervalType === null || intervalType === undefined) {
                throw new RequiredError('intervalType', 'Required parameter intervalType was null or undefined when calling getCommonChart.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/statistics/common/chart"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom instanceof Date) ?
                    dateFrom.toISOString() :
                    dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo instanceof Date) ?
                    dateTo.toISOString() :
                    dateTo;
            }
            if (chartType !== undefined) {
                localVarQueryParameter['chartType'] = chartType;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter['intervalType'] = intervalType;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonSummary: function (accountId, dateFrom, dateTo, timezone, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getCommonSummary.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom', 'Required parameter dateFrom was null or undefined when calling getCommonSummary.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo', 'Required parameter dateTo was null or undefined when calling getCommonSummary.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/statistics/common/summary"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom instanceof Date) ?
                    dateFrom.toISOString() :
                    dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo instanceof Date) ?
                    dateTo.toISOString() :
                    dateTo;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get chart for operator\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorChart: function (accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorChart.');
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError('operatorId', 'Required parameter operatorId was null or undefined when calling getOperatorChart.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom', 'Required parameter dateFrom was null or undefined when calling getOperatorChart.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo', 'Required parameter dateTo was null or undefined when calling getOperatorChart.');
            }
            // verify required parameter 'chartType' is not null or undefined
            if (chartType === null || chartType === undefined) {
                throw new RequiredError('chartType', 'Required parameter chartType was null or undefined when calling getOperatorChart.');
            }
            // verify required parameter 'intervalType' is not null or undefined
            if (intervalType === null || intervalType === undefined) {
                throw new RequiredError('intervalType', 'Required parameter intervalType was null or undefined when calling getOperatorChart.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/statistics/operator/{operatorId}/chart"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("operatorId", "}"), encodeURIComponent(String(operatorId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom instanceof Date) ?
                    dateFrom.toISOString() :
                    dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo instanceof Date) ?
                    dateTo.toISOString() :
                    dateTo;
            }
            if (chartType !== undefined) {
                localVarQueryParameter['chartType'] = chartType;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter['intervalType'] = intervalType;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get chart for operator group\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupChart: function (accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorGroupChart.');
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError('operatorGroupId', 'Required parameter operatorGroupId was null or undefined when calling getOperatorGroupChart.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom', 'Required parameter dateFrom was null or undefined when calling getOperatorGroupChart.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo', 'Required parameter dateTo was null or undefined when calling getOperatorGroupChart.');
            }
            // verify required parameter 'chartType' is not null or undefined
            if (chartType === null || chartType === undefined) {
                throw new RequiredError('chartType', 'Required parameter chartType was null or undefined when calling getOperatorGroupChart.');
            }
            // verify required parameter 'intervalType' is not null or undefined
            if (intervalType === null || intervalType === undefined) {
                throw new RequiredError('intervalType', 'Required parameter intervalType was null or undefined when calling getOperatorGroupChart.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/statistics/operator-group/{operatorGroupId}/chart"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("operatorGroupId", "}"), encodeURIComponent(String(operatorGroupId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom instanceof Date) ?
                    dateFrom.toISOString() :
                    dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo instanceof Date) ?
                    dateTo.toISOString() :
                    dateTo;
            }
            if (chartType !== undefined) {
                localVarQueryParameter['chartType'] = chartType;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter['intervalType'] = intervalType;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupSummary: function (accountId, operatorGroupId, dateFrom, dateTo, timezone, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorGroupSummary.');
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError('operatorGroupId', 'Required parameter operatorGroupId was null or undefined when calling getOperatorGroupSummary.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom', 'Required parameter dateFrom was null or undefined when calling getOperatorGroupSummary.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo', 'Required parameter dateTo was null or undefined when calling getOperatorGroupSummary.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/statistics/operator-group/{operatorGroupId}/summary"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("operatorGroupId", "}"), encodeURIComponent(String(operatorGroupId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom instanceof Date) ?
                    dateFrom.toISOString() :
                    dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo instanceof Date) ?
                    dateTo.toISOString() :
                    dateTo;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorSummary: function (accountId, operatorId, dateFrom, dateTo, timezone, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorSummary.');
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError('operatorId', 'Required parameter operatorId was null or undefined when calling getOperatorSummary.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom', 'Required parameter dateFrom was null or undefined when calling getOperatorSummary.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo', 'Required parameter dateTo was null or undefined when calling getOperatorSummary.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/statistics/operator/{operatorId}/summary"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat("operatorId", "}"), encodeURIComponent(String(operatorId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom instanceof Date) ?
                    dateFrom.toISOString() :
                    dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo instanceof Date) ?
                    dateTo.toISOString() :
                    dateTo;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary transfer topics statistics for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferTopicsStatistics: function (accountId, dateFrom, dateTo, timezone, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getTransferTopicsStatistics.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom', 'Required parameter dateFrom was null or undefined when calling getTransferTopicsStatistics.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo', 'Required parameter dateTo was null or undefined when calling getTransferTopicsStatistics.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/statistics/common/themes"
                .replace("{".concat("accountId", "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom instanceof Date) ?
                    dateFrom.toISOString() :
                    dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo instanceof Date) ?
                    dateTo.toISOString() :
                    dateTo;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * StatisticsApi - functional programming interface
 * @export
 */
export var StatisticsApiFp = function (configuration) {
    return {
        /**
         * Get chart for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonChart: function (accountId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            var localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getCommonChart(accountId, dateFrom, dateTo, chartType, intervalType, timezone, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonSummary: function (accountId, dateFrom, dateTo, timezone, options) {
            var localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getCommonSummary(accountId, dateFrom, dateTo, timezone, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get chart for operator\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorChart: function (accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            var localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getOperatorChart(accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get chart for operator group\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupChart: function (accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            var localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getOperatorGroupChart(accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupSummary: function (accountId, operatorGroupId, dateFrom, dateTo, timezone, options) {
            var localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getOperatorGroupSummary(accountId, operatorGroupId, dateFrom, dateTo, timezone, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorSummary: function (accountId, operatorId, dateFrom, dateTo, timezone, options) {
            var localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getOperatorSummary(accountId, operatorId, dateFrom, dateTo, timezone, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get summary transfer topics statistics for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferTopicsStatistics: function (accountId, dateFrom, dateTo, timezone, options) {
            var localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getTransferTopicsStatistics(accountId, dateFrom, dateTo, timezone, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * StatisticsApi - factory interface
 * @export
 */
export var StatisticsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get chart for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonChart: function (accountId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            return StatisticsApiFp(configuration).getCommonChart(accountId, dateFrom, dateTo, chartType, intervalType, timezone, options)(axios, basePath);
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonSummary: function (accountId, dateFrom, dateTo, timezone, options) {
            return StatisticsApiFp(configuration).getCommonSummary(accountId, dateFrom, dateTo, timezone, options)(axios, basePath);
        },
        /**
         * Get chart for operator\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorChart: function (accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            return StatisticsApiFp(configuration).getOperatorChart(accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options)(axios, basePath);
        },
        /**
         * Get chart for operator group\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupChart: function (accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            return StatisticsApiFp(configuration).getOperatorGroupChart(accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options)(axios, basePath);
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupSummary: function (accountId, operatorGroupId, dateFrom, dateTo, timezone, options) {
            return StatisticsApiFp(configuration).getOperatorGroupSummary(accountId, operatorGroupId, dateFrom, dateTo, timezone, options)(axios, basePath);
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorSummary: function (accountId, operatorId, dateFrom, dateTo, timezone, options) {
            return StatisticsApiFp(configuration).getOperatorSummary(accountId, operatorId, dateFrom, dateTo, timezone, options)(axios, basePath);
        },
        /**
         * Get summary transfer topics statistics for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferTopicsStatistics: function (accountId, dateFrom, dateTo, timezone, options) {
            return StatisticsApiFp(configuration).getTransferTopicsStatistics(accountId, dateFrom, dateTo, timezone, options)(axios, basePath);
        },
    };
};
/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
var StatisticsApi = /** @class */ (function (_super) {
    __extends(StatisticsApi, _super);
    function StatisticsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get chart for operator place
     * @param {number} accountId Account identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {ChartType} chartType The type of building chart
     * @param {IntervalType} intervalType Interval with which statistics will be generated
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    StatisticsApi.prototype.getCommonChart = function (accountId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
        return StatisticsApiFp(this.configuration).getCommonChart(accountId, dateFrom, dateTo, chartType, intervalType, timezone, options)(this.axios, this.basePath);
    };
    /**
     * Get summary statistics for operator
     * @param {number} accountId Account identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    StatisticsApi.prototype.getCommonSummary = function (accountId, dateFrom, dateTo, timezone, options) {
        return StatisticsApiFp(this.configuration).getCommonSummary(accountId, dateFrom, dateTo, timezone, options)(this.axios, this.basePath);
    };
    /**
     * Get chart for operator\'s chats
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {ChartType} chartType The type of building chart
     * @param {IntervalType} intervalType Interval with which statistics will be generated
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    StatisticsApi.prototype.getOperatorChart = function (accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
        return StatisticsApiFp(this.configuration).getOperatorChart(accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options)(this.axios, this.basePath);
    };
    /**
     * Get chart for operator group\'s chats
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {ChartType} chartType The type of building chart
     * @param {IntervalType} intervalType Interval with which statistics will be generated
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    StatisticsApi.prototype.getOperatorGroupChart = function (accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
        return StatisticsApiFp(this.configuration).getOperatorGroupChart(accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options)(this.axios, this.basePath);
    };
    /**
     * Get summary statistics for operator
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    StatisticsApi.prototype.getOperatorGroupSummary = function (accountId, operatorGroupId, dateFrom, dateTo, timezone, options) {
        return StatisticsApiFp(this.configuration).getOperatorGroupSummary(accountId, operatorGroupId, dateFrom, dateTo, timezone, options)(this.axios, this.basePath);
    };
    /**
     * Get summary statistics for operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    StatisticsApi.prototype.getOperatorSummary = function (accountId, operatorId, dateFrom, dateTo, timezone, options) {
        return StatisticsApiFp(this.configuration).getOperatorSummary(accountId, operatorId, dateFrom, dateTo, timezone, options)(this.axios, this.basePath);
    };
    /**
     * Get summary transfer topics statistics for operator place
     * @param {number} accountId Account identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    StatisticsApi.prototype.getTransferTopicsStatistics = function (accountId, dateFrom, dateTo, timezone, options) {
        return StatisticsApiFp(this.configuration).getTransferTopicsStatistics(accountId, dateFrom, dateTo, timezone, options)(this.axios, this.basePath);
    };
    return StatisticsApi;
}(BaseAPI));
export { StatisticsApi };
/**
 * SystemApi - axios parameter creator
 * @export
 */
export var SystemApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/healthCheck";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/version";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SystemApi - functional programming interface
 * @export
 */
export var SystemApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            var localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).healthCheck(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version: function (options) {
            var localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).version(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SystemApi - factory interface
 * @export
 */
export var SystemApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            return SystemApiFp(configuration).healthCheck(options)(axios, basePath);
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version: function (options) {
            return SystemApiFp(configuration).version(options)(axios, basePath);
        },
    };
};
/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
var SystemApi = /** @class */ (function (_super) {
    __extends(SystemApi, _super);
    function SystemApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    SystemApi.prototype.healthCheck = function (options) {
        return SystemApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Service version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    SystemApi.prototype.version = function (options) {
        return SystemApiFp(this.configuration).version(options)(this.axios, this.basePath);
    };
    return SystemApi;
}(BaseAPI));
export { SystemApi };
