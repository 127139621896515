// tslint:disable
// @ts-nocheck
/**
 * Aimychat service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as globalImportUrl from 'url';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError } from './base';
/**
 *
 * @export
 * @enum {string}
 */
export var ChartType;
(function (ChartType) {
    ChartType["CHATSCOUNT"] = "CHATS_COUNT";
    ChartType["AVERAGECONNECTTIME"] = "AVERAGE_CONNECT_TIME";
    ChartType["AVERAGEFIRSTMESSAGETIME"] = "AVERAGE_FIRST_MESSAGE_TIME";
    ChartType["AVERAGEDURATION"] = "AVERAGE_DURATION";
})(ChartType || (ChartType = {}));
/**
 * How clients are sorted in the \"Queue\" tab
 * @export
 * @enum {string}
 */
export var ClientQueueSorting;
(function (ClientQueueSorting) {
    ClientQueueSorting["ASC"] = "ASC";
    ClientQueueSorting["DESC"] = "DESC";
})(ClientQueueSorting || (ClientQueueSorting = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var IntervalType;
(function (IntervalType) {
    IntervalType["DAY"] = "DAY";
    IntervalType["WEEK"] = "WEEK";
    IntervalType["MONTH"] = "MONTH";
})(IntervalType || (IntervalType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var OperatorRole;
(function (OperatorRole) {
    OperatorRole["AGENT"] = "AGENT";
    OperatorRole["ADMIN"] = "ADMIN";
})(OperatorRole || (OperatorRole = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var SoundNotificationMode;
(function (SoundNotificationMode) {
    SoundNotificationMode["ALL"] = "ALL";
    SoundNotificationMode["ONLYNEWMESSAGES"] = "ONLY_NEW_MESSAGES";
    SoundNotificationMode["ONLYNEWCHATS"] = "ONLY_NEW_CHATS";
    SoundNotificationMode["NOTHING"] = "NOTHING";
})(SoundNotificationMode || (SoundNotificationMode = {}));
/**
 * AlfbApi - axios parameter creator
 * @export
 */
export var AlfbApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get client
         * @param {string} clientId Client identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbClient: function (clientId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId', 'Required parameter clientId was null or undefined when calling getAlfbClient.');
            }
            var localVarPath = "/api/aimychat-be/alfb/clients/{clientId}".replace("{".concat('clientId', "}"), encodeURIComponent(String(clientId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get client list
         * @param {boolean} [isActive] return active clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbClients: function (isActive, options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/aimychat-be/alfb/clients";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get alfb config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbConfig: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/aimychat-be/alfb/config";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current operator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbCurrentOperator: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/aimychat-be/alfb/current-operator";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get group chat client list
         * @param {boolean} [isActive] return active clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbGroupChatClients: function (isActive, options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/aimychat-be/alfb/group-chat-clients";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get unread chat by token
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadChatStats: function (token, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token', 'Required parameter token was null or undefined when calling getUnreadChatStats.');
            }
            var localVarPath = "/api/aimychat-be/alfb/tokens/{token}/operator-chats/unread".replace("{".concat('token', "}"), encodeURIComponent(String(token)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Join client alfb
         * @param {JoinClientDto} joinClientDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinClient: function (joinClientDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'joinClientDto' is not null or undefined
            if (joinClientDto === null || joinClientDto === undefined) {
                throw new RequiredError('joinClientDto', 'Required parameter joinClientDto was null or undefined when calling joinClient.');
            }
            var localVarPath = "/api/aimychat-be/alfb/clients/join";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof joinClientDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(joinClientDto !== undefined ? joinClientDto : {})
                : joinClientDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AlfbApi - functional programming interface
 * @export
 */
export var AlfbApiFp = function (configuration) {
    return {
        /**
         * Get client
         * @param {string} clientId Client identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbClient: function (clientId, options) {
            var localVarAxiosArgs = AlfbApiAxiosParamCreator(configuration).getAlfbClient(clientId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get client list
         * @param {boolean} [isActive] return active clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbClients: function (isActive, options) {
            var localVarAxiosArgs = AlfbApiAxiosParamCreator(configuration).getAlfbClients(isActive, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get alfb config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbConfig: function (options) {
            var localVarAxiosArgs = AlfbApiAxiosParamCreator(configuration).getAlfbConfig(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get current operator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbCurrentOperator: function (options) {
            var localVarAxiosArgs = AlfbApiAxiosParamCreator(configuration).getAlfbCurrentOperator(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get group chat client list
         * @param {boolean} [isActive] return active clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbGroupChatClients: function (isActive, options) {
            var localVarAxiosArgs = AlfbApiAxiosParamCreator(configuration).getAlfbGroupChatClients(isActive, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get unread chat by token
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadChatStats: function (token, options) {
            var localVarAxiosArgs = AlfbApiAxiosParamCreator(configuration).getUnreadChatStats(token, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Join client alfb
         * @param {JoinClientDto} joinClientDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinClient: function (joinClientDto, options) {
            var localVarAxiosArgs = AlfbApiAxiosParamCreator(configuration).joinClient(joinClientDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AlfbApi - factory interface
 * @export
 */
export var AlfbApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get client
         * @param {string} clientId Client identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbClient: function (clientId, options) {
            return AlfbApiFp(configuration).getAlfbClient(clientId, options)(axios, basePath);
        },
        /**
         * Get client list
         * @param {boolean} [isActive] return active clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbClients: function (isActive, options) {
            return AlfbApiFp(configuration).getAlfbClients(isActive, options)(axios, basePath);
        },
        /**
         * Get alfb config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbConfig: function (options) {
            return AlfbApiFp(configuration).getAlfbConfig(options)(axios, basePath);
        },
        /**
         * Get current operator
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbCurrentOperator: function (options) {
            return AlfbApiFp(configuration).getAlfbCurrentOperator(options)(axios, basePath);
        },
        /**
         * Get group chat client list
         * @param {boolean} [isActive] return active clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlfbGroupChatClients: function (isActive, options) {
            return AlfbApiFp(configuration).getAlfbGroupChatClients(isActive, options)(axios, basePath);
        },
        /**
         * Get unread chat by token
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadChatStats: function (token, options) {
            return AlfbApiFp(configuration).getUnreadChatStats(token, options)(axios, basePath);
        },
        /**
         * Join client alfb
         * @param {JoinClientDto} joinClientDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinClient: function (joinClientDto, options) {
            return AlfbApiFp(configuration).joinClient(joinClientDto, options)(axios, basePath);
        },
    };
};
/**
 * AlfbApi - object-oriented interface
 * @export
 * @class AlfbApi
 * @extends {BaseAPI}
 */
var AlfbApi = /** @class */ (function (_super) {
    __extends(AlfbApi, _super);
    function AlfbApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get client
     * @param {string} clientId Client identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlfbApi
     */
    AlfbApi.prototype.getAlfbClient = function (clientId, options) {
        return AlfbApiFp(this.configuration).getAlfbClient(clientId, options)(this.axios, this.basePath);
    };
    /**
     * Get client list
     * @param {boolean} [isActive] return active clients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlfbApi
     */
    AlfbApi.prototype.getAlfbClients = function (isActive, options) {
        return AlfbApiFp(this.configuration).getAlfbClients(isActive, options)(this.axios, this.basePath);
    };
    /**
     * Get alfb config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlfbApi
     */
    AlfbApi.prototype.getAlfbConfig = function (options) {
        return AlfbApiFp(this.configuration).getAlfbConfig(options)(this.axios, this.basePath);
    };
    /**
     * Get current operator
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlfbApi
     */
    AlfbApi.prototype.getAlfbCurrentOperator = function (options) {
        return AlfbApiFp(this.configuration).getAlfbCurrentOperator(options)(this.axios, this.basePath);
    };
    /**
     * Get group chat client list
     * @param {boolean} [isActive] return active clients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlfbApi
     */
    AlfbApi.prototype.getAlfbGroupChatClients = function (isActive, options) {
        return AlfbApiFp(this.configuration).getAlfbGroupChatClients(isActive, options)(this.axios, this.basePath);
    };
    /**
     * Get unread chat by token
     * @param {string} token Operator place token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlfbApi
     */
    AlfbApi.prototype.getUnreadChatStats = function (token, options) {
        return AlfbApiFp(this.configuration).getUnreadChatStats(token, options)(this.axios, this.basePath);
    };
    /**
     * Join client alfb
     * @param {JoinClientDto} joinClientDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlfbApi
     */
    AlfbApi.prototype.joinClient = function (joinClientDto, options) {
        return AlfbApiFp(this.configuration).joinClient(joinClientDto, options)(this.axios, this.basePath);
    };
    return AlfbApi;
}(BaseAPI));
export { AlfbApi };
/**
 * BillingApi - axios parameter creator
 * @export
 */
export var BillingApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get tariff limits for operator place.
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffLimits: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getTariffLimits.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/billing/tariff-limits".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update tariff limits for operator place.
         * @param {number} accountId Account identifier
         * @param {TariffLimitsDto} tariffLimitsDto Operator place tariff limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTariffLimits: function (accountId, tariffLimitsDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateTariffLimits.');
            }
            // verify required parameter 'tariffLimitsDto' is not null or undefined
            if (tariffLimitsDto === null || tariffLimitsDto === undefined) {
                throw new RequiredError('tariffLimitsDto', 'Required parameter tariffLimitsDto was null or undefined when calling updateTariffLimits.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/billing/tariff-limits".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof tariffLimitsDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(tariffLimitsDto !== undefined ? tariffLimitsDto : {})
                : tariffLimitsDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * BillingApi - functional programming interface
 * @export
 */
export var BillingApiFp = function (configuration) {
    return {
        /**
         * Get tariff limits for operator place.
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffLimits: function (accountId, options) {
            var localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).getTariffLimits(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update tariff limits for operator place.
         * @param {number} accountId Account identifier
         * @param {TariffLimitsDto} tariffLimitsDto Operator place tariff limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTariffLimits: function (accountId, tariffLimitsDto, options) {
            var localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).updateTariffLimits(accountId, tariffLimitsDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * BillingApi - factory interface
 * @export
 */
export var BillingApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get tariff limits for operator place.
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffLimits: function (accountId, options) {
            return BillingApiFp(configuration).getTariffLimits(accountId, options)(axios, basePath);
        },
        /**
         * Update tariff limits for operator place.
         * @param {number} accountId Account identifier
         * @param {TariffLimitsDto} tariffLimitsDto Operator place tariff limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTariffLimits: function (accountId, tariffLimitsDto, options) {
            return BillingApiFp(configuration).updateTariffLimits(accountId, tariffLimitsDto, options)(axios, basePath);
        },
    };
};
/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
var BillingApi = /** @class */ (function (_super) {
    __extends(BillingApi, _super);
    function BillingApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get tariff limits for operator place.
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.getTariffLimits = function (accountId, options) {
        return BillingApiFp(this.configuration).getTariffLimits(accountId, options)(this.axios, this.basePath);
    };
    /**
     * Update tariff limits for operator place.
     * @param {number} accountId Account identifier
     * @param {TariffLimitsDto} tariffLimitsDto Operator place tariff limits
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    BillingApi.prototype.updateTariffLimits = function (accountId, tariffLimitsDto, options) {
        return BillingApiFp(this.configuration).updateTariffLimits(accountId, tariffLimitsDto, options)(this.axios, this.basePath);
    };
    return BillingApi;
}(BaseAPI));
export { BillingApi };
/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export var E2ECheckApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck: function (loggingEnabledE2ECheck, zRequestId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
            if (loggingEnabledE2ECheck === null || loggingEnabledE2ECheck === undefined) {
                throw new RequiredError('loggingEnabledE2ECheck', 'Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.');
            }
            var localVarPath = "/api/aimychat-be/e2e-check";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (zRequestId !== undefined && zRequestId !== null) {
                localVarHeaderParameter['Z-requestId'] = String(zRequestId);
            }
            if (loggingEnabledE2ECheck !== undefined && loggingEnabledE2ECheck !== null) {
                localVarHeaderParameter['loggingEnabledE2ECheck'] = String(JSON.stringify(loggingEnabledE2ECheck));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export var E2ECheckApiFp = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck: function (loggingEnabledE2ECheck, zRequestId, options) {
            var localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * E2ECheckApi - factory interface
 * @export
 */
export var E2ECheckApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck: function (loggingEnabledE2ECheck, zRequestId, options) {
            return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(axios, basePath);
        },
    };
};
/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
var E2ECheckApi = /** @class */ (function (_super) {
    __extends(E2ECheckApi, _super);
    function E2ECheckApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2ECheckApi
     */
    E2ECheckApi.prototype.e2ECheck = function (loggingEnabledE2ECheck, zRequestId, options) {
        return E2ECheckApiFp(this.configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(this.axios, this.basePath);
    };
    return E2ECheckApi;
}(BaseAPI));
export { E2ECheckApi };
/**
 * OperatorApi - axios parameter creator
 * @export
 */
export var OperatorApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {AddOperatorDto} addOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToOperatorPlace: function (accountId, addOperatorDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling addOperatorToOperatorPlace.');
            }
            // verify required parameter 'addOperatorDto' is not null or undefined
            if (addOperatorDto === null || addOperatorDto === undefined) {
                throw new RequiredError('addOperatorDto', 'Required parameter addOperatorDto was null or undefined when calling addOperatorToOperatorPlace.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operators/add-operator".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof addOperatorDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(addOperatorDto !== undefined ? addOperatorDto : {})
                : addOperatorDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperator: function (accountId, operatorId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperator.');
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError('operatorId', 'Required parameter operatorId was null or undefined when calling getOperator.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operators/{operatorId}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('operatorId', "}"), encodeURIComponent(String(operatorId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {number} ccUserId Conversational cloud user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorByCcUserId: function (accountId, ccUserId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorByCcUserId.');
            }
            // verify required parameter 'ccUserId' is not null or undefined
            if (ccUserId === null || ccUserId === undefined) {
                throw new RequiredError('ccUserId', 'Required parameter ccUserId was null or undefined when calling getOperatorByCcUserId.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operators/by-cc-user-id".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (ccUserId !== undefined) {
                localVarQueryParameter['ccUserId'] = ccUserId;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operators
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperators: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperators.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operators".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operators by common groups
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorsByCommonGroups: function (accountId, operatorId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorsByCommonGroups.');
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError('operatorId', 'Required parameter operatorId was null or undefined when calling getOperatorsByCommonGroups.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operators/{operatorId}/by-common-groups"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('operatorId', "}"), encodeURIComponent(String(operatorId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromOperatorPlace: function (accountId, operatorId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling removeOperatorFromOperatorPlace.');
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError('operatorId', 'Required parameter operatorId was null or undefined when calling removeOperatorFromOperatorPlace.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operators/{operatorId}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('operatorId', "}"), encodeURIComponent(String(operatorId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorDto} updateOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperator: function (accountId, operatorId, updateOperatorDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateOperator.');
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError('operatorId', 'Required parameter operatorId was null or undefined when calling updateOperator.');
            }
            // verify required parameter 'updateOperatorDto' is not null or undefined
            if (updateOperatorDto === null || updateOperatorDto === undefined) {
                throw new RequiredError('updateOperatorDto', 'Required parameter updateOperatorDto was null or undefined when calling updateOperator.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operators/{operatorId}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('operatorId', "}"), encodeURIComponent(String(operatorId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof updateOperatorDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateOperatorDto !== undefined ? updateOperatorDto : {})
                : updateOperatorDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OperatorApi - functional programming interface
 * @export
 */
export var OperatorApiFp = function (configuration) {
    return {
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {AddOperatorDto} addOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToOperatorPlace: function (accountId, addOperatorDto, options) {
            var localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).addOperatorToOperatorPlace(accountId, addOperatorDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperator: function (accountId, operatorId, options) {
            var localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperator(accountId, operatorId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {number} ccUserId Conversational cloud user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorByCcUserId: function (accountId, ccUserId, options) {
            var localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperatorByCcUserId(accountId, ccUserId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operators
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperators: function (accountId, options) {
            var localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperators(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operators by common groups
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorsByCommonGroups: function (accountId, operatorId, options) {
            var localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperatorsByCommonGroups(accountId, operatorId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromOperatorPlace: function (accountId, operatorId, options) {
            var localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).removeOperatorFromOperatorPlace(accountId, operatorId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorDto} updateOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperator: function (accountId, operatorId, updateOperatorDto, options) {
            var localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).updateOperator(accountId, operatorId, updateOperatorDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OperatorApi - factory interface
 * @export
 */
export var OperatorApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {AddOperatorDto} addOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToOperatorPlace: function (accountId, addOperatorDto, options) {
            return OperatorApiFp(configuration).addOperatorToOperatorPlace(accountId, addOperatorDto, options)(axios, basePath);
        },
        /**
         * Get operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperator: function (accountId, operatorId, options) {
            return OperatorApiFp(configuration).getOperator(accountId, operatorId, options)(axios, basePath);
        },
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {number} ccUserId Conversational cloud user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorByCcUserId: function (accountId, ccUserId, options) {
            return OperatorApiFp(configuration).getOperatorByCcUserId(accountId, ccUserId, options)(axios, basePath);
        },
        /**
         * Get operators
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperators: function (accountId, options) {
            return OperatorApiFp(configuration).getOperators(accountId, options)(axios, basePath);
        },
        /**
         * Get operators by common groups
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorsByCommonGroups: function (accountId, operatorId, options) {
            return OperatorApiFp(configuration).getOperatorsByCommonGroups(accountId, operatorId, options)(axios, basePath);
        },
        /**
         * Remove operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromOperatorPlace: function (accountId, operatorId, options) {
            return OperatorApiFp(configuration).removeOperatorFromOperatorPlace(accountId, operatorId, options)(axios, basePath);
        },
        /**
         * Update operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorDto} updateOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperator: function (accountId, operatorId, updateOperatorDto, options) {
            return OperatorApiFp(configuration).updateOperator(accountId, operatorId, updateOperatorDto, options)(axios, basePath);
        },
    };
};
/**
 * OperatorApi - object-oriented interface
 * @export
 * @class OperatorApi
 * @extends {BaseAPI}
 */
var OperatorApi = /** @class */ (function (_super) {
    __extends(OperatorApi, _super);
    function OperatorApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Add operator to operator place
     * @param {number} accountId Account identifier
     * @param {AddOperatorDto} addOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    OperatorApi.prototype.addOperatorToOperatorPlace = function (accountId, addOperatorDto, options) {
        return OperatorApiFp(this.configuration).addOperatorToOperatorPlace(accountId, addOperatorDto, options)(this.axios, this.basePath);
    };
    /**
     * Get operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    OperatorApi.prototype.getOperator = function (accountId, operatorId, options) {
        return OperatorApiFp(this.configuration).getOperator(accountId, operatorId, options)(this.axios, this.basePath);
    };
    /**
     * Add operator to operator place
     * @param {number} accountId Account identifier
     * @param {number} ccUserId Conversational cloud user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    OperatorApi.prototype.getOperatorByCcUserId = function (accountId, ccUserId, options) {
        return OperatorApiFp(this.configuration).getOperatorByCcUserId(accountId, ccUserId, options)(this.axios, this.basePath);
    };
    /**
     * Get operators
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    OperatorApi.prototype.getOperators = function (accountId, options) {
        return OperatorApiFp(this.configuration).getOperators(accountId, options)(this.axios, this.basePath);
    };
    /**
     * Get operators by common groups
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    OperatorApi.prototype.getOperatorsByCommonGroups = function (accountId, operatorId, options) {
        return OperatorApiFp(this.configuration).getOperatorsByCommonGroups(accountId, operatorId, options)(this.axios, this.basePath);
    };
    /**
     * Remove operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    OperatorApi.prototype.removeOperatorFromOperatorPlace = function (accountId, operatorId, options) {
        return OperatorApiFp(this.configuration).removeOperatorFromOperatorPlace(accountId, operatorId, options)(this.axios, this.basePath);
    };
    /**
     * Update operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {UpdateOperatorDto} updateOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    OperatorApi.prototype.updateOperator = function (accountId, operatorId, updateOperatorDto, options) {
        return OperatorApiFp(this.configuration).updateOperator(accountId, operatorId, updateOperatorDto, options)(this.axios, this.basePath);
    };
    return OperatorApi;
}(BaseAPI));
export { OperatorApi };
/**
 * OperatorGroupApi - axios parameter creator
 * @export
 */
export var OperatorGroupApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Add operator to operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToGroup: function (accountId, operatorGroupId, operatorIdDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling addOperatorToGroup.');
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError('operatorGroupId', 'Required parameter operatorGroupId was null or undefined when calling addOperatorToGroup.');
            }
            // verify required parameter 'operatorIdDto' is not null or undefined
            if (operatorIdDto === null || operatorIdDto === undefined) {
                throw new RequiredError('operatorIdDto', 'Required parameter operatorIdDto was null or undefined when calling addOperatorToGroup.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}/add-operator"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('operatorGroupId', "}"), encodeURIComponent(String(operatorGroupId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof operatorIdDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(operatorIdDto !== undefined ? operatorIdDto : {})
                : operatorIdDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create operator group
         * @param {number} accountId Account identifier
         * @param {CreateOperatorGroupDto} createOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorGroup: function (accountId, createOperatorGroupDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createOperatorGroup.');
            }
            // verify required parameter 'createOperatorGroupDto' is not null or undefined
            if (createOperatorGroupDto === null || createOperatorGroupDto === undefined) {
                throw new RequiredError('createOperatorGroupDto', 'Required parameter createOperatorGroupDto was null or undefined when calling createOperatorGroup.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof createOperatorGroupDto !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createOperatorGroupDto !== undefined ? createOperatorGroupDto : {})
                : createOperatorGroupDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroup: function (accountId, operatorGroupId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorGroup.');
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError('operatorGroupId', 'Required parameter operatorGroupId was null or undefined when calling getOperatorGroup.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('operatorGroupId', "}"), encodeURIComponent(String(operatorGroupId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator group by main operator
         * @param {number} accountId Account identifier
         * @param {string} mainOperator Main operator (id or login or email)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupByMainOperator: function (accountId, mainOperator, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorGroupByMainOperator.');
            }
            // verify required parameter 'mainOperator' is not null or undefined
            if (mainOperator === null || mainOperator === undefined) {
                throw new RequiredError('mainOperator', 'Required parameter mainOperator was null or undefined when calling getOperatorGroupByMainOperator.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups/by-main-operator".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (mainOperator !== undefined) {
                localVarQueryParameter['mainOperator'] = mainOperator;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroups: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorGroups.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator groups
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupsByToken: function (token, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token', 'Required parameter token was null or undefined when calling getOperatorGroupsByToken.');
            }
            var localVarPath = "/api/aimychat-be/tokens/{token}/operator-groups".replace("{".concat('token', "}"), encodeURIComponent(String(token)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove operator from operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromGroup: function (accountId, operatorGroupId, operatorIdDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling removeOperatorFromGroup.');
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError('operatorGroupId', 'Required parameter operatorGroupId was null or undefined when calling removeOperatorFromGroup.');
            }
            // verify required parameter 'operatorIdDto' is not null or undefined
            if (operatorIdDto === null || operatorIdDto === undefined) {
                throw new RequiredError('operatorIdDto', 'Required parameter operatorIdDto was null or undefined when calling removeOperatorFromGroup.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}/remove-operator"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('operatorGroupId', "}"), encodeURIComponent(String(operatorGroupId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof operatorIdDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(operatorIdDto !== undefined ? operatorIdDto : {})
                : operatorIdDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorGroup: function (accountId, operatorGroupId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling removeOperatorGroup.');
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError('operatorGroupId', 'Required parameter operatorGroupId was null or undefined when calling removeOperatorGroup.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('operatorGroupId', "}"), encodeURIComponent(String(operatorGroupId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroup: function (accountId, operatorGroupId, updateOperatorGroupDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateOperatorGroup.');
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError('operatorGroupId', 'Required parameter operatorGroupId was null or undefined when calling updateOperatorGroup.');
            }
            // verify required parameter 'updateOperatorGroupDto' is not null or undefined
            if (updateOperatorGroupDto === null || updateOperatorGroupDto === undefined) {
                throw new RequiredError('updateOperatorGroupDto', 'Required parameter updateOperatorGroupDto was null or undefined when calling updateOperatorGroup.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('operatorGroupId', "}"), encodeURIComponent(String(operatorGroupId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof updateOperatorGroupDto !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateOperatorGroupDto !== undefined ? updateOperatorGroupDto : {})
                : updateOperatorGroupDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OperatorGroupApi - functional programming interface
 * @export
 */
export var OperatorGroupApiFp = function (configuration) {
    return {
        /**
         * Add operator to operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToGroup: function (accountId, operatorGroupId, operatorIdDto, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create operator group
         * @param {number} accountId Account identifier
         * @param {CreateOperatorGroupDto} createOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorGroup: function (accountId, createOperatorGroupDto, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).createOperatorGroup(accountId, createOperatorGroupDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroup: function (accountId, operatorGroupId, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroup(accountId, operatorGroupId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator group by main operator
         * @param {number} accountId Account identifier
         * @param {string} mainOperator Main operator (id or login or email)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupByMainOperator: function (accountId, mainOperator, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroupByMainOperator(accountId, mainOperator, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroups: function (accountId, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroups(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator groups
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupsByToken: function (token, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroupsByToken(token, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove operator from operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromGroup: function (accountId, operatorGroupId, operatorIdDto, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorGroup: function (accountId, operatorGroupId, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).removeOperatorGroup(accountId, operatorGroupId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroup: function (accountId, operatorGroupId, updateOperatorGroupDto, options) {
            var localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OperatorGroupApi - factory interface
 * @export
 */
export var OperatorGroupApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Add operator to operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToGroup: function (accountId, operatorGroupId, operatorIdDto, options) {
            return OperatorGroupApiFp(configuration).addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options)(axios, basePath);
        },
        /**
         * Create operator group
         * @param {number} accountId Account identifier
         * @param {CreateOperatorGroupDto} createOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorGroup: function (accountId, createOperatorGroupDto, options) {
            return OperatorGroupApiFp(configuration).createOperatorGroup(accountId, createOperatorGroupDto, options)(axios, basePath);
        },
        /**
         * Get operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroup: function (accountId, operatorGroupId, options) {
            return OperatorGroupApiFp(configuration).getOperatorGroup(accountId, operatorGroupId, options)(axios, basePath);
        },
        /**
         * Get operator group by main operator
         * @param {number} accountId Account identifier
         * @param {string} mainOperator Main operator (id or login or email)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupByMainOperator: function (accountId, mainOperator, options) {
            return OperatorGroupApiFp(configuration).getOperatorGroupByMainOperator(accountId, mainOperator, options)(axios, basePath);
        },
        /**
         * Get operator groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroups: function (accountId, options) {
            return OperatorGroupApiFp(configuration).getOperatorGroups(accountId, options)(axios, basePath);
        },
        /**
         * Get operator groups
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupsByToken: function (token, options) {
            return OperatorGroupApiFp(configuration).getOperatorGroupsByToken(token, options)(axios, basePath);
        },
        /**
         * Remove operator from operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromGroup: function (accountId, operatorGroupId, operatorIdDto, options) {
            return OperatorGroupApiFp(configuration).removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options)(axios, basePath);
        },
        /**
         * Remove operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorGroup: function (accountId, operatorGroupId, options) {
            return OperatorGroupApiFp(configuration).removeOperatorGroup(accountId, operatorGroupId, options)(axios, basePath);
        },
        /**
         * Update operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroup: function (accountId, operatorGroupId, updateOperatorGroupDto, options) {
            return OperatorGroupApiFp(configuration).updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options)(axios, basePath);
        },
    };
};
/**
 * OperatorGroupApi - object-oriented interface
 * @export
 * @class OperatorGroupApi
 * @extends {BaseAPI}
 */
var OperatorGroupApi = /** @class */ (function (_super) {
    __extends(OperatorGroupApi, _super);
    function OperatorGroupApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Add operator to operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {OperatorIdDto} operatorIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.addOperatorToGroup = function (accountId, operatorGroupId, operatorIdDto, options) {
        return OperatorGroupApiFp(this.configuration).addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options)(this.axios, this.basePath);
    };
    /**
     * Create operator group
     * @param {number} accountId Account identifier
     * @param {CreateOperatorGroupDto} createOperatorGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.createOperatorGroup = function (accountId, createOperatorGroupDto, options) {
        return OperatorGroupApiFp(this.configuration).createOperatorGroup(accountId, createOperatorGroupDto, options)(this.axios, this.basePath);
    };
    /**
     * Get operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.getOperatorGroup = function (accountId, operatorGroupId, options) {
        return OperatorGroupApiFp(this.configuration).getOperatorGroup(accountId, operatorGroupId, options)(this.axios, this.basePath);
    };
    /**
     * Get operator group by main operator
     * @param {number} accountId Account identifier
     * @param {string} mainOperator Main operator (id or login or email)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.getOperatorGroupByMainOperator = function (accountId, mainOperator, options) {
        return OperatorGroupApiFp(this.configuration).getOperatorGroupByMainOperator(accountId, mainOperator, options)(this.axios, this.basePath);
    };
    /**
     * Get operator groups
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.getOperatorGroups = function (accountId, options) {
        return OperatorGroupApiFp(this.configuration).getOperatorGroups(accountId, options)(this.axios, this.basePath);
    };
    /**
     * Get operator groups
     * @param {string} token Operator place token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.getOperatorGroupsByToken = function (token, options) {
        return OperatorGroupApiFp(this.configuration).getOperatorGroupsByToken(token, options)(this.axios, this.basePath);
    };
    /**
     * Remove operator from operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {OperatorIdDto} operatorIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.removeOperatorFromGroup = function (accountId, operatorGroupId, operatorIdDto, options) {
        return OperatorGroupApiFp(this.configuration).removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options)(this.axios, this.basePath);
    };
    /**
     * Remove operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.removeOperatorGroup = function (accountId, operatorGroupId, options) {
        return OperatorGroupApiFp(this.configuration).removeOperatorGroup(accountId, operatorGroupId, options)(this.axios, this.basePath);
    };
    /**
     * Update operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    OperatorGroupApi.prototype.updateOperatorGroup = function (accountId, operatorGroupId, updateOperatorGroupDto, options) {
        return OperatorGroupApiFp(this.configuration).updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options)(this.axios, this.basePath);
    };
    return OperatorGroupApi;
}(BaseAPI));
export { OperatorGroupApi };
/**
 * OperatorPlaceApi - axios parameter creator
 * @export
 */
export var OperatorPlaceApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get operator place configuration
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlace: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorPlace.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-place".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator place configuration
         * @param {number} accountId Account identifier
         * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlace: function (accountId, updateOperatorPlaceDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateOperatorPlace.');
            }
            // verify required parameter 'updateOperatorPlaceDto' is not null or undefined
            if (updateOperatorPlaceDto === null || updateOperatorPlaceDto === undefined) {
                throw new RequiredError('updateOperatorPlaceDto', 'Required parameter updateOperatorPlaceDto was null or undefined when calling updateOperatorPlace.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-place".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof updateOperatorPlaceDto !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateOperatorPlaceDto !== undefined ? updateOperatorPlaceDto : {})
                : updateOperatorPlaceDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator place token
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceToken: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateOperatorPlaceToken.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/operator-place/token".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OperatorPlaceApi - functional programming interface
 * @export
 */
export var OperatorPlaceApiFp = function (configuration) {
    return {
        /**
         * Get operator place configuration
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlace: function (accountId, options) {
            var localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).getOperatorPlace(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator place configuration
         * @param {number} accountId Account identifier
         * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlace: function (accountId, updateOperatorPlaceDto, options) {
            var localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).updateOperatorPlace(accountId, updateOperatorPlaceDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator place token
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceToken: function (accountId, options) {
            var localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).updateOperatorPlaceToken(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OperatorPlaceApi - factory interface
 * @export
 */
export var OperatorPlaceApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get operator place configuration
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlace: function (accountId, options) {
            return OperatorPlaceApiFp(configuration).getOperatorPlace(accountId, options)(axios, basePath);
        },
        /**
         * Update operator place configuration
         * @param {number} accountId Account identifier
         * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlace: function (accountId, updateOperatorPlaceDto, options) {
            return OperatorPlaceApiFp(configuration).updateOperatorPlace(accountId, updateOperatorPlaceDto, options)(axios, basePath);
        },
        /**
         * Update operator place token
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceToken: function (accountId, options) {
            return OperatorPlaceApiFp(configuration).updateOperatorPlaceToken(accountId, options)(axios, basePath);
        },
    };
};
/**
 * OperatorPlaceApi - object-oriented interface
 * @export
 * @class OperatorPlaceApi
 * @extends {BaseAPI}
 */
var OperatorPlaceApi = /** @class */ (function (_super) {
    __extends(OperatorPlaceApi, _super);
    function OperatorPlaceApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get operator place configuration
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceApi
     */
    OperatorPlaceApi.prototype.getOperatorPlace = function (accountId, options) {
        return OperatorPlaceApiFp(this.configuration).getOperatorPlace(accountId, options)(this.axios, this.basePath);
    };
    /**
     * Update operator place configuration
     * @param {number} accountId Account identifier
     * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceApi
     */
    OperatorPlaceApi.prototype.updateOperatorPlace = function (accountId, updateOperatorPlaceDto, options) {
        return OperatorPlaceApiFp(this.configuration).updateOperatorPlace(accountId, updateOperatorPlaceDto, options)(this.axios, this.basePath);
    };
    /**
     * Update operator place token
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceApi
     */
    OperatorPlaceApi.prototype.updateOperatorPlaceToken = function (accountId, options) {
        return OperatorPlaceApiFp(this.configuration).updateOperatorPlaceToken(accountId, options)(this.axios, this.basePath);
    };
    return OperatorPlaceApi;
}(BaseAPI));
export { OperatorPlaceApi };
/**
 * ProductInfoApi - axios parameter creator
 * @export
 */
export var ProductInfoApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get product information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInfo: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/aimychat-be/product-info";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ProductInfoApi - functional programming interface
 * @export
 */
export var ProductInfoApiFp = function (configuration) {
    return {
        /**
         * Get product information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInfo: function (options) {
            var localVarAxiosArgs = ProductInfoApiAxiosParamCreator(configuration).getProductInfo(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ProductInfoApi - factory interface
 * @export
 */
export var ProductInfoApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get product information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInfo: function (options) {
            return ProductInfoApiFp(configuration).getProductInfo(options)(axios, basePath);
        },
    };
};
/**
 * ProductInfoApi - object-oriented interface
 * @export
 * @class ProductInfoApi
 * @extends {BaseAPI}
 */
var ProductInfoApi = /** @class */ (function (_super) {
    __extends(ProductInfoApi, _super);
    function ProductInfoApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get product information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductInfoApi
     */
    ProductInfoApi.prototype.getProductInfo = function (options) {
        return ProductInfoApiFp(this.configuration).getProductInfo(options)(this.axios, this.basePath);
    };
    return ProductInfoApi;
}(BaseAPI));
export { ProductInfoApi };
/**
 * PrompterApi - axios parameter creator
 * @export
 */
export var PrompterApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create prompter
         * @param {number} accountId Account identifier
         * @param {CreatePrompterDto} createPrompterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrompter: function (accountId, createPrompterDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createPrompter.');
            }
            // verify required parameter 'createPrompterDto' is not null or undefined
            if (createPrompterDto === null || createPrompterDto === undefined) {
                throw new RequiredError('createPrompterDto', 'Required parameter createPrompterDto was null or undefined when calling createPrompter.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/prompters".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof createPrompterDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createPrompterDto !== undefined ? createPrompterDto : {})
                : createPrompterDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompter: function (accountId, prompterId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getPrompter.');
            }
            // verify required parameter 'prompterId' is not null or undefined
            if (prompterId === null || prompterId === undefined) {
                throw new RequiredError('prompterId', 'Required parameter prompterId was null or undefined when calling getPrompter.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/prompters/{prompterId}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('prompterId', "}"), encodeURIComponent(String(prompterId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get prompters
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompters: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getPrompters.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/prompters".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePrompter: function (accountId, prompterId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling removePrompter.');
            }
            // verify required parameter 'prompterId' is not null or undefined
            if (prompterId === null || prompterId === undefined) {
                throw new RequiredError('prompterId', 'Required parameter prompterId was null or undefined when calling removePrompter.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/prompters/{prompterId}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('prompterId', "}"), encodeURIComponent(String(prompterId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {UpdatePrompterDto} updatePrompterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrompter: function (accountId, prompterId, updatePrompterDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updatePrompter.');
            }
            // verify required parameter 'prompterId' is not null or undefined
            if (prompterId === null || prompterId === undefined) {
                throw new RequiredError('prompterId', 'Required parameter prompterId was null or undefined when calling updatePrompter.');
            }
            // verify required parameter 'updatePrompterDto' is not null or undefined
            if (updatePrompterDto === null || updatePrompterDto === undefined) {
                throw new RequiredError('updatePrompterDto', 'Required parameter updatePrompterDto was null or undefined when calling updatePrompter.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/prompters/{prompterId}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('prompterId', "}"), encodeURIComponent(String(prompterId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof updatePrompterDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updatePrompterDto !== undefined ? updatePrompterDto : {})
                : updatePrompterDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PrompterApi - functional programming interface
 * @export
 */
export var PrompterApiFp = function (configuration) {
    return {
        /**
         * Create prompter
         * @param {number} accountId Account identifier
         * @param {CreatePrompterDto} createPrompterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrompter: function (accountId, createPrompterDto, options) {
            var localVarAxiosArgs = PrompterApiAxiosParamCreator(configuration).createPrompter(accountId, createPrompterDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompter: function (accountId, prompterId, options) {
            var localVarAxiosArgs = PrompterApiAxiosParamCreator(configuration).getPrompter(accountId, prompterId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get prompters
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompters: function (accountId, options) {
            var localVarAxiosArgs = PrompterApiAxiosParamCreator(configuration).getPrompters(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePrompter: function (accountId, prompterId, options) {
            var localVarAxiosArgs = PrompterApiAxiosParamCreator(configuration).removePrompter(accountId, prompterId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {UpdatePrompterDto} updatePrompterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrompter: function (accountId, prompterId, updatePrompterDto, options) {
            var localVarAxiosArgs = PrompterApiAxiosParamCreator(configuration).updatePrompter(accountId, prompterId, updatePrompterDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * PrompterApi - factory interface
 * @export
 */
export var PrompterApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Create prompter
         * @param {number} accountId Account identifier
         * @param {CreatePrompterDto} createPrompterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrompter: function (accountId, createPrompterDto, options) {
            return PrompterApiFp(configuration).createPrompter(accountId, createPrompterDto, options)(axios, basePath);
        },
        /**
         * Get prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompter: function (accountId, prompterId, options) {
            return PrompterApiFp(configuration).getPrompter(accountId, prompterId, options)(axios, basePath);
        },
        /**
         * Get prompters
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompters: function (accountId, options) {
            return PrompterApiFp(configuration).getPrompters(accountId, options)(axios, basePath);
        },
        /**
         * Remove prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePrompter: function (accountId, prompterId, options) {
            return PrompterApiFp(configuration).removePrompter(accountId, prompterId, options)(axios, basePath);
        },
        /**
         * Update prompter
         * @param {number} accountId Account identifier
         * @param {number} prompterId Prompter identifier
         * @param {UpdatePrompterDto} updatePrompterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrompter: function (accountId, prompterId, updatePrompterDto, options) {
            return PrompterApiFp(configuration).updatePrompter(accountId, prompterId, updatePrompterDto, options)(axios, basePath);
        },
    };
};
/**
 * PrompterApi - object-oriented interface
 * @export
 * @class PrompterApi
 * @extends {BaseAPI}
 */
var PrompterApi = /** @class */ (function (_super) {
    __extends(PrompterApi, _super);
    function PrompterApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create prompter
     * @param {number} accountId Account identifier
     * @param {CreatePrompterDto} createPrompterDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrompterApi
     */
    PrompterApi.prototype.createPrompter = function (accountId, createPrompterDto, options) {
        return PrompterApiFp(this.configuration).createPrompter(accountId, createPrompterDto, options)(this.axios, this.basePath);
    };
    /**
     * Get prompter
     * @param {number} accountId Account identifier
     * @param {number} prompterId Prompter identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrompterApi
     */
    PrompterApi.prototype.getPrompter = function (accountId, prompterId, options) {
        return PrompterApiFp(this.configuration).getPrompter(accountId, prompterId, options)(this.axios, this.basePath);
    };
    /**
     * Get prompters
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrompterApi
     */
    PrompterApi.prototype.getPrompters = function (accountId, options) {
        return PrompterApiFp(this.configuration).getPrompters(accountId, options)(this.axios, this.basePath);
    };
    /**
     * Remove prompter
     * @param {number} accountId Account identifier
     * @param {number} prompterId Prompter identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrompterApi
     */
    PrompterApi.prototype.removePrompter = function (accountId, prompterId, options) {
        return PrompterApiFp(this.configuration).removePrompter(accountId, prompterId, options)(this.axios, this.basePath);
    };
    /**
     * Update prompter
     * @param {number} accountId Account identifier
     * @param {number} prompterId Prompter identifier
     * @param {UpdatePrompterDto} updatePrompterDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrompterApi
     */
    PrompterApi.prototype.updatePrompter = function (accountId, prompterId, updatePrompterDto, options) {
        return PrompterApiFp(this.configuration).updatePrompter(accountId, prompterId, updatePrompterDto, options)(this.axios, this.basePath);
    };
    return PrompterApi;
}(BaseAPI));
export { PrompterApi };
/**
 * SoundNotificationApi - axios parameter creator
 * @export
 */
export var SoundNotificationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get all sound notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSoundNotifications: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/aimychat-be/notification-sounds";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SoundNotificationApi - functional programming interface
 * @export
 */
export var SoundNotificationApiFp = function (configuration) {
    return {
        /**
         * Get all sound notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSoundNotifications: function (options) {
            var localVarAxiosArgs = SoundNotificationApiAxiosParamCreator(configuration).getAllSoundNotifications(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SoundNotificationApi - factory interface
 * @export
 */
export var SoundNotificationApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get all sound notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSoundNotifications: function (options) {
            return SoundNotificationApiFp(configuration).getAllSoundNotifications(options)(axios, basePath);
        },
    };
};
/**
 * SoundNotificationApi - object-oriented interface
 * @export
 * @class SoundNotificationApi
 * @extends {BaseAPI}
 */
var SoundNotificationApi = /** @class */ (function (_super) {
    __extends(SoundNotificationApi, _super);
    function SoundNotificationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get all sound notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundNotificationApi
     */
    SoundNotificationApi.prototype.getAllSoundNotifications = function (options) {
        return SoundNotificationApiFp(this.configuration).getAllSoundNotifications(options)(this.axios, this.basePath);
    };
    return SoundNotificationApi;
}(BaseAPI));
export { SoundNotificationApi };
/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export var StatisticsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get chart for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonChart: function (accountId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getCommonChart.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom', 'Required parameter dateFrom was null or undefined when calling getCommonChart.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo', 'Required parameter dateTo was null or undefined when calling getCommonChart.');
            }
            // verify required parameter 'chartType' is not null or undefined
            if (chartType === null || chartType === undefined) {
                throw new RequiredError('chartType', 'Required parameter chartType was null or undefined when calling getCommonChart.');
            }
            // verify required parameter 'intervalType' is not null or undefined
            if (intervalType === null || intervalType === undefined) {
                throw new RequiredError('intervalType', 'Required parameter intervalType was null or undefined when calling getCommonChart.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/statistics/common/chart".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] =
                    dateFrom instanceof Date ? dateFrom.toISOString() : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo instanceof Date ? dateTo.toISOString() : dateTo;
            }
            if (chartType !== undefined) {
                localVarQueryParameter['chartType'] = chartType;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter['intervalType'] = intervalType;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonSummary: function (accountId, dateFrom, dateTo, timezone, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getCommonSummary.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom', 'Required parameter dateFrom was null or undefined when calling getCommonSummary.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo', 'Required parameter dateTo was null or undefined when calling getCommonSummary.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/statistics/common/summary".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] =
                    dateFrom instanceof Date ? dateFrom.toISOString() : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo instanceof Date ? dateTo.toISOString() : dateTo;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get chart for operator\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorChart: function (accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorChart.');
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError('operatorId', 'Required parameter operatorId was null or undefined when calling getOperatorChart.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom', 'Required parameter dateFrom was null or undefined when calling getOperatorChart.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo', 'Required parameter dateTo was null or undefined when calling getOperatorChart.');
            }
            // verify required parameter 'chartType' is not null or undefined
            if (chartType === null || chartType === undefined) {
                throw new RequiredError('chartType', 'Required parameter chartType was null or undefined when calling getOperatorChart.');
            }
            // verify required parameter 'intervalType' is not null or undefined
            if (intervalType === null || intervalType === undefined) {
                throw new RequiredError('intervalType', 'Required parameter intervalType was null or undefined when calling getOperatorChart.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/statistics/operator/{operatorId}/chart"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('operatorId', "}"), encodeURIComponent(String(operatorId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] =
                    dateFrom instanceof Date ? dateFrom.toISOString() : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo instanceof Date ? dateTo.toISOString() : dateTo;
            }
            if (chartType !== undefined) {
                localVarQueryParameter['chartType'] = chartType;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter['intervalType'] = intervalType;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get chart for operator group\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupChart: function (accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorGroupChart.');
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError('operatorGroupId', 'Required parameter operatorGroupId was null or undefined when calling getOperatorGroupChart.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom', 'Required parameter dateFrom was null or undefined when calling getOperatorGroupChart.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo', 'Required parameter dateTo was null or undefined when calling getOperatorGroupChart.');
            }
            // verify required parameter 'chartType' is not null or undefined
            if (chartType === null || chartType === undefined) {
                throw new RequiredError('chartType', 'Required parameter chartType was null or undefined when calling getOperatorGroupChart.');
            }
            // verify required parameter 'intervalType' is not null or undefined
            if (intervalType === null || intervalType === undefined) {
                throw new RequiredError('intervalType', 'Required parameter intervalType was null or undefined when calling getOperatorGroupChart.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/statistics/operator-group/{operatorGroupId}/chart"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('operatorGroupId', "}"), encodeURIComponent(String(operatorGroupId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] =
                    dateFrom instanceof Date ? dateFrom.toISOString() : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo instanceof Date ? dateTo.toISOString() : dateTo;
            }
            if (chartType !== undefined) {
                localVarQueryParameter['chartType'] = chartType;
            }
            if (intervalType !== undefined) {
                localVarQueryParameter['intervalType'] = intervalType;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupSummary: function (accountId, operatorGroupId, dateFrom, dateTo, timezone, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorGroupSummary.');
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError('operatorGroupId', 'Required parameter operatorGroupId was null or undefined when calling getOperatorGroupSummary.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom', 'Required parameter dateFrom was null or undefined when calling getOperatorGroupSummary.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo', 'Required parameter dateTo was null or undefined when calling getOperatorGroupSummary.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/statistics/operator-group/{operatorGroupId}/summary"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('operatorGroupId', "}"), encodeURIComponent(String(operatorGroupId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] =
                    dateFrom instanceof Date ? dateFrom.toISOString() : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo instanceof Date ? dateTo.toISOString() : dateTo;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorSummary: function (accountId, operatorId, dateFrom, dateTo, timezone, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getOperatorSummary.');
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError('operatorId', 'Required parameter operatorId was null or undefined when calling getOperatorSummary.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom', 'Required parameter dateFrom was null or undefined when calling getOperatorSummary.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo', 'Required parameter dateTo was null or undefined when calling getOperatorSummary.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/statistics/operator/{operatorId}/summary"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('operatorId', "}"), encodeURIComponent(String(operatorId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] =
                    dateFrom instanceof Date ? dateFrom.toISOString() : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo instanceof Date ? dateTo.toISOString() : dateTo;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get summary transfer topics statistics for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferTopicsStatistics: function (accountId, dateFrom, dateTo, timezone, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getTransferTopicsStatistics.');
            }
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom', 'Required parameter dateFrom was null or undefined when calling getTransferTopicsStatistics.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo', 'Required parameter dateTo was null or undefined when calling getTransferTopicsStatistics.');
            }
            var localVarPath = "/api/aimychat-be/accounts/{accountId}/statistics/common/themes".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] =
                    dateFrom instanceof Date ? dateFrom.toISOString() : dateFrom;
            }
            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo instanceof Date ? dateTo.toISOString() : dateTo;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter['Timezone'] = String(JSON.stringify(timezone));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * StatisticsApi - functional programming interface
 * @export
 */
export var StatisticsApiFp = function (configuration) {
    return {
        /**
         * Get chart for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonChart: function (accountId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            var localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getCommonChart(accountId, dateFrom, dateTo, chartType, intervalType, timezone, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonSummary: function (accountId, dateFrom, dateTo, timezone, options) {
            var localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getCommonSummary(accountId, dateFrom, dateTo, timezone, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get chart for operator\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorChart: function (accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            var localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getOperatorChart(accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get chart for operator group\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupChart: function (accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            var localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getOperatorGroupChart(accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupSummary: function (accountId, operatorGroupId, dateFrom, dateTo, timezone, options) {
            var localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getOperatorGroupSummary(accountId, operatorGroupId, dateFrom, dateTo, timezone, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorSummary: function (accountId, operatorId, dateFrom, dateTo, timezone, options) {
            var localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getOperatorSummary(accountId, operatorId, dateFrom, dateTo, timezone, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get summary transfer topics statistics for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferTopicsStatistics: function (accountId, dateFrom, dateTo, timezone, options) {
            var localVarAxiosArgs = StatisticsApiAxiosParamCreator(configuration).getTransferTopicsStatistics(accountId, dateFrom, dateTo, timezone, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * StatisticsApi - factory interface
 * @export
 */
export var StatisticsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get chart for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonChart: function (accountId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            return StatisticsApiFp(configuration).getCommonChart(accountId, dateFrom, dateTo, chartType, intervalType, timezone, options)(axios, basePath);
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommonSummary: function (accountId, dateFrom, dateTo, timezone, options) {
            return StatisticsApiFp(configuration).getCommonSummary(accountId, dateFrom, dateTo, timezone, options)(axios, basePath);
        },
        /**
         * Get chart for operator\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorChart: function (accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            return StatisticsApiFp(configuration).getOperatorChart(accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options)(axios, basePath);
        },
        /**
         * Get chart for operator group\'s chats
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {ChartType} chartType The type of building chart
         * @param {IntervalType} intervalType Interval with which statistics will be generated
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupChart: function (accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
            return StatisticsApiFp(configuration).getOperatorGroupChart(accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options)(axios, basePath);
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupSummary: function (accountId, operatorGroupId, dateFrom, dateTo, timezone, options) {
            return StatisticsApiFp(configuration).getOperatorGroupSummary(accountId, operatorGroupId, dateFrom, dateTo, timezone, options)(axios, basePath);
        },
        /**
         * Get summary statistics for operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorSummary: function (accountId, operatorId, dateFrom, dateTo, timezone, options) {
            return StatisticsApiFp(configuration).getOperatorSummary(accountId, operatorId, dateFrom, dateTo, timezone, options)(axios, basePath);
        },
        /**
         * Get summary transfer topics statistics for operator place
         * @param {number} accountId Account identifier
         * @param {Date} dateFrom The date-time from which the stats will be built.
         * @param {Date} dateTo The date by which the stats will be built.
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferTopicsStatistics: function (accountId, dateFrom, dateTo, timezone, options) {
            return StatisticsApiFp(configuration).getTransferTopicsStatistics(accountId, dateFrom, dateTo, timezone, options)(axios, basePath);
        },
    };
};
/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
var StatisticsApi = /** @class */ (function (_super) {
    __extends(StatisticsApi, _super);
    function StatisticsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get chart for operator place
     * @param {number} accountId Account identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {ChartType} chartType The type of building chart
     * @param {IntervalType} intervalType Interval with which statistics will be generated
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    StatisticsApi.prototype.getCommonChart = function (accountId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
        return StatisticsApiFp(this.configuration).getCommonChart(accountId, dateFrom, dateTo, chartType, intervalType, timezone, options)(this.axios, this.basePath);
    };
    /**
     * Get summary statistics for operator
     * @param {number} accountId Account identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    StatisticsApi.prototype.getCommonSummary = function (accountId, dateFrom, dateTo, timezone, options) {
        return StatisticsApiFp(this.configuration).getCommonSummary(accountId, dateFrom, dateTo, timezone, options)(this.axios, this.basePath);
    };
    /**
     * Get chart for operator\'s chats
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {ChartType} chartType The type of building chart
     * @param {IntervalType} intervalType Interval with which statistics will be generated
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    StatisticsApi.prototype.getOperatorChart = function (accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
        return StatisticsApiFp(this.configuration).getOperatorChart(accountId, operatorId, dateFrom, dateTo, chartType, intervalType, timezone, options)(this.axios, this.basePath);
    };
    /**
     * Get chart for operator group\'s chats
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {ChartType} chartType The type of building chart
     * @param {IntervalType} intervalType Interval with which statistics will be generated
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    StatisticsApi.prototype.getOperatorGroupChart = function (accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options) {
        return StatisticsApiFp(this.configuration).getOperatorGroupChart(accountId, operatorGroupId, dateFrom, dateTo, chartType, intervalType, timezone, options)(this.axios, this.basePath);
    };
    /**
     * Get summary statistics for operator
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    StatisticsApi.prototype.getOperatorGroupSummary = function (accountId, operatorGroupId, dateFrom, dateTo, timezone, options) {
        return StatisticsApiFp(this.configuration).getOperatorGroupSummary(accountId, operatorGroupId, dateFrom, dateTo, timezone, options)(this.axios, this.basePath);
    };
    /**
     * Get summary statistics for operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    StatisticsApi.prototype.getOperatorSummary = function (accountId, operatorId, dateFrom, dateTo, timezone, options) {
        return StatisticsApiFp(this.configuration).getOperatorSummary(accountId, operatorId, dateFrom, dateTo, timezone, options)(this.axios, this.basePath);
    };
    /**
     * Get summary transfer topics statistics for operator place
     * @param {number} accountId Account identifier
     * @param {Date} dateFrom The date-time from which the stats will be built.
     * @param {Date} dateTo The date by which the stats will be built.
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    StatisticsApi.prototype.getTransferTopicsStatistics = function (accountId, dateFrom, dateTo, timezone, options) {
        return StatisticsApiFp(this.configuration).getTransferTopicsStatistics(accountId, dateFrom, dateTo, timezone, options)(this.axios, this.basePath);
    };
    return StatisticsApi;
}(BaseAPI));
export { StatisticsApi };
/**
 * SystemApi - axios parameter creator
 * @export
 */
export var SystemApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/healthCheck";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/version";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SystemApi - functional programming interface
 * @export
 */
export var SystemApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            var localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).healthCheck(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version: function (options) {
            var localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).version(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SystemApi - factory interface
 * @export
 */
export var SystemApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            return SystemApiFp(configuration).healthCheck(options)(axios, basePath);
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version: function (options) {
            return SystemApiFp(configuration).version(options)(axios, basePath);
        },
    };
};
/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
var SystemApi = /** @class */ (function (_super) {
    __extends(SystemApi, _super);
    function SystemApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    SystemApi.prototype.healthCheck = function (options) {
        return SystemApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Service version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    SystemApi.prototype.version = function (options) {
        return SystemApiFp(this.configuration).version(options)(this.axios, this.basePath);
    };
    return SystemApi;
}(BaseAPI));
export { SystemApi };
