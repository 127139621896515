import React, { memo, useCallback, useMemo } from 'react';
import { OperatorChatDto, OperatorChatStatus } from '@just-ai/aimychat-shared/dist/api/client/operator';
import './Message.scss';
import classNames from 'classnames';
import { getUserFullName } from '../operatorPlaceHelpers';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';
import { Icon } from '@just-ai/just-ui';
import { File } from './File';
import { useOperatorDataContext } from '../OperatorDataContext';
import { MessageAuthor, MessageDto, MessageType } from '@just-ai/aimychat-shared/dist/api/client/websocket';
import { t } from '../../../localization';

type Props = {
  message: MessageDto;
  chat: OperatorChatDto;
  isClient: boolean;
  isBot?: boolean;
  choosePrompterMessage: (message: MessageDto) => void;
  contextPrompterId?: number;
  isComment: boolean;
};

export const Message = memo(
  ({ message, isClient, chat, choosePrompterMessage, contextPrompterId, isComment }: Props) => {
    const { messageForPrompter } = useOperatorDataContext();
    const isMobile = useIsMobile();
    const creationTimestamp = message.creationTimestamp;
    const minutes = creationTimestamp ? new Date(creationTimestamp).getMinutes().toString().padStart(2, '0') : '';
    const hours = creationTimestamp ? new Date(creationTimestamp).getHours().toString().padStart(2, '0') : '';

    const name = useMemo(() => {
      if (message.author === MessageAuthor.BOT) {
        return t('OperatorPlace: bot');
      }
      return isClient ? getUserFullName(chat.clientInfo) : getUserFullName(message.operator);
    }, [chat.clientInfo, isClient, message.operator]);

    const isMessageEmpty = useMemo(() => !message.text?.trim(), [message.text]);

    const handleIconClick = useCallback(() => {
      if (!contextPrompterId) {
        return;
      }

      choosePrompterMessage(message);
    }, [choosePrompterMessage, contextPrompterId, message]);

    return (
      <div
        data-test-id={`Chat.MessageType.${message.type}`}
        className={classNames('DialogMessage_container', {
          DialogMessage_container_client: isClient,
        })}
      >
        <div
          className={classNames('DialogMessage', {
            DialogMessage_mobile: isMobile,
            DialogMessage_mobile__bot: !isClient,
            DialogMessage_comment: isComment,
          })}
        >
          {name && (
            <div className='DialogMessage_name'>
              {name}
              {contextPrompterId &&
                message.type === MessageType.CLIENTMESSAGE &&
                chat.status === OperatorChatStatus.ACTIVE && (
                  <Icon
                    className={classNames('margin-left-2x', {
                      PrompterIcon: messageForPrompter?.id !== message.id,
                    })}
                    name='farRobot'
                    color={messageForPrompter?.id === message.id ? 'primary' : 'secondary'}
                    onClick={handleIconClick}
                  />
                )}
            </div>
          )}
          <div
            className={classNames('DialogMessage_text', { ClientMessage: message.type === MessageType.CLIENTMESSAGE })}
          >
            {!isMessageEmpty && message.text && <div dangerouslySetInnerHTML={{ __html: message.text }} />}
            {message.files?.map(file => (
              <File key={file.url} file={file} />
            ))}
          </div>
          {message.creationTimestamp && (
            <div className='DialogMessage_date'>
              <span>{`${hours}:${minutes}`}</span>
            </div>
          )}
        </div>
        {message.type === MessageType.BOTMESSAGE && <div className='DialogMessage_avatar bot'></div>}
        {[MessageType.OPERATORMESSAGE, MessageType.COMMENT].includes(message.type) && (
          <div className='DialogMessage_avatar operator'></div>
        )}
      </div>
    );
  }
);
