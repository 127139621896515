import React, { useCallback, useState } from 'react';
import { IconButton, Button, SwitchButton, InputText, JustSelect } from '@just-ai/just-ui';
import { useAppContext } from '../../../AppContext';
import { Spinner } from '@just-ai/just-ui/dist/Spinner';
import { t } from '../../../localization';
import './Params.scss';
import { getInputWidth, msToMinutes, msToSeconds } from './utils';
import { TimeInput } from '../components/TimeInput';
import { Filedset } from '../components/Filedset';
import { SoundNotifications } from '../components/SoundNotifications';
import { OperatorPlaceDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';

const MIN_DIALOGS = 0;
const MIN_MINUTES = 0;
const MAX_MINUTES = 59;
const MAX_SECONDS = 59;

enum ORDER_TYPE {
  ASC = 'ASC',
  DESC = 'DESC',
}

export default function Params() {
  const {
    operatorPlace,
    setOperatorPlace,
    getOperatorPlace,
    operatorPlaceService,
    productInfo,
    config,
    isOperatorInvitationAccess,
  } = useAppContext();
  const [maxDialogs, setMaxDialogs] = useState<number | undefined>(operatorPlace?.maxActiveChatsForOperator);
  const [seconds, setSeconds] = useState<number>(msToSeconds(operatorPlace?.maxOperatorResponseTime));
  const [minutes, setMinutes] = useState<number>(msToMinutes(operatorPlace?.maxOperatorResponseTime, 0));

  const copyToken = useCallback(
    () => window.navigator.clipboard.writeText(operatorPlace?.token as string),
    [operatorPlace?.token]
  );
  const regenerateToken = useCallback(async () => {
    await operatorPlaceService.updateToken();
    getOperatorPlace();
  }, [getOperatorPlace, operatorPlaceService]);

  const updateOperatorPlace = useCallback(
    async (operatorPlace: OperatorPlaceDto) => {
      const newOperatorPlace = await operatorPlaceService.updateOperatorPlace(operatorPlace);
      setOperatorPlace(newOperatorPlace);
    },
    [operatorPlaceService, setOperatorPlace]
  );

  const toggleAutoAssignment = useCallback(
    async value => {
      if (!operatorPlace) return;

      updateOperatorPlace({
        ...operatorPlace,
        autoAssignment: value,
      });
    },
    [operatorPlace, updateOperatorPlace]
  );

  const toggleIsOperatorInvitation = useCallback(
    async value => {
      if (!operatorPlace) return;

      updateOperatorPlace({
        ...operatorPlace,
        isOperatorInvitation: value,
      });
    },
    [operatorPlace, updateOperatorPlace]
  );

  const onChangeOrder = useCallback(
    async value => {
      if (!operatorPlace) return;

      updateOperatorPlace({
        ...operatorPlace,
        clientQueueSorting: Array.isArray(value) ? value[0] : value,
      });
    },
    [operatorPlace, updateOperatorPlace]
  );
  const onChangeMinutes = useCallback(
    (value: number) => {
      if (!operatorPlace) {
        return;
      }
      let result = value;
      if (value < MIN_MINUTES || !value) result = MIN_MINUTES;
      if (value > MAX_MINUTES) result = MAX_MINUTES;
      if (!value && !seconds) {
        result = 0;
      }
      updateOperatorPlace({ ...operatorPlace, maxOperatorResponseTime: (seconds + result * 60) * 1000 });
      setMinutes(result);
    },
    [operatorPlace, seconds, updateOperatorPlace]
  );

  const onChangeSeconds = useCallback(
    (value: number = 0) => {
      if (!operatorPlace) {
        return;
      }
      let result = value < 0 ? 0 : value || 0;
      if (value > MAX_SECONDS) result = MAX_SECONDS;
      if (!value && !minutes) {
        result = 0;
      }
      updateOperatorPlace({ ...operatorPlace, maxOperatorResponseTime: (result + minutes * 60) * 1000 });
      setSeconds(result);
    },
    [minutes, operatorPlace, updateOperatorPlace]
  );

  const setMaxDialogsWithValidation = useCallback(
    (value: number) => {
      if (!operatorPlace) {
        return;
      }
      const result = value < MIN_DIALOGS ? MIN_DIALOGS : value;
      setMaxDialogs(result);
      updateOperatorPlace({ ...operatorPlace, maxActiveChatsForOperator: result });
    },
    [operatorPlace, updateOperatorPlace]
  );

  return (
    <div className='Params_container'>
      <div className='flex-column gap-6x'>
        {!operatorPlace && <Spinner size='2x' />}
        <fieldset className='flex-column gap-2x'>
          <label className='font-size-14 margin-bottom-0x font-bold'>{t('Settings:Params token label')}</label>
          <span className='font-size-12 font-color-light-gray'>
            {t(
              'Settings:Params token description',
              productInfo.productName,
              config.botadmin.productName,
              config.zenflow.productName
            )}
          </span>
          <div className='flex-row'>
            <IconButton
              data-test-id='Params.token.copy'
              name='farCopy'
              flat
              color='secondary'
              withoutPadding
              onClick={copyToken}
            />
            <div
              data-test-id='Params.token.value'
              className='font-size-12 margin-left-2x'
              style={{ color: 'var(--primary)' }}
            >
              {operatorPlace?.token}
            </div>
          </div>
          <div>
            <Button
              className='font-size-12'
              color='primary'
              size='xs'
              data-test-id='Params.token.regenerate'
              flat
              withoutPadding
              onClick={regenerateToken}
            >
              {t('Settings:Params token regenerate')}
            </Button>
          </div>
        </fieldset>
        {isOperatorInvitationAccess && (
          <Filedset label={t('Settings:Params:OperatorInvitation:Label')} description=''>
            <SwitchButton
              id='settingsIsOperatorInvitation'
              className='SwitchButton'
              offLabel={t('Settings:Params autoset button disable')}
              onLabel={t('Settings:Params autoset button enabled')}
              data-test-id='Params.isOperatorInvitation.toggle'
              onChange={toggleIsOperatorInvitation}
              value={Boolean(operatorPlace?.isOperatorInvitation)}
            />
          </Filedset>
        )}
        <Filedset label={t('Settings:Params autoset label')} description={t('Settings:Params autoset description')}>
          <SwitchButton
            id='settingsAutoset'
            className='SwitchButton'
            offLabel={t('Settings:Params autoset button disable')}
            onLabel={t('Settings:Params autoset button enabled')}
            data-test-id='Params.autoAssignment.toggle'
            onChange={toggleAutoAssignment}
            value={Boolean(operatorPlace?.autoAssignment)}
          />
        </Filedset>
        {operatorPlace && (
          <Filedset label={t('Settings:Params:Notification:Label')} description=''>
            <SoundNotifications operatorPlace={operatorPlace} />
          </Filedset>
        )}
        <Filedset label={t('Settings:Params max label')} description={t('Settings:Params max description')}>
          <div className='flex-row gap-2x'>
            <IconButton
              size='sm'
              disabled={maxDialogs! <= MIN_DIALOGS}
              outline
              data-test-id='Params.maxDialogs.decrease'
              onClick={() => setMaxDialogsWithValidation(maxDialogs! - 1)}
              name='farMinus'
              className='icon-withBorder'
            />
            <InputText
              type='number'
              style={{
                width: getInputWidth(maxDialogs),
                height: '32px',
                padding: '0.5rem 0',
                textAlign: 'center',
              }}
              compact
              min={MIN_DIALOGS}
              data-test-id='Params.maxDialogs'
              value={maxDialogs?.toString()}
              onChange={value => setMaxDialogsWithValidation(Number.parseInt(value))}
            />
            <IconButton
              size='sm'
              onClick={() => setMaxDialogsWithValidation(maxDialogs! + 1)}
              name='farPlus'
              data-test-id='Params.maxDialogs.increase'
              className='icon-withBorder'
            />
          </div>
        </Filedset>

        <Filedset label={t('Settings:Params order label')} description={t('Settings:Params order description')}>
          <JustSelect
            onChange={onChangeOrder}
            data-test-id='Params.order'
            options={Object.values(ORDER_TYPE).map(option => ({
              value: option,
              label: t(`Settings:Params order ${option}`),
            }))}
            position='fixed'
            value={operatorPlace?.clientQueueSorting || ORDER_TYPE.ASC}
          />
        </Filedset>

        <Filedset label={t('Settings:Params time label')} description={t('Settings:Params time description')}>
          <div className='flex-row gap-2x'>
            <TimeInput
              id='transfer-time-minutes'
              dataTestId='Params.time.minutes'
              onChange={onChangeMinutes}
              min={0}
              max={MAX_MINUTES}
              value={minutes}
              title={t('Settings:Params:Minutes')}
            />
            <TimeInput
              id='transfer-time-seconds'
              dataTestId='Params.time.seconds'
              onChange={onChangeSeconds}
              min={0}
              max={MAX_SECONDS}
              value={seconds}
              title={t('Settings:Params:Seconds')}
            />
          </div>
        </Filedset>
      </div>
    </div>
  );
}
