import { memo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../../AppContext';
import { useOperatorDataContext } from '../OperatorDataContext';
import { StateListsName } from '@just-ai/aimychat-shared/dist/operatorPlaceTypes';
import { Tabs as JustUiTabs } from '@just-ai/just-ui';
import { TabLabel } from './TabLabel';

export const Tabs = memo(() => {
  const navigate = useNavigate();
  const { operatorPlace } = useAppContext();
  const contextData = useOperatorDataContext();
  const { tab: currentTab } = useParams<{ tab: StateListsName }>();
  const { loading } = contextData;

  const onChangeTab = useCallback((value: string) => navigate(`/operator/${value}`, { replace: true }), [navigate]);

  if (!currentTab) {
    return null;
  }

  return (
    <JustUiTabs
      tabs={(operatorPlace?.autoAssignment
        ? [StateListsName.ACTIVE, StateListsName.COMPLETED]
        : Object.values(StateListsName)
      ).map(tab => ({
        name: <TabLabel tab={tab} amount={contextData[tab]?.length} loading={loading[tab]} contextData={contextData} />,
        value: tab,
        dataTestId: `OperatorPlace.Tab.${tab}`,
      }))}
      fullWidth
      onChange={onChangeTab}
      activeTab={currentTab}
    />
  );
});
