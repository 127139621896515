export var sortChats = function (chats, isArchive, clientQueueSorting) {
    return chats.sort(function (chatA, chatB) {
        if (isArchive && chatA.completionTime && chatB.completionTime) {
            return new Date(chatA.completionTime).getTime() - new Date(chatB.completionTime).getTime();
        }
        var chatACreationTime = new Date(chatA.creationTime).getTime();
        var chatBCreationTime = new Date(chatB.creationTime).getTime();
        return clientQueueSorting === 'ASC' ? chatACreationTime - chatBCreationTime : chatBCreationTime - chatACreationTime;
    });
};
