import { ComponentProps, useEffect, useState, useRef } from 'react';
import { Spinner } from '@just-ai/just-ui/dist/Spinner';
import './SmoothSpinner.scss';
import classNames from 'classnames';

interface SmoothSpinnerInterface extends ComponentProps<typeof Spinner> {
  shown: boolean;
}

const TIME = 500;

export function SmoothSpinner({ shown, ...props }: SmoothSpinnerInterface) {
  const shownRef = useRef<boolean>(shown);
  const [toHide, setHide] = useState<boolean>(false);
  const [toShow, setToShow] = useState<boolean>(false);
  useEffect(() => {
    const newShown = shownRef.current;
    if (newShown && !shown) {
      setHide(true);
      setToShow(false);
    }
    if (!newShown && shown) {
      setHide(false);
      setToShow(true);
    }
    const timer = setTimeout(() => {
      setHide(false);
      setToShow(false);
    }, TIME);
    shownRef.current = shown;

    return () => clearTimeout(timer);
  }, [shownRef.current, shown]);

  if (!shownRef.current && !toHide && !toShow) return null;

  return (
    <div
      className={classNames('SmoothSpinner', {
        SmoothSpinner_toShow: toShow,
        SmoothSpinner_toHide: toHide,
      })}
      style={{ animationDuration: `${TIME}ms` }}
    >
      <Spinner {...props} key='spinner' />
    </div>
  );
}
