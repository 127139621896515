export const commonLocalization = {
  Aimychat: {
    eng: 'Aimychat',
    ru: 'Aimychat',
    pt: 'Aimychat',
  },
  Add: {
    eng: 'Add',
    ru: 'Добавить',
    pt: 'Adicionar',
  },
  Edit: {
    eng: 'Edit',
    ru: 'Изменить',
    pt: 'Editar',
  },
  Delete: {
    eng: 'Delete',
    ru: 'Удалить',
    pt: 'Excluir',
  },
  Change: {
    eng: 'Edit',
    ru: 'Изменить',
    pt: 'Editar',
  },
  Save: {
    eng: 'Save',
    ru: 'Сохранить',
    pt: 'Salvar',
  },
  Cancel: {
    eng: 'Cancel',
    ru: 'Отмена',
    pt: 'Cancelar',
  },
  Apply: {
    eng: 'Apply',
    ru: 'Применить',
    pt: 'Aplicar',
  },
  Group: {
    eng: 'Group',
    ru: 'Группа',
    pt: 'Grupo',
  },
  Agent: {
    eng: 'Agent',
    ru: 'Оператор',
    pt: 'Agente',
  },
  Period: {
    eng: 'Period',
    ru: 'Период',
    pt: 'Período',
  },
  'CloudMenu: aimylogic': {
    eng: 'Aimylogic',
    ru: 'Aimylogic',
    pt: 'Aimylogic',
  },
  'CloudMenu: Tovie DialogStudio': {
    eng: 'Tovie DialogStudio',
    ru: 'Tovie DialogStudio',
    pt: 'Tovie DialogStudio',
  },
  'CloudMenu: aimyvoice': {
    eng: 'Aimyvoice',
    ru: 'Aimyvoice',
    pt: 'Aimyvoice',
  },
  'CloudMenu: Tovie Voice': {
    eng: 'Tovie Voice',
    ru: 'Tovie Voice',
    pt: 'Tovie Voice',
  },
  'CloudMenu: caila': {
    eng: 'CAILA NLP Platform',
    ru: 'CAILA NLP Platform',
    pt: 'CAILA NLP Platform',
  },
  'CloudMenu: Tovie NLP': {
    eng: 'Tovie NLP Platform',
    ru: 'Tovie NLP Platform',
    pt: 'Tovie NLP Platform',
  },
  'CloudMenu: cc': {
    eng: 'Conversational Cloud',
    ru: 'Conversational Cloud',
    pt: 'Conversational Cloud',
  },
  'CloudMenu: Tovie Cloud': {
    eng: 'Tovie Cloud',
    ru: 'Tovie Cloud',
    pt: 'Tovie Cloud',
  },
  'CloudMenu: jaicp': {
    eng: 'JAICP',
    ru: 'JAICP',
    pt: 'JAICP',
  },
  'CloudMenu: Tovie Platform': {
    eng: 'Tovie Platform',
    ru: 'Tovie Platform',
    pt: 'Tovie Platform',
  },
  'CloudMenu: aimychat': {
    eng: 'Aimychat',
    ru: 'Aimychat',
    pt: 'Aimychat',
  },
  'CloudMenu: Tovie Agent': {
    eng: 'Tovie Agent',
    ru: 'Tovie Agent',
    pt: 'Tovie Agent',
  },
  'Header:my account': {
    eng: 'My account',
    ru: 'Мой профиль',
    pt: 'Minha conta',
  },
  'Header:documentation': {
    eng: 'Documentation',
    ru: 'Документация',
    pt: 'Documentação',
  },
  'Header:Documentation:Link': {
    eng: '#{config.aimychat.helpUrl}',
    ru: '#{config.aimychat.helpUrl}',
    pt: '#{config.aimychat.helpUrl}',
  },
  'Header:sign out': {
    eng: 'Sign out',
    ru: 'Выйти',
    pt: 'Sair',
  },
  'Links:chats': {
    eng: 'Chats',
    ru: 'Диалоги',
    pt: 'Chats',
  },
  'Links:statistics': {
    eng: 'Statistics',
    ru: 'Статистика',
    pt: 'Estatísticas',
  },
  'Links:settings': {
    eng: 'Settings',
    ru: 'Настройки',
    pt: 'Configurações',
  },
  'Analytics: Show': {
    eng: 'Show',
    ru: 'Показать',
    pt: 'Mostrar',
  },
  'Offline status': {
    eng: 'Offline',
    ru: 'Не в сети',
    pt: 'Offline',
  },
  'Online status': {
    eng: 'Online',
    ru: 'В сети',
    pt: 'On-line',
  },
  'NoAccessPage: title': {
    eng: 'Access restricted',
    ru: 'Доступ ограничен',
    pt: 'Acesso restrito',
  },
  'NoAccessPage:Title:NoLicense': {
    eng: 'No access',
    ru: 'Нет доступа',
    pt: 'Sem acesso',
  },
  'NoAccessPage: description noAdmin': {
    eng: 'You do not have access to statistics and administrator settings.',
    ru: 'У вас нет доступа к статистике и настройкам администратора.',
    pt: 'Você não tem acesso às estatísticas e configurações do administrador.',
  },
  'NoAccessPage: description': {
    eng: 'Aimychat is not enabled for this account or you are not added to it. Contact the account owner.',
    ru: 'Aimychat не подключен для этого аккаунта или вы в него не добавлены. Обратитесь к владельцу аккаунта.',
  },
  'NoAccessPage:Description:Tovie': {
    eng: 'Tovie Agent is not enabled for this account or you are not added to it. Contact the account owner.',
    pt: 'O Tovie Agent não está ativado para esta conta, ou você não foi adicionado a ela. Entre em contato com o proprietário da conta.',
  },
  'NoAccessPage:Description:NoLicense': {
    eng: 'Your license does not allow you to use this feature. Contact your manager to get access.',
    ru: 'Ваша лицензия не позволяет использовать этот функционал. Чтобы получить доступ, обратитесь к вашему менеджеру.',
    pt: 'O Tovie Agent não está ativado para esta conta, ou você não foi adicionado a ela. Entre em contato com o proprietário da conta.',
  },
  'OperatorPlace:SendMessage:Error': {
    eng: 'Error sending a message. Please try again later, reload the page, or contact your administrator.',
    ru: 'Ошибка отправки сообщения. Попробуйте еще раз позже, перезагрузите страницу или обратитесь к администратору.',
  },
  'NoAccessPage: role admin': {
    eng: 'Administrator',
    ru: 'Администратор',
    pt: 'Administrador',
  },
  'NoAccessPage: role operator': {
    eng: 'Agent',
    ru: 'Оператор',
    pt: 'Agente',
  },
  'NoAccessPage: role agent': {
    eng: 'Agent',
    ru: 'Оператор',
    pt: 'Agente',
  },
  Confirm: {
    eng: 'Confirm',
    ru: 'Подтвердить',
    pt: 'Confirmar',
  },
  RequiredField: {
    eng: 'Required field',
    ru: 'Обязательное поле',
    pt: 'Campo obrigatório',
  },
  'Notification:Failed:Alert': {
    ru: 'Звуковые уведомления не проигрываются на этой странице. Чтобы это исправить, разрешите их в настройках вашего браузера или нажмите на любую область.',
    eng: 'Sound notifications are not playing on this page. To fix this, allow them in your browser settings or click on any area.',
  },
};
