import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { InputGroup, InputText } from '@just-ai/just-ui';
import { t } from '../../localization';
import './OperatorChatTabs.scss';
import { useOperatorDataContext } from './OperatorDataContext';
import ChatsList from './tabsComponents/ChatsList';
import { StateListsName } from '@just-ai/aimychat-shared/dist/operatorPlaceTypes';
import { Route, Routes, Outlet, useParams } from 'react-router-dom';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';
import { Tabs } from './tabsComponents/Tabs';
import { useAppContext } from '../../AppContext';

export default function OperatorChatTabs() {
  const { tab: currentTab } = useParams<{ tab: StateListsName }>();
  const [filter, setFilter] = useState<string>('');
  const [chosenGroups, setChosenGroups] = useState<number[]>([]);
  const previousHistoryValues = useRef<{ filter: string; groupIds: number[] }>({ filter, groupIds: chosenGroups });
  const isMobile = useIsMobile();
  const { groupsColors, operatorId, operators, operator, isAdmin } = useAppContext();
  const contextData = useOperatorDataContext();
  const {
    completed,
    active,
    created,
    loading,
    chooseChat,
    chosenChatId,
    isCompletedHasToLoad,
    getHistoryChats,
    operatorGroups,
  } = contextData;

  const groups = useMemo(() => {
    if (operatorGroups.length) {
      return operatorGroups;
    }
    const currentOperator = isAdmin ? operators.find(({ id }) => id === operatorId) : operator;

    return currentOperator?.groups || [];
  }, [operatorGroups, isAdmin, operators, operator, operatorId]);

  const toggleGroup = useCallback((groupId: number) => {
    setChosenGroups(oldGroupIds => {
      if (oldGroupIds.includes(groupId)) {
        return [...oldGroupIds].filter(id => id !== groupId);
      } else {
        return [...oldGroupIds, groupId];
      }
    });
  }, []);

  useEffect(() => {
    if (currentTab !== StateListsName.COMPLETED) return;
    const { filter: prevFilter, groupIds: prevGroups } = previousHistoryValues.current;

    let timeout;

    if (prevFilter !== filter.trim() || prevGroups.sort().join('') !== chosenGroups.sort().join('')) {
      previousHistoryValues.current = { filter: filter.trim(), groupIds: chosenGroups };
      const query = { ...previousHistoryValues.current, getFromStart: true };
      timeout = setTimeout(() => getHistoryChats(query), 300);
    }
    return () => clearTimeout(timeout);
  }, [filter, currentTab, chosenGroups, getHistoryChats]);

  const hasSeveralGroups = groups.length > 1;

  if (!currentTab || (chosenChatId && isMobile)) {
    return null;
  }

  return (
    <div className='OperatorChatTabs_container'>
      <Tabs />
      <InputGroup className='margin-bottom-4x' PrependInner={[{ name: 'faSearch' }]}>
        <InputText
          data-test-id='OperatorPlace.Tab.FilterInput'
          onChange={setFilter}
          blurInactive
          placeholder={t('OperatorPlace: filter input placeholder')}
        />
      </InputGroup>
      {hasSeveralGroups && (
        <div className='flex-row gap-2x flex-wrap margin-bottom-4x'>
          {groups.map(group => {
            const isChosen = chosenGroups.includes(group.id);

            return (
              <button
                data-test-id={`Chats.Tab.group.${group.id}`}
                data-test-name={`Chats.Tab.group.${group.name}`}
                key={group.id}
                className='reset-button'
                onClick={() => toggleGroup(group.id)}
                style={{
                  borderRadius: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  backgroundColor: isChosen
                    ? groupsColors[group.id]?.darkBackground
                    : groupsColors[group.id]?.backgroundColor,
                  color: isChosen ? 'var(--white)' : groupsColors[group.id]?.color,
                }}
              >
                {group.name}
              </button>
            );
          })}
        </div>
      )}

      <Outlet />

      <Routes>
        <Route
          path='completed'
          element={
            <ChatsList
              key='completed'
              showGroupFlag={hasSeveralGroups}
              tab={currentTab}
              chats={completed}
              chooseChat={chooseChat}
              chosenGroups={chosenGroups}
              filter={filter}
              chosenChatId={chosenChatId}
              loading={loading.completed}
              onGetHistoryChats={getHistoryChats}
              loadMore={isCompletedHasToLoad}
            />
          }
        />
        <Route
          path='active'
          element={
            <ChatsList
              key='active'
              showGroupFlag={hasSeveralGroups}
              tab={currentTab}
              chats={active}
              chosenGroups={chosenGroups}
              filter={filter}
              chooseChat={chooseChat}
              chosenChatId={chosenChatId}
              loading={loading.active}
              loadMore={false}
            />
          }
        />
        <Route
          path='created'
          element={
            <ChatsList
              key='created'
              showGroupFlag={hasSeveralGroups}
              tab={currentTab}
              chats={created}
              chosenGroups={chosenGroups}
              filter={filter}
              chooseChat={chooseChat}
              chosenChatId={chosenChatId}
              loading={loading.created}
              loadMore={false}
            />
          }
        />
      </Routes>
      <div id='iframe-wrapper' />
    </div>
  );
}
