export var getUserLanguage = function (userCountryIsoCode) {
    return ((userCountryIsoCode === null || userCountryIsoCode === void 0 ? void 0 : userCountryIsoCode.toLocaleLowerCase()) || window.navigator.language.split('-')[0]) === 'ru' ? 'ru' : 'en';
};
export var humanizeDuration = function (duration, withoutEmptyHours) {
    if (withoutEmptyHours === void 0) { withoutEmptyHours = false; }
    if (duration === null) {
        return null;
    }
    var calcSeconds = +((duration / 1000) % 60).toFixed(1) > 1
        ? Math.floor(+((duration / 1000) % 60).toFixed(1))
        : +((duration / 1000) % 60).toFixed(1);
    var calcMinutes = Math.floor(+((duration / 1000 / 60) % 60));
    var calcHours = Math.floor(+(duration / 1000 / 60 / 60));
    var checkForZero = function (value) { return (value < 10 && value > -10 ? "0".concat(value) : value); };
    var _a = [calcHours, calcMinutes, calcSeconds].map(checkForZero), hours = _a[0], minutes = _a[1], seconds = _a[2];
    return (withoutEmptyHours && !calcHours ? "".concat(minutes, ":").concat(seconds) : "".concat(hours, ":").concat(minutes, ":").concat(seconds)).trim();
};
export var getPluralType = function (value) { return ['Zero', 'One', 'Two', 'Few', 'Few'][value] || 'Many'; };
export var scrollToBottom = function () {
    var messagesContainer = document.querySelector('.ClientDialog_MessagesContainer');
    messagesContainer === null || messagesContainer === void 0 ? void 0 : messagesContainer.scrollTo({ top: messagesContainer.scrollHeight + 2000 });
};
