import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { useAppContext } from '../../../AppContext';

import { t } from '../../../localization';
import './Params.scss';
import './Prompters.scss';

import { Button, InputText, Checkbox } from '@just-ai/just-ui';
import { CreatePrompterDto, PrompterDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { PrompterItem } from '../components/Prompters/PrompterItem';
import { PrompterEditModal } from '../components/Prompters/PrompterEditModal';
import { PrompterRemoveModal } from '../components/Prompters/PrompterRemoveModal';

type Modal = 'edit' | 'remove';

export type ModalProps = {
  setOpenedModal: Dispatch<SetStateAction<Modal | null>>;
  selectedPrompter: PrompterDto | null;
  setSelectedPrompter: Dispatch<SetStateAction<PrompterDto | null>>;
};

export const Errors = {
  name: 'Settings:Prompters:Name:Error:AlreadyExists',
  url: 'Settings:Prompters:Url:Error:AlreadyExists',
  urlNotFound: 'Settings:Prompters:Url:Error:NotFound',
};

export default function Prompters() {
  const { getPrompters, prompters, getGroups, groups, prompterApiService } = useAppContext();
  const [values, setValues] = useState<CreatePrompterDto>({ name: '', url: '', enabledSendStart: false });
  const [nameError, setNameError] = useState();
  const [urlError, setUrlError] = useState();
  const [selectedPrompter, setSelectedPrompter] = useState<PrompterDto | null>(null);
  const [openedModal, setOpenedModal] = useState<Modal | null>(null);

  const groupsByPrompterId = useMemo(
    () =>
      groups.reduce((acc, group) => {
        group.prompters.forEach(({ id }) => {
          if (acc[id]) {
            acc[id].push(group);
          } else {
            acc[id] = [group];
          }
        });
        if (group.contextPrompterId) {
          if (acc[group.contextPrompterId]) {
            acc[group.contextPrompterId].push(group);
          } else {
            acc[group.contextPrompterId] = [group];
          }
        }
        return acc;
      }, {}),

    [groups]
  );

  const handleOpenModal = useCallback((prompter: PrompterDto, modalType: Modal) => {
    setSelectedPrompter(prompter);
    setOpenedModal(modalType);
  }, []);

  const handleChangeName = useCallback((name: string) => setValues({ ...values, name }), [values]);
  const handleChangeUrl = useCallback((url: string) => setValues({ ...values, url }), [values]);
  const handleChangeSendStart = useCallback(
    (enabledSendStart: boolean) => setValues({ ...values, enabledSendStart }),
    [values]
  );

  const save = useCallback(async () => {
    if (!values.name.trim() || !values.url.trim()) {
      return;
    }

    const isUniqName = !prompters.some(({ name }) => name === values.name);
    const isUniqUrl = !prompters.some(({ url }) => url === values.url);

    if (!isUniqName) {
      setNameError(t(Errors.name));
      return;
    } else {
      setNameError(undefined);
    }

    if (!isUniqUrl) {
      setUrlError(t(Errors.url));
      return;
    } else {
      setUrlError(undefined);
    }

    const data = await prompterApiService.createPrompter(values);
    if (data) {
      getPrompters();
      setValues({ name: '', url: '', enabledSendStart: false });
    } else {
      setUrlError(t(Errors.urlNotFound));
    }
  }, [getPrompters, prompterApiService, prompters, values]);

  useEffect(() => {
    getGroups();
    getPrompters();
  }, [getPrompters, getGroups]);

  return (
    <div className='Params_container'>
      <p className='Operator_description'>
        <small>{t('Settings:Prompters:Description')}</small>
        <div
          dangerouslySetInnerHTML={{
            __html: `<small>${t('Settings:Prompters:Description:Link')}</small>`,
          }}
        />
      </p>
      <div className='Prompters_container'>
        <p>
          <small>
            <b>{t('Settings:Prompters:Add')}</b>
          </small>
        </p>
        <fieldset>
          <div className='margin-bottom-2x'>
            <InputText
              id='name'
              bsSize='sm'
              placeholder={t('Settings:Prompters:Name:Placeholder')}
              data-test-id='Prompter.name'
              onChange={handleChangeName}
              value={values.name}
              errorText={nameError}
            />
          </div>
          <div className='margin-bottom-2x'>
            <InputText
              id='url'
              bsSize='sm'
              placeholder={t('Settings:Prompters:Url:Placeholder')}
              data-test-id='Prompter.link'
              onChange={handleChangeUrl}
              value={values.url}
              errorText={urlError}
            />
          </div>

          <div>
            <Checkbox
              inline
              label={t('Settings:Prompters:SendStart:Label')}
              name='send-start'
              onChange={handleChangeSendStart}
              value={values.enabledSendStart}
              data-test-id='Prompter.enabledSendStart'
            />
          </div>

          <Button
            data-test-id='Prompter.Button.Save'
            disabled={!values.name.trim() || !values.url.trim()}
            className='margin-top-4x'
            color='primary'
            onClick={save}
            size='sm'
          >
            <small>{t('Save')}</small>
          </Button>
        </fieldset>
      </div>

      {prompters.map(prompter => (
        <PrompterItem
          key={prompter.id}
          groups={groupsByPrompterId[prompter.id]}
          openEditModal={() => handleOpenModal(prompter, 'edit')}
          openRemoveModal={() => handleOpenModal(prompter, 'remove')}
          {...prompter}
        />
      ))}

      {openedModal === 'edit' && (
        <PrompterEditModal
          setOpenedModal={setOpenedModal}
          selectedPrompter={selectedPrompter}
          setSelectedPrompter={setSelectedPrompter}
        />
      )}

      {openedModal === 'remove' && (
        <PrompterRemoveModal
          setOpenedModal={setOpenedModal}
          selectedPrompter={selectedPrompter}
          setSelectedPrompter={setSelectedPrompter}
        />
      )}
    </div>
  );
}
