import { Button, useToggle } from '@just-ai/just-ui';
import React, { useCallback } from 'react';
import { t } from '../../../localization';

import { Spinner } from '@just-ai/just-ui/dist/Spinner';
import { useWSCallbacks } from '../operatorPlaceHelpers';
import { MoreMessagesFunction } from '@just-ai/aimychat-shared';

export const LoadMoreButton = ({ loadMoreMessages }: { loadMoreMessages: MoreMessagesFunction }) => {
  const [loading, setLoading, setNotLoading] = useToggle();
  const callbacks = useWSCallbacks({ success: () => setNotLoading() });
  const onClick = useCallback(() => {
    setLoading();
    loadMoreMessages(callbacks);
  }, [callbacks, loadMoreMessages, setLoading]);

  return (
    <div data-test-id='Chat.MessageType.loadMore' key='loadMore' className='Messages__element'>
      <Button
        style={{ position: 'relative', borderRadius: '2rem', overflow: 'hidden' }}
        size='sm'
        compact
        color='secondary'
        outline
        onClick={onClick}
        disabled={loading}
      >
        {loading && <Spinner size='1x' />}
        {t('OperatorPlace: Load More')}
      </Button>
    </div>
  );
};
