import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './ChatInfo.scss';
import { t } from '../../localization';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';
import { ChatHeaderMobile } from './chatComponents/ChatHeaderMobile';
import { AboutProps } from './Chat';
import { IconButton, TabValue, Tabs } from '@just-ai/just-ui';
import { AboutTab } from './chatInfoTabs/AboutTab';
import { PromptersTab } from './chatInfoTabs/PromptersTab';
import { useAppContext } from '../../AppContext';
import { useOperatorDataContext } from './OperatorDataContext';
import { OperatorChatStatus } from '@just-ai/aimychat-shared/dist/api/client/operator';

enum TABS {
  prompters = 'prompters',
  about = 'about',
}

export default function ChatInfo({ setShowAbout }: AboutProps) {
  const { isPrompterAccess, getPrompters, prompters } = useAppContext();
  const { chosenChat, groupsForTransfer: groups } = useOperatorDataContext();
  const isMobile = useIsMobile();
  const hideChatInfo = useCallback(() => setShowAbout && setShowAbout(false), [setShowAbout]);
  const [activeTab, setActiveTab] = useState<TABS>(TABS.about);

  const hasPrompter = useMemo(
    () =>
      Boolean(groups?.find(({ id }) => id === chosenChat?.operatorGroup.id)?.prompterIds?.length) &&
      chosenChat?.status === OperatorChatStatus.ACTIVE &&
      prompters.length,

    [chosenChat?.operatorGroup.id, chosenChat?.status, groups, prompters.length]
  );
  const tabs: TabValue[] = [
    { name: t('OperatorPlace:AboutClient:Header'), value: TABS.about, dataTestId: 'Tabs.AboutClient' },
    {
      name: t('OperatorPlace:Prompters:Header'),
      value: TABS.prompters,
      disabled: !hasPrompter,
      dataTestId: 'Tabs.Prompters',
    },
  ].filter(({ value }) => value === TABS.about || isPrompterAccess);
  const changeActiveTab = useCallback((value: string) => setActiveTab(value as TABS), []);

  useEffect(() => {
    if (activeTab === TABS.prompters && !hasPrompter) {
      changeActiveTab(TABS.about);
    }
  }, [activeTab, changeActiveTab, hasPrompter]);

  useEffect(() => {
    getPrompters();
  }, [getPrompters]);

  return (
    <>
      {isMobile && (
        <ChatHeaderMobile
          elements={{
            Left: () => <IconButton name='faChevronLeft' onClick={hideChatInfo} flat />,
            Middle: () => null,
          }}
        />
      )}

      <div className='flex-column gap-16 ChatInfo_container'>
        <Tabs tabs={tabs} activeTab={activeTab} onChange={changeActiveTab} />
        {activeTab === TABS.about && <AboutTab />}
        {activeTab === TABS.prompters && <PromptersTab />}
      </div>
    </>
  );
}
