import { Dispatch, memo, SetStateAction, useCallback } from 'react';
import { useOperatorDataContext } from '../OperatorDataContext';
import { OperatorChatStatus } from '@just-ai/aimychat-shared/dist/api/client/operator';

import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';
import { t } from '../../../localization';
import { Button, Icon } from '@just-ai/just-ui';
import { AboutProps } from '../Chat';
import { BanButton, FinishDialogButton, TransferButton, UnBanButton } from './Buttons';
import { FilterMessages } from './Buttons/FilterMessages';

export type ChatHeaderProps = { setShowMobileMenu?: Dispatch<SetStateAction<boolean>> } & AboutProps;

export const ChatHeaderButtons = memo(({ setShowAbout, setShowMobileMenu }: ChatHeaderProps) => {
  const isMobile = useIsMobile();
  const { chosenChat } = useOperatorDataContext();
  const isBlocked = !!chosenChat?.blockingInfo?.isBlocked;

  const showAboutInfo = useCallback(() => setShowAbout && setShowAbout(true), [setShowAbout]);

  if (isMobile) {
    return (
      <>
        <div className='ChatHeader_buttons__mobile'>
          <FilterMessages />
          <TransferButton />
          <FinishDialogButton />
          <Button
            data-test-id='Chat.Header.Info.About'
            className='ChatHeader_buttons__mobile__info'
            onClick={showAboutInfo}
            flat
          >
            <Icon name='farInfoCircle' />
            {isMobile && t('OperatorPlace:AboutClient:Header')}
          </Button>
          <BanButton />
          <UnBanButton />
        </div>
        <div className='Mobile_backdrop' onClick={() => setShowMobileMenu && setShowMobileMenu(false)}></div>
      </>
    );
  }

  return (
    <div className='flex-row ChatHeader_buttons'>
      <UnBanButton />
      {!isBlocked && chosenChat?.status === OperatorChatStatus.ACTIVE && (
        <>
          <FilterMessages />
          <div className='flex-row'>
            <TransferButton />
          </div>
          <BanButton />
          <FinishDialogButton />
        </>
      )}
    </div>
  );
});
