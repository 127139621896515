import React, { memo, useCallback, useMemo } from 'react';
import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle, Icon } from '@just-ai/just-ui';
import './index.scss';
import { AccountDataDto, useAppContext } from '../../AppContext';
import { useNavigate } from 'react-router-dom';

const AccountsSelector = () => {
  const { accountsList, changeAccount: changeAccountInContext, id, accountShortName } = useAppContext();
  const navigate = useNavigate();

  const changeAccount = useCallback(
    id => () => {
      navigate('/', { replace: true });
      changeAccountInContext(id);
    },
    [changeAccountInContext, navigate]
  );

  const name = useMemo(() => accountsList.find(account => account.id === id)?.name || accountShortName, [
    accountShortName,
    accountsList,
    id,
  ]);

  const ownAccounts = useMemo(() => accountsList?.filter(account => account.owner), [accountsList]);
  const notOwnAccounts = useMemo(() => accountsList?.filter(account => !account.owner), [accountsList]);

  return (
    <div className='Header_account' data-test-id='AccountSelector'>
      <DropdownButton direction='down'>
        <DropdownToggle color='primary' tag='div'>
          <div className='account-selector' data-test-id='AccountSelector.Toggle'>
            <div className='Header_organization'>{name}</div>
            <Icon name='faCaretDown' />
          </div>
        </DropdownToggle>
        <DropdownMenu className='account-selector-menu'>
          <div className='account-selector-menu-scroll'>
            {ownAccounts.map(account => (
              <AccountItem key={`dropdown_account_${account.id}`} account={account} changeAccount={changeAccount} />
            ))}
            {notOwnAccounts.length > 0 && <DropdownItem divider />}
            {notOwnAccounts.map(account => (
              <AccountItem key={`dropdown_account_${account.id}`} account={account} changeAccount={changeAccount} />
            ))}
          </div>
        </DropdownMenu>
      </DropdownButton>
    </div>
  );
};

const AccountItem = memo(
  ({ account, changeAccount }: { account: AccountDataDto; changeAccount: (id: number) => () => void }) => (
    <DropdownItem
      key={`dropdown_account_${account.id}`}
      onClick={changeAccount(account.id)}
      data-test-id={`AccountSelector.Group.${account.name}`}
    >
      {account.name}
    </DropdownItem>
  )
);

export default AccountsSelector;
