import { IconButton } from '@just-ai/just-ui';
import { Dispatch, memo, SetStateAction, useCallback, useEffect, useState } from 'react';
import { IncomingPrompterResponseDto, TextMessage } from '@just-ai/aimychat-shared/dist/api/client/websocket';
import { useOperatorDataContext } from '../OperatorDataContext';

type PrompterHistoryItem = {
  requestId: string;
  prompterRequest: string;
  prompterResponse?: IncomingPrompterResponseDto;
};
type PrompterHistory = Record<string, Record<string, PrompterHistoryItem[]>>;
type Props = {
  prompterId: number;
  requestId?: string;
  currentHistoryIndex?: number;
  setCurrentHistoryIndex: Dispatch<SetStateAction<number | undefined>>;
};

const PROMPTER_HISTORY = 'prompterHistory';
const PROMPTER_HISTORY_MAX = 10;

export const getPrompterHistory = () => JSON.parse(sessionStorage.getItem(PROMPTER_HISTORY) || '{}') as PrompterHistory;

export const PrompterHistory = memo(({ currentHistoryIndex, setCurrentHistoryIndex, requestId, prompterId }: Props) => {
  const {
    chosenChat,
    prompterRequest,
    onGetNewPrompterMessage,
    setPrompterRequest,
    prompterFullResponse,
    clearPrompterResponse,
  } = useOperatorDataContext();

  const [prompterHistory, setPrompterHistory] = useState<PrompterHistory>(getPrompterHistory());

  const updatePrompterHistory = useCallback(
    (requestId: string, prompterResponse: IncomingPrompterResponseDto) => {
      const answerLength = prompterResponse.answerOptions.length;

      const firstAnswer = answerLength ? prompterResponse.answerOptions[0] : null;
      if (!firstAnswer) {
        return;
      }
      const messages = firstAnswer.messages;

      const hasNoAnswer =
        answerLength === 1 &&
        messages.length === 1 &&
        messages[0].type === 'TEXT' &&
        !(messages[0] as TextMessage).text;

      if (!chosenChat || hasNoAnswer) {
        return;
      }

      const prompterHistory = getPrompterHistory();
      const items = prompterHistory[chosenChat.id]?.[prompterId] || [];
      const prompterResponseRequestIdIndex = items.map(({ requestId }) => requestId).indexOf(requestId);
      if (prompterResponseRequestIdIndex === -1) {
        const data = { requestId, prompterRequest, prompterResponse };
        items.push(data);
      }

      if (!prompterHistory[chosenChat.id]) {
        prompterHistory[chosenChat.id] = {};
      }

      prompterHistory[chosenChat.id][prompterId] =
        items.length > PROMPTER_HISTORY_MAX ? items.slice(1, PROMPTER_HISTORY_MAX + 1) : items;

      sessionStorage.setItem(PROMPTER_HISTORY, JSON.stringify(prompterHistory));
      setPrompterHistory(prompterHistory);
    },
    [chosenChat, prompterId, prompterRequest]
  );

  const updatePrompterByHistoryIndex = useCallback(
    (index: number, history: PrompterHistoryItem[]) => {
      const data = history[index];
      setCurrentHistoryIndex(index);

      if (data) {
        setPrompterRequest(data.prompterRequest || '');
        onGetNewPrompterMessage({ prompterResponse: data.prompterResponse });
      }
    },
    [onGetNewPrompterMessage, setCurrentHistoryIndex, setPrompterRequest]
  );

  const previousPrompter = useCallback(() => {
    const history = chosenChat ? prompterHistory[chosenChat.id]?.[prompterId] : null;

    if (!history) {
      return;
    }
    const index =
      currentHistoryIndex === undefined ? history.length - 1 : currentHistoryIndex ? currentHistoryIndex - 1 : 0;

    updatePrompterByHistoryIndex(
      prompterRequest && history[index].requestId === requestId ? index - 1 : index,
      history
    );
  }, [
    chosenChat,
    currentHistoryIndex,
    prompterHistory,
    prompterId,
    prompterRequest,
    requestId,
    updatePrompterByHistoryIndex,
  ]);

  const nextPrompter = useCallback(() => {
    const history = chosenChat ? prompterHistory[chosenChat.id]?.[prompterId] : null;

    if (currentHistoryIndex === undefined || !history) {
      return;
    }
    if (currentHistoryIndex === history.length - 1) {
      setPrompterRequest('');
      setCurrentHistoryIndex(undefined);

      if (history[0].requestId.includes('start')) {
        clearPrompterResponse();
      }
    }

    const index = currentHistoryIndex < PROMPTER_HISTORY_MAX ? currentHistoryIndex + 1 : PROMPTER_HISTORY_MAX;
    updatePrompterByHistoryIndex(index, history);
  }, [
    chosenChat,
    clearPrompterResponse,
    currentHistoryIndex,
    prompterHistory,
    prompterId,
    setCurrentHistoryIndex,
    setPrompterRequest,
    updatePrompterByHistoryIndex,
  ]);

  useEffect(() => {
    if (prompterFullResponse?.prompterResponse && requestId && requestId === prompterFullResponse?.requestId) {
      updatePrompterHistory(prompterFullResponse?.requestId, prompterFullResponse.prompterResponse);
    }
  }, [
    updatePrompterHistory,
    requestId,
    prompterFullResponse?.requestId,
    prompterFullResponse?.prompterResponse,
    prompterRequest,
  ]);

  useEffect(() => {
    setCurrentHistoryIndex(undefined);
  }, [chosenChat, setCurrentHistoryIndex]);

  useEffect(() => {
    const clearHistory = () => sessionStorage.removeItem(PROMPTER_HISTORY);
    window.addEventListener('beforeunload', clearHistory);
    return window.removeEventListener('beforeunload', clearHistory);
  }, []);

  if (!chosenChat) {
    return null;
  }

  const historyLength = prompterHistory[chosenChat.id]?.[prompterId]?.length || 0;

  return (
    <div className='PromptersPagination'>
      <IconButton
        onClick={previousPrompter}
        className='margin-right-4x'
        name='farChevronLeft'
        size='sm'
        disabled={currentHistoryIndex === 0 || (historyLength === 1 && !!prompterRequest) || !historyLength}
      />
      <IconButton
        onClick={nextPrompter}
        name='farChevronRight'
        size='sm'
        disabled={
          currentHistoryIndex === undefined ||
          (currentHistoryIndex || 0) >= PROMPTER_HISTORY_MAX ||
          (historyLength === 1 && currentHistoryIndex !== 0) ||
          historyLength <= currentHistoryIndex
        }
      />
    </div>
  );
});
