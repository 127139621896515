export const statisticsLocalization = {
  'Statistics:main header': {
    eng: 'Statistics',
    ru: 'Статистика',
    pt: 'Estatísticas',
  },
  'Statistics: tab groups': {
    eng: 'Groups',
    ru: 'Группы',
    pt: 'Grupos',
  },
  'Statistics: tab main': {
    eng: 'Overall',
    ru: 'Общая',
    pt: 'Geral',
  },
  'Statistics: tab operators': {
    eng: 'Agents',
    ru: 'Операторы',
    pt: 'Agentes',
  },
  'Statistics: intervals day': {
    eng: 'Daily',
    ru: 'По дням',
    pt: 'Diário',
  },
  'Statistics: intervals week': {
    eng: 'Weekly',
    ru: 'По неделям',
    pt: 'Semanal',
  },
  'Statistics: intervals month': {
    eng: 'Monthly',
    ru: 'По месяцам',
    pt: 'Mensal',
  },
  'Statistics: charts CHATS_COUNT': {
    eng: 'Chats with agents',
    ru: 'Диалоги операторов',
    pt: 'Chat com agentes',
  },
  'Statistics: charts AVERAGE_FIRST_MESSAGE_TIME': {
    eng: 'Time to first message',
    ru: 'Время до первого сообщения',
    pt: 'Tempo para a primeira mensagem',
  },
  'Statistics: charts AVERAGE_DURATION': {
    eng: 'Chat duration',
    ru: 'Продолжительность диалога',
    pt: 'Duração do chat',
  },
  'Statistics: charts AVERAGE_CONNECT_TIME': {
    eng: 'Time to join the chat',
    ru: 'Время подключения оператора',
    pt: 'Tempo até entrar no chat',
  },
  'Statistics: summary total': {
    eng: 'Total chats',
    ru: 'Всего диалогов',
    pt: 'Total de chats',
  },
  'Statistics: summary answered': {
    eng: 'Answered',
    ru: 'Отвеченные',
    pt: 'Atendida',
  },
  'Statistics: summary notAnswered': {
    eng: 'Unanswered',
    ru: 'Неотвеченные',
    pt: 'Sem resposta',
  },
  'Statistics: summary connectDelay': {
    eng: 'Time to join the chat',
    ru: 'Время подключения оператора',
    pt: 'Tempo até entrar no chat',
  },
  'Statistics: summary firstAnswerDelay': {
    eng: 'Time to first message',
    ru: 'Время до первого сообщения',
    pt: 'Tempo para a primeira mensagem',
  },
  'Statistics: summary duration': {
    eng: 'Chat duration',
    ru: 'Продолжительность диалога',
    pt: 'Duração do chat',
  },
  'Statistics: tooltip main total': {
    eng: 'Total number of chats transferred from the script.',
    ru: 'Общее количество диалогов, переведенных из сценария.',
    pt: 'Número total de chats transferidos do script.',
  },
  'Statistics: tooltip main answered': {
    eng: 'Number of chats with at least one message from an agent.',
    ru: 'Количество диалогов, где оператор оставил хотя бы одно сообщение.',
    pt: 'Número de chats com pelo menos uma mensagem de um agente.',
  },
  'Statistics: tooltip main notAnswered': {
    eng: 'Number of requests without any response.',
    ru: 'Количество обращений, которые остались без ответа.',
    pt: 'Número de solicitações sem nenhuma resposta.',
  },
  'Statistics: tooltip main firstAnswerDelay': {
    eng: 'Average time from the moment a chat is transferred from the script to the first message from an agent.',
    ru: 'Среднее время от перевода диалога из сценария до первого сообщения оператора.',
    pt: 'Tempo médio desde o momento em que um chat é transferido do script até a primeira mensagem de um agente.',
  },
  'Statistics: tooltip main duration': {
    eng: 'Average time from the moment a chat is transferred from the script until it is finished.',
    ru: 'Среднее время от перевода диалога из сценария до завершения диалога.',
    pt: 'Tempo médio desde o momento em que um chat é transferido do script até seu término.',
  },
  'Statistics: tooltip groups total': {
    eng: 'Total number of chats transferred to the group. If a chat is transferred to the group several times, only the first transfer is counted.',
    ru: 'Общее количество диалогов, переведенных на группу. Если диалог был переведен на группу несколько раз, учитывается только первый перевод.',
    pt: 'Número total de chats transferidos para o grupo. Se um chat for transferido várias vezes para o grupo, apenas a primeira transferência será contabilizada.',
  },
  'Statistics: tooltip groups answered': {
    eng: 'Number of chats with at least one message sent by an agent from the group. If a chat is transferred to the group several times, only the message after the first transfer is counted.',
    ru: 'Количество диалогов, где оператор группы оставил хотя бы одно сообщение. Если диалог был переведен на группу несколько раз, учитывается наличие ответа только после первого перевода.',
    pt: 'Número de chats com pelo menos uma mensagem enviada por um agente do grupo. Se um chat for transferido várias vezes para o grupo, apenas a mensagem após a primeira transferência é contabilizada.',
  },
  'Statistics: tooltip groups notAnswered': {
    eng: 'Number of requests without any response. If a chat is transferred to the group several times, only the first transfer is considered: whether there is a response from an agent or not.',
    ru: 'Количество обращений, которые остались без ответа. Если диалог был переведен на группу несколько раз, учитывается наличие ответа только после первого перевода.',
    pt: 'Número de solicitações sem nenhuma resposta. Se um chat for transferido várias vezes para o grupo, apenas a primeira transferência é considerada: se houver resposta de um agente ou não.',
  },
  'Statistics: tooltip groups connectDelay': {
    eng: 'Average time from the moment a chat is transferred to the group until an agent joins the chat.',
    ru: 'Среднее время от перевода диалога на группу до подключения оператора.',
    pt: 'Tempo médio desde o momento em que um chat é transferido para o grupo até que um agente entre no chat.',
  },
  'Statistics: tooltip groups firstAnswerDelay': {
    eng: 'Average time from the moment an agent joins a chat until they send the first message.',
    ru: 'Среднее время от подключения оператора к диалогу до первого сообщения.',
    pt: 'Tempo médio desde o momento em que um agente entra em um chat até enviar a primeira mensagem.',
  },
  'Statistics: tooltip groups duration': {
    eng: 'Average chat duration from the moment a chat is transferred to the group until it is finished or transferred again. If a chat is transferred to the group several times, the chat duration after each transfer is considered.',
    ru: 'Средняя продолжительность диалога от его перевода на группу до завершения или последующего перевода. Если диалог был переведен на группу несколько раз, учитывается продолжительность диалога после каждого перевода.',
    pt: 'Duração média do chat desde o momento em que ele é transferido para o grupo até que seja finalizado ou transferido novamente. Se um chat for transferido várias vezes para o grupo, a duração do chat após cada transferência será considerada.',
  },
  'Statistics: tooltip operators total': {
    eng: 'Total number of times the agent has joined chats. Each time the agent joins chats is counted, even if they join the same chat several times.',
    ru: 'Общее количество подключений оператора к диалогам. Если оператор подключался к одному диалогу несколько раз, учитывается каждое подключение.',
    pt: 'Número total de vezes que o agente entrou em chats. Cada vez que o agente entra no chat é contabilizado, mesmo que ele entre no mesmo chat várias vezes.',
  },
  'Statistics: tooltip operators answered': {
    eng: 'Number of chats with at least one message from the agent. If the agent has joined the same chat several times, a response after each time the agent has joined the chat is considered.',
    ru: 'Количество диалогов, где оператор оставил хотя бы одно сообщение. Если оператор подключался к одному диалогу несколько раз, учитывается наличие ответа после каждого подключения.',
    pt: 'Número de chats com pelo menos uma mensagem do agente. Se o agente entrou no mesmo chat várias vezes, uma resposta após cada vez que o agente entrou nele é considerada.',
  },
  'Statistics: tooltip operators notAnswered': {
    eng: 'Number of requests without any response. If the agent has joined the same chat several times, a response after each time the agent has joined the chat is considered.',
    ru: 'Количество обращений, которые остались без ответа. Если оператор подключался к одному диалогу несколько раз, учитывается наличие ответа после каждого подключения.',
    pt: 'Número de solicitações sem nenhuma resposta. Se o agente entrou no mesmo chat várias vezes, uma resposta após cada vez que o agente entrou nele é considerada.',
  },
  'Statistics: tooltip operators connectDelay': {
    eng: 'Average time from the moment a chat is transferred to the group until the agent joins the chat.',
    ru: 'Среднее время от перевода диалога на группу до подключения оператора.',
    pt: 'Tempo médio desde o momento em que um chat é transferido para o grupo até o agente entrar no chat.',
  },
  'Statistics: tooltip operators firstAnswerDelay': {
    eng: 'Average time from the moment the agent joins a chat until they send the first message.',
    ru: 'Среднее время от подключения оператора к диалогу до первого сообщения.',
    pt: 'Tempo médio desde o momento em que o agente entra em um chat até enviar a primeira mensagem.',
  },
  'Statistics: tooltip operators duration': {
    eng: 'Average chat duration from the moment the agent joins a chat until it is finished or transferred again.',
    ru: 'Средняя продолжительность диалога от подключения оператора до завершения диалога или его перевода.',
    pt: 'Duração média do chat desde o momento em que o agente entra em um chat até que ele seja encerrado ou transferido novamente.',
  },
  'Statistics: title main': {
    eng: 'Overall statistics on chats transferred to agents from the script. Only finished chats are used for calculating the metrics.',
    ru: 'Общая статистика по диалогам, которые были переведены на операторов из сценария. При расчете показателей учитываются только завершенные диалоги.',
    pt: 'Estatísticas gerais sobre chats transferidos para agentes do script. Somente chats concluídos são usados para calcular as métricas.',
  },
  'Statistics: title groups': {
    eng: 'Overall statistics on chats with agents from a specific group. Only finished chats are used for calculating the metrics.',
    ru: 'Общая статистика по диалогам с операторами выбранной группы. При расчете показателей учитываются только завершенные диалоги.',
    pt: 'Estatísticas gerais sobre chats com agentes de um grupo específico. Somente chats concluídos são usados para calcular as métricas.',
  },
  'Statistics: title operators': {
    eng: 'Statistics on chats with a specific agent. Only finished chats are used for calculating the metrics.',
    ru: 'Статистика по диалогам с выбранным оператором. При расчете показателей учитываются только завершенные диалоги.',
    pt: 'Estatísticas de chats com um agente específico. Somente chats concluídos são usados para calcular as métricas.',
  },
  'Statistics: theme not specified': {
    eng: 'No theme specified',
    ru: 'Тема не указана',
    pt: 'Nenhum tema especificado',
  },
  'Statistics:RequestSubjects': {
    eng: 'Request subjects',
    ru: 'Темы обращений',
    pt: 'Assuntos de solicitação',
  },
  'Statistics:RequestSubjects:Description': {
    eng: 'Number of requests according to the subjects. You can specify subjects in the bot script in $[1].',
    ru: 'Темы и количество обращений по ним. Вы можете указать темы в сценарии бота в $[1].',
    pt: 'Número de solicitações de acordo com os assuntos. Você pode especificar assuntos no script do bot em $[1].',
  },
};
