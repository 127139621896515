import React, { useCallback, useState } from 'react';
import { JustSelect, Modal, useToggle } from '@just-ai/just-ui';
import { LabelComponentType } from '@just-ai/just-ui/dist/JustSelect/JustSelectItem';
import { t } from '../../localization';
import { OFLINE_ACTIONS } from '@just-ai/aimychat-shared/dist/operatorPlaceTypes';
import { useOperatorDataContext } from '../OperatorPlace/OperatorDataContext';
import { useWSCallbacks } from '../OperatorPlace/operatorPlaceHelpers';

type Props = {
  onClose: () => void;
  callback?: () => void;
};

export const SetOfflineModal = React.memo(({ onClose, callback }: Props) => {
  const { setOnlineStatusOperator } = useOperatorDataContext();
  const [action, setAction] = useState<OFLINE_ACTIONS>(OFLINE_ACTIONS.IN_QUEUE);
  const [fetching, setFeching, finishFetching] = useToggle();
  const callbacks = useWSCallbacks({
    success: () => {
      finishFetching();
      onClose();
      callback && callback();
    },
  });
  const setOffline = useCallback(() => {
    setFeching();
    setOnlineStatusOperator({ isOnline: false, action, callbacks });
  }, [action, callbacks, setFeching, setOnlineStatusOperator]);

  return (
    <Modal
      onCancelClick={fetching ? undefined : onClose}
      buttonCancelColor='secondary'
      onActionClick={setOffline}
      title={t('Header: set offline modal title')}
      buttonSubmitText={t('Confirm')}
      buttonCancelText={t('Cancel')}
      buttonSubmitColor='primary'
      buttonCancelTestId='Header.SetOfflineModal.cancel'
      buttonSubmitTestId='Header.SetOfflineModal.submit'
      naturalScrollbar
      inProgress={fetching}
      disableActionButtonAutoFocus
      isOpen
    >
      <fieldset className='margin-bottom-2x'>
        <label className='margin-bottom-6x'>{t('Header: set offline description')}</label>
        <JustSelect
          className='OfflineModal'
          fullWidth
          position='fixed'
          onChange={value => value && value[0] && setAction(value[0] as OFLINE_ACTIONS)}
          value={action}
          optionComponent={LabelComponent}
          options={Object.values(OFLINE_ACTIONS).map(action => ({
            value: action,
            label: t(`Header: set offline option label ${action}`),
          }))}
        />
      </fieldset>
    </Modal>
  );
});

const LabelComponent: LabelComponentType = ({ value, label }) => {
  return (
    <div className='flex-column'>
      <span>{t(`Header: set offline option label ${value}`)}</span>
      <span>{t(`Header: set offline option ${value} description`)}</span>
    </div>
  );
};
