import { t } from '../../localization';
import { BlockingBanner } from '../BlockingBanner/BlockingBanner';
import { useAppContext } from '../../AppContext';
import { useOperatorDataContext } from '../../modules/OperatorPlace/OperatorDataContext';
import { useLocation } from 'react-router-dom';
import { getPluralType } from '@just-ai/aimychat-shared/dist/utils';
import { AgentLimits } from '@just-ai/aimychat-shared/dist/api/client/accountadmin';

export function LimitExceededBanner() {
  const { pathname } = useLocation();
  const { isAdmin, operatorPlace, groups, operators, agentLimits = {}, productInfo, isUnlimited } = useAppContext();
  const { reconnectingFailed, reconnecting } = useOperatorDataContext();
  const { blockType, blocked, maxOperatorGroups = 0, maxOperators = 0 } = (agentLimits || {}) as AgentLimits;
  const { isBlockedByTariff } = operatorPlace || {};
  const isGroupsLimit = groups.length > maxOperatorGroups;
  const isOperatorsLimit = operators.length > maxOperators;

  if (reconnectingFailed || reconnecting || !agentLimits || isUnlimited) {
    return null;
  }

  if (blocked && blockType === 'NOT_PAYED') {
    return (
      <BlockingBanner
        title={t(
          `OperatorPlace:Account:Blocked:${isAdmin ? 'Admin' : 'Agent'}${
            productInfo.euroInstance && isAdmin ? ':Tovie' : ''
          }`
        )}
      />
    );
  }

  if (!isAdmin && isBlockedByTariff) {
    return <BlockingBanner title={t('OperatorPlace:Limit:Agent')} />;
  }

  if (!pathname.startsWith('/operator') || (!isOperatorsLimit && !isGroupsLimit && !isBlockedByTariff)) {
    return null;
  }

  if (isGroupsLimit && !isOperatorsLimit) {
    return (
      <BlockingBanner
        title={t(
          'OperatorPlace:Groups:Limit:Admin',
          t(`Plural:Groups:${getPluralType(groups.length)}`, groups.length),
          maxOperatorGroups
        )}
      />
    );
  }

  if (isOperatorsLimit && !isGroupsLimit) {
    return (
      <BlockingBanner
        title={t(
          'OperatorPlace:Users:Limit:Admin',
          t(`Plural:Users:${getPluralType(operators.length)}`, operators.length),
          maxOperators
        )}
      />
    );
  }

  return (
    <BlockingBanner
      title={t('OperatorPlace:All:Limit:Admin', groups.length, operators.length, maxOperatorGroups, maxOperators)}
    />
  );
}
