import { useAppContext } from '../../AppContext';
import './NoAccessPage.scss';
import { t } from '../../localization';
import { useMemo } from 'react';
import cn from 'classnames';

export default function NoAccessPage({ hasAccessAimychat = true }) {
  const { id, account, name, role = 'operator', accountsList, accountShortName, config, productInfo } = useAppContext();
  const accountName = useMemo(
    () => accountsList.find(account => account.id === id)?.name || accountShortName,
    [accountShortName, accountsList, id]
  );

  const isAimyChatEnabledByLicense = useMemo(() => config.systemFeatures?.livechat_licensed, [config]);
  const email = account?.owner?.email;
  const noAccessDescriptionText = productInfo.euroInstance
    ? 'NoAccessPage:Description:Tovie'
    : 'NoAccessPage: description';

  return (
    <>
      <div className='NoAccessPage_container'>
        <div className='NoAccessPage_centerPart'>
          <h2 className={cn('font-size-24', { 'mb-5': !isAimyChatEnabledByLicense })}>
            {t(isAimyChatEnabledByLicense ? 'NoAccessPage: title' : 'NoAccessPage:Title:NoLicense')}
          </h2>

          {hasAccessAimychat ? (
            <>
              <div className='NoAccessPage_userInfo'>
                <span className='font-size-14'>{name}</span>
                <span>{email}</span>
                <span className='font-size-14'>{t(`NoAccessPage: role ${role.toLocaleLowerCase()}`)}</span>
              </div>
              <div>{t('NoAccessPage: description noAdmin')}</div>
            </>
          ) : (
            <div className='NoAccessPage_container_description'>
              {isAimyChatEnabledByLicense && (
                <div className='NoAccessPage_userInfo NoAccessPage_userInfo__organization'>{accountName}</div>
              )}
              {t(isAimyChatEnabledByLicense ? noAccessDescriptionText : 'NoAccessPage:Description:NoLicense')}
              {isAimyChatEnabledByLicense && (
                <div>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
