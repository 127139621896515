import React, { useCallback } from 'react';
import './Header.scss';
import { Logo } from '../../components/Logo';
import { useAppContext } from '../../AppContext';
import { IconButton, useToggle, SwitchButton, Modal } from '@just-ai/just-ui';
import { t } from '../../localization';
import CloudMenuWrap from '../../components/CloudMenu';
import User from '../../components/User/User';
import HelpMenu from '../../components/HelpMenu/HelpMenu';
import { useOperatorDataContext } from '../OperatorPlace/OperatorDataContext';
import { SetOfflineModal } from './SetOfflineModal';
import { HeaderMenu } from './HeaderMenu';
import { InitOflineModal } from './InitOflineModal';
import { NavLink } from 'react-router-dom';
import AccountsSelector from '../../components/AccountsSelector';
import { LocaleSwitcher } from './LocaleSwitcher';

export function Header({ hasNoAccess = false }) {
  const { name, email, logoutHandler, isAdmin, operatorPlace, agentLimits, isUnlimited, isIframeEnabled } =
    useAppContext();
  const { active, setOnlineStatusOperator, isOnline, wasDisconnectFromServer } = useOperatorDataContext();
  const [offlineModal, showOfflineModal, hideOfflineModal] = useToggle();
  const [menuOpened, , , toggleMenu] = useToggle();
  const { isBlockedByTariff } = operatorPlace || {};

  const onToggleOnline = useCallback(async () => {
    if (!isUnlimited && agentLimits && (hasNoAccess || isBlockedByTariff)) {
      return;
    }
    if (isOnline === false) {
      setOnlineStatusOperator({ isOnline: true });
    } else if (active.length > 0) {
      showOfflineModal();
    } else {
      setOnlineStatusOperator({ isOnline: false });
    }
  }, [
    isUnlimited,
    agentLimits,
    hasNoAccess,
    isBlockedByTariff,
    isOnline,
    active.length,
    setOnlineStatusOperator,
    showOfflineModal,
  ]);

  return (
    <header className='Header'>
      <div className='Header_container'>
        <div>
          <NavLink to='/operator'>
            <Logo />
          </NavLink>
          <AccountsSelector />
        </div>

        <div className='Header_container_controls'>
          <LocaleSwitcher />
          <div className='Header_onlineStatus'>
            <SwitchButton
              size='sm'
              labelPosition='right'
              onChange={onToggleOnline}
              offLabel={t('Offline status')}
              onLabel={t('Online status')}
              id='ToggleOnline'
              value={hasNoAccess || (operatorPlace?.isBlockedByTariff && agentLimits) ? false : isOnline}
            />
          </div>
          <div className='Header_help'>
            <HelpMenu />
          </div>
          <div className='Header_name'>
            <User email={email} name={name} />
          </div>
          <div className='Header_cc'>
            <CloudMenuWrap />
          </div>
          <div className='Header_burger'>
            <IconButton onClick={toggleMenu} flat withoutPadding name={menuOpened ? 'farTimes' : 'farBars'} />
          </div>
        </div>
      </div>
      <HeaderMenu
        isShown={menuOpened}
        name={name}
        email={email}
        onLogout={logoutHandler}
        isAdmin={isAdmin}
        hasNoAccess={hasNoAccess}
        showOfflineModal={showOfflineModal}
        isIframeEnabled={isIframeEnabled}
      />
      {offlineModal && <SetOfflineModal onClose={hideOfflineModal} />}
      {wasDisconnectFromServer && (
        <Modal
          title={t('Header: disconnect modal title')}
          buttonSubmitText={t('Header: disconnect modal button')}
          buttonSubmitColor='primary'
          onActionClick={() => window.location.reload()}
          centered
          isOpen
        >
          <span
            style={{ textDecoration: 'none' }}
            dangerouslySetInnerHTML={{
              __html: t('Header: disconnect modal description'),
            }}
          ></span>
        </Modal>
      )}
      <InitOflineModal onToggleOnline={onToggleOnline} />
    </header>
  );
}
