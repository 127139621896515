import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../../../AppContext';
import { t } from '../../../../localization';
import LimitsTitle from './LimitsTitle';

export const Limits = memo(() => {
  const { agentLimits, isLts, isUnlimited } = useAppContext();
  const location = useLocation();
  const isGroups = location.pathname.includes('groups');

  if (!agentLimits || isLts || isUnlimited) return null;

  return (
    <div className='Limits_wrapper'>
      <p>
        <LimitsTitle isGroups={isGroups} />
      </p>
      <p>
        <small
          dangerouslySetInnerHTML={{
            __html: `<small>${t(isGroups ? t('Settings:Groups:Upgrade') : t('Settings:Users:Upgrade'))}</small>`,
          }}
        />
      </p>
    </div>
  );
});
