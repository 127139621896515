import { MessageFilterKey } from '@just-ai/aimychat-shared';
import { DropdownButton, DropdownMenu, DropdownToggle, Icon, SwitchButton } from '@just-ai/just-ui';
import { memo, useCallback, useMemo, useState } from 'react';
import { t } from '../../../../localization';
import { useOperatorDataContext, USER_CHAT_FILTERS } from '../../OperatorDataContext';

type Filters = Record<MessageFilterKey, boolean>;

const initialFilters = Object.keys(MessageFilterKey).reduce((acc, current) => {
  acc[current] = true;
  return acc;
}, {} as Filters);

export const FilterMessages = memo(() => {
  const { chosenChatId, getNewMessages } = useOperatorDataContext();
  const [filters, setFilters] = useState<Record<string, MessageFilterKey[]>>(
    JSON.parse(sessionStorage.getItem(USER_CHAT_FILTERS) || '{}')
  );

  const filtersForChat: Filters = useMemo(() => {
    if (!chosenChatId || !filters[chosenChatId]) {
      return initialFilters;
    }
    return filters[chosenChatId].reduce((acc, current) => {
      acc[current] = true;
      return acc;
    }, {} as Filters);
  }, [chosenChatId, filters]);

  const filterIcon = Object.keys(filtersForChat).length === 3 ? 'farFilter' : 'faFilter';

  const changeFilter = useCallback(
    (value: boolean, filter: MessageFilterKey) => {
      if (!chosenChatId) {
        return;
      }

      if (!value) {
        delete filtersForChat[filter];
      } else {
        filtersForChat[filter] = true;
      }

      const newFilters = { ...filters, [chosenChatId]: Object.keys(filtersForChat) as MessageFilterKey[] };

      sessionStorage.setItem(USER_CHAT_FILTERS, JSON.stringify(newFilters));
      setFilters(newFilters);
      getNewMessages();
    },
    [chosenChatId, filters, filtersForChat]
  );

  if (!chosenChatId) {
    return null;
  }

  return (
    <DropdownButton className='FilterDropdown' data-test-id='dropdown-wrapper' direction='down' size='sm'>
      <DropdownToggle className='FilterDropdownButton' data-test-id='dropdown-toggle'>
        <Icon name={filterIcon} className='margin-right-2x' size='sm' />
      </DropdownToggle>
      <DropdownMenu className='FilterDropdownMenu' data-test-id='dropdown-menu'>
        <p className='margin-bottom-2x'>
          <small>{t('OperationPlace:Filters:Show')}:</small>
        </p>

        {Object.keys(MessageFilterKey).map(filter => (
          <SwitchButton
            key={filter}
            id={filter}
            data-test-id={`filter-messages-${filter}`}
            label={t(`OperationPlace:Filters:${filter.toUpperCase()}`)}
            labelPosition='right'
            onChange={value => changeFilter(value, filter as MessageFilterKey)}
            size='sm'
            className='FilterRadio'
            value={filtersForChat[filter]}
          />
        ))}
      </DropdownMenu>
    </DropdownButton>
  );
});
