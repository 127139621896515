export const channelsLocalization = {
  'ChannelType YANDEX': {
    eng: 'Yandex Alice',
    ru: 'Алиса',
    pt: 'Yandex Alice',
  },
  'ChannelType NO_CHANNELS': {
    eng: 'Without channels',
    ru: 'Без каналов',
    pt: 'Sem canais',
  },
  'ChannelType INSTAGRAM': {
    eng: 'Instagram',
    ru: 'Instagram',
    pt: 'Instagram',
  },
  'ChannelType CHAT_WIDGET': {
    eng: 'Chat widget',
    ru: 'Чат-виджет',
    pt: 'Widget de chat',
    cn: '聊天小部件!',
  },
  'ChannelType CHATWIDGET': {
    eng: 'Chat widget',
    ru: 'Чат-виджет',
    pt: 'Widget de chat',
    cn: '聊天小部件!',
  },
  'ChannelType GOOGLE': {
    eng: 'Google Assistant',
    ru: 'Google Ассистент',
    pt: 'Google Assistente',
    cn: 'google助手',
  },
  'ChannelType TELEGRAM': {
    eng: 'Telegram',
    ru: 'Телеграм',
    pt: 'Telegram',
    cn: '电报',
  },
  'ChannelType ZENBOX': {
    eng: 'Aimybox',
    ru: 'Aimybox',
    pt: 'Aimybox',
  },
  'ChannelType JAICP_ALEXA': {
    eng: 'Alexa',
    ru: 'Alexa',
    pt: 'Alexa',
  },
  'ChannelType DIALOGFLOW': {
    eng: 'Dialogflow',
    ru: 'Dialogflow',
    pt: 'Dialogflow',
  },
  'ChannelType CHAT_API': {
    eng: 'Chat API',
    ru: 'Chat API',
    pt: 'Chat API',
    cn: '聊天API',
  },
  'ChannelType CHATAPI': {
    eng: 'Chat API',
    ru: 'Chat API',
    pt: 'Chat API',
    cn: '聊天API',
  },
  'ChannelType VK': {
    eng: 'VK',
    ru: 'ВКонтакте',
    pt: 'VK',
  },
  'ChannelType FACEBOOK': {
    eng: 'Facebook',
    ru: 'Facebook',
    pt: 'Facebook',
  },
  'ChannelType VIBER': {
    eng: 'Viber',
    ru: 'Viber',
    pt: 'Viber',
  },
  'ChannelType RESTERISK': {
    eng: 'Telephony',
    ru: 'Телефония',
    pt: 'Telefonia',
    cn: '电话',
  },
  'ChannelType SLACK': {
    eng: 'Slack',
    ru: 'Slack',
    pt: 'Slack',
  },
  'ChannelType LIVETEX_PROMPTER': {
    eng: 'LiveTex',
    ru: 'LiveTex',
    pt: 'LiveTex',
  },
  'ChannelType WECHAT': {
    eng: 'WeChat',
    ru: 'WeChat',
    pt: 'WeChat',
    cn: '微信',
  },
  'ChannelType ZENDESK': {
    eng: 'ZenDesk',
    ru: 'ZenDesk',
    pt: 'ZenDesk',
  },
  'ChannelType ZOPIM': {
    eng: 'ZenDesk Chat',
    ru: 'ZenDesk Chat',
    pt: 'ZenDesk Chat',
  },
  'ChannelType WEBIM': {
    eng: 'Webim (Custom Channel API)',
    ru: 'Webim (Custom Channel API)',
    pt: 'Webim (Custom Channel API)',
  },
  'ChannelType INCOMING_WEBIM2': {
    eng: 'Webim (External Bot API 2.0)',
    ru: 'Webim (External Bot API 2.0)',
    pt: 'Webim (External Bot API 2.0)',
  },
  'ChannelType NEXMO': {
    eng: 'Vonage',
    ru: 'Vonage',
    pt: 'Vonage',
  },
  'ChannelType THREADS': {
    eng: 'edna.chatCenter',
    ru: 'edna.chatCenter',
    pt: 'edna.chatCenter',
  },
  'ChannelType I_DIGITAL': {
    eng: 'i-Digital WhatsApp',
    ru: 'i-Digital WhatsApp',
    pt: 'i-Digital WhatsApp',
  },
  'ChannelType INCOMING_JIVOSITE': {
    eng: 'JivoChat',
    ru: 'Jivo',
    pt: 'JivoChat',
  },
  'ChannelType AZURE': {
    eng: 'Azure Bot',
    ru: 'Azure Bot',
    pt: 'Azure Bot',
  },
  'ChannelType MARUSIA': {
    eng: 'Marusia',
    ru: 'Маруся',
    pt: 'Marusia',
  },
  'ChannelType WAZZUP': {
    eng: 'Wazzup',
    ru: 'Wazzup',
    pt: 'Wazzup',
  },
  'ChannelType WHATSAPP': {
    eng: 'WhatsApp',
    ru: 'WhatsApp',
  },
  'ChannelType BITRIX': {
    eng: 'Bitrix24',
    ru: 'Битрикс24',
    pt: 'Bitrix24',
  },
  'ChannelType ODNOKLASSNIKI': {
    eng: 'Odnoklassniki',
    ru: 'Одноклассники',
    pt: 'Odnoklassniki',
  },
  'ChannelType SALESFORCE': {
    eng: 'Salesforce',
    ru: 'Salesforce',
    pt: 'Salesforce',
  },
  'ChannelType JIVOSITE': {
    eng: 'JivoChat',
    ru: 'Jivo',
    pt: 'JivoChat',
  },
  'ChannelType AIMYCHAT': {
    eng: 'Aimychat',
    ru: 'Aimychat',
    pt: 'Aimychat',
  },
  'ChannelType TOVIE AGENT': {
    eng: 'Tovie Agent',
    ru: 'Tovie Agent',
    pt: 'Tovie Agent',
  },
  'ChannelType OPERATORAPI': {
    eng: 'Operator API',
    ru: 'Operator API',
    pt: 'Operator API',
  },
  'ChannelType SBER': {
    eng: 'Sber Salut',
    ru: 'Сбер Салют',
    pt: 'Sber Salut',
  },
  'ChannelType LIVETEX': {
    eng: 'LiveTex',
    ru: 'LiveTex',
    pt: 'LiveTex',
  },
  'ChannelType INBOUND_LIVETEX': {
    eng: 'LiveTex',
    ru: 'LiveTex',
    pt: 'LiveTex',
  },
  'ChannelType CHAT2DESK': {
    eng: 'Chat2Desk',
    ru: 'Chat2Desk',
    pt: 'Chat2Desk',
  },
  'ChannelType EDNA_PLATFORM': {
    eng: 'edna WhatsApp',
    ru: 'edna WhatsApp',
    pt: 'edna WhatsApp',
  },
  'ChannelType resterisk': {
    eng: 'Telephony',
    ru: 'Телефония',
    pt: 'Telefonia',
    cn: '电话',
  },
};
