import React, { useState, useCallback, memo } from 'react';
import { t } from '../../../localization';
import { Button, useToggle } from '@just-ai/just-ui';
import './Groups.scss';
import { OperatorGroupFullDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import CreateGroupModal from '../components/Groups/GroupModal/CreateGroupModal';
import GroupsList from '../components/Groups/GroupsList';
import DeleteGroupModal from '../components/Groups/GroupModal/DeleteGroupModal';
import { Spinner } from '@just-ai/just-ui/dist/Spinner';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';
import { useAppContext } from '../../../AppContext';

export const Groups = memo(() => {
  const { id, groupsFetching, setGroups, groups, agentLimits, isUnlimited } = useAppContext();
  const [showCreateModal, setShowCreateModal, hideCreateModal] = useToggle(false);
  const isMobile = useIsMobile();
  const [groupToEdit, setGroupToEdit] = useState<OperatorGroupFullDto>();
  const [groupToDelete, setGroupToDelete] = useState<OperatorGroupFullDto>();

  const removeGroup = useCallback(
    (id: number) => setGroups(groups => groups.filter(group => group.id !== id)),
    [setGroups]
  );

  const setGroupToEditAndOpenModal = useCallback(
    (group: OperatorGroupFullDto) => {
      setGroupToEdit(group);
      setShowCreateModal();
    },
    [setGroupToEdit, setShowCreateModal]
  );
  const hideCreateModalAndClearEdit = useCallback(() => {
    setGroupToEdit(undefined);
    hideCreateModal();
  }, [setGroupToEdit, hideCreateModal]);

  const hideDeleteModal = useCallback(() => {
    setGroupToDelete(undefined);
  }, [setGroupToDelete]);
  return (
    <>
      <div className='Groups'>
        <p className='Group_description'>
          <small>{t('Settings:Groups: description')}</small>
        </p>
        <div className='Groups_createButton'>
          <Button
            color='primary'
            outline
            data-test-id='Groups.createGroupButton'
            size={isMobile ? 'sm' : 'md'}
            onClick={setShowCreateModal}
            disabled={!isUnlimited && groups.length >= (agentLimits?.maxOperatorGroups || 0) && !!agentLimits}
          >
            {t('Settings:Groups: create group button')}
          </Button>
        </div>
        {groupsFetching && <Spinner data-test-id='Groups.fetchGroups' size='4x' />}
        <GroupsList setGroupToEdit={setGroupToEditAndOpenModal} setGroupToDelete={setGroupToDelete} />
      </div>
      {showCreateModal && <CreateGroupModal groupToEdit={groupToEdit} onClose={hideCreateModalAndClearEdit} />}
      {groupToDelete && (
        <DeleteGroupModal
          groupToDelete={groupToDelete}
          onClose={hideDeleteModal}
          deleteGroup={removeGroup}
          accountId={id}
        />
      )}
    </>
  );
});

export default Groups;
