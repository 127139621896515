var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ClientsApi, SessionsApi, MessagesApi, AdvancedStatsApi, SimpleStatsApi, OverviewStatsApi, AnalyticsTokenApi, FiltersApi, StateRoutesApi, ReportApi, LabelsApi, } from '../api/client';
import { axios as localAxios } from '../utils/pipes/functions';
import globalAxios from 'axios';
export class ProjectStatsService {
    constructor(accountId, projectNam, axios = localAxios) {
        this.getOverviewCapacityTableXls = (dateFrom1, dateTo1, dateFrom2, dateTo2, showUrls, timezone, language, options, botIds) => __awaiter(this, void 0, void 0, function* () {
            const { data, headers } = yield this.overviewStats.getOverviewCapacityTableXlsx(this.accountId, dateFrom1, dateTo1, dateFrom2, dateTo2, this.projectShortName, botIds, showUrls, timezone, language, undefined, options);
            return { data, headers };
        });
        this.getSessionsWithNewFilter = (filters) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.advancedStats.getMessageStats(this.accountId, this.projectShortName, undefined, undefined, undefined, filters);
            return data;
        });
        this.createAnalyticsToken = (projectShortName) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.tokens.createAnalyticsToken(this.accountId, projectShortName || this.projectShortName);
            return data;
        });
        this.getAnalyticsTokenByProjectShortName = (projectShortName) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.tokens.getAnalyticsTokenByProjectShortName(this.accountId, projectShortName || this.projectShortName);
            return data;
        });
        this.deleteAnalyticsTokenByProjectShortName = (projectShortName) => __awaiter(this, void 0, void 0, function* () {
            yield this.tokens.deleteAnalyticsTokenByProjectShortName(this.accountId, projectShortName || this.projectShortName);
            return;
        });
        this.getMessageWithNewFilters = (filters) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.advancedStats.getMessageStats(this.accountId, this.projectShortName, undefined, undefined, undefined, filters);
            return data;
        });
        this.getFilterSetId = (filters) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.filtersApi.createFilterSetId(this.accountId, filters, this.projectShortName);
            return data;
        });
        this.getLogsByFilter2 = (page, size, filtersDto) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.messages.getMessageDataByFilter(this.accountId, this.projectShortName, page, size, filtersDto);
            return data;
        });
        this.getRoutesDataByFilter = (page, size, filtersDto) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.routes.getStateRoutesDataByFilter(this.accountId, this.projectShortName, page, size, filtersDto);
            return data;
        });
        this.getSessionsByFilter = (page, size, filtersDto) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.sessions.getSessionDataByFilter(this.accountId, this.projectShortName, page, size, undefined, filtersDto);
            return data;
        });
        this.getSessionWithNewFilters = (filters) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.advancedStats.getSessionStats(this.accountId, this.projectShortName, undefined, undefined, undefined, filters);
            return data;
        });
        this.getSessionLabelWithNewFilters = (filters) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.advancedStats.getSessionLabelStats(this.accountId, this.projectShortName, undefined, undefined, undefined, filters);
            return data;
        });
        this.getLogLabelWithNewFilters = (filters) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.advancedStats.getLogLabelStats(this.accountId, this.projectShortName, undefined, undefined, undefined, filters);
            return data;
        });
        this.getSwitchedSessionCountByDay = (dateFrom, dateTo, transferToOperatorMethod, botIds) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.simpleStats.getSwitchedSessionCountByDay(this.accountId, dateFrom, dateTo, transferToOperatorMethod, this.projectShortName, botIds);
            return data;
        });
        this.getOverviewClientCapacityByInterval = (dateFrom, dateTo, interval, botIds) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.overviewStats.getOverviewClientCapacityByInterval(this.accountId, dateFrom, dateTo, interval, this.projectShortName, botIds);
            return data;
        });
        this.getOverviewMessagesCapacityByInterval = (dateFrom, dateTo, interval, botIds) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.overviewStats.getOverviewMessagesCapacityByInterval(this.accountId, dateFrom, dateTo, interval, this.projectShortName, botIds);
            return data;
        });
        this.getOverviewDialogsCapacityByInterval = (dateFrom, dateTo, interval, botIds) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.overviewStats.getOverviewSessionsCapacityByInterval(this.accountId, dateFrom, dateTo, interval, this.projectShortName, botIds);
            return data;
        });
        this.getClientByFilter = (page, size, filtersDto, options) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.clients.getClientDataByFilter(this.accountId, this.projectShortName, page, size, filtersDto, options);
            return data;
        });
        this.createClientReport = (filtersDto, timezone) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.report.createClientReport(this.accountId, this.projectShortName, undefined, timezone, filtersDto);
            return data;
        });
        this.createSessionReport = (options) => __awaiter(this, void 0, void 0, function* () {
            const payload = yield this.report.createSessionReport(this.accountId, this.projectShortName, options.language, options.timezone, options.createReportDto, options.options);
            return payload;
        });
        this.getMessagePageInSession = (sessionId, questionId, botId) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.messages.getMessagePageInSession(this.accountId, sessionId, questionId, undefined, this.projectShortName, botId);
            return data;
        });
        this.getMessagesForSession = (sessionId, page, responseDataJsonPath, botId, startTime, hideEmptyMessages, options) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.messages.getMessagesForSession(this.accountId, sessionId, page, responseDataJsonPath, undefined, this.projectShortName, botId, startTime, hideEmptyMessages, options);
            return data;
        });
        this.getMessagesForClient = (botId, clientId, logCount, startTime, sort, page, channelType, options) => {
            return this.messages
                .getMessagesForClient(this.accountId, botId, clientId, page, logCount, this.projectShortName, startTime, undefined, sort, undefined, channelType, options)
                .then(({ data }) => data)
                .catch(reason => {
                if (!globalAxios.isCancel(reason))
                    return reason;
            });
        };
        this.blockClient = (clientId, botId, blockClientDto) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.clients.blockClient(this.accountId, botId, clientId, blockClientDto);
            return data;
        });
        this.unblockClient = (clientId, botId) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.clients.unblockClient(this.accountId, botId, clientId);
            return data;
        });
        this.getTelephonyReport = (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const payload = yield this.report.createTelephonyReport(this.accountId, this.projectShortName, options.startTime, options.endTime, options.botIds, options.language, options.timezone);
            return payload;
        });
        this.createMessageReport = (options) => __awaiter(this, void 0, void 0, function* () {
            const payload = yield this.report.createMessageReport(this.accountId, this.projectShortName, options.language, options.timezone, options.createReportDto, options.options);
            return payload;
        });
        this.createRouteReport = (options) => __awaiter(this, void 0, void 0, function* () {
            const payload = yield this.report.createStateRoutesReport(this.accountId, this.projectShortName, options.language, options.timezone, options.createReportDto, options.options);
            return payload;
        });
        this.deleteReportGenerationTask = (options) => __awaiter(this, void 0, void 0, function* () {
            const payload = yield this.report.deleteReportGenerationTask(this.accountId, options.reportGenerationTaskId, options.options);
            return payload;
        });
        this.deleteCompletedReportGenerationTasks = (options) => __awaiter(this, void 0, void 0, function* () {
            const payload = yield this.report.deleteCompletedReportGenerationTasks(this.accountId, options);
            return payload;
        });
        this.getSessionLabelsStats = (filters) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.advancedStats.getSessionLabelStats(this.accountId, this.projectShortName, undefined, undefined, undefined, filters);
            return data;
        });
        this.getTelephonyStats = (dateFrom, dateTo, botIds) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.simpleStats.getTelephonyStats(this.accountId, dateFrom, dateTo, this.projectShortName, botIds);
            return data;
        });
        this.getOverviewCapacityTable = (dateFromP1, dateToP1, dateFromP2, dateToP2, botIds) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.overviewStats.getOverviewCapacityTable(this.accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, this.projectShortName, botIds);
            return data;
        });
        this.getResultsGraphData = (dateFromP1, dateToP1, dateFromP2, dateToP2, botIds, locale, options) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.overviewStats.getSessionsResultByInterval(this.accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, this.projectShortName, botIds, undefined, locale, options);
            return data;
        });
        this.getSessionsResultTable = (dateFromP1, dateToP1, dateFromP2, dateToP2, botIds, locale, options) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.overviewStats.getSessionsResultByIntervalTable(this.accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, this.projectShortName, botIds, undefined, locale, options);
            return data;
        });
        this.getSessionsResultByIntervalTableXlsx = (dateFromP1, dateToP1, dateFromP2, dateToP2, botIds, locale, options) => __awaiter(this, void 0, void 0, function* () {
            const { data, headers } = yield this.overviewStats.getSessionsResultByIntervalTableXlsx(this.accountId, dateFromP1, dateToP1, dateFromP2, dateToP2, this.projectShortName, botIds, undefined, locale, options);
            return {
                data,
                headers,
            };
        });
        this.accountId = accountId;
        this.projectShortName = projectNam;
        this.simpleStats = new SimpleStatsApi({}, ProjectStatsService.BASE_PATH, axios);
        this.advancedStats = new AdvancedStatsApi({}, ProjectStatsService.BASE_PATH, axios);
        this.overviewStats = new OverviewStatsApi({}, ProjectStatsService.BASE_PATH, axios);
        this.clients = new ClientsApi({}, ProjectStatsService.BASE_PATH, axios);
        this.messages = new MessagesApi({}, ProjectStatsService.BASE_PATH, axios);
        this.sessions = new SessionsApi({}, ProjectStatsService.BASE_PATH, axios);
        this.routes = new StateRoutesApi({}, ProjectStatsService.BASE_PATH, axios);
        this.report = new ReportApi({}, ProjectStatsService.BASE_PATH, axios);
        this.tokens = new AnalyticsTokenApi({}, ProjectStatsService.BASE_PATH, axios);
        this.filtersApi = new FiltersApi({}, ProjectStatsService.BASE_PATH, axios);
    }
}
ProjectStatsService.BASE_PATH = '';
export class LabelsService {
    constructor(accountId, projectNam, axios = localAxios) {
        this.setLogLabels = (questionId, sessionId, labels) => __awaiter(this, void 0, void 0, function* () {
            this.labels.setMessageLabels(this.accountId, questionId, sessionId, labels);
        });
        this.setSessionLabels = (questionId, labels) => __awaiter(this, void 0, void 0, function* () {
            this.labels.setSessionLabels(this.accountId, questionId, labels);
        });
        this.accountId = accountId;
        this.projectShortName = projectNam;
        this.labels = new LabelsApi({}, ProjectStatsService.BASE_PATH, axios);
    }
}
LabelsService.BASE_PATH = '';
