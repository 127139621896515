import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { ActionPanel } from '@just-ai/analytic-front';
import { Range } from '@just-ai/just-ui/dist/DatePicker';
import { t } from '../../../../localization';

import { useAppContext } from '../../../../AppContext';
import { StatisticsThemeService } from '@just-ai/aimychat-shared/dist/service/Statistics/StatisticsThemeService';
import { ThemeDto } from '@just-ai/aimychat-shared/dist/api/client/statistics';

export const StatisticsMainTab = memo(({ range }: { range: Range }) => {
  const { id, config } = useAppContext();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [themes, setThemes] = useState<ThemeDto[]>();
  const statisticsService = useMemo(() => new StatisticsThemeService({ ...range, accountId: id }), [id, range]);
  const themesForPage = useMemo(
    () => (themes ? themes.slice(page * pageSize, page * pageSize + pageSize) : []),
    [page, pageSize, themes]
  );

  const rangeRef = useRef<Range>();

  /* update themes if range changed */
  useEffect(() => {
    if (rangeRef.current !== range && themes) {
      setThemes(undefined);
    }
    rangeRef.current = range;
  }, [range, themes]);

  useEffect(() => {
    if (themes) {
      return;
    }

    const getSummary = async () => {
      const { themes = [] } = await statisticsService.getTransferTopicsStatistics();
      setThemes(themes);
    };

    getSummary();
  }, [id, themes, range, statisticsService]);

  useEffect(() => setPage(0), [pageSize]);

  if (!themes) {
    return null;
  }

  return (
    <>
      <div className='Section'>
        <h3>{t('Statistics:RequestSubjects')}</h3>
        <p>
          <small>{t('Statistics:RequestSubjects:Description', config.botadmin.productName)}</small>
        </p>
      </div>

      <div className='List'>
        <ul>
          {themesForPage.map(({ theme, count }) => (
            <li key={theme}>
              <span>{theme || t('Statistics: theme not specified')}</span>
              <span>{count}</span>
            </li>
          ))}
        </ul>

        {!!themes.length && (
          <ActionPanel
            t={t}
            page={page}
            size={pageSize}
            withoutToThePage
            position='bottom'
            changePage={setPage}
            withViewControl={false}
            rightSideOrder={['Size']}
            totalCount={themes.length}
            changePageSize={setPageSize}
            leftSideOrder={['Paginator']}
            totalPages={Math.ceil(themes.length / pageSize)}
          />
        )}
      </div>
    </>
  );
});
