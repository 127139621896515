import React, { useState, useCallback, useMemo } from 'react';
import { t } from '../../../localization';
import { Button, InputGroup, InputText, useToggle } from '@just-ai/just-ui';
import './Operators.scss';
import { OperatorWithGroupsDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import OperatorsList from '../components/OperatorsList';
import DeleteOperatorModal from '../components/DeleteOperatorModal';
import CreateOperatorModal from '../components/CreateOperatorModal';
import { filterOperator } from '../settingsHelpers';
import { Spinner } from '@just-ai/just-ui/dist/Spinner';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';
import { useAppContext } from '../../../AppContext';

export default function Operators() {
  const { id, operators, setOperators, getGroups, operatorsFetching, agentLimits, isUnlimited } = useAppContext();
  const [showCreateModal, setShowCreateModal, hideCreateModal] = useToggle(false);
  const [operatorToEdit, setOperatorToEdit] = useState<OperatorWithGroupsDto>();
  const [operatorToDelete, setOperatorToDelete] = useState<OperatorWithGroupsDto>();
  const [filter, setFilter] = useState<string>('');
  const isMobile = useIsMobile();

  const addOperator = useCallback(
    (operator: OperatorWithGroupsDto) => setOperators(operators => [...operators, operator]),
    [setOperators]
  );

  const editOperator = useCallback(
    (editedOperator: OperatorWithGroupsDto) => {
      setOperators(operators => {
        return operators.map(operator => (operator.id === editedOperator.id ? editedOperator : operator));
      });
      getGroups();
    },
    [getGroups, setOperators]
  );

  const removeOperator = useCallback(
    (id: number) => setOperators(operators => operators.filter(operator => operator.id !== id)),
    [setOperators]
  );

  const setOperatorToEditAndOpenModal = useCallback(
    (operator: OperatorWithGroupsDto) => {
      setOperatorToEdit(operator);
      setShowCreateModal();
    },
    [setOperatorToEdit, setShowCreateModal]
  );
  const hideCreateModalAndClearEdit = useCallback(() => {
    setOperatorToEdit(undefined);
    hideCreateModal();
  }, [setOperatorToEdit, hideCreateModal]);

  const hideDeleteModal = useCallback(() => {
    setOperatorToDelete(undefined);
  }, [setOperatorToDelete]);

  const filteredOperators = useMemo(() => {
    return operators.filter(operator => filterOperator(filter, operator));
  }, [operators, filter]);

  return (
    <>
      <div className='Operators'>
        {operatorsFetching && <Spinner data-test-id='Operators.fetchingOperators' size='4x' />}
        <p className='Operator_description'>
          <small>{t('Settings:Operators: description')}</small>
        </p>
        <div className='Operators_createButton'>
          <InputGroup PrependInner={[{ name: 'faSearch' }]}>
            <InputText
              onChange={setFilter}
              data-test-id='Operators.filterOperators'
              placeholder={t('Settings:Operators: operator search full placeholder')}
            />
          </InputGroup>
          <Button
            data-test-id='Operators.create'
            color='primary'
            outline
            size={isMobile ? 'sm' : 'md'}
            onClick={setShowCreateModal}
            disabled={!isUnlimited && operators.length >= (agentLimits?.maxOperators || 0) && !!agentLimits}
          >
            {t('Settings:Operators: create operator button')}
          </Button>
        </div>
        <OperatorsList
          operators={filteredOperators}
          setOperatorToEdit={setOperatorToEditAndOpenModal}
          setOperatorToDelete={setOperatorToDelete}
        />
      </div>
      {showCreateModal && (
        <CreateOperatorModal
          operatorToEdit={operatorToEdit}
          editOperator={editOperator}
          addOperator={addOperator}
          onClose={hideCreateModalAndClearEdit}
          filteredOperators={filteredOperators}
        />
      )}
      {operatorToDelete && (
        <DeleteOperatorModal
          operatorToDelete={operatorToDelete}
          onClose={hideDeleteModal}
          deleteOperator={removeOperator}
          accountId={id}
        />
      )}
    </>
  );
}
