import { OperatorChatDto } from '@just-ai/aimychat-shared/dist/api/client/operator';
import { DynamicVirtualListPropElement } from '@just-ai/analytic-front/dist/Reporter/components/ClientsDialogs/DynamicVirtualList';
import { t } from '../../../localization';
import { Message } from './Message';
import { getUserFullName } from '../operatorPlaceHelpers';

import { LoadMoreButton } from './LoadMoreButton';
import './Messages.scss';
import { MessageDto, MessageType } from '@just-ai/aimychat-shared/dist/api/client/websocket';
import { MoreMessagesFunction } from '@just-ai/aimychat-shared';

const { CLIENTMESSAGE, BOTMESSAGE, OPERATORMESSAGE, COMMENT } = MessageType;

type Props = {
  messages: MessageDto[];
  chat: OperatorChatDto;
  isMessagesHaveToLoad: boolean;
  language: string;
  choosePrompterMessage: (value: MessageDto) => void;
  contextPrompterId?: number;
  loadMoreMessages: MoreMessagesFunction;
};

export const buildRenderMessagesComponents = ({
  messages,
  chat,
  isMessagesHaveToLoad,
  loadMoreMessages,
  language,
  choosePrompterMessage,
  contextPrompterId,
}: Props): DynamicVirtualListPropElement[] => {
  let lastDate: Date;
  const locale = language.toLocaleLowerCase().indexOf('cn') > -1 ? 'zh-CN' : language;
  const options = { month: 'short', day: 'numeric', year: 'numeric' } as object;

  return messages.reduce(
    (acc, message, index) => {
      const { type, id, operatorGroup, operator, operatorChat } = message;
      const creationTimestamp = new Date(message.creationTimestamp).toLocaleString(locale, options);
      const lastDateString = new Date(lastDate).toLocaleString(locale, options);

      if (!lastDate || lastDateString !== creationTimestamp) {
        lastDate = message.creationTimestamp;
        acc.push({
          elem: () => (
            <div data-test-id='Chat.MessageType.DATE' className='DialogMessage_lastDate'>
              {creationTimestamp}
            </div>
          ),
          id: `date_${index}_${creationTimestamp}`,
        });
      }

      let elem = () => <>{message.type}</>;

      const titles = {
        [MessageType.OPERATORCHATCOMPLETED]: t('OperatorPlace:Dialog chat completed'),
        [MessageType.CLIENTBLOCKINGBYOPERATOR]: t('OperatorPlace:Dialog blocked'),
        [MessageType.CLIENTUNBLOCKINGBYOPERATOR]: t('OperatorPlace:Dialog unblocked'),
        [MessageType.NEWOPERATORCHAT]: t('OperatorPlace:Dialog new operator'),
        [MessageType.TRANSFERAFTEROPERATORSILENCE]: t('OperatorPlace:Dialog silence operator'),
        [MessageType.OPERATORJOINED]: t(
          'OperatorPlace:Dialog operator joined',
          getUserFullName(operator || operatorChat?.clientInfo),
          operatorGroup?.name
        ),
        [MessageType.OPERATORLEFT]: t('OperatorPlace:Dialog operator left'),
        [MessageType.CLIENTLEFT]: t('OperatorPlace:Dialog client left'),
        [MessageType.TRANSFERTOOPERATORGROUP]: t('OperatorPlace:Dialog:TransferTo:AnotherGroup', operatorGroup?.name),
      };

      const title = titles[type];

      if (title) {
        elem = () => (
          <div data-test-id={`Chat.MessageType.${type}`} key={id} className='Messages__element'>
            {title}
          </div>
        );
      } else if ([CLIENTMESSAGE, BOTMESSAGE, OPERATORMESSAGE, COMMENT].includes(type)) {
        const rest = { key: id, message, chat, choosePrompterMessage, contextPrompterId };
        const { text } = message;

        if (text && text.indexOf('\n\n') > -1) {
          message.text = text.replace(/\n+/g, '\n');
        }

        elem = () => (
          <Message
            isClient={type === CLIENTMESSAGE}
            isBot={type === BOTMESSAGE}
            isComment={type === COMMENT}
            {...rest}
          />
        );
      }

      acc.push({ id: message.id.toString(), elem });

      return acc;
    },
    isMessagesHaveToLoad
      ? [{ id: 'LoadMoreButton', elem: () => <LoadMoreButton loadMoreMessages={loadMoreMessages} /> }]
      : []
  );
};
