import React from 'react';
import { t } from '../../../../localization';
import { useAppContext } from '../../../../AppContext';

const LimitsTitle = ({ isGroups }) => {
  const { groups, operators, agentLimits, isUnlimited } = useAppContext();
  const titleColorRed = (length, maxLimits) =>
    length > maxLimits ? (
      <span style={{ color: 'var(--red-600)' }}>{t('Settings:Limits', length, maxLimits)}</span>
    ) : (
      t('Settings:Limits', length, maxLimits)
    );

  if (isUnlimited) return null;

  return (
    <small className='Title'>
      {isGroups ? (
        <>
          {t('Settings:Groups:Limits')} {titleColorRed(groups.length, agentLimits?.maxOperatorGroups)}
        </>
      ) : (
        <>
          {t('Settings:Users:Limits')} {titleColorRed(operators.length, agentLimits?.maxOperators)}
        </>
      )}
    </small>
  );
};

export default LimitsTitle;
