import { Dispatch, memo, SetStateAction, useCallback, useMemo, useState } from 'react';
import { DatePicker, RadioButton } from '@just-ai/just-ui/dist';
import { Range } from '@just-ai/just-ui/dist/DatePicker';
import { useAppContext } from '../../../../AppContext';
import { useParams } from 'react-router-dom';
import { TABS } from '../../Statistics';
import { DropdownMenu } from './DropdownMenu';
import { t } from '../../../../localization';
import { getUserFullName } from '../../../OperatorPlace/operatorPlaceHelpers';

type Props = {
  range: Range;
  groupId: number;
  operatorId: number;
  setRange: Dispatch<SetStateAction<Range>>;
  setGroupId: Dispatch<SetStateAction<number | string>>;
  setOperatorId: Dispatch<SetStateAction<number | string>>;
};

export const Filters = memo(({ range, setRange, setGroupId, groupId, operatorId, setOperatorId }: Props) => {
  const [preselectedGroupId, setPreselectedGroupId] = useState<string | number>(groupId);
  const [preselectedOperatorId, setPreselectedOperatorId] = useState<string | number>(operatorId);
  const [preselectedRange, setPreselectedRange] = useState<Date | Range | null>(range);
  const { tab = TABS.MAIN } = useParams<{ tab: TABS }>();
  const { language, groups, operators } = useAppContext();
  const locale = language.toLocaleLowerCase().indexOf('cn') > -1 ? 'zh-CN' : language;
  const selectedOperatorName = useMemo(
    () => (tab === TABS.OPERATORS ? getUserFullName(operators.find(operator => operator.id === operatorId)) : ''),
    [operatorId, operators, tab]
  );

  const handleApplyGroup = useCallback(() => {
    setGroupId(+preselectedGroupId);
    sessionStorage.setItem('groupId', String(preselectedGroupId));
    hideMenu();
  }, [preselectedGroupId, setGroupId]);

  const handleApplyRange = useCallback(() => {
    const range = preselectedRange as Range;

    const startDate = new Date(range.startDate).toLocaleString('en', {
      month: '2-digit',
      day: 'numeric',
      year: 'numeric',
    });

    const endDate = new Date(range.endDate).toLocaleString('en', {
      month: '2-digit',
      day: 'numeric',
      year: 'numeric',
    });

    setRange(range);

    sessionStorage.setItem('startDate', startDate);
    sessionStorage.setItem('endDate', endDate);

    hideMenu();
  }, [preselectedRange, setRange]);

  const handleApplyOperator = useCallback(() => {
    setOperatorId(+preselectedOperatorId);
    sessionStorage.setItem('operatorId', String(preselectedOperatorId));
    hideMenu();
  }, [preselectedOperatorId, setOperatorId]);

  const hideMenu = () => {
    const menuElement = document.querySelector('.DropdownMenu.show');
    if (menuElement) {
      menuElement.classList.remove('show');
    }
  };

  const handleCancelRange = useCallback(() => {
    setPreselectedRange(range);
    hideMenu();
  }, [range]);

  const handleCancel = useCallback(() => {
    setPreselectedGroupId(groupId);
    setPreselectedOperatorId(operatorId);
    hideMenu();
  }, [groupId, operatorId]);

  return (
    <div className='flex-row'>
      <DropdownMenu
        title={`${t('Period')}: ${new Date(range.startDate).toLocaleString(locale, {
          month: 'numeric',
          day: 'numeric',
          year: '2-digit',
        })} - ${new Date(range.endDate).toLocaleString(locale, {
          month: 'numeric',
          day: 'numeric',
          year: '2-digit',
        })}`}
        handleApply={handleApplyRange}
        handleCancel={handleCancelRange}
      >
        <DatePicker
          locale={language.toLocaleLowerCase() === 'ru' ? 'ru' : 'en'}
          onChange={setPreselectedRange}
          defaultValue={preselectedRange as Range}
          maxDate={new Date()}
          integratedPicker
          withTime={false}
        />
      </DropdownMenu>

      {tab === TABS.GROUPS && (
        <DropdownMenu
          title={`${t('Group')}: ${groups.find(group => group.id === groupId)?.name}`}
          handleApply={handleApplyGroup}
          handleCancel={handleCancel}
        >
          {groups.map(group => (
            <RadioButton
              key={group.name}
              label={group.name}
              name='radio'
              value={String(group.id)}
              onChange={setPreselectedGroupId}
              defaultChecked={group.id === +preselectedGroupId}
            />
          ))}
        </DropdownMenu>
      )}

      {tab === TABS.OPERATORS && (
        <DropdownMenu
          title={`${t('Agent')}: ${selectedOperatorName}`}
          handleApply={handleApplyOperator}
          handleCancel={handleCancel}
        >
          {operators.map(operator => (
            <RadioButton
              key={operator.id}
              label={getUserFullName(operator)}
              name='radio'
              value={String(operator.id)}
              onChange={setPreselectedOperatorId}
              defaultChecked={operator.id === operatorId}
            />
          ))}
        </DropdownMenu>
      )}
    </div>
  );
});
