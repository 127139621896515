import React, { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { MessageType, OperatorChatWithLastMessageSimpleDto } from '@just-ai/aimychat-shared/dist/api/client/operator';
import classNames from 'classnames';
import { Spinner } from '@just-ai/just-ui/dist/Spinner';
import { getUserFullName, useWSCallbacks } from '../operatorPlaceHelpers';
import { t } from '../../../localization';
import { ChooseChatFunction, GetChatsFunction, StateListsName } from '@just-ai/aimychat-shared/dist/operatorPlaceTypes';
import { useToggle } from '@just-ai/just-ui';
import { GroupsColor, useAppContext } from '../../../AppContext';
import { useOperatorDataContext } from '../OperatorDataContext';

const ChatsList = ({
  chats,
  chosenChatId,
  chooseChat,
  loading,
  tab,
  chosenGroups,
  filter,
  showGroupFlag,
  onGetHistoryChats,
  loadMore,
}: {
  chats: OperatorChatWithLastMessageSimpleDto[];
  loading: boolean;
  chosenGroups: number[];
  filter?: string;
  chosenChatId?: number;
  tab: StateListsName;
  showGroupFlag: boolean;
  loadMore: Boolean;
  onGetHistoryChats?: GetChatsFunction;
  chooseChat: ChooseChatFunction;
}) => {
  const [loadingMore, setLoading, setNotLoading] = useToggle(false);
  const containerRef = useRef<HTMLUListElement | null>(null);
  const chosenRef = useRef<HTMLLIElement>(null);
  const { language, groupsColors } = useAppContext();
  const callbacks = useWSCallbacks({
    success: () => {
      setNotLoading();
    },
  });
  const locale = language.toLocaleLowerCase().indexOf('cn') > -1 ? 'zh-CN' : language;

  const filteredChats = useMemo(() => {
    if (chosenGroups.length || filter) {
      return chats.filter(({ clientInfo, lastMessage }) => {
        if (tab === StateListsName.COMPLETED) return true;
        const groupId = lastMessage?.operatorGroup?.id;
        if (filter) {
          if (clientInfo.id.includes(filter)) return true;
          if (!getUserFullName(clientInfo).toLowerCase().includes(filter.toLowerCase())) return false;
        }

        if (groupId && chosenGroups.length && !chosenGroups.includes(groupId)) return false;

        return true;
      });
    }

    return chats;
  }, [filter, chosenGroups, chats, tab]);

  useEffect(() => {
    if (containerRef.current && chosenRef.current) {
      const topOffset = chosenRef.current?.offsetTop;
      containerRef.current?.scrollTo({ top: topOffset, left: 0 });
    }
  }, []);

  const onScroll = useCallback(() => {
    const container = containerRef.current;
    if (loadingMore || !container) return;

    const { scrollHeight, clientHeight, scrollTop } = container;
    if (onGetHistoryChats && scrollHeight < clientHeight + scrollTop + 10) {
      setLoading();
      onGetHistoryChats({
        groupIds: chosenGroups,
        filter: filter?.trim(),
        callbacks,
      });
    }
  }, [loadingMore, onGetHistoryChats, setLoading, chosenGroups, filter, callbacks]);

  const handleChooseChat = useCallback(
    value => {
      if (chosenChatId !== value) {
        chooseChat(value);
      }
    },
    [chooseChat, chosenChatId]
  );

  const chatsGroupedByDate = useMemo(
    () =>
      filteredChats.reduce((acc: Record<number, OperatorChatWithLastMessageSimpleDto[]>, current) => {
        const creationTimestamp = current.lastMessage?.creationTimestamp || current.creationTime;
        const lastMessageTimestamp = new Date(creationTimestamp).toLocaleString(locale, {
          month: 'long',
          day: 'numeric',
        });
        if (acc[lastMessageTimestamp]) {
          acc[lastMessageTimestamp].push(current);
        } else {
          acc[lastMessageTimestamp] = [current];
        }
        return acc;
      }, {}),
    [filteredChats, locale]
  );

  return (
    <ul className='UsersList' ref={containerRef} onScroll={loadMore ? onScroll : undefined}>
      {loading && <Spinner size='2x' style={{ zIndex: '999' }} />}
      {chats.length === 0 && (
        <div data-test-id={`Chats.Tab.${tab}.emptyPlaceholder`} className='UsersList_placeholder'>
          {t(`OperatorPlace: chat empty list ${tab}`)}
        </div>
      )}
      {Object.keys(chatsGroupedByDate).map(date => (
        <Fragment key={date}>
          <p>
            <small>{date}</small>
          </p>

          {chatsGroupedByDate[date].map(chat => (
            <ChatListElem
              key={chat.id}
              ref={chosenRef}
              chat={chat}
              chosenChatId={chosenChatId}
              tab={tab}
              showGroupFlag={showGroupFlag}
              chooseChat={handleChooseChat}
              groupsColors={groupsColors}
            />
          ))}
        </Fragment>
      ))}

      {loadMore && (
        <div className='UsersList_loadMore'>
          {loadingMore && <Spinner size='2x' inline style={{ zIndex: '999' }} />}
        </div>
      )}
    </ul>
  );
};

type Props = {
  chat: OperatorChatWithLastMessageSimpleDto;
  chosenChatId?: number;
  tab: StateListsName;
  showGroupFlag: boolean;
  chooseChat: ChooseChatFunction;
  groupsColors: GroupsColor;
};

const ChatListElem = React.forwardRef<HTMLLIElement, Props>(
  ({ chosenChatId, chat, tab, showGroupFlag, chooseChat, groupsColors }, chosenRef) => {
    const context = useOperatorDataContext();
    const chats = context[tab];
    const currentChat = chats.find(({ id }) => id === chat.id);
    const { lastMessage, clientInfo } = currentChat || chat;
    const creationTimestamp = lastMessage?.creationTimestamp ? new Date(lastMessage?.creationTimestamp) : '';
    const minutes = creationTimestamp ? creationTimestamp.getMinutes().toString().padStart(2, '0') : '';
    const time = creationTimestamp ? `${creationTimestamp.getHours()}:${minutes}` : '';
    const lastMessageModified = useMemo(() => {
      const { text = '' } = lastMessage || {};
      const customText = text || t('OperatorPlace:Chats:Attachment');
      if (lastMessage?.type === MessageType.BOTMESSAGE) {
        return `${t('OperatorPlace: bot')}: ${customText}`;
      }
      if (lastMessage?.type === MessageType.OPERATORMESSAGE) {
        const { firstName, lastName } = lastMessage.operator || {};
        return firstName && lastName
          ? `${firstName} ${lastName}: ${customText}`
          : `${firstName || lastName}: ${customText}`;
      }

      return customText;
    }, [lastMessage]);

    return (
      <li
        data-test-id={`Chats.Tab.${tab}.chat.id.${chat.id}`}
        data-test-name={`Chats.Tab.${tab}.chat.clientFullName.${getUserFullName(chat.clientInfo)}`}
        key={chat.id}
        className={classNames({ UsersList_User_active: chosenChatId === chat.id })}
        onClick={() => chooseChat(chat.id)}
        role='button'
        {...(chosenChatId === chat.id ? { ref: chosenRef } : {})}
      >
        <div className='DescriptionChat'>
          <p>
            <small className='DescriptionChat_Username' data-test-id={`Chats.Tab.${tab}.chat.name`}>
              {getUserFullName(clientInfo)}
            </small>
          </p>
          <p>
            <small data-test-id='Chats.Chat.creationTime' className='font-size-12 UsersList_User_time'>
              {time}
            </small>
          </p>
        </div>
        <div data-test-id='Chats.Chat.name' className='font-size-14 UsersList_User_lastMessage'>
          {showGroupFlag && lastMessage?.operatorGroup?.id && (
            <div
              style={{
                height: 12,
                width: 12,
                borderRadius: 6,
                backgroundColor: groupsColors[lastMessage.operatorGroup.id]?.backgroundColor,
              }}
            />
          )}
          <span>
            {lastMessageModified}
            {lastMessage?.fileUrl && (
              <a href={lastMessage.fileUrl} rel='noreferrer' target='_blank' className='margin-left-2x'>
                {lastMessage.fileUrl}
              </a>
            )}
          </span>
        </div>

        {!!chat.unreadMessageCount && (
          <div data-test-id='Chats.Chat.unreadCount' className='font-size-12 UsersList_User_unreadedMessages'>
            <div>{chat.unreadMessageCount}</div>
          </div>
        )}
      </li>
    );
  }
);

export default React.memo(ChatsList);
