import { Dispatch, memo, SetStateAction, useCallback, useEffect } from 'react';
import { Modal, usePromiseProcessing } from '@just-ai/just-ui';

import { useAppContext } from '../../../../AppContext';
import { t } from '../../../../localization';
import { IFrameOperatorPlaceDto } from '@just-ai/api/dist/generated/Aimychat';
import { Modal as ModalType } from './FramesList';

type Props = {
  setOpenedModal: Dispatch<SetStateAction<ModalType | null>>;
  iframe: IFrameOperatorPlaceDto;
  getOperatorPlaceIFrames: () => void;
};

export const FrameRemoveModal = memo(({ iframe, setOpenedModal, getOperatorPlaceIFrames }: Props) => {
  const { OperatorPlaceIFrameService, fetchIframesOperatorPlace } = useAppContext();
  const [deleteFrameState, deleteOperatorPlaceIFrame] = usePromiseProcessing((id: number) =>
    OperatorPlaceIFrameService.deleteOperatorPlaceIFrame({ id })
  );

  const removeFrame = useCallback(
    () => deleteOperatorPlaceIFrame(iframe.id).finally(() => fetchIframesOperatorPlace()),
    [deleteOperatorPlaceIFrame, fetchIframesOperatorPlace, iframe.id]
  );
  const handleCloseModal = useCallback(() => setOpenedModal(null), [setOpenedModal]);

  useEffect(() => {
    if (deleteFrameState.result?.iframes) {
      getOperatorPlaceIFrames();
      handleCloseModal();
    }
  }, [deleteFrameState.result, getOperatorPlaceIFrames, handleCloseModal, OperatorPlaceIFrameService, setOpenedModal]);

  return (
    <Modal
      isOpen={!!iframe}
      onActionClick={removeFrame}
      onCancelClick={handleCloseModal}
      title={t('Settings:Frames:Modal:Remove:Title')}
      buttonCancelColor='secondary'
      buttonSubmitColor='danger'
      buttonSubmitText={t('Delete')}
      buttonCancelText={t('Cancel')}
      buttonSubmitDisabled={deleteFrameState.loading}
      buttonCancelDisabled={deleteFrameState.loading}
      disableActionButtonAutoFocus
      centered
    >
      <p
        dangerouslySetInnerHTML={{
          __html: t('Settings:Frames:Modal:Remove:Description', { name: iframe?.name }),
        }}
      />
    </Modal>
  );
});
