import { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, usePromiseProcessing } from '@just-ai/just-ui';

import { useAppContext } from '../../../../AppContext';
import { t } from '../../../../localization';
import { IFrameOperatorPlaceDto } from '@just-ai/api/dist/generated/Aimychat';
import { Modal as ModalType } from './FramesList';
import { FrameForm } from './FrameForm';

type Props = {
  setOpenedModal: Dispatch<SetStateAction<ModalType | null>>;
  iframe: IFrameOperatorPlaceDto;
  iframes: IFrameOperatorPlaceDto[] | null;
  getOperatorPlaceIFrames: () => void;
};

export const FrameEditModal = memo(
  ({ iframe, setOpenedModal, getOperatorPlaceIFrames, iframes }: Props): JSX.Element => {
    const { OperatorPlaceIFrameService, fetchIframesOperatorPlace } = useAppContext();
    const [name, setName] = useState(iframe.name);
    const [htmlCode, setHhtmlCode] = useState(iframe.htmlCode);
    const [isTextLong, setIsTextLong] = useState(false);

    const onError = (error: Error) => {
      if (error.name === 'AxiosError') {
        // @ts-ignore
        setIsTextLong(error.response?.data?.error === 'aimychatbe.common.iframe_name_is_too_long');
      }
    };

    const [updateFrameState, updateOperatorPlaceIFrame] = usePromiseProcessing(
      (frame: IFrameOperatorPlaceDto) => OperatorPlaceIFrameService.updateOperatorPlaceIFrame(frame),
      { onError }
    );

    const isNameUniq = useMemo(
      () => !iframes?.some(i => i.name === name && i.id !== iframe.id),
      [iframe, iframes, name]
    );

    const updateFrame = useCallback(
      () => updateOperatorPlaceIFrame({ ...iframe, name, htmlCode }).finally(() => fetchIframesOperatorPlace()),
      [updateOperatorPlaceIFrame, iframe, name, htmlCode, fetchIframesOperatorPlace]
    );
    const handleCloseModal = useCallback(() => setOpenedModal(null), [setOpenedModal]);

    useEffect(() => {
      if (updateFrameState.result?.iframes) {
        getOperatorPlaceIFrames();
        handleCloseModal();
      }
    }, [
      updateFrameState.result,
      getOperatorPlaceIFrames,
      handleCloseModal,
      OperatorPlaceIFrameService,
      setOpenedModal,
    ]);

    return (
      <Modal
        isOpen={!!iframe}
        onActionClick={updateFrame}
        onCancelClick={handleCloseModal}
        title={t('Settings:Frames:Modal:Edit:Title')}
        buttonCancelColor='secondary'
        buttonSubmitText={t('Save')}
        buttonCancelText={t('Cancel')}
        buttonSubmitDisabled={updateFrameState.loading || !name || !htmlCode || !isNameUniq}
        buttonCancelDisabled={updateFrameState.loading}
        disableActionButtonAutoFocus
        centered
      >
        <FrameForm
          name={name}
          htmlCode={htmlCode}
          setName={setName}
          setHhtmlCode={setHhtmlCode}
          isNameUniq={isNameUniq}
          isTextLong={isTextLong}
          setIsTextLong={setIsTextLong}
        />
      </Modal>
    );
  }
);
