import { memo, useCallback } from 'react';
import { Modal } from '@just-ai/just-ui';

import { ModalProps } from '../../Tabs/Prompters';
import { useAppContext } from '../../../../AppContext';
import { t } from '../../../../localization';

export const PrompterRemoveModal = memo(({ selectedPrompter, setSelectedPrompter, setOpenedModal }: ModalProps) => {
  const { prompterApiService, getGroups, getPrompters } = useAppContext();

  const removePrompter = useCallback(async () => {
    if (selectedPrompter) {
      await prompterApiService.removePrompter(selectedPrompter.id);
      getPrompters();
      getGroups();
      setSelectedPrompter(null);
    }
  }, [getPrompters, prompterApiService, selectedPrompter, setSelectedPrompter, getGroups]);

  return (
    <Modal
      isOpen={!!selectedPrompter}
      onActionClick={removePrompter}
      onCancelClick={() => {
        setOpenedModal(null);
        setSelectedPrompter(null);
      }}
      title={t('Settings:Prompters:Modal:Remove:Title')}
      buttonCancelColor='secondary'
      buttonSubmitColor='danger'
      buttonSubmitText={t('Delete')}
      buttonCancelText={t('Cancel')}
      disableActionButtonAutoFocus
      centered
    >
      <p
        dangerouslySetInnerHTML={{
          __html: t('Settings:Prompters:Modal:Remove:Description', selectedPrompter?.name),
        }}
      />
    </Modal>
  );
});
