import { memo, useMemo } from 'react';
import { Button, Icon, Pophover, useToggle } from '@just-ai/just-ui';
import { useOperatorDataContext } from '../../OperatorDataContext';
import { TransferModal } from '../TransferChatModal';
import { t } from '../../../../localization';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';

export const TransferButton = memo(() => {
  const isMobile = useIsMobile();
  const { transferChat, groupsForTransfer, operatorGroups } = useOperatorDataContext();
  const [showTransferModal, setTransferBanModal, hideTransferModal] = useToggle();
  const description = t('OperatorPlace: header transfer button title');

  const isShowTransferButton = useMemo(
    () => !!(operatorGroups?.length || groupsForTransfer?.length),
    [operatorGroups, groupsForTransfer]
  );

  if (!isShowTransferButton) {
    return null;
  }

  return (
    <>
      <Pophover closable={false} placement='bottom' target='transferChat' dark>
        {description}
      </Pophover>
      <Button id='transferChat' data-test-id='Chat.Header.Info.transferButton' onClick={setTransferBanModal} flat>
        <Icon name='farShare' color='secondary' size='sm' />
        {isMobile && description}
      </Button>
      {showTransferModal && (
        <TransferModal onClose={hideTransferModal} onTransfer={transferChat} hideModal={hideTransferModal} />
      )}
    </>
  );
});
