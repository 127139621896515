import { useState, useCallback } from 'react';
import useMediaQuery from './useMediaQuery';
export var useToggle = function (initialValue) {
    if (initialValue === void 0) { initialValue = false; }
    var _a = useState(initialValue), value = _a[0], setValue = _a[1];
    var setTrue = useCallback(function () { return setValue(true); }, []);
    var setFalse = useCallback(function () { return setValue(false); }, []);
    var toggle = useCallback(function () { return setValue(function (value) { return !value; }); }, []);
    return [value, setTrue, setFalse, toggle];
};
export var useIsMobile = function () {
    return useMediaQuery('(max-width: 620px)');
};
