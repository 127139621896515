import { Button, Icon } from '@just-ai/just-ui';
import './styles.scss';
import { useAppContext } from '../../../../AppContext';
import { memo, useState } from 'react';
import { IFrameOperatorPlaceDto } from '@just-ai/api/dist/generated/Aimychat';
import { FrameRemoveModal } from './FrameRemoveModal';
import { FrameEditModal } from './FrameEditModal';
import { Spinner } from 'reactstrap';

export type Modal = {
  frame: IFrameOperatorPlaceDto;
  modalType: 'edit' | 'remove';
};

type Props = {
  getOperatorPlaceIFrames: () => void;
  iframes: IFrameOperatorPlaceDto[] | null;
};

export const FramesList = memo(({ iframes = [], getOperatorPlaceIFrames }: Props) => {
  const { groupsColors } = useAppContext();
  const [openedModal, setOpenedModal] = useState<Modal | null>(null);

  return (
    <div className='FramesList_container'>
      {!iframes && <Spinner size='xs' color='primary' />}
      {iframes?.map(frame => (
        <div className='FramesList_item' key={frame.name}>
          <div className='FramesList_container__item__description'>
            <small>
              <b data-test-id={`frame-${frame.name}`}>{frame.name}</b>
            </small>
            <div className='FramesList_container__item__groups'>
              {frame.operatorGroups?.map(group => (
                <small
                  data-test-id={`FramesList.group.${group.id}`}
                  key={group.id}
                  className='FramesList_group'
                  style={groupsColors[group.id]}
                >
                  {group.name}
                </small>
              ))}
            </div>
          </div>
          <div>
            <Button
              size='sm'
              flat
              outline
              color='secondary'
              data-test-id={`edit-frame-${frame.name}`}
              onClick={() => setOpenedModal({ frame, modalType: 'edit' })}
            >
              <Icon size='sm' name='farPen' />
            </Button>
            <Button
              size='sm'
              flat
              outline
              color='secondary'
              data-test-id={`remove-frame-${frame.name}`}
              onClick={() => setOpenedModal({ frame, modalType: 'remove' })}
            >
              <Icon size='sm' name='farTrashAlt' />
            </Button>
          </div>
        </div>
      ))}
      {openedModal?.modalType === 'edit' && (
        <FrameEditModal
          setOpenedModal={setOpenedModal}
          iframe={openedModal.frame}
          getOperatorPlaceIFrames={getOperatorPlaceIFrames}
          iframes={iframes}
        />
      )}

      {openedModal?.modalType === 'remove' && (
        <FrameRemoveModal
          setOpenedModal={setOpenedModal}
          iframe={openedModal.frame}
          getOperatorPlaceIFrames={getOperatorPlaceIFrames}
        />
      )}
    </div>
  );
});
