import React from 'react';
import { t } from '../../localization';
import CloudMenu from '@just-ai/just-ui/dist/CloudMenu';
import { useAppContext } from '../../AppContext';

const CloudMenuWrap = () => {
  const { options, productInfo } = useAppContext();

  if (!options?.domains) {
    return null;
  }

  return (
    <CloudMenu
      cloudDomains={options?.domains}
      countryIsoCode='ru'
      translate={t}
      universalLoginMenuEnabled
      isEuroInstance={productInfo.euroInstance}
    />
  );
};

export default CloudMenuWrap;
