export var wsPath = {
    chatCreated: '/chats/created',
    chatActive: '/chats/active',
    chatCompleted: '/chats/completed',
    chatHistory: '/chat-history',
    newMessage: '/new-message',
    newChatStatus: '/new-chat-status',
    newSetGroupForOperator: '/new-set-of-groups-for-operator',
    operatorGroups: '/operator-groups',
    newMessageStatus: '/new-message-status',
    chatBlockInfo: '/chat-blocking-info',
    newOperatorStatus: '/new-operator-status',
    inviteOperatorToChat: '/invite-operator-to-chat',
    removeOperatorFromChat: '/remove-operator-from-chat',
    chatsActiveInvited: '/chats/active/invited',
    chatsCompletedInvited: '/chats/completed/invited',
    chatOperators: '/chat/operators',
    changeMainOperatorInChat: '/change-main-operator-in-chat',
    notInChatOperatorsInGroup: '/not-in-chat-operators-in-groups',
    chatOperatorsToSwap: '/chat/operators_to_swap_with',
    groupChats: '/group-chats',
};
