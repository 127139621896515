import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { InputText, JustSelect, OptionType, Spinner } from '@just-ai/just-ui';
import { PrompterRequestIds, useOperatorDataContext } from '../OperatorDataContext';
import './PromptersTab.scss';
import { t } from '../../../localization';
import { debounce } from 'lodash';

import classNames from 'classnames';
import { getPrompterHistory, PrompterHistory } from './PrompterHistory';
import { OperatorChatStatus } from '@just-ai/aimychat-shared/dist/api/client/operator';
import { useAppContext } from '../../../AppContext';
import { PrompterMessage } from '../chatComponents/Chatfooter/PrompterMessage';

export const PromptersTab = memo(() => {
  const {
    prompterResponse,
    chosenChat,
    getNewPromterMessage,
    clearPrompterChoice,
    prompterRequest,
    setPrompterRequest,
    prompterFullResponse,
  } = useOperatorDataContext();
  const { getPrompters, prompters } = useAppContext();
  const { groupsForTransfer: groups, getOperatorGroups, clearContextPrompterNameWithError } = useOperatorDataContext();
  const [isLoading, setIsLoading] = useState(false);
  const [requestId, setRequestId] = useState<string>();
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState<number>();
  const answerOptions = useMemo(() => prompterResponse?.answerOptions || [], [prompterResponse?.answerOptions]);
  const promptersOptions: OptionType[] = useMemo(
    () =>
      groups
        .find(({ id }) => id === chosenChat?.operatorGroup.id)
        ?.prompterIds?.map(id => {
          const prompter = prompters.find(prompter => prompter.id === id);
          return {
            label: prompter?.name || '',
            value: prompter?.id || 0,
          };
        }) || [],
    [chosenChat?.operatorGroup.id, groups, prompters]
  );
  const [prompterId, setPrompterId] = useState(+promptersOptions[0]?.value);

  const showPrompterMessages = useMemo(() => {
    if (!chosenChat) return false;
    if (prompterRequest || prompterFullResponse?.requestId?.includes('start')) {
      return true;
    }
    const history = getPrompterHistory();
    const curentQuestionIdIsStart = history[chosenChat.id]?.[prompterId]?.some(
      ({ prompterResponse: res }) => res?.questionId === prompterResponse?.questionId
    );

    return curentQuestionIdIsStart;
  }, [chosenChat, prompterFullResponse?.requestId, prompterId, prompterRequest, prompterResponse?.questionId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hangleChangeRequestWithDebounce = useCallback(
    debounce((text: string, customRequestId?: string) => {
      setIsLoading(false);
      if (!prompterId) {
        return;
      }

      const requestId = getNewPromterMessage({
        text,
        prompterId: prompterId.toString(),
        operatorChatId: chosenChat?.id,
        requestId: customRequestId || PrompterRequestIds.prompter,
      });

      setRequestId(requestId);
    }, 500),
    [chosenChat?.id, getNewPromterMessage, prompterId]
  );

  const hangleChangeRequest = useCallback(
    (text: string) => {
      const isOnlySpaces = text && !text.trim();
      if (isOnlySpaces) {
        setPrompterRequest('');
      } else {
        setPrompterRequest(text);
        setIsLoading(true);
        hangleChangeRequestWithDebounce(text);
      }

      if (!text || isOnlySpaces) {
        clearContextPrompterNameWithError();
      }

      setCurrentHistoryIndex(undefined);
    },
    [hangleChangeRequestWithDebounce, setPrompterRequest, clearContextPrompterNameWithError]
  );

  const handleChangePrompter = useCallback(
    (value: (string | number)[] | null) => {
      if (value) {
        setPrompterId(+value[0]);
        clearPrompterChoice();
        setCurrentHistoryIndex(undefined);
      }
    },
    [clearPrompterChoice]
  );

  const clearChoice = useCallback(() => {
    setIsLoading(false);
    clearPrompterChoice();
  }, [clearPrompterChoice]);

  useEffect(() => {
    if (!isLoading && showPrompterMessages && answerOptions) {
      const messagesContainer = document.querySelector('.PrompterMessagesWrapper');
      messagesContainer?.scrollTo({ top: 0 });
    }
  }, [answerOptions, isLoading, showPrompterMessages]);

  useEffect(() => {
    getPrompters();
  }, [getPrompters]);

  useEffect(() => {
    getOperatorGroups();
  }, [getOperatorGroups]);

  useEffect(() => {
    if (chosenChat?.status !== OperatorChatStatus.ACTIVE) {
      return;
    }
    const currentPrompter = prompters.find(({ id }) => id === prompterId);
    const prompterHistory = getPrompterHistory();
    const isStartWasSentAlready = prompterHistory[chosenChat?.id]?.[prompterId]?.some(({ requestId }) =>
      requestId.includes('start')
    );
    if (!isStartWasSentAlready) {
      currentPrompter?.enabledSendStart && hangleChangeRequestWithDebounce('/start', 'start');
    }
  }, [chosenChat?.id, chosenChat?.status, hangleChangeRequestWithDebounce, prompterId, prompters]);

  if (chosenChat?.status !== OperatorChatStatus.ACTIVE) {
    return null;
  }

  return (
    <div className='PromtersTabWrapper'>
      {promptersOptions.length === 1 ? (
        <span data-test-id='Prompters.Single' className='PromtersTabWrapper__prompter'>
          {promptersOptions[0].label}
        </span>
      ) : (
        <JustSelect
          data-test-id='Prompters.Selector'
          value={prompterId}
          options={promptersOptions}
          onChange={handleChangePrompter}
          fullWidth
        />
      )}

      <div
        className={classNames('PromptersAnswers', {
          WithPrompts: !isLoading && answerOptions.length && showPrompterMessages,
        })}
      >
        {isLoading && <Spinner size='xl' inline />}

        <div className='PrompterMessagesWrapper'>
          {!isLoading &&
            showPrompterMessages &&
            answerOptions.map(({ messages, intent }) => (
              <PrompterMessage
                key={`prompt_${intent?.id}`}
                messages={messages}
                intent={intent}
                sendMessage={() => {}}
                callback={clearChoice}
                changeRequest={hangleChangeRequest}
                setCurrentHistoryIndex={setCurrentHistoryIndex}
              />
            ))}
        </div>

        {!isLoading && (!answerOptions.length || !showPrompterMessages) && (
          <p
            className='PromptersAnswers__description'
            dangerouslySetInnerHTML={{
              __html: showPrompterMessages
                ? t('Prompters:Description:NoAnswer')
                : t('Prompters:Description:AnswersHere'),
            }}
          />
        )}
      </div>
      <div className='PromptersTabBottom'>
        <div className='ChatInfo_divider' />
        <PrompterHistory
          prompterId={prompterId}
          requestId={requestId}
          currentHistoryIndex={currentHistoryIndex}
          setCurrentHistoryIndex={setCurrentHistoryIndex}
        />
        <InputText
          data-test-id='Prompters.Request.Input'
          value={prompterRequest}
          placeholder={t('Prompters:Tab:YourRequest')}
          onChange={hangleChangeRequest}
        />
      </div>
    </div>
  );
});
