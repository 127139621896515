import { memo, useCallback } from 'react';
import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle, Icon } from '@just-ai/just-ui';
import { useAppContext } from '../../AppContext';
import { Locale } from '@just-ai/aimychat-shared/dist/api/client/accountadmin';
import localization from '../../localization';
import { updateLanguage } from '@just-ai/aimychat-shared/dist/pipes/functions';

export const LocaleSwitcher = memo(() => {
  const { productInfo, userService, userId, language, setLanguage } = useAppContext();

  const locales = {
    [Locale.EN]: 'English',
    [Locale.PT]: 'Português',
  };

  const changeLocale = useCallback(
    (language: Locale) => {
      userService.editProfile(userId, { language });
      localization.setLocale(language === Locale.EN ? 'eng' : language.toLocaleLowerCase());
      updateLanguage(language);
      setLanguage(language);
    },
    [setLanguage, userId, userService]
  );

  if (!productInfo.euroInstance) {
    return null;
  }

  return (
    <DropdownButton className='LocaleSwitcher margin-right-2x' direction='down'>
      <DropdownToggle color='none' data-test-id='dropdown-toggle'>
        {locales[language.substring(0, 2)]}
        <Icon name='faSort' className='margin-left-2x' size='sm' />
      </DropdownToggle>
      <DropdownMenu data-test-id='dropdown-menu'>
        {Object.keys(locales).map(locale => (
          <DropdownItem key={locale} onClick={() => changeLocale(locale as Locale)}>
            {locales[locale]}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </DropdownButton>
  );
});
