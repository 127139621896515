import React, { useEffect } from 'react';
import { IconButton, Icon, Button, Tooltip } from '@just-ai/just-ui';
import { OperatorGroupFullDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { t } from '../../../../localization';
import { useIsMobile } from '@just-ai/aimychat-shared/dist/utils/hooks';
import { useAppContext } from '../../../../AppContext';
import { Limits } from '../Limits/Limits';
import { getPluralType } from '@just-ai/aimychat-shared/dist/utils';

type Props = {
  setGroupToEdit: (group: OperatorGroupFullDto) => void;
  setGroupToDelete: (group: OperatorGroupFullDto) => void;
};
const GroupsList = React.memo(({ setGroupToEdit, setGroupToDelete }: Props) => {
  const { groups, groupsColors, isPrompterAccess, getPrompters, prompters } = useAppContext();

  useEffect(() => {
    getPrompters();
  }, [getPrompters]);

  const isMobile = useIsMobile();
  return (
    <div className='Groups_groupList_wrapper'>
      <div className='Groups_groupList'>
        {groups.map(group => (
          <div className='Groups_group' key={group.id}>
            <div>
              <span className='font-color-bright d-flex align-items-center gap-8'>
                <span className='Groups_group_groupText' data-test-id={`Groups.Group.name.${group.name}`}>
                  {group.name}
                </span>
                <div
                  className='Groups_group_colorMark'
                  style={{ backgroundColor: groupsColors[group.id]?.backgroundColor }}
                />
                <span className='Groups_group_groupText'>{group.mainOperator?.firstName}</span>
              </span>
              <span data-test-id='Groups.Group.id' className='font-color-primary font-size-12'>
                ID {group.id}
              </span>
            </div>
            <div className='font-color-light-gray'>
              {group.operators.length}{' '}
              {t.decline(
                [
                  t('Settings:Groups:CreateGroup:Count:1'),
                  t('Settings:Groups:CreateGroup:Count:2'),
                  t('Settings:Groups:CreateGroup:Count:3'),
                ],
                group.operators.length
              )}
            </div>
            {isPrompterAccess && (
              <div className='font-color-light-gray'>
                {t(
                  `Plural:Prompters:${getPluralType(group.prompters.length + (group.contextPrompterId ? 1 : 0))}`,
                  group.prompters.length + (group.contextPrompterId ? 1 : 0)
                )}
                {Boolean(group.prompters.length || group.contextPrompterId) && (
                  <>
                    <Icon id={`icon-info-${group.id}`} className='margin-left-1x' name='farInfoCircle' size='sm' />
                    <Tooltip placement='top' target={`icon-info-${group.id}`} delay={{ show: 400, hide: 200 }}>
                      {Boolean(group.contextPrompterId) && (
                        <p>
                          {t('Settings:Prompters:Context')}:
                          <div>
                            <b>{prompters.find(({ id }) => group.contextPrompterId === id)?.name}</b>
                          </div>
                        </p>
                      )}
                      {Boolean(group.prompters.length) && (
                        <p className='margin-bottom-0x'>
                          {t('Settings:Prompters:Helpers')}:
                          {group.prompters.map(({ name }) => (
                            <div key={name}>
                              <b>{name}</b>
                            </div>
                          ))}
                        </p>
                      )}
                    </Tooltip>
                  </>
                )}
              </div>
            )}
            {isMobile ? (
              <div>
                <Button
                  name='farPen'
                  outline
                  size='sm'
                  color='secondary'
                  data-test-id={`Groups.Group.edit.${group.id}`}
                  className='margin-right-2x'
                  onClick={() => setGroupToEdit(group)}
                >
                  <Icon size='sm' name='farPen' />
                  {t('Change')}
                </Button>
                <Button
                  name='farTrashAlt'
                  color='secondary'
                  outline
                  size='sm'
                  data-test-id={`Groups.Group.delete.${group.id}`}
                  onClick={() => setGroupToDelete(group)}
                >
                  <Icon size='sm' name='farTrashAlt' />
                  {t('Delete')}
                </Button>
              </div>
            ) : (
              <div className='flex-row'>
                <IconButton
                  name='farPen'
                  outline
                  color='secondary'
                  size='sm'
                  data-test-id={`Groups.Group.edit.${group.id}`}
                  className='margin-right-2x'
                  onClick={() => setGroupToEdit(group)}
                />
                <IconButton
                  name='farTrashAlt'
                  color='secondary'
                  outline
                  size='sm'
                  data-test-id={`Groups.Group.delete.${group.id}`}
                  onClick={() => setGroupToDelete(group)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <Limits />
    </div>
  );
});

export default GroupsList;
