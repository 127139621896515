export var StateListsName;
(function (StateListsName) {
    StateListsName["CREATED"] = "created";
    StateListsName["ACTIVE"] = "active";
    StateListsName["COMPLETED"] = "completed";
})(StateListsName || (StateListsName = {}));
export var OFLINE_ACTIONS;
(function (OFLINE_ACTIONS) {
    OFLINE_ACTIONS["IN_QUEUE"] = "in_queue";
    OFLINE_ACTIONS["LEAVE_OPEN"] = "leave_open";
    OFLINE_ACTIONS["FINISH"] = "finish";
})(OFLINE_ACTIONS || (OFLINE_ACTIONS = {}));
