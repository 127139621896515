import axios from 'axios';
import { StatisticsApi } from '../../api/client/statistics';
var StatisticsService = /** @class */ (function () {
    function StatisticsService(_a) {
        var accountId = _a.accountId, startDate = _a.startDate, endDate = _a.endDate;
        this.service = new StatisticsApi({}, '', axios);
        this.accountId = accountId;
        this.dateFrom = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0));
        this.dateTo = endDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999));
    }
    return StatisticsService;
}());
export { StatisticsService };
