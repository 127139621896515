export const getInputWidth = (value = 0): number => {
  let result = 32;

  if (value > 99) result = 40;
  if (value > 999) result = 48;
  if (value > 9999) result = 56;
  if (value > 99999) result = 62;
  if (value > 999999) result = 70;

  return result;
};
export const msToSeconds = (ms = 0) => (ms ? Math.floor((ms / 1000) % 60) : 0);
export const msToMinutes = (ms = 0, defaultValue = 0) => (ms ? Math.floor((ms / 1000 / 60) % 60) : defaultValue);
