import { memo, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../../../AppContext';
import { StatisticsSummaryService } from '@just-ai/aimychat-shared/dist/service/Statistics/StatisticsSummaryService';
import { humanizeDuration } from '@just-ai/aimychat-shared/dist/utils';
import { Rest, TABS } from '../../Statistics';
import { Block } from './Block';

type SummaryStatsDto = {
  total: number;
  answered: number;
  notAnswered: number;
  connectDelay?: number;
  firstAnswerDelay: number;
  duration: number;
};

export const Blocks = memo(({ operatorId, groupId, startDate, endDate }: Rest) => {
  const { id } = useAppContext();
  const { tab = TABS.MAIN } = useParams<{ tab: TABS }>();
  const [data, setData] = useState<SummaryStatsDto>();

  const blocks = useMemo(() => {
    if (!data) {
      return [];
    }

    return Object.keys(data).map(label => ({ label, tab, value: data[label] }));
  }, [data, tab]);

  const lines = Math.ceil(blocks.length / 3);

  const statisticsService = useMemo(
    () => new StatisticsSummaryService({ accountId: id, startDate, endDate }),
    [endDate, id, startDate]
  );

  useEffect(() => {
    const getSummary = async () => {
      let data;
      switch (tab) {
        case TABS.MAIN:
          data = await statisticsService.getCommonSummary();
          break;
        case TABS.GROUPS:
          if (groupId) {
            data = await statisticsService.getOperatorGroupSummary(groupId);
          }
          break;
        case TABS.OPERATORS:
          if (operatorId) {
            data = await statisticsService.getOperatorSummary(operatorId);
          }
          break;
        default:
      }

      if (data) {
        data.duration = humanizeDuration(data.duration, true);
        data.firstAnswerDelay = humanizeDuration(data.firstAnswerDelay, true);
        data.connectDelay = humanizeDuration(data.connectDelay, true);
      }
      setData(data);
    };

    getSummary();
  }, [startDate, endDate, tab, statisticsService, groupId, operatorId]);

  return (
    <div className='Blocks_Wrapper'>
      {[...Array(lines)].map((line, index) => (
        <div className='Blocks' key={line}>
          {blocks.slice(index * 3, index * 3 + 3).map(block => (
            <Block key={block.label} {...block} />
          ))}
        </div>
      ))}
    </div>
  );
});
