import { memo, useMemo } from 'react';
import { t } from '../../../localization';
import { useOperatorDataContext } from '../OperatorDataContext';
import { getUserFullName } from '../operatorPlaceHelpers';

export const AboutTab = memo(() => {
  const { chosenChat, chosenChatId } = useOperatorDataContext();
  const preChatFields = useMemo(() => Object.entries(chosenChat?.preChatFields || {}), [chosenChat?.preChatFields]);
  const { phone, email } = chosenChat?.clientInfo || {};

  if (!chosenChatId || !chosenChat) {
    return null;
  }

  return (
    <>
      <div>
        <h5>{t('OperatorPlace: about client id header')}</h5>
        <div data-test-id='ChatInfo.id' className='ChatInfo_info'>
          {chosenChat.clientInfo.id}
        </div>
      </div>
      <div>
        <h5>{t('OperatorPlace: about client name header')}</h5>
        <div data-test-id='ChatInfo.fullName' className='ChatInfo_info'>
          {getUserFullName(chosenChat.clientInfo)}
        </div>
      </div>
      {phone && (
        <div>
          <h5>{t('OperatorPlace: about client phone header')}</h5>
          <div data-test-id='ChatInfo.phone' className='ChatInfo_info'>
            {phone}
          </div>
        </div>
      )}
      {email && (
        <div>
          <h5>{t('OperatorPlace: about client email header')}</h5>
          <div data-test-id='ChatInfo.email' className='ChatInfo_info'>
            {email}
          </div>
        </div>
      )}

      <div className='ChatInfo_divider' />

      <h3>{t('OperatorPlace: fields header')}</h3>
      <div>
        <h5>{t('OperatorPlace: about client chatId header')}</h5>
        <div className='ChatInfo_info'>{chosenChat.context?.botId}</div>
      </div>

      {Boolean(preChatFields.length) && (
        <>
          <div className='ChatInfo_divider' />
          <h3>{t('OperatorPlace: bot info header')}</h3>
          {preChatFields.map(entrie => (
            <div key={entrie[0]}>
              <h5>{entrie[0]}</h5>
              <div data-test-id={`ChatInfo.${entrie[0]}`} className='ChatInfo_info'>
                {entrie[1] as string}
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
});
