import React from 'react';
import { Button, Dropdown, DropdownItem, Icon, Popover, useToggle } from '@just-ai/just-ui';
import { Link } from 'react-router-dom';
import { t } from '../../localization';
import { useAppContext } from '../../AppContext';
import { SetOfflineModal } from '../../modules/Header/SetOfflineModal';

const User = ({ email, name }) => {
  const [openPopover, , , togglePopover] = useToggle(false);
  const [offlineModal, showOfflineModal, hideOfflineModal] = useToggle();

  const { logoutHandler, productInfo } = useAppContext();

  const handleLogout = event => {
    event.preventDefault();
    event.stopPropagation();
    showOfflineModal();
  };

  const moveToProfile = event => {
    event.preventDefault();
    event.stopPropagation();
    window.location.href = `${window.location.origin}/c/profile`;
  };

  return (
    <>
      <Button onClick={togglePopover} id='userMenuPopover' className='navbar-btn user-menu-btn p-0'>
        {name || email}
      </Button>
      {offlineModal && <SetOfflineModal onClose={hideOfflineModal} callback={logoutHandler} />}
      <Popover
        isOpen={openPopover}
        placement='bottom'
        target='userMenuPopover'
        className='header-menu-popover header-menu-popover-user Header_name_popover'
        toggle={togglePopover}
      >
        <Dropdown isOpen toggle={togglePopover}>
          {!productInfo.euroInstance && (
            <>
              <Link to='/c/profile' onClick={moveToProfile} className='text-decoration-none'>
                <DropdownItem className='px-3'>
                  <Icon color='secondary' name='farUserCircle' />
                  <span className='font-size-14'>{t('Header:my account')}</span>
                  <div></div>
                  <span className='font-size-12 font-color-placeholder-default'>{email}</span>
                </DropdownItem>
              </Link>
              <DropdownItem divider />
            </>
          )}

          <Link to='/logout' className='text-decoration-none' onClick={handleLogout}>
            <DropdownItem className='px-3'>
              <Icon color='secondary' name='farSignOut' />
              {t('Header:sign out')}
            </DropdownItem>
          </Link>
        </Dropdown>
      </Popover>
    </>
  );
};

export default React.memo(User);
