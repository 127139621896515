import { useEffect } from 'react';
import { useAppContext } from '../../AppContext';
import { Spinner } from 'reactstrap';
import classNames from 'classnames';
import useStarterIframeOperatorPlace from '../UseStarterIframeOperatorPlace';

export const IFrames = () => {
  const { loadAndRunScript, isLoading } = useStarterIframeOperatorPlace();
  const { activeIframe, iframesOperatorPlace, fetchIframesOperatorPlace } = useAppContext();

  useEffect(() => {
    fetchIframesOperatorPlace();
  }, [fetchIframesOperatorPlace]);

  return (
    <div className='Iframes'>
      <hr className='Iframes_hr' />
      {iframesOperatorPlace.map(iframe => (
        <div
          key={iframe.name}
          className={classNames('Iframes_item', {
            active: iframe.id === activeIframe?.id,
            disabled: isLoading && iframe.id === activeIframe?.id,
          })}
          onClick={() => loadAndRunScript(iframe)}
        >
          {iframe.name}
          {isLoading && iframe.id === activeIframe?.id && <Spinner inline />}
        </div>
      ))}
    </div>
  );
};
