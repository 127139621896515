// tslint:disable
/**
 * Dto set for websocket connection
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var MessageAuthor;
(function (MessageAuthor) {
    MessageAuthor["BOT"] = "BOT";
    MessageAuthor["OPERATOR"] = "OPERATOR";
    MessageAuthor["CLIENT"] = "CLIENT";
    MessageAuthor["SYSTEM"] = "SYSTEM";
})(MessageAuthor || (MessageAuthor = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var MessageStatus;
(function (MessageStatus) {
    MessageStatus["CREATED"] = "CREATED";
    MessageStatus["SENT"] = "SENT";
    MessageStatus["FAILED"] = "FAILED";
})(MessageStatus || (MessageStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var MessageType;
(function (MessageType) {
    MessageType["NEWOPERATORCHAT"] = "NEW_OPERATOR_CHAT";
    MessageType["CLIENTMESSAGE"] = "CLIENT_MESSAGE";
    MessageType["OPERATORMESSAGE"] = "OPERATOR_MESSAGE";
    MessageType["BOTMESSAGE"] = "BOT_MESSAGE";
    MessageType["OPERATORJOINED"] = "OPERATOR_JOINED";
    MessageType["OPERATORLEFT"] = "OPERATOR_LEFT";
    MessageType["CLIENTLEFT"] = "CLIENT_LEFT";
    MessageType["TRANSFERTOOPERATORGROUP"] = "TRANSFER_TO_OPERATOR_GROUP";
    MessageType["OPERATORCHATCOMPLETED"] = "OPERATOR_CHAT_COMPLETED";
    MessageType["CLIENTBLOCKINGBYOPERATOR"] = "CLIENT_BLOCKING_BY_OPERATOR";
    MessageType["CLIENTUNBLOCKINGBYOPERATOR"] = "CLIENT_UNBLOCKING_BY_OPERATOR";
    MessageType["TRANSFERAFTEROPERATORSILENCE"] = "TRANSFER_AFTER_OPERATOR_SILENCE";
})(MessageType || (MessageType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var OperatorChatStatus;
(function (OperatorChatStatus) {
    OperatorChatStatus["CREATED"] = "CREATED";
    OperatorChatStatus["ACTIVE"] = "ACTIVE";
    OperatorChatStatus["COMPLETED"] = "COMPLETED";
})(OperatorChatStatus || (OperatorChatStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var UpdateOperatorStatusAction;
(function (UpdateOperatorStatusAction) {
    UpdateOperatorStatusAction["CLOSEACTIVECHATS"] = "CLOSE_ACTIVE_CHATS";
    UpdateOperatorStatusAction["TRANSFERACTIVECHATSTOQUEUE"] = "TRANSFER_ACTIVE_CHATS_TO_QUEUE";
})(UpdateOperatorStatusAction || (UpdateOperatorStatusAction = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var WsErrorCode;
(function (WsErrorCode) {
    WsErrorCode["UNAUTHORIZED"] = "UNAUTHORIZED";
    WsErrorCode["ACCESSDENIED"] = "ACCESS_DENIED";
    WsErrorCode["OPERATORCHATNOTFOUND"] = "OPERATOR_CHAT_NOT_FOUND";
    WsErrorCode["OPERATORNOTFOUND"] = "OPERATOR_NOT_FOUND";
    WsErrorCode["CLIENTISALREADYBLOCKED"] = "CLIENT_IS_ALREADY_BLOCKED";
    WsErrorCode["TOOMANYCONNECTIONS"] = "TOO_MANY_CONNECTIONS";
})(WsErrorCode || (WsErrorCode = {}));
