import { init } from '@sentry/react';
import { BrowserTracing, Replay } from '@sentry/browser';
import { AppLogger, LogLevel } from '@just-ai/logger';
import { SentryLogTransport } from '@just-ai/logger/build/Transports/SentryLogTransport';

import { isDev } from './utils/pureFunctions';

if (!isDev() && window.env?.SENTRY_DSN && window.env?.SENTRY_RELEASE) {
  init({
    dsn: window.env.SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing(),
      new Replay({
        maskAllText: true,
        blockAllMedia: true,
        networkDetailAllowUrls: ['/restapi', '/api', '/cailapub'],
      }),
    ],
    tracesSampleRate: 1,
    release: window.env.SENTRY_RELEASE,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
  AppLogger.addTransport('sentry', new SentryLogTransport(LogLevel.ERROR));
}
