import React, { useCallback, useMemo, useState } from 'react';
import { JustSelect, Modal, useToggle } from '@just-ai/just-ui';
import { t } from '../../../localization';
import { TransferChatFunction } from '@just-ai/aimychat-shared/dist/operatorPlaceTypes';
import { useOperatorDataContext } from '../OperatorDataContext';

type Props = {
  onClose: () => void;
  onTransfer: TransferChatFunction;
  hideModal: () => void;
};

export const TransferModal = React.memo(({ onClose, onTransfer, hideModal }: Props) => {
  const { groupsForTransfer, operatorGroups } = useOperatorDataContext();
  const [fetching, setFeching, finishFetching] = useToggle();
  const groups = useMemo(
    () => (operatorGroups.length ? operatorGroups : groupsForTransfer),
    [operatorGroups, groupsForTransfer]
  );
  const [groupId, setGroupId] = useState(groups[0]?.id);
  const onlineGroups = useMemo(
    () => groups.filter(group => group.isOnline).map(({ id, name }) => ({ value: id, label: name })),
    [groups]
  );

  const handleConfirm = useCallback(() => {
    setFeching();
    onTransfer({
      groupId,
      callbacks: {
        success: () => {
          hideModal();
          finishFetching();
        },
      },
    });
  }, [groupId, hideModal, finishFetching, setFeching, onTransfer]);

  return (
    <Modal
      onCancelClick={fetching ? undefined : onClose}
      buttonCancelColor='secondary'
      onActionClick={handleConfirm}
      title={t('OperatorPlace: transfer modal title')}
      buttonSubmitText={t('OperatorPlace: transfer modal confirm button')}
      buttonCancelText={t('Cancel')}
      buttonSubmitColor='primary'
      buttonCancelTestId='OperatorPlace.TransferModal.cancel'
      buttonSubmitTestId='OperatorPlace.TransferModal.submit'
      naturalScrollbar
      disableActionButtonAutoFocus
      isOpen
      inProgress={fetching}
    >
      <fieldset className='margin-bottom-2x'>
        <label className='margin-bottom-6x'>{t('OperatorPlace: transfer modal description')}</label>
        <JustSelect
          fullWidth
          position='fixed'
          onChange={value => value && value[0] && setGroupId(value[0] as number)}
          value={groupId}
          options={onlineGroups}
        />
      </fieldset>
    </Modal>
  );
});
