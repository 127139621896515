var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import { getUserLanguage } from '../utils';
var timezone = new Date().getTimezoneOffset();
var axiosWithSessionCheck = axios.create({
    headers: {
        language: getUserLanguage().substring(0, 2).toUpperCase(),
        Product: 'aimychat',
        Timezone: timezone,
    },
});
//@ts-ignore
axiosWithSessionCheck._get = function (url, options) {
    return new Promise(function (resolve, reject) {
        axiosWithSessionCheck
            .get(url, options)
            .then(function (response) {
            response.config = __assign(__assign({}, response.config), options);
            resolve(response);
        })
            .catch(reject);
    });
};
var postPutDeleteInterceptor = function (method) { return function (url, data, config) {
    return new Promise(function (resolve, reject) {
        //@ts-ignore
        axiosWithSessionCheck[method](url, data, config)
            .then(function (response) {
            response.config = __assign(__assign({}, response.config), config);
            resolve(response);
        })
            .catch(reject);
    });
}; };
//@ts-ignore
axiosWithSessionCheck._post = postPutDeleteInterceptor('post');
//@ts-ignore
axiosWithSessionCheck._put = postPutDeleteInterceptor('put');
//@ts-ignore
axiosWithSessionCheck._delete = postPutDeleteInterceptor('delete');
export var setAxiosHeadersLanguage = function (val) {
    axiosWithSessionCheck.defaults.headers.language = val;
};
export var updateLanguage = function (language) {
    return setAxiosHeadersLanguage((language === null || language === void 0 ? void 0 : language.substr(0, 2).toUpperCase()) || 'en');
};
export { axiosWithSessionCheck as axios };
