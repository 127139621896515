import React, { useCallback } from 'react';
import classNames from 'classnames';
import Links from '../../components/Links/Links';
import { Button, Icon, SwitchButton } from '@just-ai/just-ui';
import { t } from '../../localization';
import { useOperatorDataContext } from '../OperatorPlace/OperatorDataContext';
import { SetTrueCallback } from '@just-ai/aimychat-shared/dist/utils/hooks';

type Props = {
  isShown: boolean;
  onLogout: () => void;
  name?: string;
  email?: string;
  isAdmin: boolean;
  hasNoAccess: boolean;
  showOfflineModal: SetTrueCallback;
  isIframeEnabled: boolean;
};

export const HeaderMenu = React.memo(
  ({ name, email, isShown, onLogout, isAdmin, showOfflineModal, hasNoAccess = false, isIframeEnabled }: Props) => {
    const { active, setOnlineStatusOperator, isOnline } = useOperatorDataContext();

    const onToggleOnline = useCallback(async () => {
      if (hasNoAccess) {
        return;
      }
      if (isOnline === false) {
        setOnlineStatusOperator({ isOnline: true });
      } else if (active.length > 0) {
        showOfflineModal();
      } else {
        setOnlineStatusOperator({ isOnline: false });
      }
    }, [active?.length, hasNoAccess, isOnline, setOnlineStatusOperator, showOfflineModal]);

    return (
      <div className={classNames('Header_menu', { shown: isShown })}>
        <div>
          <div className='Header_menu_organization'></div>
          <div className='Header_menu_divider' />
          {isAdmin && (
            <div className='Header_menu_links'>
              <Links isAdmin={isAdmin} isIframeEnabled={isIframeEnabled} />
            </div>
          )}
          <div className='Header_menu_divider' />
          <div className='Header_menu_name'>
            <Icon data-test-id='Header.menu' name='farUserCircle' color='secondary' />
            {name}
            {email && <div className='Header_menu_name_email'>{email}</div>}
          </div>
          <a href={t('Header:Documentation:Link')} className='Header_menu_help' target='_blank' rel='noreferrer'>
            <Icon data-test-id='Header.help' color='secondary' name='farQuestionCircle' />
            {t('Header:documentation')}
          </a>
        </div>
        <div>
          <div className='Header_menu_logout'>
            <Button data-test-id='Header.menu.logout' flat withoutPadding color='primary' onClick={onLogout}>
              <Icon color='secondary' name='farSignOut' />
              {t('Header:sign out')}
            </Button>
          </div>
          <div className='Header_menu_divider' />
          <div className='Header_menu_onlineStatus'>
            <SwitchButton
              size='sm'
              labelPosition='right'
              onChange={onToggleOnline}
              offLabel={t('Offline status')}
              onLabel={t('Online status')}
              id='ToggleOnline'
              value={hasNoAccess ? false : isOnline}
            />
          </div>
        </div>
      </div>
    );
  }
);
