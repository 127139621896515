import { IconButton } from '@just-ai/just-ui';
import { memo, useMemo } from 'react';
import { OperatorGroupFullDto, PrompterDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';
import { useAppContext } from '../../../../AppContext';
import { t } from '../../../../localization';

type Props = PrompterDto & { groups: OperatorGroupFullDto[]; openEditModal: () => void; openRemoveModal: () => void };

export const PrompterItem = memo(({ name, id, groups = [], openEditModal, openRemoveModal }: Props) => {
  const { groupsColors } = useAppContext();
  const uniqGroups = useMemo(() => groups.filter((value, index, self) => self.indexOf(value) === index), [groups]);

  return (
    <div className='Prompters_container__item' key={id}>
      <div className='Prompters_container__item__description'>
        <small>
          <b>{name} </b>
        </small>
        {!uniqGroups.length && (
          <p>
            <small>{t('Setings:Prompters:NotConnected')}</small>
          </p>
        )}
        <div className='Prompters_container__item__groups'>
          {uniqGroups.map(group => (
            <div
              data-test-id={`Operators.Operator.group.${group.id}`}
              key={group.id}
              className='Operators_operator_group'
              style={groupsColors[group.id]}
            >
              {group.name}
            </div>
          ))}
        </div>
      </div>
      <div>
        <IconButton
          data-test-id={`edit-prompter-${name}`}
          name='farPen'
          flat
          color='secondary'
          size='sm'
          outline
          onClick={openEditModal}
        />
        <IconButton
          name='farTrashAlt'
          data-test-id={`remove-prompter-${name}`}
          flat
          color='secondary'
          size='sm'
          outline
          onClick={openRemoveModal}
        />
      </div>
    </div>
  );
});
