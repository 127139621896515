import { OperatorWithGroupsDto } from '@just-ai/aimychat-shared/dist/api/client/aimychat';

export function filterOperator(
  filter: string,
  operator: OperatorWithGroupsDto,
  choosenArray?: number[],
  noGroupCheck?: boolean
): boolean {
  const { groups, id, login, email, firstName, lastName } = operator;
  const trimmedFilter = filter.trim().toLowerCase();
  if (!trimmedFilter) return true;
  if (choosenArray && choosenArray.includes(id)) return true;
  if (!noGroupCheck && groups?.find(group => group.name?.toLowerCase().includes(trimmedFilter))) return true;
  if (login?.toLowerCase().includes(trimmedFilter)) return true;
  if (email?.toLowerCase().includes(trimmedFilter)) return true;
  if (firstName?.toLowerCase().includes(trimmedFilter)) return true;
  if (firstName?.trim() && lastName?.trim() && `${firstName} ${lastName}`.toLowerCase().includes(trimmedFilter))
    return true;
  return false;
}

const COLORS = [
  'var(--green',
  'var(--blue',
  'var(--red',
  'var(--yellow',
  'var(--grey',
  'var(--aimylogic',
  'var(--aimyvoice',
  'var(--primary',
];

export function getBackColorFromId(id: number) {
  return COLORS[id % COLORS.length] + '-200)';
}

export function getDargeBackColorFromId(id: number) {
  return COLORS[id % COLORS.length] + '-600)';
}

export function getColorFromId(id: number) {
  return COLORS[id % COLORS.length] + '-800)';
}
